var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, Input, Select } from "@g4p/react-styleguide-library";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppContext, UserType } from "../../../../services/appContext";
import { getLocaleFromLocalStorage, getSupportedLanguages, setLocaleInLocalStorage } from "../../../../services/appStorage";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import { useTenantContext } from "../../../../services/tenantContext";
var LoginForm = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var form = Form.useForm()[0];
    var currentLang = getLocaleFromLocalStorage();
    var configurations = useAppContext().configurations;
    var tenant = useTenantContext().tenant;
    var searchParams = useSearchParams()[0];
    var ddeParam = searchParams.get("ddeParam");
    var ddeDigitalExternalChannelLoginPageLink = configurations["ddeDigitalExternalChannelLoginPageLink_" + tenant];
    if (ddeDigitalExternalChannelLoginPageLink && ddeParam) {
        ddeDigitalExternalChannelLoginPageLink = ddeDigitalExternalChannelLoginPageLink + "&ddeParam=" + encodeURI(ddeParam);
    }
    var languageOptions = useMemo(function () { return getSupportedLanguages().map(function (lang) { return ({ value: lang, label: formatMessage("dde.login.language.label.".concat(lang)) }); }); }, [formatMessage, getSupportedLanguages]);
    var initialValues = __assign(__assign({}, props.initialValues), { language: languageOptions.find(function (item) { return item.value === props.initialValues.language; }) });
    var handleLanguageChange = function (selectOption) {
        var selectedOption = selectOption || languageOptions.find(function (item) { return item.value === props.initialValues.language; }) || languageOptions[0];
        form.setFieldsValue({ language: selectedOption });
        props.onLanguageChange(selectedOption.value);
    };
    var onFinish = function (values) {
        setLocaleInLocalStorage(currentLang);
        props.onSubmit(values);
    };
    useEffect(function () {
        form.setFieldValue("language", languageOptions.find(function (item) { return item.value === currentLang; }));
    }, [form, currentLang, formatMessage, languageOptions]);
    return (_jsxs(Form, __assign({ initialValues: initialValues, onFinish: onFinish, autoComplete: "off", form: form }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "language" }, { children: formatMessage("dde.login.language.label") })), _jsx(Form.Item, __assign({ rules: [{ required: true, message: formatMessage("dde.validation.common.requiredField") }], name: "language" }, { children: _jsx(Select, { placeholder: formatMessage("dde.login.language.label"), onChange: handleLanguageChange, options: languageOptions, isClearable: false, isSearchable: false }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "username" }, { children: formatMessage("dde.login.username.label") })), _jsx(Form.Item, __assign({ rules: [{ required: true, message: formatMessage("dde.validation.common.requiredField") }], name: "username" }, { children: _jsx(Input, { onInput: function (e) {
                        if (props.userType === UserType.BANKER) {
                            e.target.value = e.target.value.toUpperCase();
                        }
                    }, placeholder: formatMessage("dde.login.username.label") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "password" }, { children: formatMessage("dde.login.password.label") })), _jsx(Form.Item, __assign({ rules: [{ required: true, message: formatMessage("dde.validation.common.requiredField") }], name: "password" }, { children: _jsx(Input.Password, { placeholder: formatMessage("dde.login.password.label") }) })), _jsx(Form.Item, { children: _jsx(Button, __assign({ type: "primary", htmlType: "submit" }, { children: formatMessage("dde.login.login.btn") })) }), props.userType === UserType.CLIENT && ddeDigitalExternalChannelLoginPageLink && (_jsx(Form.Item, { children: _jsx(Button, __assign({ type: "link", htmlType: "button", onClick: function () {
                        window.location.href = ddeDigitalExternalChannelLoginPageLink;
                    } }, { children: formatMessage("dde.login.digital.external.channel.page.link") })) }))] })));
};
export default LoginForm;
