var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NotificationType } from "../../../common/NotificationIcon/model";
import { executeAxios } from "../../../../services/axiosHook";
export var filterTaskConfig = {
    url: "/dde/usermanagement/banker/getTaskList",
    method: "POST",
};
export var editTaskConfig = {
    url: "/dde/settings/task/editTask",
    method: "POST",
};
export var lockTaskApi = {
    url: "/dde/settings/task/lockTask",
    method: "POST",
};
export var unlockTaskApi = {
    url: "/dde/settings/task/unlockTask",
    method: "POST",
};
export var assignedToOptionsApi = {
    url: "/dde/usermanagement/banker/getAssignedToOptions",
    method: "POST",
};
export function lockAndAssignTask(notificationType, taskId, setCurrentLockedTaskId, username, navigate) {
    executeAxios({
        params: __assign(__assign({}, lockTaskApi), { data: { taskId: taskId } }),
        onSuccess: function (lockResponse) {
            setCurrentLockedTaskId(taskId);
            if (username === (lockResponse === null || lockResponse === void 0 ? void 0 : lockResponse.assignedTo)) {
                goToTaskId(navigate, notificationType, taskId, false);
            }
            else {
                assignTask(username, taskId, navigate, notificationType);
            }
        },
        onFail: function (resp) { return goToTaskId(navigate, notificationType, taskId, true); },
    });
}
function assignTask(username, taskId, navigate, notificationType) {
    executeAxios({
        params: __assign(__assign({}, editTaskConfig), { data: {
                assignedTo: username.toUpperCase(),
                assignedToMe: true,
                taskId: taskId,
            } }),
        onSuccess: function () {
            goToTaskId(navigate, notificationType, taskId, false);
        },
        onFail: function (resp) {
            goToTaskId(navigate, notificationType, taskId, true);
        },
    });
}
var goToTaskId = function (navigate, notificationType, taskId, isViewMode) {
    if (NotificationType.ApproveClientTaskCreated === notificationType) {
        navigate("/app/usermanagement/createuser/temporaryClientUser/" + taskId, { state: { isViewMode: isViewMode } });
    }
    else if (NotificationType.ClientCorrectionNeededTaskCreated === notificationType) {
        navigate("/app/usermanagement/createuser/changeTemporaryClientUser/" + taskId, { state: { isViewMode: isViewMode } });
    }
    else if (NotificationType.ChangeClientTaskCreated === notificationType) {
        navigate("/app/usermanagement/manageuser/temporaryClientUser/" + taskId, { state: { isViewMode: isViewMode } });
    }
    else if (NotificationType.ChangeClientCorrectionNeededTaskCreated === notificationType) {
        navigate("/app/usermanagement/manageuser/changeTemporaryClientUser/" + taskId, { state: { isViewMode: isViewMode } });
    }
};
