var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, Input } from "@g4p/react-styleguide-library";
import { useEffect, useState } from "react";
import { UserType } from "../../../../services/appContext";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import flags from 'react-phone-number-input/flags';
import { getCountryCode, getPhoneNumberPrefix, phoneInputChange } from "../services/phoneNumber";
import "react-phone-number-input/style.css";
import { PhoneInputStyled } from "../style";
import { useTenantContext } from "../../../../services/tenantContext";
export var ForgottenPasswordForm = function (props) {
    var form = Form.useForm()[0];
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(false), disabledSave = _a[0], setDisabledSave = _a[1];
    var requiredRule = { required: true, message: formatMessage("dde.usermanagement.registerClient.requiredError") };
    var defaultCountry = useTenantContext();
    var _b = useState(getCountryCode(props.initialValues.phoneNumber
        ? props.initialValues.phoneNumber : formatMessage("dde.forgottenPassword.phoneNumberPlaceholder"), defaultCountry.tenant)), countryCode = _b[0], setCountryCode = _b[1];
    var _c = useState(getPhoneNumberPrefix(props.initialValues.phoneNumber ?
        props.initialValues.phoneNumber : formatMessage("dde.forgottenPassword.phoneNumberPlaceholder"))), phonePrefix = _c[0], setPhonePrefix = _c[1];
    useEffect(function () {
        form.setFieldValue("phoneNumber", phonePrefix);
    });
    var handleFormSubmit = function (values) {
        setDisabledSave(true);
        props.onSubmit(values);
    };
    var validateMessages = {
        required: formatMessage("dde.changePasswordForm.requiredError"),
        types: {
            email: formatMessage("dde.forgottenPassword.invalidemail"),
        },
    };
    var countryCodeChange = function (value) {
        setCountryCode(value);
    };
    var phonePrefixChange = function (value) {
        form.setFieldValue("phoneNumber", value);
        setPhonePrefix(value);
    };
    var isPhoneNumberValid = function (phoneNumber) {
        var phonePatternRegex = RegExp("^[+]?\\d{12,15}$");
        return phonePatternRegex.test(phoneNumber);
    };
    var validatePhoneNumber = function (rule, value, callback) {
        var _a;
        if (countryCode == "BG" || countryCode == "SK") {
            if (!isPhoneNumberValid(value)) {
                callback(formatMessage("dde.forgottenPassword.invalidPhoneNumber"));
            }
            callback();
        }
        else {
            if (!((_a = parsePhoneNumber(value, countryCode)) === null || _a === void 0 ? void 0 : _a.isValid())) {
                callback(formatMessage("dde.forgottenPassword.invalidPhoneNumber"));
            }
            callback();
        }
    };
    return (_jsxs(Form, __assign({ form: form, initialValues: props.initialValues, onFinish: handleFormSubmit, validateMessages: validateMessages }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "email" }, { children: formatMessage("dde.forgottenPassword.email") })), _jsx(Form.Item, __assign({ rules: [{ required: true }, { type: "email" }], name: "email" }, { children: _jsx(Input, { id: "email", placeholder: formatMessage("dde.forgottenPassword.email") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "userName" }, { children: formatMessage("dde.forgottenPassword.userName") })), _jsx(Form.Item, __assign({ rules: [{ required: true }], name: "userName" }, { children: _jsx(Input, { maxLength: 50, onInput: function (e) {
                        if (props.userType === UserType.BANKER) {
                            e.target.value = e.target.value.toUpperCase();
                        }
                    }, placeholder: formatMessage("dde.forgottenPassword.userName") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "phoneNumber" }, { children: formatMessage("dde.forgottenPassword.phoneNumber") })), _jsxs(PhoneInputStyled, { children: [_jsx(PhoneInput, { flags: flags, international: true, countryCallingCodeEditable: false, defaultCountry: countryCode, name: "countrySelect", rules: { required: true }, onChange: phonePrefixChange, onCountryChange: countryCodeChange }), _jsx(Form.Item, __assign({ name: "phoneNumber", rules: [requiredRule, { validator: validatePhoneNumber }] }, { children: _jsx(Input, { id: "phoneNumber", maxLength: 15, onChange: function (event) {
                                phoneInputChange(event, form, phonePrefix);
                            } }) }))] }), _jsx(Button, __assign({ type: "primary", disabled: disabledSave, htmlType: "submit" }, { children: formatMessage("dde.changePasswordForm.submitBtn") }))] })));
};
