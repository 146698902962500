var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FolderFilled, FolderOpenFilled } from "@ant-design/icons";
import { ShowIcon, IndividualIcon, SignatureIcon, UploadIcon, JobIcon } from "@g4p/react-styleguide-library";
import { Typography } from "antd";
import { useFormatMessage } from "../../services/formatMessageHook";
import styled, { css } from "styled-components";
import { DokuStatus } from "../../pages/admin/DokuBoxList/services/domain";
var CircleIconWrapperStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\theight: 30px;\n\twidth: 30px;\n\tcolor: white;\n\tborder-radius: 50%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\t", "\n"], ["\n\theight: 30px;\n\twidth: 30px;\n\tcolor: white;\n\tborder-radius: 50%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\t", "\n"])), function (props) {
    return props.$client
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t\t\tbackground: #007a91;\n\t\t\t  "], ["\n\t\t\t\t\tbackground: #007a91;\n\t\t\t  "]))) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\t\t\tbackground: #000000;\n\t\t\t  "], ["\n\t\t\t\t\tbackground: #000000;\n\t\t\t  "])));
});
var InlineTitleStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tdisplay: inline-flex;\n\talign-items: center;\n\n\tspan.ant-typography {\n\t\tmargin-bottom: 0;\n\t\tmargin-left: 10px;\n\t\ttext-transform: uppercase;\n\t\tfont-weight: bold;\n\t\t\tfont-size: 14px;\n\t}\n"], ["\n\tdisplay: inline-flex;\n\talign-items: center;\n\n\tspan.ant-typography {\n\t\tmargin-bottom: 0;\n\t\tmargin-left: 10px;\n\t\ttext-transform: uppercase;\n\t\tfont-weight: bold;\n\t\t\tfont-size: 14px;\n\t}\n"])));
var HEIGHT = 16;
var StatusTitle = function (props) {
    var _a;
    var formatMessage = useFormatMessage().formatMessage;
    var statusText = formatMessage("dde.catalogue.dokustatus." + ((_a = props.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()));
    switch (props.status) {
        case DokuStatus.ARCHIVED:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_NEGOTIATION_BANK:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_BANK:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_BANK_1:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_BANK_2:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_BANK_3:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UPLOADED_BANK:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_NEGOTIATION_CLIENT:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(IndividualIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_CLIENT:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(ShowIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_CLIENT_1:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(ShowIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_CLIENT_2:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(ShowIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_REVIEW_CLIENT_3:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(ShowIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UPLOADED_CLIENT:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(IndividualIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.UNDER_ARCHIVING:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(FolderFilled, { color: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_ARCHIVAL:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(FolderOpenFilled, { color: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_BANK_FEEDBACK:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(JobIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_BANK_SIGNATURE:
        case DokuStatus.WAITING_BANK_SIGNATURE_AUX:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(SignatureIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_BANK_SIGNATURE_2:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(SignatureIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_BANK_UPLOAD:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, { children: _jsx(UploadIcon, { fill: "#fff", height: HEIGHT }) }) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_CLIENT_ACKNOWLEDGEMENT:
        case DokuStatus.WAITING_CLIENT_FEEDBACK:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(IndividualIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_CLIENT_SIGNATURE:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(SignatureIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        case DokuStatus.WAITING_CLIENT_UPLOAD:
            return (_jsxs(InlineTitleStyled, { children: [_jsx("div", { children: _jsx(CircleIconWrapperStyled, __assign({ "$client": true }, { children: _jsx(UploadIcon, { fill: "#fff", height: HEIGHT }) })) }), _jsx(Typography.Text, { children: statusText })] }));
        default:
            return _jsx(_Fragment, {});
    }
};
export default StatusTitle;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
