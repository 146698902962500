import { isObject, isString } from "lodash";
import { useEffect } from "react";
import { errorLogApi } from "./services/errorLogApi";
window.logs = {
    error: [],
    info: [],
    debug: [],
};
window.onerror = function (message) {
    window.logs.error.push(message);
};
["error", "info", "debug"].forEach(function (fn) {
    var originalFn = typeof console[fn] === "function" ? console[fn].bind(console) : function () { };
    console[fn] = function () {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        (_a = window.logs[fn]).push.apply(_a, args);
        return originalFn.apply(void 0, args);
    };
});
var resetCustomWindowLogs = function () {
    window.logs = {
        error: [],
        info: [],
        debug: [],
    };
};
var logsPresent = function () {
    return window.logs.error.length > 0 || window.logs.info.length > 0 || window.logs.debug.length > 0;
};
var ErrorHandler = function (props) {
    useEffect(function () {
        var interval = setInterval(function () {
            if (logsPresent()) {
                errorLogApi({ data: obfuscateErrorData(window.logs) });
            }
            resetCustomWindowLogs();
        }, 5000);
        return function () {
            clearInterval(interval);
        };
    }, []);
    return props.children;
};
var MAX_DEPTH = 100;
var SENSITIVE_FIELD_NAMES_LOWERCASE = [
    "authorization",
    "password",
    "token",
    "refreshToken",
    "pin",
    "filecontent",
    "securitypin",
    "passwordforcommunication",
    "authenticationrequestid",
    "current",
    "updated",
    "repeat",
];
var obfuscateErrorData = function (error) {
    if (!error) {
        return "null";
    }
    var obfuscated = obfuscateNode(error, 0);
    return !obfuscated ? "****" : obfuscated;
};
var obfuscateNode = function (node, depth) {
    if (depth > MAX_DEPTH || !node) {
        return null;
    }
    else if (isObject(node)) {
        return obfuscateObjectNode(node, depth);
    }
    else if (isString(node)) {
        return SENSITIVE_FIELD_NAMES_LOWERCASE.some(function (fieldName) { return node.toLowerCase().includes('"' + fieldName + '"') || node.toLowerCase().includes('"' + fieldName + "\\"); })
            ? null
            : node;
    }
    return node;
};
var obfuscateObjectNode = function (objectNode, depth) {
    var fieldNamesToObfuscateInThisObject = [];
    Object.keys(objectNode).forEach(function (key) {
        var value = objectNode[key];
        if (value) {
            var obfuscated = obfuscateNode(value, depth + 1);
            if (!obfuscated || (key && SENSITIVE_FIELD_NAMES_LOWERCASE.includes(key.toLowerCase()))) {
                fieldNamesToObfuscateInThisObject.push(key);
            }
        }
    });
    fieldNamesToObfuscateInThisObject.forEach(function (key) {
        objectNode[key] = "****";
    });
    return objectNode;
};
export default ErrorHandler;
