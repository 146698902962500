var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal, Spinner } from "@g4p/react-styleguide-library";
import { useTranslationsContext } from "../../../services/translationsContext";
import { useFormatMessage } from "../../../services/formatMessageHook";
export function TabLock(props) {
    var formatMessage = useFormatMessage().formatMessage;
    var isVisible = props.isVisible;
    var translations = useTranslationsContext().translations;
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ width: 1200, open: isVisible, title: translations ? formatMessage("TabLock.modalTitle") : "", closable: false, bodyStyle: { overflowY: "scroll" }, footer: null }, { children: [translations && _jsx("span", { children: formatMessage("TabLock.anotherOpenTab.label") }), !translations && _jsx(Spinner, {})] })) }));
}
