var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useState } from "react";
import { axiosInstance } from "../../services/axiosHook";
var usersByUsernamesWithFullnameApiConfig = {
    method: "post",
    url: "dde/usermanagement/getUsersByUsernamesWithFullname",
};
export var usersByUsernamesWithFullnameApi = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    return {
        usersByUsernamesWithFullnameLoading: loading,
        getUsersByUsernamesWithFullname: useCallback(function (apiConfig) {
            setLoading(true);
            return axiosInstance.request(__assign(__assign({}, usersByUsernamesWithFullnameApiConfig), apiConfig)).finally(function () {
                setLoading(false);
            });
        }, []),
    };
};
