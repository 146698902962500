var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, showErrorMessage, showSuccessMessage } from "@g4p/react-styleguide-library";
import LoginWrapper from "../../common/Login/components/LoginWrapper";
import { useState } from "react";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { changePasswordApi, mapToChangePasswordApiConfig } from "./services/changePasswordApi";
import { useAppContext } from "../../../services/appContext";
var ChangePasswordDialog = function (props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var _a = useState({
        currentPassword: "",
        newPassword: "",
        repeatedPassword: "",
    }), initialValues = _a[0], setInitialValues = _a[1];
    var formatMessage = useFormatMessage().formatMessage;
    var form = Form.useForm()[0];
    var handleCancel = function () {
        setInitialValues({ currentPassword: "", newPassword: "", repeatedPassword: "" });
        setIsVisible(false);
        form.resetFields();
        props.onClose();
    };
    var user = useAppContext().user;
    function handleSubmit(formValues) {
        executeAxios({
            params: __assign(__assign({}, changePasswordApi), mapToChangePasswordApiConfig(formValues, user.username || '', "SETTINGS")),
            onSuccess: function () {
                showSuccessMessage({
                    title: formatMessage("dde.changePasswordDlg.successTitle"),
                    message: formatMessage("dde.changePasswordDlg.successMsg"),
                });
                form.resetFields();
                setIsVisible(false);
                props.onClose();
            },
            onLoading: function () { },
            onFail: function (resp) {
                var _a;
                showErrorMessage({
                    title: formatMessage("dde.changePasswordDlg.errorTitle"),
                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
                setIsVisible(true);
            },
        });
    }
    return (_jsx(_Fragment, { children: isVisible && (_jsx("div", __assign({ style: { position: "fixed", inset: 0, background: "white", zIndex: 999 } }, { children: _jsx(LoginWrapper, __assign({ title: formatMessage("dde.changePasswordDlg.title"), showBackButton: true, onBackActionClick: handleCancel, goBackLabel: formatMessage("dde.changePasswordDlg.button.GoBack"), helpTooltipMessage: "".concat(formatMessage("dde.login.help.doyouneedsupport.client.passwordchange"), " ").concat(formatMessage("dde.login.help.doyouneedsupport.client.support.phonenumber")) }, { children: _jsx(ChangePasswordForm, { form: form, onSubmit: function (formValues) {
                        handleSubmit(formValues);
                    }, initialValues: initialValues }) })) }))) }));
};
export default ChangePasswordDialog;
