var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FeedbackMessage } from "@g4p/react-styleguide-library";
import { useEffect } from "react";
import { useAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import styled from "styled-components";
var config = {
    url: "gateway/noauth/logError",
    method: "post",
    data: { log: "General error caused by invalid url." },
};
var GeneralErrorStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n"], ["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n"])));
var GeneralError = function (_a) {
    var _b = _a.textCode, textCode = _b === void 0 ? "dbn.invalid.url" : _b;
    var formatMessage = useFormatMessage().formatMessage;
    var fetchData = useAxios(config).fetchData;
    useEffect(function () {
        fetchData();
    }, [fetchData]);
    return (_jsx(GeneralErrorStyled, { children: _jsx(FeedbackMessage, { active: true, title: formatMessage("dde.error.errorTitle"), description: formatMessage(textCode), blurFullPage: true, type: "alert" }) }));
};
export default GeneralError;
var templateObject_1;
