var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { axiosInstance } from "./axiosHook";
var defaultApiConfig = { url: "gateway/noauth/logError", method: "post" };
export function errorLogApi(apiConfig) {
    return axiosInstance.request(__assign(__assign({}, defaultApiConfig), apiConfig));
}
