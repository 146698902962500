var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BackIcon, Button, Col, Divider, ForthIcon, Row } from "@g4p/react-styleguide-library";
import { Typography } from "antd";
import { isIgnorePortalBlock } from "../../../../services/appStorage";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import styled from "styled-components";
import { useAppContext } from "../../../../services/appContext";
import PortalBlock from "../../GenericPortal/PortalBlock";
var BackButtonStyled = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tcolor: black;\n\tmargin-bottom: 20px;\n"], ["\n\tcolor: black;\n\tmargin-bottom: 20px;\n"])));
var LoginContentStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmargin-top: 20px;\n\tmax-width: 600px;\n\n\t@media screen and (max-width: 800px) {\n\t\twidth: 100%;\n\t\tmax-width: 100%;\n\t\tmargin-left: 0;\n\t}\n"], ["\n\tmargin-top: 20px;\n\tmax-width: 600px;\n\n\t@media screen and (max-width: 800px) {\n\t\twidth: 100%;\n\t\tmax-width: 100%;\n\t\tmargin-left: 0;\n\t}\n"])));
var AdditionalCtaStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tpadding-bottom: 40px;\n\tdisplay: flex;\n\talign-items: flex-start;\n\n\tbutton {\n\t\tmargin-right: 40px;\n\t}\n"], ["\n\tpadding-bottom: 40px;\n\tdisplay: flex;\n\talign-items: flex-start;\n\n\tbutton {\n\t\tmargin-right: 40px;\n\t}\n"])));
var LoginBoxStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tpadding-top: 40px;\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tflex-direction: column;\n"], ["\n\tpadding-top: 40px;\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tflex-direction: column;\n"])));
var LoginBox = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var portalBlocked = useAppContext().portalBlocked;
    return portalBlocked && !isIgnorePortalBlock() ? (_jsx(PortalBlock, {})) : (_jsxs(LoginBoxStyled, { children: [_jsx(PortalBlock, {}), _jsxs("div", { children: [!!props.showBackButton && (_jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsx(Col, __assign({ ds: 12, mb: 12 }, { children: _jsxs(BackButtonStyled, __assign({ type: "text", onClick: props.onBackActionClick }, { children: [_jsx(BackIcon, {}), " ", props.goBackLabel ? props.goBackLabel : formatMessage("dde.login.box.GoBackToLogin")] })) })) }))), _jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsxs(Col, __assign({ ds: 12 }, { children: [props.title && _jsx(Typography.Title, __assign({ level: 1 }, { children: props.title })), props.subtitle && _jsx(Typography.Title, __assign({ level: 4 }, { children: props.subtitle }))] })) })), _jsx(LoginContentStyled, __assign({ style: props.fullWidth ? { maxWidth: "100%" } : {} }, { children: _jsxs(Row, __assign({ maxWidth: "100%", style: { marginLeft: 0 } }, { children: [_jsxs(Col, __assign({ ds: props.fullWidth ? 12 : 6 }, { children: [!!props.description && (_jsx(Typography.Text, { children: _jsx(_Fragment, { children: props.description }) })), _jsx("div", __assign({ style: { marginTop: "20px" } }, { children: props.children }))] })), !!props.rightDescription && (_jsx(Col, __assign({ ds: 6 }, { children: _jsx(_Fragment, { children: props.rightDescription }) })))] })) }))] }), _jsx("div", { children: props.actions && props.actions.length > 0 && (_jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsxs(Col, __assign({ ds: 12 }, { children: [_jsx(Divider, {}), _jsx(AdditionalCtaStyled, { children: props.actions &&
                                    props.actions.map(function (item, index) { return (_jsx(Button, __assign({ type: "text", onClick: item.onActionClick, disabled: item.disabled }, { children: _jsxs(_Fragment, { children: [item.actionLabel, " ", _jsx(ForthIcon, {})] }) }), index)); }) })] })) }))) })] }));
};
export default LoginBox;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
