var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Col } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { CookieDetailsBody } from "./styles";
var CookiesDetails = function () {
    var formatRichTextMessage = useFormatMessage().formatRichTextMessage;
    return (_jsx(CookieDetailsBody, { children: _jsxs(Col, __assign({ ds: 12 }, { children: [formatRichTextMessage("dde.cookiesConsent.aboutPage.header"), formatRichTextMessage("dde.cookiesConsent.aboutPage.body")] })) }));
};
export default CookiesDetails;
