import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from "@g4p/react-styleguide-library";
import { useNavigate } from "react-router-dom";
import { useFormatMessage } from "../../services/formatMessageHook";
var ExitingDokuBoxPopUp = function (props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var navigate = useNavigate();
    var formatMessage = useFormatMessage().formatMessage;
    var handleClose = function () {
        setIsVisible(false);
    };
    var handleEXIT = function () {
        navigate("/app/dokuboxlist/");
    };
    return (_jsx(_Fragment, { children: _jsx(Modal, { title: props.title, open: isVisible, onOk: handleClose, onCancel: handleEXIT, okText: formatMessage("dde.manageDokuBox.header.ExitingDokuBoxPopUp.okText"), cancelText: formatMessage("dde.manageDokuBox.header.ExitingDokuBoxPopUp.cancelText"), closable: false }) }));
};
export default ExitingDokuBoxPopUp;
