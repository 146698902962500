var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BackIcon, Button, Col, Row, showErrorMessage } from "@g4p/react-styleguide-library";
import { PageHeader } from "../../../../../components/PageHeader/PageHeader";
import { MainContainerStyled } from "../../../../../styles/styles";
import { executeAxios } from "../../../../../services/axiosHook";
import { useEffect, useState } from "react";
import { useFormatMessage } from "../../../../../services/formatMessageHook";
import { useNavigate } from "react-router-dom";
import { clientLogout } from "../../services/clientLogoutApi";
import { removeAuthFromLocalStorage } from "../../../../../services/appStorage";
import { useTenantContext } from "../../../../../services/tenantContext";
import { useAppContext } from "../../../../../services/appContext";
var ContractProcessError = function () {
    var _a = useState(false), loadingWaitProcessToFinish = _a[0], setLoadingWaitProcessToFinish = _a[1];
    var navigate = useNavigate();
    var formatMessage = useFormatMessage().formatMessage;
    var _b = useState("in_progress"), callbackStatus = _b[0], setCallBackStatus = _b[1];
    var tenant = useTenantContext().tenant;
    var _c = useAppContext(), isLoggedIn = _c.isLoggedIn, setIsLoggedIn = _c.setIsLoggedIn;
    var showGeneralError = function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    };
    useEffect(function () {
        if (isLoggedIn) {
            executeAxios({
                params: __assign({}, clientLogout),
                onSuccess: function () {
                    console.log("logout");
                    removeAuthFromLocalStorage();
                    setIsLoggedIn(false);
                },
            });
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: formatMessage("dde.usermanagement.contractProcessError.title") }), _jsx(MainContainerStyled, { children: _jsxs("div", { children: [_jsx("h1", { children: formatMessage("dde.error.generalError") }), _jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsx(Col, __assign({ ds: 12, mb: 12 }, { children: _jsxs(Button, __assign({ type: "text", onClick: function () {
                                        navigate("/index".concat(tenant, ".html"));
                                    } }, { children: [_jsx(BackIcon, {}), " ", formatMessage("dde.link.backToLogin")] })) })) }))] }) })] }));
};
export default ContractProcessError;
