var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Form, Input, Modal, Select, SendIcon, showErrorMessage, showSuccessMessage, Spinner } from "@g4p/react-styleguide-library";
import moment from "moment";
import { useEffect, useState } from "react";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import SaveDokuMessageWarningDlg from "./services/components/SaveDokuMessageWarningDlg";
import { getMessagesApiConfig, saveReadMessages } from "./services/dokuMessagesApi";
import { UserType } from "./services/domain";
import { DokuMessagesContentStyled, DokuMessagesFormStyled, DokuMessagesStyled, MessageContentStyled, MessageTypeLegend, MessageWrapStyled, MessageWrapTitleStyled, Square, } from "./styles";
import { useTenantContext } from "../../../services/tenantContext";
import { useAppContext } from "../../../services/appContext";
var DokuMessages = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var _a = useState(false), loading = _a[0], setloading = _a[1];
    var _b = useState([]), messages = _b[0], setMessages = _b[1];
    var form = Form.useForm()[0];
    var _c = useState(false), isWarningVisible = _c[0], setIsWarningVisible = _c[1];
    var _d = useState(), computedMessages = _d[0], setComputedMessages = _d[1];
    var tenant = useTenantContext().tenant;
    var user = useAppContext().user;
    var _e = useState(true), disabledSubmitButton = _e[0], setDisabledSubmitButton = _e[1];
    var maxLengthRule = { max: 2000, message: formatMessage("dde.validation.common.maxLength2000") };
    var handleCancel = function () {
        if (form.getFieldValue("messageContent")) {
            setIsWarningVisible(true);
        }
        else {
            form.resetFields();
            setIsWarningVisible(false);
            setIsVisible(false);
        }
        setDisabledSubmitButton(true);
    };
    var handleWarningSubmit = function () {
        form.setFieldsValue({ messageContent: "" });
        form.resetFields();
        setIsVisible(false);
    };
    useEffect(function () {
        if (isVisible) {
            executeAxios({
                params: {
                    url: getMessagesApiConfig.url + props.dokuId + getMessagesApiConfig.urlPostFix,
                    method: getMessagesApiConfig.method,
                },
                onLoading: setloading,
                onSuccess: function (response) {
                    setMessages(__spreadArray(__spreadArray([], response, true), props.unsavedMessages, true).sort(function (a, b) { return b.createdAt.getTime() - a.createdAt.getTime(); }));
                    highlightMessages(response);
                },
                onFail: function (response) {
                    var _a;
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                    });
                },
            });
        }
    }, [props.dokuId, props.unsavedMessages, isVisible]);
    var highlightMessages = function (messages) {
        var messageIdList = [];
        messages.map(function (value) {
            if (value.id != null) {
                messageIdList.push(value.id);
            }
        });
        var payload = {
            messageId: messageIdList,
        };
        executeAxios({
            params: {
                url: saveReadMessages.url + props.dokuId + saveReadMessages.urlPostFix,
                method: saveReadMessages.method,
                data: payload,
            },
            onSuccess: function () {
                props.setUnreadMessagesCount(0);
            },
            onFail: function (response) {
                var _a;
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
        });
    };
    var visibilityOptions = [
        {
            label: formatMessage("dde.common.dokuMessages.internal.select.label"),
            value: "INTERNAL",
        },
        {
            label: formatMessage("dde.common.dokuMessages.external.select.label"),
            value: "EXTERNAL",
        },
    ];
    var handleSelectChange = function (option) {
        form.setFieldValue("messageVisibility", option);
        setDisabledSubmitButton(false);
    };
    var saveMessage = function (fieldValues) {
        props.onSaveMessage({
            content: fieldValues.messageContent,
            messageVisibility: user.userType === UserType.BANKER ? fieldValues.messageVisibility.value : "EXTERNAL",
        });
        showSuccessMessage({ message: formatMessage("dde.common.dde.dokuMessages.saveSuccess") });
        form.setFieldsValue({ messageContent: "" });
        handleCancel();
    };
    useEffect(function () {
        if (messages) {
            var timeMessagesList_1 = messages
                .sort(function (a, b) { return a.createdAt.valueOf() - b.createdAt.valueOf(); })
                .map(function (item) { return moment(item.createdAt).format("DD-MM-YYYY"); })
                .reduce(function (accumulator, value) {
                if (accumulator.indexOf(value) < 0) {
                    accumulator.push(value);
                }
                return accumulator;
            }, [])
                .reduce(function (accumulator, value) {
                var _a;
                return (__assign(__assign({}, accumulator), (_a = {}, _a[value] = [], _a)));
            }, {});
            messages.forEach(function (item) {
                var createdAtFormatted = moment(item.createdAt).format("DD-MM-YYYY");
                if (Object.keys(timeMessagesList_1).includes(createdAtFormatted)) {
                    timeMessagesList_1[createdAtFormatted].push(item);
                }
            });
            setComputedMessages(timeMessagesList_1);
        }
    }, [messages]);
    var renderMessageDate = function (date) {
        var myDate = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");
        var todayDate = moment().format("DD-MM-YYYY");
        var yesterdayDate = moment().subtract(1, "days").format("DD-MM-YYYY");
        if (myDate === todayDate) {
            return "Today";
        }
        else if (myDate === yesterdayDate) {
            return "Yesterday";
        }
        else {
            return date;
        }
    };
    function get$isOnLeftSide(message) {
        if (props.userType && props.userType == UserType.BANKER) {
            return message.senderUser.type === UserType.BANKER;
        }
        else {
            return message.senderUser.type === UserType.CLIENT;
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(Modal, __assign({ title: formatMessage("dde.common.dokuMessages.title"), open: isVisible, onCancel: function (e) {
                    e === null || e === void 0 ? void 0 : e.preventDefault();
                    e === null || e === void 0 ? void 0 : e.stopPropagation();
                    handleCancel();
                }, footer: _jsx(_Fragment, {}), cancelText: formatMessage("dde.common.dokuMessages.closeBtn"), cancelButtonProps: {
                    style: { visibility: "hidden" },
                }, okButtonProps: {
                    style: { visibility: "hidden" },
                }, bodyStyle: { marginTop: "30px" } }, { children: _jsxs(DokuMessagesStyled, { children: [user.userType === UserType.BANKER && (_jsxs(MessageTypeLegend, { children: [_jsx(Square, { "$bgColor": "#fcd5d5" }), formatMessage("dde.common.dokuMessages.internal.message"), _jsx(Square, { "$bgColor": "#e1e1e1" }), formatMessage("dde.common.dokuMessages.external.message")] })), props.dokuId && computedMessages && Object.keys(computedMessages).length > 0 ? (_jsx(DokuMessagesContentStyled, { children: props.dokuId && (_jsx(_Fragment, { children: Object.keys(computedMessages).map(function (item) {
                                    return (_jsxs(MessageWrapStyled, { children: [_jsx(MessageWrapTitleStyled, { children: _jsx("span", { children: renderMessageDate(item) }) }), computedMessages[item].map(function (message) { return (_jsxs(MessageContentStyled, __assign({ "$isOnLeftSide": get$isOnLeftSide(message) }, { children: [tenant === "HU" && message.senderUser.type === UserType.BANKER && user.userType === UserType.CLIENT ? (_jsxs("span", { children: [formatMessage("dde.common.dokuMessages.bankerSender"), ", ", moment(message.createdAt).format("HH:mm")] })) : (_jsxs("span", { children: [message.senderUser.fullName, ", ", moment(message.createdAt).format("HH:mm")] })), _jsx("p", __assign({ className: message.messageVisibility == "INTERNAL" ? "internalMessage" : "externalMessage" }, { children: message.content }))] }))); })] }));
                                }) })) })) : (_jsx("p", __assign({ className: "warning-message" }, { children: formatMessage("dde.common.dokuMessages.noMessages") }))), !props.readOnly && (_jsxs(DokuMessagesFormStyled, __assign({ form: form, initialValues: props.initialValues, onFinish: function () {
                                saveMessage(form.getFieldsValue());
                            } }, { children: [user.userType === UserType.BANKER && (_jsx(Form.Item, __assign({ name: "messageVisibility" }, { children: _jsx(Select, { id: "messageVisibility", onChange: function (option) {
                                            handleSelectChange(option);
                                        }, placeholder: formatMessage("dde.common.dokuMessages.select.label.PlaceHolder"), options: visibilityOptions, menuPosition: "fixed" }) }))), _jsx(Form.Item, __assign({ className: "input-message", name: "messageContent", rules: [maxLengthRule] }, { children: _jsx(Input, { id: "messageContent", onPressEnter: function () {
                                            saveMessage(form.getFieldsValue());
                                        }, placeholder: formatMessage("dde.common.dokuMessages.newMessage.messageContentPlaceHolder") }) })), _jsx("div", __assign({ style: { textAlign: "right", marginBottom: "25px" } }, { children: _jsx(Button, __assign({ type: "text", htmlType: "submit", onClick: function () {
                                            saveMessage(form.getFieldsValue());
                                        }, disabled: user.userType === UserType.BANKER ? disabledSubmitButton : false }, { children: _jsx(SendIcon, { width: "64px" }) })) }))] }))), loading && _jsx(Spinner, {})] }) })), _jsx(SaveDokuMessageWarningDlg, { isVisible: isWarningVisible, setIsVisible: setIsWarningVisible, onSubmit: handleWarningSubmit })] }));
};
export default DokuMessages;
