export var ALLOWED_CHARS = "\\w\\s\\@\\_\\.\\-";
export var getMin = function (tenant) {
    switch (tenant) {
        case "CZ":
            return 6;
        case "SK":
            return 6;
        case "RS":
            return 6;
        case "BG":
            return 8;
        case "HR":
            return 6;
        case "RO":
            return 6;
        default:
            return 0;
    }
};
export var getMax = function (tenant) {
    switch (tenant) {
        case "CZ":
            return 254;
        case "SK":
            return 254;
        case "RS":
            return 254;
        case "BG":
            return 254;
        case "HR":
            return 254;
        case "RO":
            return 254;
        default:
            return 1000;
    }
};
