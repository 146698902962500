import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
export function IdleWarningDialog(props) {
    var formatMessage = useFormatMessage().formatMessage;
    var remainingMinutes = Math.floor(props.remainingTime / 60);
    var remainingSeconds = props.remainingTime % 60;
    return (_jsx(Modal, { open: true, title: formatMessage(remainingMinutes === 0 ? "Login.inactivityModal.title.secondsOnly" : "Login.inactivityModal.title", {
            minutes: remainingMinutes,
            seconds: remainingSeconds,
        }), okText: formatMessage("Login.inactivityModal.button.Logout"), onOk: props.logout, cancelText: formatMessage("Login.inactivityModal.button.Continue"), onCancel: props.dismiss }));
}
