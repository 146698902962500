var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "styles/App.scss";
import InternetLoginRouterTenant from "./InternetLoginRouterTenant";
import IntranetLoginRouterTenant from "./IntranetLoginRouterTenant";
import GenericPortal from "./pages/common/GenericPortal/GeneralPortal";
import { useAppContext } from "./services/appContext";
import { TabLock } from "./pages/common/TabLock/TabLock";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { executeAxios } from "./services/axiosHook";
import { getFlowIdFromLocalStorage, setExternalParamInLocalStorage, userIsLoggedIn } from "./services/appStorage";
import { removeWindowOpen } from "./services/appApi";
var newTabUuid = uuidv4();
var getWindowOpenConfig = {
    url: "/dde/usermanagement/getUserOpenedWindow",
    method: "GET",
};
var setWindowOpenConfig = {
    url: "/dde/usermanagement/noauth/setUserOpenedWindow",
    method: "GET",
};
function AppWrapper(props) {
    var _a = useAppContext(), isLoggedIn = _a.isLoggedIn, user = _a.user;
    var location = useLocation();
    var _b = useState(false), showTabLock = _b[0], setShowTabLock = _b[1];
    var _c = useState(false), otherWindowOpen = _c[0], setOtherWindowOpen = _c[1];
    var skipTabLockUris = ["/oauth/authorize"];
    function setTabLock(uuid) {
        var lock = { value: uuid, lastTimestamp: new Date().getTime() };
        try {
            localStorage.setItem("tabLock", JSON.stringify(lock));
        }
        catch (e) {
            console.error("LocalStorage Error", e);
        }
    }
    function chackAndSetUserWindowOpen() {
        if (userIsLoggedIn()) {
            executeAxios({
                params: __assign({}, getWindowOpenConfig),
                onSuccess: function (response) {
                    var flowid = getFlowIdFromLocalStorage();
                    if (response && response !== flowid) {
                        setOtherWindowOpen(true);
                    }
                    else {
                        executeAxios({
                            params: __assign({}, setWindowOpenConfig),
                            onSuccess: function () {
                                setOtherWindowOpen(false);
                            },
                        });
                    }
                },
            });
        }
    }
    var getPersistedTabLock = useCallback(function () {
        try {
            var lock = localStorage.getItem("tabLock");
            return lock != null ? JSON.parse(lock) : 0;
        }
        catch (e) {
            console.error("LocalStorage Error", e);
        }
    }, []);
    function removeTabLock() {
        try {
            var tabLock = getPersistedTabLock();
            if (tabLock["value"] === undefined || tabLock["value"] === newTabUuid) {
                localStorage.removeItem("tabLock");
            }
        }
        catch (e) {
            console.error("LocalStorage Error", e);
        }
    }
    var canOpenAnotherTab = useCallback(function (uuid) {
        var tabLock = getPersistedTabLock();
        var lastTimestamp = tabLock["lastTimestamp"];
        if (tabLock["value"] === undefined || tabLock["value"] === uuid) {
            //same tab
            return true;
        }
        return !isNaN(lastTimestamp) ? new Date().getTime() - lastTimestamp > 10000 : true;
    }, [getPersistedTabLock]);
    useEffect(function () {
        if (!skipTabLockUris.includes(location.pathname)) {
            if (!canOpenAnotherTab(newTabUuid)) {
                setShowTabLock(true);
            }
            else {
                setShowTabLock(false);
            }
        }
    }, [canOpenAnotherTab, newTabUuid]);
    useEffect(function () {
        window.addEventListener("beforeunload", function () {
            removeWindowOpen();
            removeTabLock();
        });
        window.addEventListener("unload", function () {
            removeWindowOpen();
            removeTabLock();
        });
        window.addEventListener("onhashchange", function () {
            removeWindowOpen();
            removeTabLock();
        });
    }, []);
    useEffect(function () {
        if (userIsLoggedIn()) {
            chackAndSetUserWindowOpen();
        }
    }, [isLoggedIn]);
    useEffect(function () {
        var tabLock = getPersistedTabLock();
        var uuidFromStorage = tabLock["value"];
        chackAndSetUserWindowOpen();
        console.log("location", location);
        if (location.search) {
            var params = new URLSearchParams(location.search);
            var ddeParam = params.get("ddeParam");
            if (ddeParam) {
                setExternalParamInLocalStorage(ddeParam);
            }
        }
        if (!skipTabLockUris.includes(location.pathname) &&
            (uuidFromStorage === undefined || uuidFromStorage === newTabUuid || new Date().getTime() - tabLock["lastTimestamp"] > 10000)) {
            window.setTimeout(function () {
                setTabLock(newTabUuid);
            }, 200);
            var interval_1 = window.setInterval(function () {
                setTabLock(newTabUuid);
            }, 5000);
            var intervalWindow_1 = window.setInterval(function () {
                chackAndSetUserWindowOpen();
            }, 60000);
            return function () {
                window.clearInterval(interval_1);
                window.clearInterval(intervalWindow_1);
            };
        }
        return;
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(TabLock, { isVisible: showTabLock || otherWindowOpen }), _jsxs(GenericPortal, { children: [isLoggedIn && props.children, !isLoggedIn && props.isExternalUser && _jsx(InternetLoginRouterTenant, {}), !isLoggedIn && !props.isExternalUser && _jsx(IntranetLoginRouterTenant, {})] })] }));
}
export default AppWrapper;
