var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "@g4p/react-styleguide-library";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { GlobalEvents } from "../../../services/globalEvents";
import { forceUserToLoginAgain } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { dateFormat24 } from "../../../services/formatters";
var PortalBlockDialog = function () {
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState("-"), dateTo = _b[0], setDateTo = _b[1];
    var handleCancel = function () {
        setVisible(false);
        forceUserToLoginAgain();
    };
    var handleOk = function () {
        setVisible(false);
        // TODO: add logic to extend session
    };
    useEffect(function () {
        var listener = function (_a) {
            var detail = _a.detail;
            if (detail === null || detail === void 0 ? void 0 : detail.currentlyBlocked) {
                setVisible(true);
                setDateTo(detail.timeTo);
            }
        };
        document.addEventListener(GlobalEvents.PORTAL_BLOCKED, listener);
        return function () {
            document.removeEventListener(GlobalEvents.PORTAL_BLOCKED, listener);
        };
    }, []);
    return (_jsxs(Modal, __assign({ title: formatMessage("dde.in.app.portal.block.title"), open: visible, onOk: handleOk, okText: formatMessage("dde.in.app.portal.block.stay.on.page"), onCancel: handleCancel, cancelText: formatMessage("dde.in.app.portal.block.logout") }, { children: [formatMessage("dde.in.app.portal.block.message", { dateAndTime: moment(dateTo).format(dateFormat24) }), _jsx("br", {}), _jsx("br", {}), _jsx("b", { children: formatMessage("dde.in.app.portal.block.warning") })] })));
};
export default PortalBlockDialog;
