var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import { useState } from 'react';
import { Button, DownIcon, ForthIcon } from "@g4p/react-styleguide-library";
import { ExpandableListItemStyled, ExpandableListItemWrapperStyled, ExpandableListStyled } from "./styled";
var ExpandableItem = function (_a) {
    var item = _a.item;
    var formatMessage = useFormatMessage().formatMessage;
    var _b = useState(false), expanded = _b[0], setExpanded = _b[1];
    var handleExpand = function () {
        setExpanded(!expanded);
    };
    return (_jsxs(ExpandableListItemWrapperStyled, __assign({ "$hoverable": !!item.children }, { children: [_jsxs(ExpandableListItemStyled, __assign({ className: "list-item", onClick: handleExpand }, { children: [item.children && (_jsx(Button, __assign({ type: "text", className: "row-action-icon" }, { children: expanded ? _jsx(DownIcon, {}) : _jsx(ForthIcon, {}) }))), _jsxs("div", __assign({ className: "content" }, { children: [_jsx("div", __assign({ className: "label" }, { children: item.renderLabel ? item.renderLabel("".concat(formatMessage(item.label || ""))) : formatMessage(item.label || "") })), item.text && (_jsx("div", __assign({ className: "value" }, { children: _jsx("div", { children: item.renderText ? item.renderText("".concat(formatMessage(item.text || ""))) : formatMessage(item.text || "") }) })))] }))] })), item.children && item.children.length > 0 && (_jsx("div", __assign({ style: { display: expanded ? "block" : "none", marginLeft: "21px" } }, { children: _jsx("div", { children: getList(item.children) }) })))] })));
};
var getList = function (items) {
    return (_jsx(_Fragment, { children: items.map(function (item) { return (_jsx(ExpandableItem, { item: item }, item.key)); }) }));
};
var ExpandableList = function (props) {
    return _jsx(ExpandableListStyled, { children: props.dataSource && _jsx(_Fragment, { children: getList(props.dataSource) }) });
};
export default ExpandableList;
