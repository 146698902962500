var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown } from "antd";
import { getLocaleFromLocalStorage, getSupportedLanguages } from "../../services/appStorage";
import { useFormatMessage } from "../../services/formatMessageHook";
import { flagsIcons, LanguagesIcon } from "@cbp/fe-commons";
import { HeaderDropdownContainer } from "./HeaderDropdownStyle";
import { DocumentsIcon, OpenedFolderIcon, SignatureIcon, SingleTickIcon, } from "@g4p/react-styleguide-library";
import { useState } from "react";
import { DdeSettingsIcon, IconWrapper } from "../Header/styles";
var SettingsHeaderDropdown = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(false), isDropdownOpen = _a[0], setIsDropdownOpen = _a[1];
    var _b = useState(getLocaleFromLocalStorage()), selectedElement = _b[0], setSelectedElement = _b[1];
    var getFlag = function (lang) {
        switch (lang) {
            case "en":
                return _jsx(flagsIcons.GbIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "bg":
                return _jsx(flagsIcons.BgIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "hu":
                return _jsx(flagsIcons.HuIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "hr":
                return _jsx(flagsIcons.HrIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "ro":
                return _jsx(flagsIcons.RoIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "sr":
                return _jsx(flagsIcons.RsIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "cs":
                return _jsx(flagsIcons.CzIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
            case "sk":
                return _jsx(flagsIcons.SkIcon, { height: 24, width: 24, style: { paddingTop: "4px" } });
        }
    };
    var onClick = function (_a) {
        var key = _a.key;
        if (key !== "namirial") {
            console.log(key);
            setSelectedElement(key);
        }
        setIsDropdownOpen(false);
    };
    var getItems = function () {
        var items = [];
        var children = [];
        console.log(getSupportedLanguages());
        getSupportedLanguages().forEach(function (lang) {
            if (items !== undefined) {
                children.push({
                    icon: getFlag(lang),
                    key: lang,
                    label: formatMessage("dde.login.language.label.".concat(lang)),
                    onClick: function () { return props.handleTranslationUpdate(lang); },
                    itemIcon: selectedElement === lang && _jsx(SingleTickIcon, { height: 24, width: 24, className: "icon" }),
                });
            }
        });
        if (props.userType === "CLIENT") {
            items.push({
                icon: _jsx(LanguagesIcon, { height: 24, width: 24, fill: "black", className: "dde-icon" }),
                key: "0",
                className: "non-selectable",
                disabled: true,
                label: _jsx("span", __assign({ style: { fontWeight: "bold", color: "black" } }, { children: formatMessage("dde.header.ChangeLanguage") })),
            });
            items.push({
                type: "divider",
            });
            items.push({
                key: "1",
                type: "group",
                children: children,
            });
            items.push({
                type: "divider",
            });
            if (props.tenant !== "BG" && props.archivedTermsconAvailable === true) {
                items.push({
                    icon: _jsx(DocumentsIcon, { height: 24, width: 24 }),
                    key: "2",
                    label: formatMessage("dde.header.termsAndConditions.download"),
                    onClick: function () { return props.handleDownload(true); },
                });
            }
            if (props.archivedContractAvailable === true && (props.tenant === "CZ" || props.tenant === "SK")) {
                items.push({
                    icon: _jsx(OpenedFolderIcon, { height: 24, width: 24 }),
                    key: "3",
                    onClick: function () { return props.handleDownload(false); },
                    label: formatMessage("dde.header.contract.download"),
                });
            }
            if (props.tenant === "RO") {
                items.push({
                    key: "1",
                    label: (_jsxs("div", __assign({ className: "change-pass", onClick: function () { return props.setIsNamirialSettingsVisible(true); } }, { children: [_jsx(SignatureIcon, { height: 20, width: 20, style: { marginRight: 5 } }), _jsx("span", { children: formatMessage("dde.header.NamirialSettings") })] }))),
                });
            }
        }
        else {
            items.push({
                icon: _jsx(LanguagesIcon, { height: 24, width: 24, fill: "black", className: "dde-icon" }),
                key: "0",
                disabled: true,
                className: "non-selectable",
                label: _jsx("span", __assign({ style: { fontWeight: "bold", color: "black" } }, { children: formatMessage("dde.header.ChangeLanguage") })),
            });
            items.push({
                type: "divider",
            });
            items.push({
                key: "1",
                type: "group",
                children: children,
            });
            items.push({
                type: "divider",
            });
            if (props.tenant === "CZ" || props.tenant === "SK" || props.tenant === "HR" || props.tenant === "RO") {
                items.push({
                    key: "namirial",
                    disabled: false,
                    label: (_jsxs("div", __assign({ className: "change-pass", onClick: function () { return props.setIsNamirialSettingsVisible(true); } }, { children: [_jsx(SignatureIcon, { height: 20, width: 20, style: { marginRight: 5 } }), _jsx("span", { children: formatMessage("dde.header.NamirialSettings") })] }))),
                });
            }
        }
        return items;
    };
    var items = getItems();
    return (_jsx("div", __assign({ style: { marginRight: 30, marginLeft: 30 } }, { children: _jsx(Dropdown, __assign({ menu: { items: items, onClick: onClick, selectable: true, defaultSelectedKeys: [], selectedKeys: [] }, placement: "bottomRight", onOpenChange: function (flag) { return setIsDropdownOpen(flag); }, dropdownRender: function (menu) { return (_jsx(HeaderDropdownContainer, { children: menu })); } }, { children: _jsx(IconWrapper, { children: _jsx(DdeSettingsIcon, { isActive: isDropdownOpen }) }) }), items.toString()) })));
};
export default SettingsHeaderDropdown;
