var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useFormatMessage } from "../../services/formatMessageHook";
import { useEffect, useState } from "react";
import { Table as AntdTable } from "@g4p/react-styleguide-library";
import { ConfigProvider } from "antd";
import { getLocaleFromLocalStorage } from "../../services/appStorage";
import { useTenantContext } from "../../services/tenantContext";
import en_US from "antd/es/locale/en_US";
import ro_RO from "antd/es/locale/ro_RO";
import cs_CZ from "antd/es/locale/cs_CZ";
import sk_SK from "antd/es/locale/sk_SK";
import sr_RS from "antd/es/locale/sr_RS";
import hu_HU from "antd/es/locale/hu_HU";
import hr_HR from "antd/es/locale/hr_HR";
import bg_BG from "antd/es/locale/bg_BG";
var Table = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(), tableLocale = _a[0], setTableLocale = _a[1];
    var tenant = useTenantContext().tenant;
    var getTableLocale = function () { return __awaiter(void 0, void 0, void 0, function () {
        var antdLocale, localeLanguage;
        return __generator(this, function (_a) {
            localeLanguage = getLocaleFromLocalStorage().toUpperCase() == "EN" ?
                "en_US"
                :
                    getLocaleFromLocalStorage().toLowerCase() + "_" + tenant.toUpperCase();
            switch (localeLanguage) {
                case "ro_RO":
                    antdLocale = ro_RO;
                    break;
                case "cs_CZ":
                    antdLocale = cs_CZ;
                    break;
                case "sk_SK":
                    antdLocale = sk_SK;
                    break;
                case "sr_RS":
                    antdLocale = sr_RS;
                    break;
                case "hu_HU":
                    antdLocale = hu_HU;
                    break;
                case "hr_HR":
                    antdLocale = hr_HR;
                    break;
                case "bg_BG":
                    antdLocale = bg_BG;
                    break;
                default: antdLocale = en_US;
            }
            updateTableText(antdLocale);
            setTableLocale(antdLocale);
            return [2 /*return*/];
        });
    }); };
    var updateTableText = function (locale) {
        if (locale.Table === undefined)
            locale.Table = {};
        if (locale.Empty === undefined)
            locale.Empty = { description: "" };
        locale.Table.triggerAsc = formatMessage("dde.table.triggerAsc") !== "dde.table.triggerAsc" ?
            formatMessage("dde.table.triggerAsc") : locale.Table.triggerAsc;
        locale.Table.triggerDesc = formatMessage("dde.table.triggerDesc") !== "dde.table.triggerDesc" ?
            formatMessage("dde.table.triggerDesc") : locale.Table.triggerDesc;
        locale.Table.cancelSort = formatMessage("dde.table.cancelSort") !== "dde.table.cancelSort" ?
            formatMessage("dde.table.cancelSort") : locale.Table.cancelSort;
        locale.Empty.description = formatMessage("dde.table.emptyText") !== "dde.table.emptyText" ?
            formatMessage("dde.table.emptyText") : locale.Empty.description;
        return locale;
    };
    useEffect(function () {
        getTableLocale();
    });
    return (_jsx(ConfigProvider, __assign({ locale: tableLocale }, { children: _jsx(AntdTable, __assign({}, props, { labelSortingAscending: formatMessage("dde.table.triggerAsc") !== "dde.table.triggerAsc" ? formatMessage("dde.table.triggerAsc") : props.labelSortingAscending, labelSortingDescending: formatMessage("dde.table.triggerDesc") !== "dde.table.triggerDesc" ? formatMessage("dde.table.triggerDesc") : props.labelSortingDescending, labelCancelSorting: formatMessage("dde.table.cancelSort") !== "dde.table.cancelSort" ? formatMessage("dde.table.cancelSort") : props.labelCancelSorting })) })));
};
export default Table;
