import moment from "moment";
import 'moment/locale/hu';
import 'moment/locale/cs';
import 'moment/locale/sk';
import 'moment/locale/sr';
import 'moment/locale/bg';
import 'moment/locale/hr';
import 'moment/locale/ro';
export var LOCALE_STORAGE_KEY = "locale";
export function userIsLoggedIn() {
    return localStorage.getItem("loggedInUserName") !== null;
}
export function getLoggedInUserName() {
    return localStorage.getItem("loggedInUserName");
}
export function getLoggedInAlias() {
    return localStorage.getItem("loggedInAlias");
}
export function setTenantForHeader(tenant) {
    sessionStorage.setItem("tenant", tenant);
}
export function getTenantForHeader() {
    return sessionStorage.getItem("tenant");
}
export function checkLoggedInUser(username) {
    var currentUser = localStorage.getItem("loggedInUserName");
    return (currentUser === null || currentUser === void 0 ? void 0 : currentUser.toUpperCase()) === username.toUpperCase();
}
export function setAuthInLocalStorage(_a) {
    var flowId = _a.flowId, username = _a.username, alias = _a.alias;
    localStorage.setItem("flowId", flowId);
    localStorage.setItem("loggedInUserName", username);
    if (alias != null) {
        localStorage.setItem("loggedInAlias", alias);
    }
}
export function setFlowIdInLocalStorage(flowId) {
    localStorage.setItem("flowId", flowId);
}
export function getFlowIdFromLocalStorage() {
    return localStorage.getItem("flowId");
}
export function setExternalParamInLocalStorage(ddeParam) {
    localStorage.setItem("ddeParam", ddeParam);
}
export function getExternalParamFromLocalStorage() {
    return localStorage.getItem("ddeParam");
}
export function removeExternalParamFromLocalStorage() {
    localStorage.removeItem("ddeParam");
}
export function removeAuthFromLocalStorage() {
    localStorage.removeItem("flowId");
    localStorage.removeItem("loggedInUserName");
    localStorage.removeItem("loggedInAlias");
    localStorage.removeItem("clientList");
}
var supportedTenantsWithLanguages = [
    {
        tenant: "HU",
        supportedLanguages: ["hu", "en"],
    },
    {
        tenant: "CZ",
        supportedLanguages: ["cs", "en"],
    },
    {
        tenant: "SK",
        supportedLanguages: ["sk", "en"],
    },
    {
        tenant: "RS",
        supportedLanguages: ["sr", "en"],
    },
    {
        tenant: "BG",
        supportedLanguages: ["bg", "en"],
    },
    {
        tenant: "HR",
        supportedLanguages: ["hr", "en"],
    },
    {
        tenant: "RO",
        supportedLanguages: ["ro", "en"],
    },
];
export function getSupportedTenants() {
    return supportedTenantsWithLanguages.map(function (config) { return config.tenant; });
}
export function getSupportedLanguages() {
    var _a;
    var tenant = getTenantForHeader();
    return ((_a = supportedTenantsWithLanguages.find(function (config) { return config.tenant === (tenant === null || tenant === void 0 ? void 0 : tenant.toUpperCase()); })) === null || _a === void 0 ? void 0 : _a.supportedLanguages) || ["en"];
}
export function getDefaultLocale() {
    if (process.env.REACT_APP_IS_LOCAL_DEV_ENV === "true") {
        return "en";
    }
    return getSupportedLanguages()[0];
}
export function setLocaleInLocalStorage(selectedLocaleKey) {
    localStorage.setItem(LOCALE_STORAGE_KEY, selectedLocaleKey);
}
export function getLocaleFromLocalStorage() {
    var storedItem = localStorage.getItem(LOCALE_STORAGE_KEY);
    var supported = getSupportedLanguages();
    return storedItem && supported.includes(storedItem) ? storedItem : getDefaultLocale();
}
export function setLastLinkPressed_Dev(pathPrefix, tenant) {
    localStorage.setItem("pathPrefix_Dev", pathPrefix);
    localStorage.setItem("tenant_Dev", tenant);
}
export function getPathPrefix_Dev() {
    var storedItem = localStorage.getItem("pathPrefix_Dev");
    var supported = ["intranet", "index"];
    return storedItem && supported.includes(storedItem) ? storedItem : "index";
}
export function getTenant_Dev() {
    var storedItem = localStorage.getItem("tenant_Dev");
    var supported = getSupportedTenants();
    return storedItem && supported.includes(storedItem) ? storedItem : supported[0];
}
export function setIgnorePortalBlockForCurrentDayInLocalStorage() {
    localStorage.setItem("ignorePortalBlockForDate", moment().format("YYYY-MM-DD"));
}
export function removeIgnorePortalBlockFromLocalStorage() {
    localStorage.removeItem("ignorePortalBlockForDate");
}
export function isIgnorePortalBlock() {
    var storedDate = localStorage.getItem("ignorePortalBlockForDate");
    if (storedDate && moment(storedDate).isValid()) {
        return moment(storedDate).isSame(moment(), "day");
    }
    return false;
}
export function setCookieConsentAccepted() {
    localStorage.setItem("cookieConsentAccepted", "true");
}
export function isCookieConsentAccepted() {
    return localStorage.getItem("cookieConsentAccepted") === "true";
}
var defaultDateFormats = [
    {
        language: 'hu',
        dateFormat: 'YYYY.MM.DD'
    },
    {
        language: 'sr',
        dateFormat: 'DD.MM.YYYY'
    },
];
export function getDateFormat() {
    var _a;
    var format = (_a = defaultDateFormats.find(function (x) { return x.language === getLocaleFromLocalStorage(); })) === null || _a === void 0 ? void 0 : _a.dateFormat;
    return format !== null && format !== void 0 ? format : moment.localeData(getLocaleFromLocalStorage()).longDateFormat('L');
}
