var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { PageHeader } from "../../../../../components/PageHeader/PageHeader";
import { MainContainerStyled } from "../../../../../styles/styles";
import { executeAxios } from "../../../../../services/axiosHook";
import { useEffect, useState } from "react";
import { useFormatMessage } from "../../../../../services/formatMessageHook";
import { waitForContractProcessFinish } from "../../services/clientLoginApi";
import { useNavigate } from "react-router-dom";
import { removeAuthFromLocalStorage } from "../../../../../services/appStorage";
import { clientLogout } from "../../services/clientLogoutApi";
import { useTenantContext } from "../../../../../services/tenantContext";
import { useAppContext } from "../../../../../services/appContext";
import { afterLoggedInUserDetailsLoaded, removeWindowOpen } from "../../../../../services/appApi";
var WaitForContractProcessFinish = function () {
    var _a = useState(false), loadingWaitProcessToFinish = _a[0], setLoadingWaitProcessToFinish = _a[1];
    var navigate = useNavigate();
    var formatMessage = useFormatMessage().formatMessage;
    var _b = useState("in_progress"), callbackStatus = _b[0], setCallBackStatus = _b[1];
    var tenant = useTenantContext().tenant;
    var _c = useAppContext(), setIsLoggedIn = _c.setIsLoggedIn, setUser = _c.setUser, setClientList = _c.setClientList, clientList = _c.clientList;
    var showGeneralError = function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    };
    var waitForProcessFinishCall = function () {
        executeAxios({
            params: __assign({}, waitForContractProcessFinish),
            onFail: showGeneralError,
            onLoading: setLoadingWaitProcessToFinish,
            onSuccess: function (res) {
                setCallBackStatus(res.callbackResult);
                if ("success" === res.callbackResult) {
                    console.log("WaitForContractProcessFinish - afterLoggedInUserDetailsLoaded");
                    afterLoggedInUserDetailsLoaded(setUser, function () { }, showGeneralError, function () {
                        var list = clientList.map(function (client) { return client.clientNumber === localStorage.getItem("selectedCompany") ? __assign(__assign({}, client), { contractSigned: true }) : client; });
                        setClientList(list);
                        localStorage.setItem("clientList", JSON.stringify(list));
                        navigate("/app");
                    }, false, true);
                }
                if ("failed" === res.callbackResult) {
                    if ("rejected" === res.failureReason) {
                        removeWindowOpen();
                        executeAxios({
                            params: __assign({}, clientLogout),
                            onSuccess: function () {
                                setLoadingWaitProcessToFinish(false);
                                removeAuthFromLocalStorage();
                                setIsLoggedIn(false);
                                navigate("/index".concat(tenant, ".html"));
                            },
                        });
                    }
                    else {
                        navigate("/app/contract/contractprocesserror");
                    }
                }
                // CZ/SK contract signing
                if ("signature_needed" === res.callbackResult) {
                    setLoadingWaitProcessToFinish(false);
                }
            },
        });
    };
    useEffect(function () {
        // CZ/SK contract signing
        if ("signature_needed" !== callbackStatus) {
            var timer_1 = setTimeout(waitForProcessFinishCall, 1000);
            return function () { return clearTimeout(timer_1); };
        }
    });
    var handleOnClose = function () {
        executeAxios({
            params: __assign({}, clientLogout),
            onSuccess: function () {
                removeAuthFromLocalStorage();
                setIsLoggedIn(false);
                navigate("/index".concat(tenant, ".html"));
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: formatMessage("dde.usermanagement.waitForContractProcessFinishCallback.title") }), _jsxs(MainContainerStyled, { children: [_jsx("div", { children: ("in_progress" === callbackStatus || loadingWaitProcessToFinish) && _jsx(Spinner, {}) }), "signature_needed" === callbackStatus && (_jsx(Modal, { title: formatMessage("dde.login.error.contract.moreSignatures.required"), open: "signature_needed" === callbackStatus, onOk: handleOnClose, onClose: handleOnClose, cancelButtonProps: { style: { visibility: "hidden" } }, okText: "OK" }))] })] }));
};
export default WaitForContractProcessFinish;
