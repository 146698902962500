var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Menu } from "antd";
import styled from "styled-components";
import { SettingsIcon } from "@g4p/react-styleguide-library";
import { DownIcon, UpIcon } from "@cbp/fe-commons";
export var HeaderStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tpadding: 12px 0;\n\tmin-height: 64px;\n\tdisplay: flex;\n\talign-items: center;\n"], ["\n\tpadding: 12px 0;\n\tmin-height: 64px;\n\tdisplay: flex;\n\talign-items: center;\n"])));
export var LogoStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: flex-end;\n  cursor: pointer;\n\n\t> span {\n\t\tmargin-left: 10px;\n\t\tline-height: 1;\n\t\tfont-size: 20px;\n\t\tfont-weight: 700;\n\t\tcolor: #666666;\n\t}\n"], ["\n\tdisplay: flex;\n\talign-items: flex-end;\n  cursor: pointer;\n\n\t> span {\n\t\tmargin-left: 10px;\n\t\tline-height: 1;\n\t\tfont-size: 20px;\n\t\tfont-weight: 700;\n\t\tcolor: #666666;\n\t}\n"])));
export var MenuWrapperStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex: 1;\n\talign-items: flex-end;\n\tflex-direction: column;\n\n\t> div {\n\t\tdisplay: flex;\n\t\ttext-transform: uppercase;\n\t}\n\n\t.menu {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tflex-direction: row;\n\t\twidth: auto;\n\t\tpadding: 0;\n\t\tcursor: pointer;\n\t}\n\n\t.role {\n\t\tcolor: #666666;\n\t\tmargin-right: 10px !important;\n\t\tmax-width: 300px;\n\t\ttext-overflow: ellipsis;\n\t\twhite-space: nowrap;\n\t\toverflow: hidden;\n\t}\n"], ["\n\tdisplay: flex;\n\tflex: 1;\n\talign-items: flex-end;\n\tflex-direction: column;\n\n\t> div {\n\t\tdisplay: flex;\n\t\ttext-transform: uppercase;\n\t}\n\n\t.menu {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tflex-direction: row;\n\t\twidth: auto;\n\t\tpadding: 0;\n\t\tcursor: pointer;\n\t}\n\n\t.role {\n\t\tcolor: #666666;\n\t\tmargin-right: 10px !important;\n\t\tmax-width: 300px;\n\t\ttext-overflow: ellipsis;\n\t\twhite-space: nowrap;\n\t\toverflow: hidden;\n\t}\n"])));
export var DropdownMenuStyled = styled(Menu)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tpadding: 0;\n\n\tli {\n\t\theight: auto;\n\t\tmin-height: 40px;\n\t\tfont-weight: 700;\n\t\ttext-transform: uppercase;\n\t\tcolor: #262626;\n\t\twhite-space: nowrap;\n\t\tcursor: default;\n\n\t\tsvg {\n\t\t\torder: 1;\n\t\t}\n\n\t\t&:not(.ant-dropdown-menu-item-only-child) {\n\t\t\tline-height: 1 !important;\n\t\t\tmargin-top: 5px;\n\n\t\t\tdiv {\n\t\t\t\tpadding-left: 10px;\n\n\t\t\t\tp {\n\t\t\t\t\tcursor: pointer;\n\n\t\t\t\t\t&:hover {\n\t\t\t\t\t\tcolor: #007a91;\n\t\t\t\t\t}\n\n\t\t\t\t\t&.active {\n\t\t\t\t\t\tcolor: #007a91;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&:hover {\n\t\t\t\tbackground-color: initial;\n\t\t\t}\n\t\t}\n\n\t\t&:last-child {\n\t\t\tbackground-color: #262626;\n\t\t\tcolor: #fff;\n\t\t}\n\n\t\t.logout, .change-pass, .cookie-details {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n"], ["\n\tpadding: 0;\n\n\tli {\n\t\theight: auto;\n\t\tmin-height: 40px;\n\t\tfont-weight: 700;\n\t\ttext-transform: uppercase;\n\t\tcolor: #262626;\n\t\twhite-space: nowrap;\n\t\tcursor: default;\n\n\t\tsvg {\n\t\t\torder: 1;\n\t\t}\n\n\t\t&:not(.ant-dropdown-menu-item-only-child) {\n\t\t\tline-height: 1 !important;\n\t\t\tmargin-top: 5px;\n\n\t\t\tdiv {\n\t\t\t\tpadding-left: 10px;\n\n\t\t\t\tp {\n\t\t\t\t\tcursor: pointer;\n\n\t\t\t\t\t&:hover {\n\t\t\t\t\t\tcolor: #007a91;\n\t\t\t\t\t}\n\n\t\t\t\t\t&.active {\n\t\t\t\t\t\tcolor: #007a91;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&:hover {\n\t\t\t\tbackground-color: initial;\n\t\t\t}\n\t\t}\n\n\t\t&:last-child {\n\t\t\tbackground-color: #262626;\n\t\t\tcolor: #fff;\n\t\t}\n\n\t\t.logout, .change-pass, .cookie-details {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n"])));
export var ProfileDetails = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 5px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  margin-left: 5px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"])));
export var UserName = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 15.4px;\n  font-weight: 700;\n  text-transform: uppercase;\n"], ["\n  font-size: 14px;\n  line-height: 15.4px;\n  font-weight: 700;\n  text-transform: uppercase;\n"])));
export var Role = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tfont-size: 12px;\n\tline-height: 13.2px;\n\ttext-transform: uppercase;\n\tfont-weight: 700;\n\tcolor: #666666;\n"], ["\n\tfont-size: 12px;\n\tline-height: 13.2px;\n\ttext-transform: uppercase;\n\tfont-weight: 700;\n\tcolor: #666666;\n"])));
export var IconWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmargin: 2px 0;\n\twidth: auto ;\n\theight: auto;\n"], ["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmargin: 2px 0;\n\twidth: auto ;\n\theight: auto;\n"])));
export var DdeSettingsIcon = styled(SettingsIcon)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\n\tfont-size: 26px !important;\n  width: 26px !important;\n  height: 26px !important;\n  display: inline-block;\n  fill: ", ";\n"], ["\n\n\tfont-size: 26px !important;\n  width: 26px !important;\n  height: 26px !important;\n  display: inline-block;\n  fill: ", ";\n"])), function (_a) {
    var isActive = _a.isActive;
    return (isActive ? '#007A91' : 'black');
});
export var DdeUpIcon = styled(UpIcon)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\n\tfont-size: 12px !important;\n  width: 12px !important;\n  height: 12px !important;\n  display: inline-block;\n  fill: #007A91;\n"], ["\n\n\tfont-size: 12px !important;\n  width: 12px !important;\n  height: 12px !important;\n  display: inline-block;\n  fill: #007A91;\n"])));
export var DdeDownIcon = styled(DownIcon)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\n\tfont-size: 12px !important;\n  width: 12px !important;\n  height: 12px !important;\n  display: inline-block;\n  fill: black\n"], ["\n\n\tfont-size: 12px !important;\n  width: 12px !important;\n  height: 12px !important;\n  display: inline-block;\n  fill: black\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
