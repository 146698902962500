var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Form, Input, Row } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { isComplexPassword, isPasswordContainsValidCharacters } from "./services/changePasswordApi";
import { useState } from "react";
export var ChangePasswordForm = function (props) {
    var form = props.form;
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(""), previousValue = _a[0], setPreviousValue = _a[1];
    var zxcvbn = require("zxcvbn");
    var requiredRule = { required: true, message: formatMessage("dde.changePasswordForm.requiredError") };
    var requiredRuleRepeatPassword = { required: previousValue !== "", message: formatMessage("dde.changePasswordForm.requiredError") };
    var minLengthRule = { min: 8, message: formatMessage("dde.usermanagement.password.error.length") };
    var maxLengthRule = { max: 32, message: formatMessage("dde.usermanagement.password.error.length") };
    var isComplexPasswordByEngine = function (value) {
        var _a = zxcvbn(value), feedback = _a.feedback, score = _a.score;
        var suggestions = feedback.suggestions;
        // score = 3 moderate / score = 4 strong password
        return !value || score > 2;
    };
    var handleOnSubmit = function (values) {
        if (values.repeatedPassword !== "") {
            props.onSubmit(values);
        }
    };
    return (_jsx(Row, { children: _jsx(Col, __assign({ ds: 6 }, { children: _jsxs(Form, __assign({ form: form, initialValues: props.initialValues, onFinish: handleOnSubmit }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "currentPassword" }, { children: formatMessage("dde.changePasswordForm.current") })), _jsx(Form.Item, __assign({ rules: [requiredRule, minLengthRule, maxLengthRule], name: "currentPassword" }, { children: _jsx(Input.Password, { id: "currentPassword", placeholder: formatMessage("dde.changePasswordForm.current") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "newPassword" }, { children: formatMessage("dde.changePasswordForm.newPassword") })), _jsx(Form.Item, __assign({ rules: [
                            requiredRule,
                            minLengthRule,
                            maxLengthRule,
                            function () {
                                return {
                                    validator: function (_, value) {
                                        if (!value || isPasswordContainsValidCharacters(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(formatMessage("dde.usermanagement.password.error.invalidChar")));
                                    },
                                };
                            },
                            function () {
                                return {
                                    validator: function (_, value) {
                                        if (!value || (isComplexPassword(value) && isComplexPasswordByEngine(value))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(formatMessage("dde.usermanagement.password.error.patternMatch")));
                                    },
                                };
                            },
                        ], name: "newPassword" }, { children: _jsx(Input.Password, { placeholder: formatMessage("dde.changePasswordForm.newPassword") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "repeatedPassword" }, { children: formatMessage("dde.changePasswordForm.repeatedPassword") })), _jsx(Form.Item, __assign({ dependencies: ['newPassword'], rules: [
                            requiredRuleRepeatPassword,
                            minLengthRule,
                            maxLengthRule,
                            function (_a) {
                                var getFieldValue = _a.getFieldValue;
                                return {
                                    validator: function (_, value) {
                                        setPreviousValue(value);
                                        if (!value || getFieldValue("newPassword") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(formatMessage("dde.usermanagement.password.error.identical")));
                                    },
                                };
                            },
                        ], name: "repeatedPassword" }, { children: _jsx(Input.Password, { placeholder: formatMessage("dde.changePasswordForm.repeatedPassword") }) })), _jsx(Button, __assign({ type: "primary", htmlType: "submit", onClick: function () { return setPreviousValue("submit"); } }, { children: formatMessage("dde.changePasswordForm.submitBtn") }))] })) })) }));
};
