var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTenantContext } from "../../../services/tenantContext";
import { Button, Col, InfoSmallIcon, Row, } from "@g4p/react-styleguide-library";
import { getLocaleFromLocalStorage, setCookieConsentAccepted } from "../../../services/appStorage";
import { useState } from "react";
import { useAppContext } from "../../../services/appContext";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { CookieBanner } from "./styles";
import { useLocation, useNavigate, useResolvedPath } from "react-router-dom";
var CookieConsent = function () {
    var _a = useState(true), isVisible = _a[0], setIsVisible = _a[1];
    var _b = useAppContext(), isLoggedIn = _b.isLoggedIn, configurations = _b.configurations;
    var formatMessage = useFormatMessage().formatMessage;
    var tenant = useTenantContext().tenant;
    var navigate = useNavigate();
    var location = useLocation();
    var path = configurations.hasOwnProperty("ddeCookieDetailsPageURL_".concat(tenant, "_").concat(getLocaleFromLocalStorage())) ?
        isLoggedIn ?
            "/app/" + configurations["ddeCookieDetailsPageURL_".concat(tenant, "_").concat(getLocaleFromLocalStorage())]
            :
                "/" + configurations["ddeCookieDetailsPageURL_".concat(tenant, "_").concat(getLocaleFromLocalStorage())]
        : isLoggedIn ? "/app/cookies" : "/cookies";
    var resolvedPath = useResolvedPath(path);
    var onConsentGiven = function () {
        setCookieConsentAccepted();
        setIsVisible(false);
    };
    var navigateSafely = function () {
        if (resolvedPath.pathname !== location.pathname) {
            navigate(path);
        }
    };
    var navigateToCookieDetailsPage = function () {
        if (configurations.hasOwnProperty("ddeCookieDetailsPageURL_".concat(tenant, "_").concat(getLocaleFromLocalStorage())) &&
            /(http(s?)):\/\//i.test(configurations["ddeCookieDetailsPageURL_".concat(tenant, "_").concat(getLocaleFromLocalStorage())])) {
            window.open(configurations["ddeCookieDetailsPageURL_".concat(tenant, "_").concat(getLocaleFromLocalStorage())]);
        }
        else {
            navigateSafely();
        }
    };
    return (_jsx(_Fragment, { children: isVisible &&
            _jsx(CookieBanner, { children: _jsxs(Row, __assign({ style: { padding: 15 } }, { children: [_jsx(Col, __assign({ ds: 1, justifyContent: "flex-end" }, { children: _jsx(InfoSmallIcon, { fill: "#007a91", width: 25 }) })), _jsx(Col, __assign({ ds: 10 }, { children: _jsx(Row, { children: _jsx(Col, __assign({ ds: 12 }, { children: _jsxs(Row, { children: [_jsxs(Col, __assign({ ds: 9 }, { children: [_jsx("h2", { children: formatMessage("dde.cookiesConsent.banner.title") }), _jsxs("p", { children: [formatMessage("dde.cookiesConsent.banner.details"), " ", _jsx("br", {}), _jsx(Button, __assign({ type: "link", onClick: function () { return navigateToCookieDetailsPage(); } }, { children: formatMessage("dde.cookiesConsent.banner.findOutMore") }))] })] })), _jsx(Col, __assign({ ds: 3, alignItems: "center" }, { children: _jsx(Button, __assign({ type: "primary", size: "small", htmlType: "button", onClick: onConsentGiven }, { children: formatMessage("dde.cookiesConsent.banner.consentButtonText") })) }))] }) })) }) }))] })) }) }));
};
export default CookieConsent;
