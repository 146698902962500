var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Form, Row, Select } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import { useEffect, useMemo, useState } from "react";
import { getByLocaleBG } from "../../../../services/appApi";
import { useTenantContext } from "../../../../services/tenantContext";
export var SelectClientForm = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var form = Form.useForm()[0];
    var tenant = useTenantContext().tenant;
    var options = useMemo(function () {
        return props.clientList.map(function (item) {
            return { value: item.clientNumber, label: getByLocaleBG(tenant, item.clientName, item.clientNameLocal) };
        });
    }, [props.clientList]);
    var _a = useState({
        client: options.find(function (item) { return item.value === props.initialValues.client; }),
    }), initialValues = _a[0], setInitialValues = _a[1];
    var handleClientChange = function (selectedOption) {
        form.setFieldsValue({ client: selectedOption });
    };
    var onFinish = function (values) {
        props.onSubmit({ client: values.client.value });
    };
    useEffect(function () {
        setInitialValues({
            client: options.find(function (item) { return item.value === props.initialValues.client; }),
        });
    }, [props.initialValues, options]);
    return (_jsx(Row, { children: _jsx(Col, __assign({ ds: 6 }, { children: _jsxs(Form, __assign({ form: form, initialValues: initialValues, autoComplete: "off", onFinish: onFinish }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "client" }, { children: formatMessage("dde.login.selectClient.label") })), _jsx(Form.Item, __assign({ rules: [{ required: true, message: formatMessage("dde.validation.common.requiredField") }], name: "client" }, { children: _jsx(Select, { placeholder: formatMessage("dde.login.selectClient.label"), onChange: handleClientChange, options: options, isClearable: true }) })), _jsx(Button, __assign({ type: "primary", htmlType: "submit" }, { children: formatMessage("dde.login.selectClient.btn") }))] })) })) }));
};
