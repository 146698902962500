var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import { useIdleCountdown as useIdleCountdownHook } from "./useIdleCountdown";
var IdleStatusProvider = createContext({
    countdown: 0,
    showWarning: false,
    dismissWarning: function () { },
});
export function IdleGuard(props) {
    var minutesToMs = 60e3;
    var _a = useIdleCountdownHook(props.minutesBeforeIdle * minutesToMs, props.minutesWarningDuration * minutesToMs, props.onIdle), countdown = _a.countdown, showWarning = _a.showWarning, dismissWarning = _a.dismissWarning;
    return (_jsxs(IdleStatusProvider.Provider, __assign({ value: { countdown: countdown, showWarning: showWarning, dismissWarning: dismissWarning } }, { children: [props.children, showWarning && props.renderWarning(dismissWarning, countdown)] })));
}
export function useIdleCountdown() {
    return useContext(IdleStatusProvider);
}
