var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Card, Chips, CloseIcon, Col, DeleteIcon, Form, MessagesIcon, Row, showErrorMessage, Spinner, SuccessSmallIcon, Tooltip, UnsuccessSmallIcon, } from "@g4p/react-styleguide-library";
import { Typography, Upload } from "antd";
import { ParticipantRole } from "../../../flows/BankerCreateDokuBox/services/createDokuBoxApi";
import moment from "moment";
import { DokuStatus } from "../../admin/DokuBoxList/services/domain";
import { ArchivingErrorMessage, DokuCardStyled, DraggerWrapper, InvalidSigningUsers, SigningClientUser, UploadingErrorMessage, UploadStatusStyled, } from "../../banker/BankerManageDokuBox/styles";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../services/appContext";
import { getLocaleFromLocalStorage, getLoggedInUserName } from "../../../services/appStorage";
import { useTenantContext } from "../../../services/tenantContext";
import { usePermission } from "../../../utils/permissions";
import StatusTitle from "../../../components/StatusCard/StatusTitle";
import { getByLocale } from "../../../services/appApi";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { formatServerError } from "../../banker/BankerManageDokuBox/services/bankerManageDokuBoxApi";
import DokuMessages from "../DokuMessages/DokuMessages";
import { UserType } from "../DokuMessages/services/domain";
import ElectronicSignatureTenant from "../ElectronicSignature/ElectronicSignatureTenant";
import DokuActionAdditionalDataTenant from "./DokuActionAdditionalDataTenant";
import { dateFormat } from "../../../services/formatters";
import { HighlightBadge } from "./styles";
var Dragger = Upload.Dragger;
var DokuCard = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var formatMessage = useFormatMessage().formatMessage;
    var hasAnyOfTheRoles = usePermission().hasAnyOfTheRoles;
    var _j = useState(false), uploadTransitionAvailable = _j[0], setUploadTransitionAvailable = _j[1];
    var _k = useState(false), sendToArchiveTransitionAvailable = _k[0], setSendToArchiveTransitionAvailable = _k[1];
    var _l = useState(false), isDokuMessagesDlgVisible = _l[0], setIsDokuMessagesDlgVisible = _l[1];
    var _m = useState(null), isDokuValidateSignatureDlgVisible = _m[0], setIsDokuValidateSignatureDlgVisible = _m[1];
    var _o = useState([]), unsavedMessages = _o[0], setUnsavedMessages = _o[1];
    var _p = useState([]), unsavedActions = _p[0], setUnsavedActions = _p[1];
    var _q = useState(true), showUploadPlaceholder = _q[0], setShowUploadPlaceholder = _q[1];
    var _r = useState(false), showUploadSpinner = _r[0], setShowUploadSpinner = _r[1];
    var _s = useState(false), isUploaded = _s[0], setUploaded = _s[1];
    var _t = useState([]), dokuTransitions = _t[0], setDokuTransitions = _t[1];
    var _u = useState(props.doku.dokuAction), currentDokuAction = _u[0], setCurrentDokuAction = _u[1];
    var _v = useState(!((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("ValidateSignature")) && !props.doku.bankSignedDokuUploadNeeded), sendForArchivingPossible = _v[0], setSendForArchivingPossible = _v[1];
    var _w = useState(!((_b = props.doku.dokuStaticActions) === null || _b === void 0 ? void 0 : _b.includes("ValidateSignature"))), sendToArchivePossible = _w[0], setSendToArchivePossible = _w[1];
    var _x = useState(!((_c = props.doku.dokuStaticActions) === null || _c === void 0 ? void 0 : _c.includes("ValidateSignature"))), archivePossible = _x[0], setArchivePossible = _x[1];
    var user = useAppContext().user;
    var tenant = useTenantContext().tenant;
    var _y = useState(0), unreadMessages = _y[0], setUnreadMessages = _y[1];
    var sendToClientVisible = props.doku.status === DokuStatus.WAITING_BANK_SIGNATURE_AUX &&
        props.userType === UserType.BANKER &&
        props.dokuBox.bankSignatureSystem === "CUSTOM_APP" &&
        !props.doku.clientAcknowledgementNeeded &&
        tenant !== 'HU';
    var _z = useState(false), isArtificialSendToClient = _z[0], setIsArtificialSendToClient = _z[1];
    var configurations = useAppContext().configurations;
    var _0 = useState(false), loading = _0[0], setLoading = _0[1];
    var setUnreadMessagesCount = function (count) {
        setUnreadMessages(count);
    };
    var acceptedFileTypes = function () {
        switch (tenant) {
            case "HU":
                return ["pdf", "docx", "es3", "png", "jpg", "jpeg", "xlsx", "csv", "et3", "asice", "dosszie"];
            case "HR":
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            case "RO":
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice", "msg"];
            default:
                return ["pdf", "docx", "png", "jpg", "jpeg", "xlsx", "csv", "asice"];
        }
    };
    var getNotValidCertificatesFeatureFlagEnabledPerTenant = function () {
        switch (tenant) {
            case "RO":
                return configurations["ddeNotValidCertificatesEnabled_RO"];
            case "HR":
                return configurations["ddeNotValidCertificatesEnabled_HR"];
            case "BG":
                return configurations["ddeNotValidCertificatesEnabled_BG"];
            case "RS":
                return configurations["ddeNotValidCertificatesEnabled_RS"];
            case "CZ":
            case "SK":
                return configurations["ddeNotValidCertificatesEnabled_CZSK"];
            default:
                return configurations["ddeNotValidCertificatesEnabled"];
        }
    };
    useEffect(function () {
        var _a, _b;
        setUploadTransitionAvailable((_a = props.doku.dokuTransitions) === null || _a === void 0 ? void 0 : _a.some(function (value) { return value.triggerControl === "Upload"; }));
        setSendToArchiveTransitionAvailable((_b = props.doku.dokuTransitions) === null || _b === void 0 ? void 0 : _b.some(function (value) { return value.triggerControl === "SendToArchive"; }));
        setUnsavedActions(__spreadArray([], (props.doku.submitDokuStaticActions || []), true));
        setCurrentDokuAction(undefined);
        setUnreadMessages(props.doku.unreadMessagesCount);
        if (props.doku.dokuTransitions && props.doku.dokuTransitions.length > 0) {
            setDokuTransitions(props.doku.dokuTransitions
                .filter(function (value) {
                return value.triggerControl !== "Upload" &&
                    value.triggerControl !== "SignCallback" &&
                    (value.triggerControl !== "SendToArchive" || userHasRoleToArchive()) &&
                    ((value.triggerControl === "SendToBank" && tenant === "HU") || props.doku.dokuSetupCompleted);
            })
                .map(function (dokuTransition) {
                return {
                    id: dokuTransition.triggerControl,
                    text: formatDokuTransition(dokuTransition),
                    disabled: (needToShowSigningUser(props.userType, props.doku.status) &&
                        (dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning") &&
                        props.doku.signingUsers &&
                        props.doku.signingUsers.some(function (user) {
                            return user.userType === "CLIENT" &&
                                ((props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" && isExpired(user.docExpiryDate)) ||
                                    (props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" && !user.docExpiryDate) ||
                                    (props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" && !user.docIssuerCountry) ||
                                    (czOrSkTenant() && !user.docIssuer) ||
                                    (czOrSkTenant() && user.docStolen) ||
                                    (bgTenant() && !user.docValid) ||
                                    (props.dokuBox.clientSignatureSystem === "ESAW_REMOTE" &&
                                        (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId))));
                        })) ||
                        (needToShowSigningUser(props.userType, props.doku.status) &&
                            (dokuTransition.triggerControl === "SendForSigning" ||
                                dokuTransition.triggerControl === "SendToBankForSigning" ||
                                dokuTransition.triggerControl === "SendForResigning") &&
                            props.doku.signingUsers &&
                            props.doku.signingUsers.some(function (user) {
                                return user.userType === "BANKER" &&
                                    props.dokuBox.bankSignatureSystem === "ESAW_REMOTE" &&
                                    (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                            })) ||
                        (dokuTransition.triggerControl === "SendToArchive" && (!sendToArchivePossible || !archivePossible)) ||
                        (dokuTransition.triggerControl === "SendForArchiving" && !sendForArchivingPossible) ||
                        (dokuTransition.triggerControl === "SendToBank" &&
                            props.doku.status === DokuStatus.WAITING_CLIENT_FEEDBACK &&
                            props.doku.clientSignedDokuUploadNeeded) ||
                        (dokuTransition.triggerControl === "SendToClient" &&
                            props.doku.status === DokuStatus.WAITING_BANK_FEEDBACK &&
                            props.doku.bankSignedDokuUploadNeeded),
                };
            }));
        }
        else {
            setDokuTransitions([]);
        }
        if (sendToClientVisible && !props.doku.dokuTransitions.some(function (x) { return x.triggerControl === 'SendToClient'; })) {
            setIsArtificialSendToClient(true);
            var sendToClientObj = { triggerControl: 'SendToClient', sortOrder: 1 };
            var chipData_1 = { id: sendToClientObj.triggerControl, text: formatDokuTransition(sendToClientObj) };
            setDokuTransitions(function (dokuTransitions) { return __spreadArray(__spreadArray([], dokuTransitions, true), [chipData_1], false); });
        }
    }, [props.doku, props.doku.dokuTransitions, formatMessage, props.userType, sendForArchivingPossible, sendToArchivePossible, archivePossible]);
    useEffect(function () {
        var _a;
        setSendToArchivePossible(!((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("ValidateSignature")));
    }, [props.doku.status, props.doku.dokuStaticActions]);
    useEffect(function () {
        var _a;
        if (!props.doku.submitDokuStaticActions || ((_a = props.doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            setUnsavedMessages(new Array());
        }
    }, [props.doku, props.doku.submitDokuStaticActions]);
    var userHasRoleToArchive = function () {
        var _a, _b;
        if (rsTenant()) {
            if (props.dokuBox.validatorRoleOptional) {
                return (_a = props.doku.participants) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.participantRole === ParticipantRole.BANK_RM && p.user.username === getLoggedInUserName(); });
            }
            else {
                return (_b = props.doku.participants) === null || _b === void 0 ? void 0 : _b.find(function (p) { return p.participantRole === ParticipantRole.BANK_VALIDATOR && p.user.username === getLoggedInUserName(); });
            }
        }
        else {
            return true;
        }
    };
    var addDokuStaticAction = function (doku, action, replace) {
        if (replace === void 0) { replace = false; }
        if (!doku.submitDokuStaticActions) {
            doku.submitDokuStaticActions = [];
        }
        if (replace) {
            var index = doku.submitDokuStaticActions.findIndex(function (value) { return value.dokuStaticAction === action.dokuStaticAction; });
            if (index > -1) {
                doku.submitDokuStaticActions.splice(index, 1);
            }
            doku.submitDokuStaticActions.push(action);
        }
        else {
            doku.submitDokuStaticActions.push(action);
        }
        setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
    };
    var emptyString = function (value) {
        return !value || value.trim().length == 0;
    };
    var formatDokuTransition = function (dokuTransition) {
        if (dokuTransition && dokuTransition.triggerControl === "SendForSigning" && props.userType === UserType.BANKER) {
            return formatMessage("dde.doku.action." + dokuTransition.triggerControl + "." + UserType.CLIENT);
        }
        return formatMessage("dde.doku.action." + dokuTransition.triggerControl);
    };
    var setUniqueDokuStaticAction = function (doku, action) {
        removeDokuStaticAction(doku, action);
        doku.submitDokuStaticActions = __spreadArray(__spreadArray([], doku.submitDokuStaticActions, true), [action], false);
    };
    var removeDokuStaticAction = function (doku, action) {
        if (!doku.submitDokuStaticActions) {
            doku.submitDokuStaticActions = [];
        }
        var index = doku.submitDokuStaticActions.findIndex(function (value) { return value.dokuStaticAction === action.dokuStaticAction; });
        if (index < 0) {
            return;
        }
        doku.submitDokuStaticActions.splice(index, 1);
        setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
    };
    var isForbiddenExtension = function (file) {
        var fileExtArr = file.name.split(".");
        var fileExt = fileExtArr[fileExtArr.length - 1];
        return !acceptedFileTypes().includes(String(fileExt).toLowerCase());
    };
    var getFile = function (file) {
        if (isForbiddenExtension(file)) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.fileupload.forbidden.extenstion"),
            });
            return Upload.LIST_IGNORE;
        }
        var isLt10M = file.size / 1024 / 1024 > 10;
        if (isLt10M) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.file.upload.maxsize", { maxSize: 10 }),
            });
            return Upload.LIST_IGNORE;
        }
        var isEmpty = file.size === 0;
        if (isEmpty) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.file.upload.empty"),
            });
            return Upload.LIST_IGNORE;
        }
        setShowUploadSpinner(true);
        setUploaded(false);
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
            if (evt.target && evt.target.readyState === FileReader.DONE && evt.target.result) {
                if (uploadTransitionAvailable || props.doku.addOtherDokuId) {
                    props.doku.dokuAction = "Upload";
                    setUniqueDokuStaticAction(props.doku, {
                        dokuId: props.doku.id ? props.doku.id : 0,
                        dokuStaticAction: "Upload",
                        actionData: {
                            fileName: file === null || file === void 0 ? void 0 : file.name,
                        },
                        fileContent: file,
                    });
                    props.onSubmitUpload(props.doku);
                }
                else {
                    addDokuStaticAction(props.doku, {
                        dokuId: props.doku.id ? props.doku.id : 0,
                        dokuStaticAction: "Upload",
                        actionData: {
                            fileName: file === null || file === void 0 ? void 0 : file.name,
                        },
                        fileContent: file,
                    }, !(props.doku.isReconfiguredAdded === true));
                    removeReconfigureRemoveAction(props.doku);
                    if (props.doku.reconfigured && !props.doku.fileName) {
                        props.onSubmitUpload(props.doku);
                    }
                    else if (sendToClientVisible && !getNotValidCertificatesFeatureFlagEnabledPerTenant()) {
                        checkFileValidity(file);
                    }
                }
                setShowUploadSpinner(false);
                setShowUploadPlaceholder(false);
                setUploaded(true);
            }
        };
        return false;
    };
    var checkFileValidity = function (file) {
        var formData = new FormData();
        formData.append("file", file);
        var apiConfig = {
            url: "/dde/dokubox/doku/".concat(props.doku.id, "/check-file-validity-on-upload"),
            method: "POST",
        };
        var request = {
            data: formData,
        };
        executeAxios({
            params: __assign(__assign({}, apiConfig), request),
            onSuccess: function (response) {
                setIsArtificialSendToClient(!response);
            },
            onLoading: setLoading,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var changeDokuAction = function (action) {
        props.doku.dokuAction = action;
        setCurrentDokuAction(action);
        props.onDokuActionSelected(action);
        if (action === 'SendToClient' && sendToClientVisible && !getNotValidCertificatesFeatureFlagEnabledPerTenant() && isArtificialSendToClient) {
            props.doku.dokuAction = undefined;
            setCurrentDokuAction(undefined);
            dokuTransitions.find(function (x) { return x.id === 'SendToClient'; }).active = false;
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.banker.manageDokuBox.doku.notarchivable"),
            });
        }
    };
    var downloadFile = function () {
        var apiConfig = {
            url: "/dde/dokubox/doku/" + props.doku.id + "/download",
            method: "GET",
        };
        executeAxios({
            params: __assign({}, apiConfig),
            onSuccess: function (response) {
                downloadFile1(response.fileName, response.fileContent);
            },
            onLoading: function () { },
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
        return false;
    };
    var downloadFile1 = function (fileName, data) {
        var linkSource = "data:application/octet-stream;base64," + data;
        var downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = "_blank";
        downloadLink.click();
    };
    var edsRootURL = useAppContext().configurations.edsRootURL;
    var openEDS = function () {
        var anchor = document.createElement("a");
        anchor.href = "".concat(edsRootURL, "/Document/Upload?id=").concat(props.dokuBox.edsProcessId);
        anchor.target = "_blank";
        anchor.click();
        props.closeDokuBox();
    };
    function getUserFullName() {
        var fullName;
        if (user && user.userType) {
            if (UserType.BANKER === user.userType) {
                fullName = user.displayName;
            }
            else {
                fullName = user.firstName + " " + user.lastName;
            }
        }
        return fullName;
    }
    var handleSaveMessage = function (request) {
        setUnsavedMessages(__spreadArray(__spreadArray([], unsavedMessages, true), [
            {
                content: request.content,
                createdAt: new Date(),
                tenant: tenant,
                senderUser: {
                    tenant: tenant,
                    type: props.userType,
                    username: getLoggedInUserName(),
                    fullName: getUserFullName(),
                },
                messageVisibility: request.messageVisibility,
            },
        ], false));
        addDokuStaticAction(props.doku, {
            dokuId: props.doku.id,
            dokuStaticAction: "AddMessage",
            actionData: request,
        });
    };
    var needToShowSigningUser = function (userType, status) {
        if (rsTenant()) {
            return false;
        }
        if (bgTenant() && UserType.CLIENT === userType) {
            return false;
        }
        return ((UserType.BANKER === userType && (status === DokuStatus.UPLOADED_BANK || status === DokuStatus.UNDER_NEGOTIATION_BANK)) ||
            status === DokuStatus.WAITING_BANK_FEEDBACK ||
            status === DokuStatus.WAITING_BANK_SIGNATURE ||
            status === DokuStatus.WAITING_ARCHIVAL);
    };
    var isExpired = function (date) {
        return date && moment(date).isBefore(moment.now());
    };
    var isExpireSoon = function (date) {
        return date && moment(date).isBefore(moment(moment.now()).add(1, "months"));
    };
    var getDisplayName = function (signingUser) {
        switch (getLocaleFromLocalStorage()) {
            case "bg": return ((signingUser.firstNameLocal || "") + " " + (signingUser.lastNameLocal || "")).trim();
            case "hu": return ((signingUser.lastName || "") + " " + (signingUser.firstName || "")).trim();
            default: return ((signingUser.firstName || "") + " " + (signingUser.lastName || "")).trim();
        }
    };
    var formatDate = function (date) {
        return date ? moment(date).format(dateFormat) : "N/A";
    };
    var isDownloadVisible = function () {
        var _a;
        return props.doku.fileName && props.doku.downloadable && ((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.includes("View"));
    };
    var hasReconfigureRemoveAction = unsavedActions.some(function (x) { var _a; return x.dokuStaticAction === "Reconfigure" && ((_a = x.actionData) === null || _a === void 0 ? void 0 : _a.usedInBox) === false; });
    var hasUploadStaticAction = unsavedActions.some(function (x) { return x.dokuStaticAction === "Upload"; });
    var addReconfigureRemoveAction = function (doku) {
        var _a;
        var action = ((_a = doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.dokuStaticAction === "Reconfigure"; })) ||
            {
                dokuId: props.doku.id,
                dokuStaticAction: "Reconfigure",
            };
        action.actionData = action.actionData || { dokuId: props.doku.id };
        action.actionData.usedInBox = false;
        addDokuStaticAction(doku, action, true);
    };
    var removeReconfigureRemoveAction = function (doku) {
        var _a, _b;
        var action = (_a = doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.dokuStaticAction === "Reconfigure"; });
        if (((_b = action === null || action === void 0 ? void 0 : action.actionData) === null || _b === void 0 ? void 0 : _b.usedInBox) === false) {
            if (action.actionData.bankParticipants || action.actionData.clientParticipants) {
                action.actionData.usedInBox = true;
                setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
            }
            else {
                removeDokuStaticAction(doku, action);
            }
        }
    };
    var uploadProps = {
        name: "file".concat(props.doku.id),
        multiple: false,
        beforeUpload: getFile,
        accept: acceptedFileTypes()
            .map(function (type) { return "." + type; })
            .join(", "),
        itemRender: function (originNode, file) {
            return (!showUploadSpinner &&
                ((!props.failedUpload.get(props.doku.id || 0)) && isUploaded ? (_jsxs("div", __assign({ className: "file-name" }, { children: [_jsx(SuccessSmallIcon, { fill: "#2E844D" }), " ", _jsxs(Typography.Text, { children: [" ", file.name] })] }))) : (_jsxs("div", __assign({ className: "file-name" }, { children: [_jsx(UnsuccessSmallIcon, { fill: "#D73928" }), " ", _jsxs(Typography.Text, { children: [" ", file.name] })] })))));
        },
        maxCount: 1,
        onChange: function (uploadChangeParams) {
            props.onFileChange(props.doku.id || 0);
            if (uploadChangeParams.fileList && uploadChangeParams.fileList.length > 0) {
                var anyForbiddenExtension = false;
                for (var index = 0; index < uploadChangeParams.fileList.length; index++) {
                    var file = uploadChangeParams.fileList[index];
                    if (isForbiddenExtension(file)) {
                        anyForbiddenExtension = true;
                    }
                }
                if (anyForbiddenExtension) {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.error.fileupload.forbidden.extenstion"),
                    });
                }
            }
            else {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage("dde.error.fileupload.invalid.content"),
                });
            }
        },
        onDrop: function (e) {
            props.onFileChange(props.doku.id || 0);
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                var anyForbiddenExtension = false;
                for (var index = 0; index < e.dataTransfer.files.length; index++) {
                    var file = e.dataTransfer.files.item(index);
                    if (isForbiddenExtension(file)) {
                        anyForbiddenExtension = true;
                    }
                }
                if (anyForbiddenExtension) {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.error.fileupload.forbidden.extenstion"),
                    });
                }
            }
            else {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage("dde.error.fileupload.invalid.content"),
                });
            }
        },
    };
    var czOrSkTenant = function () {
        return tenant === "CZ" || tenant === "SK";
    };
    var rsTenant = function () {
        return tenant === "RS";
    };
    var bgTenant = function () {
        return tenant === "BG";
    };
    var roTenant = function () {
        return tenant === "RO";
    };
    var hrTenant = function () {
        return tenant === "HR";
    };
    function showFinalizeForClientAddedDoku() {
        var _a;
        return (!props.readOnly &&
            !hasReconfigureRemoveAction &&
            props.doku.id &&
            !props.doku.addOtherDokuId &&
            !props.doku.dokuSetupCompleted &&
            (czOrSkTenant() || rsTenant() || bgTenant() || hrTenant()
                ? ((_a = props.doku.initiatedBy) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "client"
                : !props.doku.dokuDefinitionId) &&
            props.userType === UserType.BANKER);
    }
    function addOtherDokuCanUpload() {
        return ((props.doku.addOtherDokuId || props.doku.isReconfiguredAdded) &&
            ((props.userType === UserType.BANKER && props.doku.status === DokuStatus.WAITING_BANK_UPLOAD) || props.userType === UserType.CLIENT));
    }
    var setIsVisibleElectronicSignatureDlg = useCallback(function (visible) {
        setIsDokuValidateSignatureDlgVisible(visible);
        setSendToArchivePossible(true);
    }, []);
    var setValidDoku = useCallback(function (isValid) {
        setArchivePossible(isValid);
        setSendForArchivingPossible(isValid);
    }, []);
    return (_jsxs(_Fragment, { children: [loading && _jsx(Spinner, {}), _jsx(DokuCardStyled, __assign({ "$fileUploaded": showUploadPlaceholder }, { children: _jsxs(Card, __assign({ type: hasReconfigureRemoveAction ? "highlight" : "default" }, { children: [_jsxs(Row, __assign({ maxWidth: "100%", style: { padding: 0, alignItems: "center", marginBottom: 10 } }, { children: [_jsx(Col, __assign({ ds: 4 }, { children: _jsx(Typography.Title, __assign({ level: 3, style: { marginBottom: 0 } }, { children: getByLocale(props.doku.titleEn, props.doku.titleLocal) })) })), _jsx(Col, __assign({ ds: 4, offsetDs: 1 }, { children: _jsx(StatusTitle, { status: props.doku.status }) })), _jsxs(Col, __assign({ ds: 3, justifyContent: "flex-end" }, { children: [props.doku.id && !props.doku.addOtherDokuId && (_jsxs("div", { children: [unreadMessages > 0 && _jsx(HighlightBadge, { children: unreadMessages }), _jsxs(Button, __assign({ type: "text", style: { marginTop: 0 }, onClick: function () { return setIsDokuMessagesDlgVisible(true); } }, { children: [_jsx(MessagesIcon, {}), _jsx("span", __assign({ style: { paddingLeft: "10px" } }, { children: formatMessage("dde.doku.card.messages") }))] }))] })), !props.readOnly && hasAnyOfTheRoles(["DOKU_REMOVE"]) && !hasUploadStaticAction && !props.doku.addOtherDokuId && !props.doku.fileName && (_jsx(Tooltip, __assign({ title: !hasReconfigureRemoveAction
                                                ? formatMessage("dde.doku.card.tooltip.RemoveDoku")
                                                : formatMessage("dde.doku.card.tooltip.UndoRemoveDoku") }, { children: _jsx(Button, __assign({ type: "text", style: { marginTop: 0, marginLeft: "40px" }, onClick: function () {
                                                    if (!hasReconfigureRemoveAction) {
                                                        props.doku.removed = true;
                                                        addReconfigureRemoveAction(props.doku);
                                                    }
                                                    else {
                                                        props.doku.removed = false;
                                                        removeReconfigureRemoveAction(props.doku);
                                                    }
                                                } }, { children: _jsx(DeleteIcon, {}) })) }))), !props.readOnly && hasAnyOfTheRoles(["DOKU_REMOVE"]) && !hasUploadStaticAction && props.doku.addOtherDokuId && !props.doku.fileName && (_jsx(Tooltip, __assign({ title: formatMessage("dde.doku.card.tooltip.CancelAddOtherDoku") }, { children: _jsx(Button, __assign({ type: "text", style: { marginTop: 0, marginLeft: "40px" }, onClick: function () {
                                                    props.onUndoAddOtherDoku(props.doku);
                                                } }, { children: _jsx(DeleteIcon, {}) })) })))] }))] })), _jsxs(Row, __assign({ maxWidth: "100%", style: { padding: 0, marginBottom: 10 } }, { children: [_jsx(Col, __assign({ ds: 4 }, { children: _jsx(Typography.Text, { children: getByLocale(props.doku.instructionEn, props.doku.instructionLocal) }) })), _jsxs(Col, __assign({ ds: 4, offsetDs: 1 }, { children: [needToShowSigningUser(props.userType, props.doku.status) && (_jsxs(_Fragment, { children: [props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" &&
                                                    props.doku.signingUsers &&
                                                    props.doku.signingUsers
                                                        .filter(function (user) { return user.userType === "CLIENT"; })
                                                        .map(function (user) {
                                                        if (isExpired(user.docExpiryDate)) {
                                                            return (_jsxs(SigningClientUser, __assign({ className: "expired" }, { children: [_jsx(CloseIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.expiredSigner", {
                                                                            fullName: getDisplayName(user),
                                                                            date: formatDate(user.docExpiryDate),
                                                                        }) })] })));
                                                        }
                                                        else if (czOrSkTenant() && user.docStolen) {
                                                            return (_jsxs(SigningClientUser, __assign({ className: "expired" }, { children: [_jsx(CloseIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.stolenSigner", {
                                                                            fullName: getDisplayName(user),
                                                                            date: formatDate(user.docExpiryDate),
                                                                        }) })] })));
                                                        }
                                                        else if (bgTenant() && !user.docValid) {
                                                            return (_jsxs(SigningClientUser, __assign({ className: "expired" }, { children: [_jsx(CloseIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.invalidSigner", {
                                                                            fullName: getDisplayName(user),
                                                                        }) })] })));
                                                        }
                                                        else if (isExpireSoon(user.docExpiryDate)) {
                                                            return (_jsxs(SigningClientUser, __assign({ className: "expire-soon" }, { children: [_jsx(UnsuccessSmallIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.expireSoonSigner", {
                                                                            fullName: getDisplayName(user),
                                                                            date: formatDate(user.docExpiryDate),
                                                                        }) })] })));
                                                        }
                                                        else {
                                                            return (_jsxs(SigningClientUser, __assign({ className: "not-expired" }, { children: [_jsx(SuccessSmallIcon, {}), " ", _jsx(Typography.Text, { children: formatMessage("dde.doku.card.notExpiredSigner", {
                                                                            fullName: getDisplayName(user),
                                                                            date: formatDate(user.docExpiryDate),
                                                                        }) })] })));
                                                        }
                                                    }), props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" &&
                                                    props.doku.signingUsers &&
                                                    props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && !user.docIssuerCountry; }) &&
                                                    props.doku.dokuTransitions &&
                                                    props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingDocIssuerCountry", {
                                                            names: props.doku.signingUsers
                                                                .filter(function (user) { return user.userType === "CLIENT" && !user.docIssuerCountry; })
                                                                .map(function (user) { return getDisplayName(user); })
                                                                .join(", "),
                                                        }) }) })), czOrSkTenant() &&
                                                    props.doku.signingUsers &&
                                                    props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && !user.docIssuer; }) &&
                                                    props.doku.dokuTransitions &&
                                                    props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingDocIssuer", {
                                                            names: props.doku.signingUsers
                                                                .filter(function (user) { return user.userType === "CLIENT" && !user.docIssuer; })
                                                                .map(function (user) { return getDisplayName(user); })
                                                                .join(", "),
                                                        }) }) })), czOrSkTenant() &&
                                                    props.doku.signingUsers &&
                                                    props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && user.docStolen; }) &&
                                                    props.doku.dokuTransitions &&
                                                    props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.docStolen", {
                                                            names: props.doku.signingUsers
                                                                .filter(function (user) { return user.userType === "CLIENT" && user.docStolen; })
                                                                .map(function (user) { return getDisplayName(user); })
                                                                .join(", "),
                                                        }) }) })), props.dokuBox.clientSignatureSystem === "ESAW_DISPOSABLE" &&
                                                    props.doku.signingUsers &&
                                                    props.doku.signingUsers.find(function (user) { return user.userType === "CLIENT" && !user.docExpiryDate; }) &&
                                                    props.doku.dokuTransitions &&
                                                    props.doku.dokuTransitions.find(function (dokuTransition) { return dokuTransition.triggerControl === "SendForSigning" || dokuTransition.triggerControl === "SendForResigning"; }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingDocExpiryDate", {
                                                            names: props.doku.signingUsers
                                                                .filter(function (user) { return user.userType === "CLIENT" && !user.docExpiryDate; })
                                                                .map(function (user) { return getDisplayName(user); })
                                                                .join(", "),
                                                        }) }) })), props.doku.signingUsers &&
                                                    props.doku.signingUsers.find(function (user) {
                                                        return user.userType === "BANKER" &&
                                                            props.dokuBox.bankSignatureSystem === "ESAW_REMOTE" &&
                                                            (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                                    }) &&
                                                    props.doku.dokuTransitions &&
                                                    props.doku.dokuTransitions.find(function (dokuTransition) {
                                                        return dokuTransition.triggerControl === "SendForSigning" ||
                                                            dokuTransition.triggerControl === "SendToBankForSigning" ||
                                                            dokuTransition.triggerControl === "SendForResigning";
                                                    }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingNamirialSettings", {
                                                            names: props.doku.signingUsers
                                                                .filter(function (user) {
                                                                return user.userType === "BANKER" &&
                                                                    props.dokuBox.bankSignatureSystem === "ESAW_REMOTE" &&
                                                                    (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                                            })
                                                                .map(function (user) { return getDisplayName(user); })
                                                                .join(", "),
                                                        }) }) })), props.doku.signingUsers &&
                                                    props.doku.signingUsers.find(function (user) {
                                                        return user.userType === "CLIENT" &&
                                                            props.dokuBox.clientSignatureSystem === "ESAW_REMOTE" &&
                                                            (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                                    }) &&
                                                    props.doku.dokuTransitions &&
                                                    props.doku.dokuTransitions.find(function (dokuTransition) {
                                                        return dokuTransition.triggerControl === "SendForSigning" ||
                                                            dokuTransition.triggerControl === "ContinueToSigning" ||
                                                            dokuTransition.triggerControl === "SendForResigning";
                                                    }) && (_jsx(InvalidSigningUsers, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.missingClientNamirialSettings", {
                                                            names: props.doku.signingUsers
                                                                .filter(function (user) {
                                                                return user.userType === "CLIENT" &&
                                                                    props.dokuBox.clientSignatureSystem === "ESAW_REMOTE" &&
                                                                    (emptyString(user.namirialUserId) || emptyString(user.namirialDeviceId));
                                                            })
                                                                .map(function (user) { return getDisplayName(user); })
                                                                .join(", "),
                                                        }) }) }))] })), props.dokuBox.archivingFailed && props.doku.status === DokuStatus.WAITING_ARCHIVAL && (_jsx(ArchivingErrorMessage, { children: _jsx(Typography.Text, { children: formatMessage("dde.dokubox.archive.dokuError") }) })), showFinalizeForClientAddedDoku() && _jsx(Typography.Text, { children: formatMessage("dde.doku.card.waitInput") }), (roTenant() || hrTenant()) && (props.doku.status === DokuStatus.UPLOADED_BANK || props.doku.status === DokuStatus.UPLOADED_CLIENT)
                                            && props.doku.uploadedDocumentSigned && (_jsx(UploadingErrorMessage, { children: _jsx(Typography.Text, { children: formatMessage("dde.doku.card.uploadedFileAlreadySigned") }) }))] }))] })), _jsxs(Row, __assign({ maxWidth: "100%", style: { padding: 0, marginBottom: 10 } }, { children: [_jsxs(Col, __assign({ ds: 4 }, { children: [!props.readOnly && !hasReconfigureRemoveAction &&
                                            !(props.doku.status === "WAITING_BANK_SIGNATURE_AUX" && props.dokuBox.bankSignatureSystem !== "CUSTOM_APP") &&
                                            (((_d = props.doku.dokuStaticActions) === null || _d === void 0 ? void 0 : _d.includes("Upload")) || addOtherDokuCanUpload()) && (_jsx(Form.Item, { children: _jsxs(DraggerWrapper, { children: [_jsxs(Dragger, __assign({}, uploadProps, { children: [_jsx(Typography.Text, __assign({ strong: true }, { children: formatMessage("dde.doku.card.browseFile") })), _jsx("br", {}), _jsx(Typography.Text, { children: formatMessage("dde.doku.card.dragFile") })] })), _jsxs(UploadStatusStyled, { children: [showUploadSpinner && _jsx(Loading3QuartersOutlined, { spin: true }), !props.doku.fileName && showUploadPlaceholder && (_jsx(Typography.Text, { children: formatMessage("dde.doku.card.noFileUpload") }))] })] }) })), !isUploaded && props.doku.fileName && (_jsx(DraggerWrapper, { children: _jsxs("div", __assign({ className: "file-name" }, { children: [_jsx(SuccessSmallIcon, { fill: "#2E844D" }), _jsx(Typography.Text, { children: props.doku.fileName })] })) })), _jsx(DokuActionAdditionalDataTenant, { form: props.form, doku: props.doku, dokuBox: props.dokuBox, readOnly: props.readOnly, userType: props.userType, currentDokuAction: currentDokuAction })] })), _jsx(Col, __assign({ ds: 4, offsetDs: 1 }, { children: !props.readOnly &&
                                        (props.doku.dokuSetupCompleted ||
                                            (!props.doku.dokuSetupCompleted && props.userType === UserType.CLIENT && props.doku.status === DokuStatus.UPLOADED_CLIENT)) &&
                                        dokuTransitions &&
                                        dokuTransitions.length > 0 && (_jsx(Chips, { chipsData: dokuTransitions, onChange: function (value) { return changeDokuAction(value); } })) })), _jsx(Col, __assign({ ds: 4, offsetDs: 1 }, { children: showFinalizeForClientAddedDoku() && (_jsx(Button, __assign({ type: "text", onClick: function () { return props.onFinalize(props.doku); } }, { children: formatMessage("dde.dde.manageDokuBox.Finalize") }))) }))] })), _jsx(Row, __assign({ maxWidth: "100%", style: { padding: 0 } }, { children: _jsxs(Col, __assign({ ds: 12, justifyContent: "flex-end" }, { children: [isDownloadVisible() && (_jsx(Button, __assign({ type: "primary", onClick: downloadFile, disabled: roTenant() && sendToArchiveTransitionAvailable && (!sendToArchivePossible || !archivePossible), style: { marginLeft: "20px" } }, { children: formatMessage("dde.doku.card.download") }))), !hasReconfigureRemoveAction &&
                                        tenant === "HU" &&
                                        ((_e = props.doku.dokuStaticActions) === null || _e === void 0 ? void 0 : _e.includes("JumpToEds")) &&
                                        hasAnyOfTheRoles(["DOKU_JUMP_TO_EDS"]) && (_jsx(Button, __assign({ type: "primary", onClick: openEDS, style: { marginLeft: "20px" } }, { children: formatMessage("dde.doku.card.jumpToEds") }))), !hasReconfigureRemoveAction && ((_f = props.doku.dokuStaticActions) === null || _f === void 0 ? void 0 : _f.includes("ValidateSignature")) && (_jsx(Button, __assign({ type: "primary", onClick: function () { return setIsDokuValidateSignatureDlgVisible(true); }, style: { marginLeft: "20px" } }, { children: formatMessage("dde.doku.validateSignature.button") })))] })) }))] })) })), !hasReconfigureRemoveAction && ((_g = props.doku.dokuStaticActions) === null || _g === void 0 ? void 0 : _g.includes("ValidateSignature")) && props.doku.id && (_jsx(ElectronicSignatureTenant, { doku: props.doku, isVisible: isDokuValidateSignatureDlgVisible, setIsVisible: setIsVisibleElectronicSignatureDlg, setValidDoku: setValidDoku })), _jsx(DokuMessages, { isVisible: isDokuMessagesDlgVisible, setIsVisible: setIsDokuMessagesDlgVisible, dokuId: props.doku.id || 0, initialValues: { messageContent: "" }, readOnly: props.readOnly || !((_h = props.doku.dokuStaticActions) === null || _h === void 0 ? void 0 : _h.some(function (x) { return x === "AddMessage"; })) || hasReconfigureRemoveAction, userType: props.userType, unsavedMessages: unsavedMessages, onSaveMessage: handleSaveMessage, setUnreadMessagesCount: setUnreadMessagesCount })] }));
};
export default DokuCard;
