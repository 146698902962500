var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, MessagesIcon } from "@g4p/react-styleguide-library";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../services/appContext";
import { getLoggedInUserName } from "../../../services/appStorage";
import { useTenantContext } from "../../../services/tenantContext";
import { useFormatMessage } from "../../../services/formatMessageHook";
import DokuMessages from "../DokuMessages/DokuMessages";
import { UserType } from "../DokuMessages/services/domain";
import { ExtraButtonsWrapper, HighlightBadge } from "./styles";
import { DeleteIcon, QuickMenuActions } from "@cbp/fe-commons";
import { usePermission } from "../../../utils/permissions";
var DokuPanelExtraContent = function (props) {
    var _a, _b;
    var formatMessage = useFormatMessage().formatMessage;
    var hasAnyOfTheRoles = usePermission().hasAnyOfTheRoles;
    var _c = useState(false), isDokuMessagesDlgVisible = _c[0], setIsDokuMessagesDlgVisible = _c[1];
    var _d = useState([]), unsavedMessages = _d[0], setUnsavedMessages = _d[1];
    var _e = useState([]), unsavedActions = _e[0], setUnsavedActions = _e[1];
    var user = useAppContext().user;
    var tenant = useTenantContext().tenant;
    var addDokuStaticAction = function (doku, action, replace) {
        if (replace === void 0) { replace = false; }
        if (!doku.submitDokuStaticActions) {
            doku.submitDokuStaticActions = [];
        }
        if (replace) {
            var index = doku.submitDokuStaticActions.findIndex(function (value) { return value.dokuStaticAction === action.dokuStaticAction; });
            if (index > -1) {
                doku.submitDokuStaticActions.splice(index, 1);
            }
            doku.submitDokuStaticActions.push(action);
        }
        else {
            doku.submitDokuStaticActions.push(action);
        }
        setUnsavedActions(__spreadArray([], doku.submitDokuStaticActions, true));
    };
    var hasUploadStaticAction = unsavedActions.some(function (x) { return x.dokuStaticAction === "Upload"; });
    var _f = useState(0), unreadMessages = _f[0], setUnreadMessages = _f[1];
    var setUnreadMessagesCount = function (count) {
        setUnreadMessages(count);
    };
    useEffect(function () {
        setUnreadMessages(props.doku.unreadMessagesCount);
    }, [props.doku]);
    useEffect(function () {
        var _a;
        if (!props.doku.submitDokuStaticActions || ((_a = props.doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            setUnsavedMessages(new Array());
        }
    }, [props.doku, props.doku.submitDokuStaticActions]);
    var addReconfigureRemoveAction = function (doku) {
        var _a;
        var action = ((_a = doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.dokuStaticAction === "Reconfigure"; })) ||
            {
                dokuId: props.doku.id,
                dokuStaticAction: "Reconfigure",
            };
        action.actionData = action.actionData || { dokuId: props.doku.id };
        action.actionData.usedInBox = false;
        addDokuStaticAction(doku, action, true);
    };
    function getUserFullName() {
        var fullName;
        if (user && user.userType) {
            if (UserType.BANKER === user.userType) {
                fullName = user.displayName;
            }
            else {
                fullName = user.firstName + " " + user.lastName;
            }
        }
        return fullName;
    }
    var handleSaveMessage = function (request) {
        setUnsavedMessages(__spreadArray(__spreadArray([], unsavedMessages, true), [
            {
                content: request.content,
                createdAt: new Date(),
                tenant: tenant,
                senderUser: {
                    tenant: tenant,
                    type: props.userType,
                    username: getLoggedInUserName(),
                    fullName: getUserFullName(),
                },
                messageVisibility: request.messageVisibility,
            },
        ], false));
        addDokuStaticAction(props.doku, {
            dokuId: props.doku.id,
            dokuStaticAction: "AddMessage",
            actionData: request,
        });
    };
    var hasReconfigureRemoveAction = unsavedActions.some(function (x) { var _a; return x.dokuStaticAction === "Reconfigure" && ((_a = x.actionData) === null || _a === void 0 ? void 0 : _a.usedInBox) === false; });
    var menuItemsSmall = useCallback(function (panelKey) {
        var canDelete = !props.readOnly && hasAnyOfTheRoles(["DOKU_REMOVE"]) && !hasUploadStaticAction && !props.doku.addOtherDokuId && !props.doku.fileName;
        var undoAddOtherDoku = !props.readOnly && hasAnyOfTheRoles(["DOKU_REMOVE"]) && !hasUploadStaticAction && props.doku.addOtherDokuId && !props.doku.fileName;
        var ret = [];
        /*		if (undoAddOtherDoku) {
                    ret.push({
                        key: "Undo",
                        label: formatMessage("dde.doku.card.tooltip.CancelAddOtherDoku"),
                        icon: <DeleteIcon />,
                        action: () => props.onUndoAddOtherDoku(props.doku),
                    })
                }*/
        if (canDelete || undoAddOtherDoku) {
            var deleteMenu = {
                key: "Delete",
                label: formatMessage("dde.doku.card.delete"),
                icon: _jsx(DeleteIcon, {}),
                action: function () {
                    if (Number.isInteger((props.index - 1) / 2)) {
                        props.setActiveKeyLeft(function (prev) {
                            return Array.isArray(prev) && !prev.includes(panelKey.toString())
                                ? __spreadArray(__spreadArray([], prev, true), [panelKey.toString()], false) : [panelKey.toString()];
                        });
                    }
                    else {
                        props.setActiveKeyRight(function (prev) {
                            return Array.isArray(prev) && !prev.includes(panelKey.toString())
                                ? __spreadArray(__spreadArray([], prev, true), [panelKey.toString()], false) : [panelKey.toString()];
                        });
                    }
                    deleteDoku();
                },
            };
            ret.push(deleteMenu);
        }
        return ret;
    }, [props.doku, props.dokuBox]);
    function findPanelKeyByPosition(index) {
        var dividedIndex = index / 2;
        if (Number.isInteger(dividedIndex)) {
            return dividedIndex;
        }
        return Math.floor(dividedIndex);
    }
    var deleteDoku = function () {
        props.doku.removed = true;
        addReconfigureRemoveAction(props.doku);
        props.updateDeleteMap(props.index, props.doku);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ExtraButtonsWrapper, __assign({ onClick: function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                } }, { children: [props.doku.id && !props.doku.addOtherDokuId && ((_a = props.doku.dokuStaticActions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x === "ViewMessage"; })) && (_jsxs("div", __assign({ style: { position: 'relative' } }, { children: [unreadMessages > 0 && _jsx(HighlightBadge, { children: unreadMessages }), _jsx(Button, __assign({ type: "text", style: { marginTop: 0 }, onClick: function (e) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsDokuMessagesDlgVisible(true);
                                } }, { children: _jsx(MessagesIcon, { style: { marginRight: '5px', width: '24px', height: '24px', marginLeft: '10px' } }) }))] }))), menuItemsSmall(findPanelKeyByPosition(props.index - 1)).length !== 0 && (_jsx(QuickMenuActions, { items: menuItemsSmall(findPanelKeyByPosition(props.index - 1)), onClick: function (e) {
                            e === null || e === void 0 ? void 0 : e.preventDefault();
                            e === null || e === void 0 ? void 0 : e.stopPropagation();
                        } }))] })), _jsx("div", __assign({ onClick: function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                } }, { children: _jsx(DokuMessages, { isVisible: isDokuMessagesDlgVisible, setIsVisible: setIsDokuMessagesDlgVisible, dokuId: props.doku.id || 0, initialValues: { messageContent: "" }, readOnly: props.readOnly || !((_b = props.doku.dokuStaticActions) === null || _b === void 0 ? void 0 : _b.some(function (x) { return x === "AddMessage"; })) || hasReconfigureRemoveAction, userType: props.userType, unsavedMessages: unsavedMessages, onSaveMessage: handleSaveMessage, setUnreadMessagesCount: setUnreadMessagesCount }) }))] }));
};
export default DokuPanelExtraContent;
