var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from "@g4p/react-styleguide-library";
import { useState } from "react";
import { useCopyToClipboard } from "react-use";
import { useAppContext } from "../../../services/appContext";
import { useFormatMessage } from "../../../services/formatMessageHook";
import styled from "styled-components";
import { useTranslationsContext } from "../../../services/translationsContext";
var BadgeStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: fixed;\n\ttop: 20px;\n\tleft: 40px;\n\ttransform: rotate(-30deg);\n\ttext-transform: uppercase;\n\tcolor: red;\n\tfont-weight: bold;\n\tfont-size: 15px;\n\tz-index: 99999999;\n\ttext-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;\n\tcursor: pointer;\n\tuser-select: none;\n"], ["\n\tposition: fixed;\n\ttop: 20px;\n\tleft: 40px;\n\ttransform: rotate(-30deg);\n\ttext-transform: uppercase;\n\tcolor: red;\n\tfont-weight: bold;\n\tfont-size: 15px;\n\tz-index: 99999999;\n\ttext-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;\n\tcursor: pointer;\n\tuser-select: none;\n"])));
var EnvInfoBadge = function () {
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    var _b = useState(""), flowId = _b[0], setFlowId = _b[1];
    var _c = useCopyToClipboard(), copyToClipboard = _c[1];
    var ddeEnvironment = useAppContext().configurations.ddeEnvironment;
    var setTranslationsEnabled = useTranslationsContext().setTranslationsEnabled;
    var open = function () {
        var id = localStorage.getItem("flowId") || "";
        setFlowId(id);
        copyToClipboard(id);
        setIsVisible(true);
    };
    return (_jsx(_Fragment, { children: (ddeEnvironment === "sit" || ddeEnvironment === "uat" || ddeEnvironment === "hf") && (_jsxs(_Fragment, { children: [_jsx(BadgeStyled, __assign({ onClick: open, onContextMenu: function (e) {
                        e.preventDefault();
                        setTranslationsEnabled(function (enabled) { return !enabled; });
                    } }, { children: ddeEnvironment })), _jsxs(Modal, __assign({ open: isVisible, onCancel: function () { return setIsVisible(false); }, onOk: function () { return setIsVisible(false); } }, { children: [flowId && (_jsxs(_Fragment, { children: [_jsxs("div", { children: [formatMessage("dde.envinfobage.FlowId"), ": ", flowId] }), _jsx("div", { children: formatMessage("dde.envinfobage.FlowIdAlreadyCopied") })] })), !flowId && _jsx("div", { children: formatMessage("dde.envinfobage.FlowIdNotAvailable") })] }))] })) }));
};
export default EnvInfoBadge;
var templateObject_1;
