var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { MainContainerStyled } from "../../../styles/styles";
export var DokuCardStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t.uds__card {\n\t\tmin-height: 200px;\n\t\tpadding: 20px 40px;\n\t\tflex-direction: column;\n\t\tmargin-bottom: 40px;\n\t\t", "\n\t\t&.uds__card-highlight {\n\t\t\t.ant-upload.ant-upload-drag {\n\t\t\t\tbackground-color: transparent;\n\t\t\t}\n\t\t\t.uds__chip__wrapper.uds__active {\n\t\t\t\tborder-color: #262626;\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\t.uds__card {\n\t\tmin-height: 200px;\n\t\tpadding: 20px 40px;\n\t\tflex-direction: column;\n\t\tmargin-bottom: 40px;\n\t\t", "\n\t\t&.uds__card-highlight {\n\t\t\t.ant-upload.ant-upload-drag {\n\t\t\t\tbackground-color: transparent;\n\t\t\t}\n\t\t\t.uds__chip__wrapper.uds__active {\n\t\t\t\tborder-color: #262626;\n\t\t\t}\n\t\t}\n\t}\n"])), function (props) {
    return props.$fileUploaded
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t\t\t\tborder-color: #262626;\n\t\t\t\t  "], ["\n\t\t\t\t\t\tborder-color: #262626;\n\t\t\t\t  "]))) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\t\t\t\tborder-color: #cccccc;\n\t\t\t\t  "], ["\n\t\t\t\t\t\tborder-color: #cccccc;\n\t\t\t\t  "])));
});
export var DraggerWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tmargin-bottom: 10px;\n\t.file-name {\n\t\tdisplay: flex;\n\t\tmin-height: 20px;\n\t\tmargin-top: 10px;\n\t}\n"], ["\n\tmargin-bottom: 10px;\n\t.file-name {\n\t\tdisplay: flex;\n\t\tmin-height: 20px;\n\t\tmargin-top: 10px;\n\t}\n"])));
export var DraggerWrapperFlex = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tmargin-bottom: 10px;\n\tmargin-top: 5px;\t\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\t\n\t.file-name {\n\t\tdisplay: flex;\n\t\tmin-height: 20px;\n\t\talign-items: center;\n    \tmargin-right: 10px;\n\t\tmargin-top: 5px;\t\n\t\twhite-space: nowrap;\t\n\t\tflex:1;\n\t}\n"], ["\n\tmargin-bottom: 10px;\n\tmargin-top: 5px;\t\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\t\n\t.file-name {\n\t\tdisplay: flex;\n\t\tmin-height: 20px;\n\t\talign-items: center;\n    \tmargin-right: 10px;\n\t\tmargin-top: 5px;\t\n\t\twhite-space: nowrap;\t\n\t\tflex:1;\n\t}\n"])));
export var Container = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\talign-items: center;\n\twidth: 100%;\n"], ["\n\talign-items: center;\n\twidth: 100%;\n"])));
export var Icon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tflex: 0 0 auto;\n\tmargin-right: 8px;\n"], ["\n\tflex: 0 0 auto;\n\tmargin-right: 8px;\n"])));
export var HalfContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  width: 50%;\n"], ["\n  align-items: center;\n  width: 50%;\n"])));
export var FileNameContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n\toverflow: hidden;\n"], ["\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n\toverflow: hidden;\n"])));
export var FileNameWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tdisplay: flex;\n\tmin-width: 0;\n\toverflow: hidden;\n"], ["\n\tdisplay: flex;\n\tmin-width: 0;\n\toverflow: hidden;\n"])));
export var FileName = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\tflex-shrink: 1;\n\tmin-width: 0;\n\tfont-size: 18px;\t\n\t\t\n"], ["\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\tflex-shrink: 1;\n\tmin-width: 0;\n\tfont-size: 18px;\t\n\t\t\n"])));
export var FileExtension = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  margin-left: -1px;\n  font-size: 18px;\n"], ["\n  flex: 0 0 auto;\n  margin-left: -1px;\n  font-size: 18px;\n"])));
export var RadioButtonContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n\tdisplay: flex;\n\tjustify-content: space-between;\t\n"], ["\n\tdisplay: flex;\n\tjustify-content: space-between;\t\n"])));
export var UploadStatusStyled = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n\tmargin-top: 10px;\n"], ["\n\tmargin-top: 10px;\n"])));
export var FooterButtonsStyled = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n\tmargin-top: 30px;\n\tdisplay: flex;\n\talign-items: center;\n\t.right-side {\n\t\tflex: 1;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tbutton {\n\t\t\tmargin-left: 20px;\n\t\t}\n\t}\n"], ["\n\tmargin-top: 30px;\n\tdisplay: flex;\n\talign-items: center;\n\t.right-side {\n\t\tflex: 1;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tbutton {\n\t\t\tmargin-left: 20px;\n\t\t}\n\t}\n"])));
export var PageTitleWrapperStyled = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n\tbackground-color: #f5f5f5;\n\tpadding: 30px 0 30px;\n\tmargin-top: 10px;\n\tmargin-bottom: 20px;\n  gap: 10px;\n\t\t\n\t", " {\n\t\tdisplay: flex;\n\t\talign-items: center;\n    justify-content: flex-start;\n    gap: 10px;\n      > div:first-child {\n\t\t\tflex: 1;\n\t\t}\n\t}\n"], ["\n\tbackground-color: #f5f5f5;\n\tpadding: 30px 0 30px;\n\tmargin-top: 10px;\n\tmargin-bottom: 20px;\n  gap: 10px;\n\t\t\n\t", " {\n\t\tdisplay: flex;\n\t\talign-items: center;\n    justify-content: flex-start;\n    gap: 10px;\n      > div:first-child {\n\t\t\tflex: 1;\n\t\t}\n\t}\n"])), MainContainerStyled);
export var SigningClientUser = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n\tsvg {\n\t\theight: 15px;\n\t\twidth: 15px;\n\t}\n\t&.expired {\n\t\tsvg {\n\t\t\tfill: #d73928;\n\t\t}\n\t}\n\t&.expire-soon {\n\t\tsvg {\n\t\t\tfill: #f1c40f;\n\t\t}\n\t}\n\t&.not-expired {\n\t\tsvg {\n\t\t\tfill: #2e844d;\n\t\t}\n\t}\n"], ["\n\tsvg {\n\t\theight: 15px;\n\t\twidth: 15px;\n\t}\n\t&.expired {\n\t\tsvg {\n\t\t\tfill: #d73928;\n\t\t}\n\t}\n\t&.expire-soon {\n\t\tsvg {\n\t\t\tfill: #f1c40f;\n\t\t}\n\t}\n\t&.not-expired {\n\t\tsvg {\n\t\t\tfill: #2e844d;\n\t\t}\n\t}\n"])));
export var SigningClientUserRe = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    padding-top: 10px;\n    padding-bottom: 10px;\n\n    svg {\n        height: 14px;\n        width: 14px;\n\t\t\t\tmargin-top:5px;\n    }\n\t\t\n\t\t.ant-typography {\n        margin: 5px;\n\t\t}\n\n    &.expired {\n        svg {\n            fill: #d73928;\n        }\n    }\n\n    &.expire-soon {\n        svg {\n            fill: #f1c40f;\n        }\n    }\n\n    &.not-expired {\n        svg {\n            fill: #2e844d;\n        }\n    }\n"], ["\n    padding-top: 10px;\n    padding-bottom: 10px;\n\n    svg {\n        height: 14px;\n        width: 14px;\n\t\t\t\tmargin-top:5px;\n    }\n\t\t\n\t\t.ant-typography {\n        margin: 5px;\n\t\t}\n\n    &.expired {\n        svg {\n            fill: #d73928;\n        }\n    }\n\n    &.expire-soon {\n        svg {\n            fill: #f1c40f;\n        }\n    }\n\n    &.not-expired {\n        svg {\n            fill: #2e844d;\n        }\n    }\n"])));
export var InvalidSigningUsers = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n\tbackground-color: var(--color-error);\n\tpadding: 4px 11px;\n\t.ant-typography {\n\t\tcolor: var(--color-white);\n\t}\n\tmargin-bottom: 10px;\t\n"], ["\n\tbackground-color: var(--color-error);\n\tpadding: 4px 11px;\n\t.ant-typography {\n\t\tcolor: var(--color-white);\n\t}\n\tmargin-bottom: 10px;\t\n"])));
export var ArchivingErrorMessage = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n\tbackground-color: var(--color-white);\n\tpadding-bottom: 11px;\n\t.ant-typography {\n\t\tcolor: var(--color-error);\n\t\tfont-weight: bold;\n\t}\n"], ["\n\tbackground-color: var(--color-white);\n\tpadding-bottom: 11px;\n\t.ant-typography {\n\t\tcolor: var(--color-error);\n\t\tfont-weight: bold;\n\t}\n"])));
export var UploadingErrorMessage = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n\tbackground-color: var(--color-white);\n\tpadding-bottom: 11px;\n\t.ant-typography {\n\t\tcolor: var(--color-error);\n\t\tfont-weight: bold;\n\t}\n"], ["\n\tbackground-color: var(--color-white);\n\tpadding-bottom: 11px;\n\t.ant-typography {\n\t\tcolor: var(--color-error);\n\t\tfont-weight: bold;\n\t}\n"])));
export var CustomRowStyled = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: flex-end;\n\talign-content: baseline;\n\tpadding-bottom: 20px;\n"], ["\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: flex-end;\n\talign-content: baseline;\n\tpadding-bottom: 20px;\n"])));
export var QuickActionsPopupPlaceholderStyled = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n\tflex-basis: auto;\n"], ["\n\tflex-basis: auto;\n"])));
export var StickyButtonsContainer = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n\tposition: sticky;\n\tbottom: 0px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tbackground-color: #ffffff;\n\tpadding: 5px;\n\tpadding-top: 10px;\n\tpadding-bottom: 20px;\t\n\tmargin-bottom: -40px;\n\tgap: 20px;\n"], ["\n\tposition: sticky;\n\tbottom: 0px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tbackground-color: #ffffff;\n\tpadding: 5px;\n\tpadding-top: 10px;\n\tpadding-bottom: 20px;\t\n\tmargin-bottom: -40px;\n\tgap: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
