export var changePasswordApi = {
    url: "dde/usermanagement/password/change",
    method: "POST",
};
export function mapToChangePasswordApiConfig(formValues, username, origin) {
    return {
        data: {
            username: username,
            current: formValues.currentPassword,
            updated: formValues.newPassword,
            repeat: formValues.repeatedPassword,
            origin: origin
        },
    };
}
export function isComplexPassword(password) {
    var digitPatternRegex = RegExp("^.*\\d.*$");
    var loweCasePatternRegex = RegExp("^.*[a-z].*$");
    var upperCasePatternRegex = RegExp("^.*[A-Z].*$");
    /*
     punctuation was removed as it was not requested in specs
     const punctuationPatternRegex = RegExp("^.*[,;.:\\-_'\"].*$");
     */
    var specialPatternRegex = /[ `!@#$%^&*()+=[\]{}\\|<>/?~]/;
    var valid = digitPatternRegex.test(password) || specialPatternRegex.test(password);
    valid = valid && loweCasePatternRegex.test(password);
    valid = valid && upperCasePatternRegex.test(password);
    return valid;
}
export function isPasswordContainsValidCharacters(password) {
    var expectedCharactersRegex = RegExp("^[\\p{Script_Extensions=Latin}*,\\d,\\p{P},\\p{S}]*$", "gu");
    return expectedCharactersRegex.test(password);
}
