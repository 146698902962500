export var getMessagesApiConfig = {
    method: "GET",
    url: "/dde/dokubox/doku/",
    urlPostFix: "/messages",
};
export var saveReadMessages = {
    method: "POST",
    url: "/dde/dokubox/doku/",
    urlPostFix: "/message/highlight",
};
export var saveMessageApiConfig = {
    method: "POST",
    url: "/dde/dokubox/doku/",
    urlPostFix: "/message",
};
