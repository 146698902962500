var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import sanitizeHtml from "sanitize-html";
import { getLocaleFromLocalStorage } from "./appStorage";
import { unsafeLocalizedString } from "./formatters";
import { useTranslationsContext } from "./translationsContext";
export function createTranslation(formatMessage, translationKey) {
    return function (key, values) { return formatMessage(translationKey + key, values); };
}
var useFormatMessage = function () {
    var _a = useTranslationsContext(), translations = _a.translations, translationsEnabled = _a.translationsEnabled;
    var computeTranslationWithGivenParameters = function (translation, values) {
        var keys = Object.keys(values);
        keys.forEach(function (keyItem) {
            translation = translation.replace(new RegExp("{".concat(keyItem, "}"), "g"), "".concat(values[keyItem]));
        });
        return translation;
    };
    var formatMessage = useCallback(function (key, values) {
        if (!translations || !translationsEnabled) {
            return unsafeLocalizedString(key);
        }
        var translation = translations[key];
        if (values && translation) {
            translation = computeTranslationWithGivenParameters(translation, values);
        }
        return unsafeLocalizedString(translation ? translation : key);
    }, [translations, translationsEnabled]);
    var formatMessageMap = useCallback(function (messageMap) {
        var currentLocale = getLocaleFromLocalStorage();
        if (!messageMap || !currentLocale) {
            return "";
        }
        return messageMap[currentLocale.toUpperCase()] || messageMap[currentLocale.toLowerCase()] || "";
    }, []);
    var formatRichTextMessage = useCallback(function (key, values) {
        if (!translations || !translationsEnabled) {
            return unsafeLocalizedString(key);
        }
        var translation = translations[key];
        if (values && translation) {
            translation = computeTranslationWithGivenParameters(translation, values);
        }
        return _jsx("span", { dangerouslySetInnerHTML: { __html: sanitizeHtml(translation) } });
    }, [translations, translationsEnabled]);
    var formatLabelFromSelectOptions = useCallback(function (selectOptions, translationKey, valueProperty) {
        if (translationKey === void 0) { translationKey = "label"; }
        if (valueProperty === void 0) { valueProperty = "value"; }
        return selectOptions.map(function (sOption) { return (__assign(__assign({}, sOption), { value: sOption[valueProperty], label: formatMessage(sOption[translationKey]) })); });
    }, [formatMessage]);
    return { formatMessage: formatMessage, formatMessageMap: formatMessageMap, formatRichTextMessage: formatRichTextMessage, formatLabelFromSelectOptions: formatLabelFromSelectOptions };
};
export { useFormatMessage };
