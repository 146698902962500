var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo, useState } from "react";
export var UserType;
(function (UserType) {
    UserType["BANKER"] = "BANKER";
    UserType["CLIENT"] = "CLIENT";
})(UserType || (UserType = {}));
export var SegmentType;
(function (SegmentType) {
    SegmentType["MB"] = "MB";
    SegmentType["CORP"] = "CORP";
    SegmentType["ALL"] = "ALL";
})(SegmentType || (SegmentType = {}));
export var SubSegmentRS;
(function (SubSegmentRS) {
    SubSegmentRS["RETAIL"] = "RETAIL";
    SubSegmentRS["MB"] = "MB";
    SubSegmentRS["LARGE"] = "LARGE";
    SubSegmentRS["SME"] = "SME";
    SubSegmentRS["INTERNATIONAL"] = "INTERNATIONAL";
    SubSegmentRS["NON_SEGMENTED"] = "NON_SEGMENTED";
})(SubSegmentRS || (SubSegmentRS = {}));
export var SubSegmentBG;
(function (SubSegmentBG) {
    SubSegmentBG["LARGE"] = "LARGE";
    SubSegmentBG["MID"] = "MID";
    SubSegmentBG["INTL"] = "INTL";
    SubSegmentBG["SMALL"] = "SMALL";
    SubSegmentBG["REAL_EST"] = "REAL_EST";
    SubSegmentBG["SME"] = "SME";
})(SubSegmentBG || (SubSegmentBG = {}));
export var SubSegmentRO;
(function (SubSegmentRO) {
    SubSegmentRO["CORP_LARGE"] = "CORP_LARGE";
    SubSegmentRO["CORP_MEDIUM"] = "CORP_MEDIUM";
    SubSegmentRO["CORP_SMALL"] = "CORP_SMALL";
    SubSegmentRO["MICRO"] = "MICRO";
})(SubSegmentRO || (SubSegmentRO = {}));
export var SmeRegionRS;
(function (SmeRegionRS) {
    SmeRegionRS["NORTH"] = "NORTH";
    SmeRegionRS["CENTRAL"] = "CENTRAL";
    SmeRegionRS["SOUTH_EAST_WEST"] = "SOUTH_EAST_WEST";
})(SmeRegionRS || (SmeRegionRS = {}));
var initialContextState = {
    configurations: {},
    setConfigurations: function () { return undefined; },
    permissions: [],
    setPermissions: function () { return undefined; },
    roles: [],
    setRoles: function () { return undefined; },
    catalogues: [],
    setCatalogues: function () { return undefined; },
    isLoggedIn: false,
    setIsLoggedIn: function () { return undefined; },
    isActivationLoggedIn: false,
    setIsActivationLoggedIn: function () { return undefined; },
    banker: {
        firstName: "",
        lastName: "",
        userId: "",
        lastLogin: "",
        branchAddress: "",
        branchCode: "",
    },
    setBanker: function () { return undefined; },
    user: {},
    setUser: function () { return undefined; },
    currentFlow: "",
    setCurrentFlow: function () { return undefined; },
    portalBlockScheduled: false,
    setPortalBlockScheduled: function () { return undefined; },
    portalBlockScheduledDates: { dateAndTimeFrom: "", dateAndTimeTo: "" },
    setPortalBlockScheduledDates: function () { return undefined; },
    portalBlocked: false,
    setPortalBlocked: function () { return undefined; },
    portalBlockedDate: { dateAndTimeTo: "" },
    setPortalBlockedDate: function () { return undefined; },
    currentlyBlockedTitleKey: "",
    setCurrentlyBlockedTitleKey: function () { return undefined; },
    currentlyBlockedMessage: "",
    setCurrentlyBlockedMessage: function () { return undefined; },
    currentLockedDokuBoxId: "",
    setCurrentLockedDokuBoxId: function () { return undefined; },
    clientApp: false,
    setClientApp: function () { return undefined; },
    currentLockedTaskId: "",
    setCurrentLockedTaskId: function () { return undefined; },
    language: "",
    setLanguage: function () { return undefined; },
    clientList: [],
    setClientList: function () { return undefined; },
};
var AppContext = React.createContext(initialContextState);
var AppContextProvider = function (props) {
    var _a = useState({}), configurations = _a[0], setConfigurations = _a[1];
    var _b = useState(props.permissions), permissions = _b[0], setPermissions = _b[1];
    var _c = useState(props.roles), roles = _c[0], setRoles = _c[1];
    var _d = useState([]), catalogues = _d[0], setCatalogues = _d[1];
    var _e = useState(props.user ? (props.user.username ? true : false) : false), isLoggedIn = _e[0], setIsLoggedIn = _e[1];
    var _f = useState(false), isActivationLoggedIn = _f[0], setIsActivationLoggedIn = _f[1];
    var _g = useState({
        firstName: "",
        lastName: "",
        userId: "",
        lastLogin: "",
        branchAddress: "",
        branchCode: "",
    }), banker = _g[0], setBanker = _g[1];
    var _h = useState({}), user = _h[0], setUser = _h[1];
    var _j = useState(""), currentFlow = _j[0], setCurrentFlow = _j[1];
    var _k = useState(false), clientApp = _k[0], setClientApp = _k[1];
    var _l = useState(false), portalBlockScheduled = _l[0], setPortalBlockScheduled = _l[1];
    var _m = useState({
        dateAndTimeFrom: "",
        dateAndTimeTo: "",
    }), portalBlockScheduledDates = _m[0], setPortalBlockScheduledDates = _m[1];
    var _o = useState(false), portalBlocked = _o[0], setPortalBlocked = _o[1];
    var _p = useState({ dateAndTimeTo: "" }), portalBlockedDate = _p[0], setPortalBlockedDate = _p[1];
    var _q = useState(""), currentlyBlockedTitleKey = _q[0], setCurrentlyBlockedTitleKey = _q[1];
    var _r = useState(""), currentlyBlockedMessage = _r[0], setCurrentlyBlockedMessage = _r[1];
    var _s = useState(""), currentLockedDokuBoxId = _s[0], setCurrentLockedDokuBoxId = _s[1];
    var _t = useState(""), currentLockedTaskId = _t[0], setCurrentLockedTaskId = _t[1];
    var _u = useState(""), language = _u[0], setLanguage = _u[1];
    var _v = useState([]), clientList = _v[0], setClientList = _v[1];
    useEffect(function () {
        var portalStatus = props.portalStatus ? props.portalStatus["ALL"] : {};
        setConfigurations(props.configurations);
        setCatalogues(props.catalogues);
        setPortalBlocked(portalStatus === null || portalStatus === void 0 ? void 0 : portalStatus.currentlyBlocked);
        setPortalBlockedDate({ dateAndTimeTo: portalStatus === null || portalStatus === void 0 ? void 0 : portalStatus.timeTo });
        setPortalBlockScheduled(portalStatus === null || portalStatus === void 0 ? void 0 : portalStatus.portalBlockScheduled);
        setPortalBlockScheduledDates({ dateAndTimeFrom: portalStatus === null || portalStatus === void 0 ? void 0 : portalStatus.timeFrom, dateAndTimeTo: portalStatus === null || portalStatus === void 0 ? void 0 : portalStatus.timeTo });
        setUser(props.user);
        setPermissions(props.permissions);
        setRoles(props.roles);
        setIsLoggedIn(props.user ? (props.user.username ? true : false) : false);
        setClientApp(props.clientApp);
    }, [props.configurations, props.catalogues, props.user, props.permissions, props.roles, props.portalStatus]);
    var value = useMemo(function () { return ({
        configurations: configurations,
        setConfigurations: setConfigurations,
        permissions: permissions,
        setPermissions: setPermissions,
        roles: roles,
        setRoles: setRoles,
        catalogues: catalogues,
        setCatalogues: setCatalogues,
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        isActivationLoggedIn: isActivationLoggedIn,
        setIsActivationLoggedIn: setIsActivationLoggedIn,
        banker: banker,
        setBanker: setBanker,
        user: user,
        setUser: setUser,
        currentFlow: currentFlow,
        setCurrentFlow: setCurrentFlow,
        portalBlockScheduled: portalBlockScheduled,
        setPortalBlockScheduled: setPortalBlockScheduled,
        portalBlockScheduledDates: portalBlockScheduledDates,
        setPortalBlockScheduledDates: setPortalBlockScheduledDates,
        portalBlocked: portalBlocked,
        setPortalBlocked: setPortalBlocked,
        portalBlockedDate: portalBlockedDate,
        setPortalBlockedDate: setPortalBlockedDate,
        currentlyBlockedTitleKey: currentlyBlockedTitleKey,
        setCurrentlyBlockedTitleKey: setCurrentlyBlockedTitleKey,
        currentlyBlockedMessage: currentlyBlockedMessage,
        setCurrentlyBlockedMessage: setCurrentlyBlockedMessage,
        currentLockedDokuBoxId: currentLockedDokuBoxId,
        setCurrentLockedDokuBoxId: setCurrentLockedDokuBoxId,
        clientApp: clientApp,
        setClientApp: setClientApp,
        currentLockedTaskId: currentLockedTaskId,
        setCurrentLockedTaskId: setCurrentLockedTaskId,
        language: language,
        setLanguage: setLanguage,
        clientList: clientList,
        setClientList: setClientList,
    }); }, [
        configurations,
        permissions,
        roles,
        catalogues,
        isLoggedIn,
        isActivationLoggedIn,
        banker,
        user,
        currentFlow,
        portalBlockScheduled,
        portalBlockScheduledDates,
        portalBlocked,
        portalBlockedDate,
        currentlyBlockedTitleKey,
        currentlyBlockedMessage,
        currentLockedDokuBoxId,
        clientApp,
        currentLockedTaskId,
        language,
        clientList,
    ]);
    return _jsx(AppContext.Provider, __assign({ value: value }, { children: props.children }));
};
var useAppContext = function () {
    var _a = useContext(AppContext), configurations = _a.configurations, setConfigurations = _a.setConfigurations, permissions = _a.permissions, setPermissions = _a.setPermissions, roles = _a.roles, setRoles = _a.setRoles, catalogues = _a.catalogues, setCatalogues = _a.setCatalogues, isLoggedIn = _a.isLoggedIn, setIsLoggedIn = _a.setIsLoggedIn, isActivationLoggedIn = _a.isActivationLoggedIn, setIsActivationLoggedIn = _a.setIsActivationLoggedIn, banker = _a.banker, setBanker = _a.setBanker, user = _a.user, setUser = _a.setUser, currentFlow = _a.currentFlow, setCurrentFlow = _a.setCurrentFlow, portalBlockScheduled = _a.portalBlockScheduled, setPortalBlockScheduled = _a.setPortalBlockScheduled, portalBlockScheduledDates = _a.portalBlockScheduledDates, setPortalBlockScheduledDates = _a.setPortalBlockScheduledDates, portalBlocked = _a.portalBlocked, setPortalBlocked = _a.setPortalBlocked, portalBlockedDate = _a.portalBlockedDate, setPortalBlockedDate = _a.setPortalBlockedDate, currentlyBlockedTitleKey = _a.currentlyBlockedTitleKey, setCurrentlyBlockedTitleKey = _a.setCurrentlyBlockedTitleKey, currentlyBlockedMessage = _a.currentlyBlockedMessage, setCurrentlyBlockedMessage = _a.setCurrentlyBlockedMessage, currentLockedDokuBoxId = _a.currentLockedDokuBoxId, setCurrentLockedDokuBoxId = _a.setCurrentLockedDokuBoxId, clientApp = _a.clientApp, setClientApp = _a.setClientApp, currentLockedTaskId = _a.currentLockedTaskId, setCurrentLockedTaskId = _a.setCurrentLockedTaskId, language = _a.language, setLanguage = _a.setLanguage, clientList = _a.clientList, setClientList = _a.setClientList;
    return {
        configurations: configurations,
        setConfigurations: setConfigurations,
        permissions: permissions,
        setPermissions: setPermissions,
        roles: roles,
        setRoles: setRoles,
        catalogues: catalogues,
        setCatalogues: setCatalogues,
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        isActivationLoggedIn: isActivationLoggedIn,
        setIsActivationLoggedIn: setIsActivationLoggedIn,
        banker: banker,
        setBanker: setBanker,
        user: user,
        setUser: setUser,
        currentFlow: currentFlow,
        setCurrentFlow: setCurrentFlow,
        portalBlockScheduled: portalBlockScheduled,
        setPortalBlockScheduled: setPortalBlockScheduled,
        portalBlockScheduledDates: portalBlockScheduledDates,
        setPortalBlockScheduledDates: setPortalBlockScheduledDates,
        portalBlocked: portalBlocked,
        setPortalBlocked: setPortalBlocked,
        portalBlockedDate: portalBlockedDate,
        setPortalBlockedDate: setPortalBlockedDate,
        currentlyBlockedTitleKey: currentlyBlockedTitleKey,
        setCurrentlyBlockedTitleKey: setCurrentlyBlockedTitleKey,
        currentlyBlockedMessage: currentlyBlockedMessage,
        setCurrentlyBlockedMessage: setCurrentlyBlockedMessage,
        currentLockedDokuBoxId: currentLockedDokuBoxId,
        setCurrentLockedDokuBoxId: setCurrentLockedDokuBoxId,
        clientApp: clientApp,
        setClientApp: setClientApp,
        currentLockedTaskId: currentLockedTaskId,
        setCurrentLockedTaskId: setCurrentLockedTaskId,
        language: language,
        setLanguage: setLanguage,
        clientList: clientList,
        setClientList: setClientList,
    };
};
export { AppContextProvider, useAppContext };
