var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { Layout } from "antd";
import { DokuBoxSelectionForm } from "../CommonComponents/DokuBoxSelectionForm";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppContext } from "../../services/appContext";
import { useFormatMessage } from "../../services/formatMessageHook";
import { useTenantContext } from "../../services/tenantContext";
import { MainContainerStyled } from "../../styles/styles";
import { usePermission } from "../../utils/permissions";
import { executeAxios } from "../../services/axiosHook";
var createDokuboxApiConfig = {
    url: "/dde/dokubox/client/create",
    method: "POST",
};
var ClientCreateDokuBox = function () {
    var navigate = useNavigate();
    var hasAnyOfTheRoles = usePermission().hasAnyOfTheRoles;
    var formatMessage = useFormatMessage().formatMessage;
    var user = useAppContext().user;
    var tenant = useTenantContext().tenant;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var onSubmit = function (values) {
        var apiConfig = {
            data: {
                dokuboxDefinitionId: values.dokuBox,
                customName: values.customName,
            },
        };
        return executeAxios({
            params: __assign(__assign({}, createDokuboxApiConfig), apiConfig),
            onFail: function (resp) {
                var _a;
                showErrorMessage({
                    title: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
            onLoading: setLoading,
            onSuccess: function (result) {
                navigate("/app/editdokubox/" + result.dokuBoxId);
            },
        });
    };
    if (!hasAnyOfTheRoles(["DOKUBOX_CREATE"])) {
        return _jsx(Navigate, { to: "/app/dokuboxlist" });
    }
    return (_jsxs(MainContainerStyled, { children: [_jsxs(Layout, { children: [_jsx("h2", { children: formatMessage("dokubox.create.title") }), _jsx("div", { children: _jsx(DokuBoxSelectionForm, { initialValues: { productCategory: "", productCategorySegment: undefined, dokuBox: NaN, customName: "" }, isClient: true, segment: user === null || user === void 0 ? void 0 : user.segment, onSubmit: function (values) {
                                return onSubmit(values);
                            }, onBack: function () {
                                navigate("/app/dokuboxlist");
                            } }) })] }), _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), loading && _jsx(Spinner, {})] }));
};
export default ClientCreateDokuBox;
