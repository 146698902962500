var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Accordion } from "@cbp/fe-commons";
import { AccordionBox, AccordionContainer } from "./styles";
import DokuPanelTitle from "./DokuPanelTitle";
import DokuPanelBody from "./DokuPanelBody";
import { UserType } from "../DokuMessages/services/domain";
import DokuPanelExtraContent from "./DokuPanelExtraContent";
var getSplitDokuAccordionList = function (dokuPanels) {
    var leftList = [];
    var rightList = [];
    if (dokuPanels !== undefined) {
        dokuPanels.forEach(function (doku) {
            if (doku.index % 2 == 0) {
                leftList.push(doku);
            }
            else {
                rightList.push(doku);
            }
        });
    }
    return { leftList: leftList, rightList: rightList };
};
var AccordionSort = function (a, b) {
    if (a.ordinalNumber !== undefined && b.ordinalNumber !== undefined) {
        return a.ordinalNumber - b.ordinalNumber;
    }
    else {
        return 0;
    }
};
export var DokuAccordion = function (props) {
    var _a = useState(new Map()), dokuMap = _a[0], setDokuMap = _a[1];
    var _b = useState([getDefaultActiveKey()]), activeKeyLeft = _b[0], setActiveKeyLeft = _b[1];
    var _c = useState([getDefaultActiveKey()]), activeKeyRight = _c[0], setActiveKeyRight = _c[1];
    function getDefaultActiveKey() {
        return props.dokuBox.dokus.filter(function (value) { return value.usedInBox; }).length > 2 ? "-1" : "0";
    }
    var updateDokuMap = function (key, value) {
        setDokuMap(function (map) { return new Map(map.set(key, value)); });
    };
    var dokuPanels = props.dokuBox.dokus &&
        props.dokuBox.dokus.filter(function (doku) { return doku.usedInBox; })
            .sort(function (a, b) { return AccordionSort(a, b); })
            .map(function (doku, index) {
            var panelProps = {
                index: index,
                title: _jsx(DokuPanelTitle, { doku: doku, dokuBox: props.dokuBox, index: index + 1 }, "doku_title".concat(doku.id)),
                body: _jsx(DokuPanelBody, { form: props.form, doku: doku, dokuBox: props.dokuBox, readOnly: props.readOnly, userType: props.userType, onFinalize: props.onFinalize, onSubmitUpload: props.onSubmitUpload, closeDokuBox: props.closeDokuBox, dokuMap: dokuMap, index: index + 1, updateDeleteMap: updateDokuMap, onDokuActionSelected: props.onDokuActionSelected, failedUpload: props.failedUpload, onFileChange: props.onFileChange }, "doku_body".concat(doku.id)),
                extraContent: _jsx(DokuPanelExtraContent, { form: props.form, doku: doku, dokuBox: props.dokuBox, readOnly: props.readOnly, userType: UserType.BANKER, updateDeleteMap: updateDokuMap, index: index + 1, setActiveKeyLeft: setActiveKeyLeft, setActiveKeyRight: setActiveKeyRight, onUndoAddOtherDoku: props.onUndoAddOtherDoku }, "doku_extra".concat(doku.id)),
            };
            return panelProps;
        });
    return (_jsx("div", __assign({ className: 'use-fe-commons-styles' }, { children: _jsxs(AccordionContainer, { children: [_jsx(AccordionBox, { children: _jsx(Accordion, { panels: getSplitDokuAccordionList(dokuPanels).leftList, defaultActiveKey: getDefaultActiveKey(), activeKey: activeKeyLeft, onChange: function (activeKey) {
                            setActiveKeyLeft(activeKey);
                        } }) }), _jsx(AccordionBox, { children: _jsx(Accordion, { panels: getSplitDokuAccordionList(dokuPanels).rightList, defaultActiveKey: getDefaultActiveKey(), activeKey: activeKeyRight, onChange: function (activeKey) {
                            setActiveKeyRight(activeKey);
                        } }) })] }) })));
};
export default DokuAccordion;
