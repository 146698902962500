var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Form, Input, Select, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { findBankerDokuBoxDefinitionsByCategory, findClientDokuBoxDefinitionsByCategory, getBankerDokuBoxCategories, getClientDokuBoxCategories, } from "../BankerCreateDokuBox/services/createDokuBoxApi";
import { useCallback, useEffect, useState } from "react";
import { SegmentType, useAppContext, UserType } from "../../services/appContext";
import { executeAxios } from "../../services/axiosHook";
import { useFormatMessage } from "../../services/formatMessageHook";
import { useTenantContext } from "../../services/tenantContext";
import { getByLocale, useCatalogues } from "../../services/appApi";
export var DokuBoxSelectionForm = function (props) {
    var _a, _b;
    var formatMessage = useFormatMessage().formatMessage;
    var form = Form.useForm()[0];
    var showGeneralError = useCallback(function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    }, [formatMessage]);
    var _c = useState([]), categories = _c[0], setCategories = _c[1];
    var _d = useState([]), dokuBoxDefinitions = _d[0], setDokuBoxDefinitions = _d[1];
    var _e = useState(!props.categories), loading = _e[0], setLoading = _e[1];
    var _f = useState(false), categorySelected = _f[0], setCategorySelected = _f[1];
    var _g = useState(false), categoryChanged = _g[0], setCategoryChanged = _g[1];
    var _h = useAppContext(), catalogues = _h.catalogues, user = _h.user;
    var getProductCategoriesSegment = useCatalogues().getProductCategoriesSegment;
    var tenant = useTenantContext().tenant;
    var _j = useState(false), isSubmitting = _j[0], setIsSubmitting = _j[1];
    var _k = useState(), selectedDokuboxDefinition = _k[0], setSelectedDokuboxDefinition = _k[1];
    useEffect(function () {
        var getCategoriesCorp = function () {
            return catalogues && catalogues.catalogueList && catalogues.catalogueList.find(function (c) { return c && c.catalogueType === "ProductCategoryCORP"; })
                ? catalogues.catalogueList.find(function (c) { return c && c.catalogueType === "ProductCategoryCORP"; }).catalogueItemList || []
                : [];
        };
        var getCategoriesMb = function () {
            return catalogues && catalogues.catalogueList && catalogues.catalogueList.find(function (c) { return c && c.catalogueType === "ProductCategoryMB"; })
                ? catalogues.catalogueList.find(function (c) { return c && c.catalogueType === "ProductCategoryMB"; }).catalogueItemList || []
                : [];
        };
        var getCategoriesAll = function () {
            return catalogues && catalogues.catalogueList && catalogues.catalogueList.find(function (c) { return c && c.catalogueType === "ProductCategoryALL"; })
                ? catalogues.catalogueList.find(function (c) { return c && c.catalogueType === "ProductCategoryALL"; }).catalogueItemList || []
                : [];
        };
        var filterCategoryListBySegment = function (fullCategoryList, addSegmentAll) {
            var categoriesAll = addSegmentAll ? getCategoriesAll() : undefined;
            if (props.segment === SegmentType.MB) {
                var categoriesMb_1 = getCategoriesMb();
                return fullCategoryList.filter(function (item) {
                    return (item.segment === SegmentType.MB &&
                        (categoriesMb_1.length > 0 ? categoriesMb_1.find(function (c) { return c && c.value === item.categoryCode; }) : false)) ||
                        (addSegmentAll &&
                            item.segment === SegmentType.ALL &&
                            categoriesAll &&
                            (categoriesAll.length > 0 ? categoriesAll.find(function (c) { return c && c.value === item.categoryCode; }) : false)) ||
                        (tenant === "RO" &&
                            categoriesAll &&
                            item.segment === SegmentType.MB &&
                            (categoriesAll.length > 0 ? categoriesAll.find(function (c) { return c && c.value === item.categoryCode; }) : false));
                });
            }
            else if (props.segment === SegmentType.CORP) {
                var categoriesCorp_1 = getCategoriesCorp();
                return fullCategoryList.filter(function (item) {
                    return (item.segment === SegmentType.CORP && categoriesCorp_1.length > 0
                        ? categoriesCorp_1.find(function (c) { return c && c.value === item.categoryCode; })
                        : false) ||
                        (addSegmentAll &&
                            item.segment === SegmentType.ALL &&
                            categoriesAll &&
                            (categoriesAll.length > 0 ? categoriesAll.find(function (c) { return c && c.value === item.categoryCode; }) : false)) ||
                        (tenant === "RO" &&
                            categoriesAll &&
                            item.segment === SegmentType.CORP &&
                            (categoriesAll.length > 0 ? categoriesAll.find(function (c) { return c && c.value === item.categoryCode; }) : false));
                });
            }
            else {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: user && user.userType === UserType.BANKER
                        ? formatMessage("dokubox.create.error.nosegmenttype.banker")
                        : formatMessage("dokubox.create.error.nosegmenttype"),
                });
                return [];
            }
        };
        var resolveCategoryTranslation = function (categoryCode, segment) {
            var categoriesCorp = getCategoriesCorp();
            var categoriesMb = getCategoriesMb();
            var categoriesAll = getCategoriesAll();
            var foundItem;
            if (segment === SegmentType.MB) {
                foundItem = categoriesMb.find(function (c) { return c && c.value === categoryCode; });
            }
            else if (segment === SegmentType.CORP) {
                foundItem = categoriesCorp.find(function (c) { return c && c.value === categoryCode; });
            }
            else if (segment === SegmentType.ALL) {
                foundItem = categoriesAll.find(function (c) { return c && c.value === categoryCode; });
            }
            if (tenant === "RO" && foundItem === undefined) {
                foundItem = categoriesAll.find(function (c) { return c && c.value === categoryCode; });
            }
            return foundItem && foundItem.translationKey ? foundItem.translationKey : categoryCode;
        };
        if (props.categories && props.initialValues.productCategory && props.dokuBoxDefinitions) {
            setCategories(props.categories);
            setDokuBoxDefinitions(props.dokuBoxDefinitions);
            setSelectedDokuboxDefinition(props.dokuBoxDefinitions.find(function (dokuBoxDefinition) { return dokuBoxDefinition.id === props.initialValues.dokuBox; }));
            setCategorySelected(true);
        }
        else {
            var isShowAllCategoryValuesOfSegment = (tenant === "CZ" || tenant === "SK") && !props.isClient;
            if (isShowAllCategoryValuesOfSegment) {
                var result = !props.segment
                    ? []
                    : getProductCategoriesSegment(props.segment).map(function (item) {
                        return {
                            categoryCode: item.value,
                            categoryName: resolveCategoryTranslation(item.value, props.segment),
                            segment: props.segment,
                            key: item.value,
                        };
                    });
                setCategories(filterCategoryListBySegment(result, false));
                setLoading(false);
            }
            else {
                var isAddAllSegment_1 = tenant === "BG" || tenant === "HR" || tenant === "RO";
                var api = props.isClient ? getClientDokuBoxCategories : getBankerDokuBoxCategories;
                executeAxios({
                    params: __assign({}, api),
                    onLoading: setLoading,
                    onFail: showGeneralError,
                    onSuccess: function (result) {
                        if (result && result.length > 0) {
                            result.forEach(function (item) {
                                item.key = item.categoryCode;
                                item.categoryName = resolveCategoryTranslation(item.categoryCode, item.segment);
                            });
                            setCategories(filterCategoryListBySegment(result, isAddAllSegment_1));
                        }
                        else {
                            setCategories([]);
                        }
                    },
                });
            }
        }
        return function () { };
    }, [props, catalogues, user, formatMessage, showGeneralError]);
    function loadDokuBoxDefinitions(value, productSegment) {
        setCategorySelected(false);
        var apiConfig = {
            data: {
                categoryCode: value,
                segmentType: productSegment,
            },
        };
        var api = props.isClient ? findClientDokuBoxDefinitionsByCategory : findBankerDokuBoxDefinitionsByCategory;
        executeAxios({
            params: __assign(__assign({}, api), apiConfig),
            onFail: showGeneralError,
            onLoading: setLoading,
            onSuccess: function (result) {
                form.resetFields(["dokuBoxSelect"]);
                setSelectedDokuboxDefinition(undefined);
                if (result && result.length > 0) {
                    result.forEach(function (item) {
                        item.key = item.id;
                    });
                    setDokuBoxDefinitions(result);
                    setCategorySelected(true);
                }
                else {
                    setDokuBoxDefinitions([]);
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.createdokubox.error.category.noSelectableDokuboxDefinition"),
                    });
                }
            },
        });
    }
    var categoryOptions = (props.categories || categories).map(function (opt) { return ({
        value: opt.categoryCode,
        label: formatMessage(opt.categoryName),
    }); });
    var dokuBoxOptions = dokuBoxDefinitions.map(function (opt) { return ({
        value: opt.id,
        label: formatMessage(getByLocale(opt.titleEn, opt.titleLocal)),
    }); });
    var getDokuBoxValue = function () {
        return categoryChanged ? form.getFieldsValue().dokuBox : props.initialValues.dokuBox;
    };
    return (_jsxs("div", { children: [_jsxs(Form, __assign({ form: form, disabled: isSubmitting, onFinish: function () {
                    setIsSubmitting(true);
                    props.onSubmit(form.getFieldsValue(), categories, dokuBoxDefinitions).finally(function () { return setIsSubmitting(false); });
                } }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "productCategory" }, { children: formatMessage("dokubox.create.dokuboxselection.label.ProductCategory") })), _jsx(Form.Item, __assign({ rules: [{ required: true, message: formatMessage("dde.validation.common.requiredField") }], name: "productCategorySelect", initialValue: categoryOptions.find(function (opt) { return opt.value === props.initialValues.productCategory; }) }, { children: _jsx(Select, { placeholder: formatMessage("dokubox.create.dokuboxselection.label.ProductCategory"), onChange: function (target) {
                                var _a;
                                var productSegment = ((_a = categories === null || categories === void 0 ? void 0 : categories.find(function (category) { return category.categoryCode === (target === null || target === void 0 ? void 0 : target.value); })) === null || _a === void 0 ? void 0 : _a.segment) || props.segment;
                                form.setFieldValue("productCategory", target === null || target === void 0 ? void 0 : target.value);
                                form.setFieldValue("productCategorySegment", productSegment);
                                form.setFieldValue("dokuBoxSelect", null);
                                form.setFieldValue("dokuBox", null);
                                setCategoryChanged(true);
                                loadDokuBoxDefinitions(target === null || target === void 0 ? void 0 : target.value, productSegment);
                            }, options: categoryOptions, isClearable: true }) })), _jsx(Form.Item, { name: "productCategory", initialValue: props.initialValues.productCategory, style: { display: "none" } }), _jsx(Form.Item, { name: "productCategorySegment", initialValue: props.initialValues.productCategorySegment, style: { display: "none" } }), categorySelected && (_jsxs(_Fragment, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "dokuBox" }, { children: formatMessage("dokubox.create.dokuboxselection.label.DokuBox") })), _jsx(Form.Item, __assign({ rules: [
                                    {
                                        required: true,
                                        message: formatMessage("dde.validation.common.requiredField"),
                                    },
                                ], name: "dokuBoxSelect", initialValue: !categoryChanged ? dokuBoxOptions.find(function (opt) { return opt.value === props.initialValues.dokuBox; }) : null }, { children: _jsx(Select, { placeholder: formatMessage("dokubox.create.dokuboxselection.label.DokuBox"), onChange: function (target) {
                                        form.setFieldValue("dokuBox", target === null || target === void 0 ? void 0 : target.value);
                                        var selecteddokuBoxDefinition = dokuBoxDefinitions.find(function (dokuBoxDefinition) { return dokuBoxDefinition.id === (target === null || target === void 0 ? void 0 : target.value); });
                                        setSelectedDokuboxDefinition(selecteddokuBoxDefinition);
                                    }, options: dokuBoxOptions, isClearable: true }) })), _jsx(Form.Item, { name: "dokuBox", initialValue: !categoryChanged ? props.initialValues.dokuBox : null, style: { display: "none" } }), ((selectedDokuboxDefinition === null || selectedDokuboxDefinition === void 0 ? void 0 : selectedDokuboxDefinition.instructionEn) || (selectedDokuboxDefinition === null || selectedDokuboxDefinition === void 0 ? void 0 : selectedDokuboxDefinition.instructionLocal)) && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(Card, { type: "default", title: formatMessage("dokubox.create.dokuboxselection.label.Instruction"), text: getByLocale(selectedDokuboxDefinition.instructionEn, selectedDokuboxDefinition.instructionLocal), size: "small" })] })), !props.isClient && ((_a = dokuBoxDefinitions.find(function (opt) { return opt.id === getDokuBoxValue(); })) === null || _a === void 0 ? void 0 : _a.internalNotes) && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(Card, { type: "default", title: formatMessage("dokubox.create.dokuboxselection.label.InternalNotes"), text: (_b = dokuBoxDefinitions.find(function (opt) { return opt.id === getDokuBoxValue(); })) === null || _b === void 0 ? void 0 : _b.internalNotes, size: "small" })] })), props.isClient && dokuBoxDefinitions.find(function (opt) { return opt.id === getDokuBoxValue(); }) && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("label", __assign({ className: "input-label", htmlFor: "customName" }, { children: formatMessage("dokubox.create.dokuboxselection.label.CustomName") })), _jsx(Form.Item, __assign({ rules: [
                                            {
                                                max: 100,
                                                message: formatMessage("dde.validation.common.maxLength100"),
                                            },
                                        ], name: "customName" }, { children: _jsx(Input, { placeholder: formatMessage("dokubox.create.dokuboxselection.placeholder.CustomName") }) }))] }))] })), _jsxs("div", __assign({ style: { textAlign: "right" } }, { children: [_jsx("br", {}), _jsx("br", {}), props.onBack && (_jsx(_Fragment, { children: _jsx(Button, __assign({ style: { float: "left" }, type: "default", onClick: function () {
                                        props.onBack && props.onBack();
                                    } }, { children: formatMessage("dokubox.create.button.Back") })) })), _jsx(Button, __assign({ type: "primary", htmlType: "submit" }, { children: props.isClient ? formatMessage("dokubox.create.button.Start") : formatMessage("dokubox.create.button.Next") }))] }))] })), loading && _jsx(Spinner, {})] }));
};
