var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Form, Input, Modal, Row, Spinner, showErrorMessage, InfoSmallIcon } from "@g4p/react-styleguide-library";
import { useEffect, useState } from "react";
import { getLoggedInAlias, getLoggedInUserName } from "../../services/appStorage";
import { useFormatMessage } from "../../services/formatMessageHook";
import { useTenantContext } from "../../services/tenantContext";
import { changeUsernameApi } from "./services/changeUsernameService";
import { ALLOWED_CHARS, getMax, getMin } from "./services/domain";
import { Tooltip } from "antd";
var ChangeUsernameModal = function (props) {
    var tenant = useTenantContext().tenant;
    var _a = useState(getLoggedInAlias()), oldAlias = _a[0], setOldAlias = _a[1];
    var username = getLoggedInUserName();
    if (oldAlias === null) {
        setOldAlias(username);
    }
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), submitDisabled = _c[0], setSubmitDisabled = _c[1];
    var changeUsernameForm = Form.useForm()[0];
    var formatMessage = useFormatMessage().formatMessage;
    var changeUsername = changeUsernameApi().changeUsername;
    var onSubmit = function () {
        setIsLoading(true);
        var newAliasValue = changeUsernameForm.getFieldValue("newAlias");
        changeUsername({
            data: {
                username: username,
                oldAlias: oldAlias,
                newAlias: newAliasValue,
            },
        })
            .then(function () {
            setOldAlias(newAliasValue);
            localStorage.setItem("loggedInAlias", newAliasValue);
            changeUsernameForm.resetFields();
            setIsLoading(false);
            props.onClose();
        })
            .catch(function (error) {
            var _a, _b;
            setIsLoading(false);
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage(((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messageKey) || "dde.error.generalError"),
            });
        });
    };
    useEffect(function () {
        changeUsernameForm.setFieldValue("oldAlias", oldAlias);
    }, [oldAlias]);
    var getChangeUsernameRules = function () {
        var rules = [
            {
                required: true,
                message: formatMessage("dde.error.user.alias.required"),
            },
            {
                min: getMin(tenant),
                message: formatMessage("dde.error.user.alias.minLength", { min: getMin(tenant) }),
            },
            {
                max: getMax(tenant),
                message: formatMessage("dde.error.user.alias.maxLength", { max: getMax(tenant) }),
            },
            {
                pattern: RegExp("^(?!" + oldAlias + "$).*$"),
                message: formatMessage("dde.error.user.alias.same"),
            },
            {
                pattern: RegExp(".*[a-zA-Z0-9].*"),
                message: formatMessage("dde.error.user.alias.noSpecialCharacterOnly"),
            },
            {
                pattern: RegExp("^[" + ALLOWED_CHARS + "]*?([" + ALLOWED_CHARS + "])(?!\\1|$)[" + ALLOWED_CHARS + "]*$"),
                message: formatMessage("dde.error.user.alias.noSameCharacterOnly"),
            },
            {
                pattern: RegExp("^[" + ALLOWED_CHARS + "]*$"),
                message: formatMessage("dde.error.user.alias.specialCharacters"),
            },
        ];
        return rules;
    };
    var onChange = function () {
        changeUsernameForm
            .validateFields()
            .then(function () {
            setSubmitDisabled(changeUsernameForm.getFieldsError()[1].errors.length > 0);
        })
            .catch(function () {
            setSubmitDisabled(changeUsernameForm.getFieldsError()[1].errors.length > 0);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Modal, __assign({ title: formatMessage("dde.changeUsername.title"), open: props.isVisible, cancelText: formatMessage("dde.changeUsername.cancel"), onCancel: function () {
                    changeUsernameForm.resetFields();
                    props.onClose();
                }, okText: formatMessage("dde.changeUsername.submitBtn"), okButtonProps: {
                    disabled: submitDisabled,
                }, onOk: onSubmit }, { children: _jsx(Form, __assign({ form: changeUsernameForm }, { children: _jsxs(Row, __assign({ style: { padding: 0 } }, { children: [_jsx(Col, __assign({ ds: 1 }, { children: _jsx(_Fragment, {}) })), _jsxs(Col, __assign({ ds: 5 }, { children: [_jsxs("label", __assign({ className: "input-label", htmlFor: "reason" }, { children: [_jsx("div", __assign({ style: { display: 'inline-block' } }, { children: formatMessage("dde.changeUsername.currentUsername") })), _jsx(Tooltip, __assign({ title: formatMessage("dde.changeUsername.info.oldUsername") }, { children: _jsx(InfoSmallIcon, { style: { verticalAlign: 'middle', marginLeft: '5px' }, width: 12 }) }))] })), _jsx(Form.Item, __assign({ name: "oldAlias", initialValue: oldAlias }, { children: _jsx(Input, { disabled: true }) }))] })), _jsxs(Col, __assign({ ds: 5 }, { children: [_jsxs("label", __assign({ className: "input-label", htmlFor: "reason" }, { children: [_jsx("div", __assign({ style: { display: 'inline-block' } }, { children: formatMessage("dde.changeUsername.newUsername") })), _jsx(Tooltip, __assign({ title: formatMessage("dde.changeUsername.info.newUsername") }, { children: _jsx(InfoSmallIcon, { style: { verticalAlign: 'middle', marginLeft: '5px' }, width: 12 }) }))] })), _jsx(Form.Item, __assign({ rules: getChangeUsernameRules(), name: "newAlias" }, { children: _jsx(Input, { id: "newAlias", onChange: function () { return onChange(); } }) }))] })), _jsx(Col, __assign({ ds: 1 }, { children: _jsx(_Fragment, {}) }))] })) })) })), isLoading && _jsx(Spinner, {})] }));
};
export default ChangeUsernameModal;
