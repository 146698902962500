var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo, useState } from "react";
var initialContextState = {
    translations: {},
    setTranslations: function () { return undefined; },
    setTranslation: function (key, value) { return undefined; },
    setTranslationsEnabled: function () { return undefined; },
    translationsEnabled: true,
};
var TranslationsContext = React.createContext(initialContextState);
var TranslationsContextProvider = function (props) {
    var _a = useState({}), translations = _a[0], setTranslations = _a[1];
    var _b = useState(true), translationsEnabled = _b[0], setTranslationsEnabled = _b[1];
    var setTranslation = function (key, value) {
        return setTranslations(function (prevState) {
            prevState[key] = value;
            return prevState;
        });
    };
    useEffect(function () {
        setTranslations(props.translations);
    }, [props.translations]);
    var value = useMemo(function () { return ({
        translations: translations,
        setTranslations: setTranslations,
        setTranslation: setTranslation,
        translationsEnabled: translationsEnabled,
        setTranslationsEnabled: setTranslationsEnabled,
    }); }, [translations, translationsEnabled]);
    return _jsx(TranslationsContext.Provider, __assign({ value: value }, { children: props.children }));
};
var useTranslationsContext = function () {
    var _a = useContext(TranslationsContext), translations = _a.translations, setTranslations = _a.setTranslations, setTranslation = _a.setTranslation, translationsEnabled = _a.translationsEnabled, setTranslationsEnabled = _a.setTranslationsEnabled;
    return {
        translations: translations,
        setTranslations: setTranslations,
        setTranslation: setTranslation,
        translationsEnabled: translationsEnabled,
        setTranslationsEnabled: setTranslationsEnabled,
    };
};
export { TranslationsContextProvider, useTranslationsContext };
