var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, FaqIcon, Row } from "@g4p/react-styleguide-library";
import { Tooltip, Typography } from "antd";
import { Header } from "../../../../components/Header/Header";
import { useMemo } from "react";
import { getSupportedTenants, setLastLinkPressed_Dev } from "../../../../services/appStorage";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import styled from "styled-components";
import { LoginLeftHalfStyled, LoginRightHalfStyled, LoginWrapperStyled } from "../styles";
import LoginBox from "./LoginBox";
var RightContentStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex: 1;\n\talign-items: center;\n\tjustify-content: flex-end;\n\n\th5 {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-bottom: 0;\n\t}\n"], ["\n\tdisplay: flex;\n\tflex: 1;\n\talign-items: center;\n\tjustify-content: flex-end;\n\n\th5 {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-bottom: 0;\n\t}\n"])));
var LoginWrapper = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var developerLinkActions = useMemo(function () {
        var _a, _b;
        var actions = [];
        var jump = function (pathPrefix, tenant) {
            setLastLinkPressed_Dev(pathPrefix, tenant);
            window.location.href = "/" + pathPrefix + tenant;
        };
        if (process.env.REACT_APP_IS_LOCAL_DEV_ENV === "true") {
            var intranetPathName = (_a = window.location.href.match("intranet[A-Z][A-Z]")) === null || _a === void 0 ? void 0 : _a.at(0);
            var internetPathName = (_b = window.location.href.match("index[A-Z][A-Z]")) === null || _b === void 0 ? void 0 : _b.at(0);
            var pathName = intranetPathName || internetPathName || "";
            var urlTenant_1 = pathName ? pathName.substring(pathName.length - 2) : undefined;
            var urlPathPrefix_1 = pathName ? pathName.substring(0, pathName.length - 2) : undefined;
            var tenantList_1 = getSupportedTenants();
            actions.push({
                onActionClick: function () { return jump("index", urlTenant_1 || tenantList_1[0]); },
                actionLabel: "Client Login",
                disabled: !!internetPathName,
            });
            actions.push({
                onActionClick: function () { return jump("intranet", urlTenant_1 || tenantList_1[0]); },
                actionLabel: "Banker Login",
                disabled: !!intranetPathName,
            });
            tenantList_1.forEach(function (tenant) {
                actions.push({
                    onActionClick: function () { return jump(urlPathPrefix_1 || "index", tenant); },
                    actionLabel: tenant,
                    disabled: urlTenant_1 === tenant,
                });
            });
        }
        return actions;
    }, [window.location.href, getSupportedTenants]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { position: "fixed", width: "100%", background: "rgba(255,255,255,0.8)", padding: "0 20px" } }, { children: _jsx(Header, { children: _jsx(RightContentStyled, { children: props.helpTooltipMessage && (_jsx(Typography.Title, __assign({ level: 5, style: { cursor: "pointer" } }, { children: _jsxs(Tooltip, __assign({ trigger: "click", title: props.helpTooltipMessage }, { children: [_jsx(FaqIcon, { height: 16 }), _jsx("span", { children: formatMessage("dde.login.box.DoYouNeedSupport") })] })) }))) }) }) })), _jsxs(LoginWrapperStyled, { children: [_jsx(LoginLeftHalfStyled, {}), _jsx(LoginRightHalfStyled, { children: _jsx(Row, __assign({ style: { height: "100%", overflow: "auto" }, maxWidth: "100%" }, { children: _jsx(Col, __assign({ ds: 12 }, { children: _jsx(LoginBox, __assign({ title: props.title, subtitle: props.subtitle, actions: __spreadArray(__spreadArray([], (props.actions || []), true), developerLinkActions, true), showBackButton: props.showBackButton, onBackActionClick: props.onBackActionClick, goBackLabel: props.goBackLabel, rightDescription: props.rightDescription, description: props.description, fullWidth: props.fullWidth }, { children: props.children })) })) })) })] })] }));
};
export default LoginWrapper;
var templateObject_1;
