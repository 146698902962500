import { useNavigate, useLocation } from "react-router-dom";
import { useTenantContext } from "./tenantContext";
import { useAppContext, UserType } from "./appContext";
export function useHomeNavigation() {
    var navigate = useNavigate();
    var tenant = useTenantContext().tenant;
    var user = useAppContext().user;
    var location = useLocation();
    function navigateToHomePage() {
        if (user.userType === UserType.CLIENT) {
            if (location.pathname == "/app/dokuboxlist") {
                window.location.reload();
            }
            else {
                navigate("/app/dokuboxlist");
            }
        }
        else {
            bankerNavigateToHomeBasedOnTenant();
        }
    }
    function bankerNavigateToHomeBasedOnTenant() {
        if (tenant === "BG" || tenant === "HR" || tenant === "RO") {
            if (location.pathname == "/app/taskListView") {
                window.location.reload();
            }
            else {
                navigate("/app/taskListView");
            }
        }
        else {
            navigate("/app/usermanagement/manageuser/");
        }
    }
    return { navigateToHomePage: navigateToHomePage };
}
