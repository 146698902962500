export var filterBankerDokuBoxConfig = {
    url: "/dde/dokubox/banker/filter",
    method: "POST",
};
export var filterClientDokuBoxConfig = {
    url: "/dde/dokubox/client/filter",
    method: "POST",
};
export var clientDokuBoxQuickView = {
    url: "/dde/dokubox/client/quickview/",
    method: "GET",
};
export var bankerDokuBoxQuickView = {
    url: "/dde/dokubox/banker/quickview/",
    method: "GET",
};
