import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { useAppContext } from "../services/appContext";
var Permission = function (props) {
    var permissions = useAppContext().permissions;
    var anyRole = true, allRoles = true, someRolesMissing = true, allRolesMissing = true;
    if (props.hasAnyOfTheRoles && props.hasAnyOfTheRoles.length > 0) {
        anyRole = props.hasAnyOfTheRoles.some(function (item) { return permissions.includes(item); });
    }
    if (props.hasAllTheFollowingRoles && props.hasAllTheFollowingRoles.length > 0) {
        allRoles = props.hasAllTheFollowingRoles.every(function (item) { return permissions.includes(item); });
    }
    if (props.doesNotHaveSomeOfTheFollowingRoles && props.doesNotHaveSomeOfTheFollowingRoles.length > 0) {
        someRolesMissing = props.doesNotHaveSomeOfTheFollowingRoles.some(function (item) { return !permissions.includes(item); });
    }
    if (props.doesNotHaveTheFollowingRoles && props.doesNotHaveTheFollowingRoles.length > 0) {
        allRolesMissing = props.doesNotHaveTheFollowingRoles.every(function (item) { return !permissions.includes(item); });
    }
    if (anyRole && allRoles && someRolesMissing && allRolesMissing) {
        return _jsx(_Fragment, { children: props.children });
    }
    return _jsx(_Fragment, { children: props.children });
};
var usePermission = function () {
    var _a = useAppContext(), permissions = _a.permissions, setPermissions = _a.setPermissions;
    return {
        hasAnyOfTheRoles: useCallback(function (roles) {
            return roles.some(function (item) {
                if (!permissions || permissions.length === 0) {
                    console.error("Warning: No permissions were assigned to the user!");
                    return true;
                }
                return permissions.includes(item);
            });
        }, [permissions]),
        hasAllTheFollowingRoles: useCallback(function (roles) {
            return roles.every(function (item) {
                if (!permissions || permissions.length === 0) {
                    console.error("Warning: No permissions were assigned to the user!");
                    return true;
                }
                return permissions.includes(item);
            });
        }, [permissions]),
        doesNotHaveSomeOfTheFollowingRoles: useCallback(function (roles) {
            return roles.some(function (item) {
                if (!permissions || permissions.length === 0) {
                    console.error("Warning: No permissions were assigned to the user!");
                    return true;
                }
                return !permissions.includes(item);
            });
        }, [permissions]),
        doesNotHaveTheFollowingRoles: useCallback(function (roles) {
            return roles.every(function (item) {
                if (!permissions || permissions.length === 0) {
                    console.error("Warning: No permissions were assigned to the user!");
                    return true;
                }
                return !permissions.includes(item);
            });
        }, [permissions]),
    };
};
export { Permission, usePermission };
