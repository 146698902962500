var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BackIcon, Button, Col, Row, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainContainerStyled } from "../../../../styles/styles";
import { PageHeader } from "../../../../components/PageHeader/PageHeader";
import { executeAxios } from "../../../../services/axiosHook";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import { waitForDokuSignatures } from "../services/clientManageDokuBoxApi";
var WaitForDokuSignature = function () {
    var _a = useState(false), loadingWaitForDoku = _a[0], setLoadingWaitForDoku = _a[1];
    var formatMessage = useFormatMessage().formatMessage;
    var _b = useState("in_progress"), callbackStatus = _b[0], setCallBackStatus = _b[1];
    var navigate = useNavigate();
    var dokuBoxId = useParams().dokuBoxId;
    var showGeneralError = function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    };
    var waitForSignaturesCall = function () {
        executeAxios({
            params: {
                url: waitForDokuSignatures.url + "/" + dokuBoxId,
                method: waitForDokuSignatures.method,
            },
            onFail: showGeneralError,
            onLoading: setLoadingWaitForDoku,
            onSuccess: function (res) {
                setCallBackStatus(res.callbackResult);
                console.log("waitForDokuSignatures  ", res);
                if ("success" === callbackStatus) {
                    window.open(res.esawviewerUrl, "_self");
                }
                if ("failed" === callbackStatus) {
                    setLoadingWaitForDoku(false);
                }
                if ("not_signer" === callbackStatus) {
                    navigate("/app/dokuboxlist");
                }
            },
        });
    };
    useEffect(function () {
        var timer = setTimeout(waitForSignaturesCall, 1000);
        return function () { return clearTimeout(timer); };
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: formatMessage("dde.usermanagement.waitForDokuSignaturesCallback.title") }), _jsx(MainContainerStyled, { children: "failed" === callbackStatus && (_jsxs("div", { children: [_jsx("h1", { children: formatMessage("dde.error.generalError") }), _jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsx(Col, __assign({ ds: 12, mb: 12 }, { children: _jsxs(Button, __assign({ type: "text", onClick: function () { return navigate("/"); } }, { children: [_jsx(BackIcon, {}), " ", formatMessage("dde.link.backToDoku")] })) })) }))] })) }), "failed" !== callbackStatus && (_jsx("div", __assign({ style: { position: "fixed", top: "0", left: "0", width: "100%", height: "100%" } }, { children: _jsx(Spinner, { label: formatMessage("dde.usermanagement.waitForDokuSignaturesCallback.spinner") }) })))] }));
};
export default WaitForDokuSignature;
