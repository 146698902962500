var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from "@g4p/react-styleguide-library";
import styled from "styled-components";
export var ActionButtonStyled = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tsvg {\n\t\tmargin-right: 5px;\n\t\t", "\n\t}\n"], ["\n\tsvg {\n\t\tmargin-right: 5px;\n\t\t", "\n\t}\n"])), function (props) {
    return props.$disabled &&
        "\n      opacity: .7;\n      cursor: not-allowed;\n      pointer-events: none;\n    ";
});
var templateObject_1;
