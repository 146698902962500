var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
import moment from "moment";
import { useCallback, useState } from "react";
import { is5xxStatus } from "../helper/httpStatusCheck";
import { getLocaleFromLocalStorage, getTenantForHeader, isIgnorePortalBlock, removeAuthFromLocalStorage, removeExternalParamFromLocalStorage, setAuthInLocalStorage, } from "./appStorage";
import { GlobalEvents } from "./globalEvents";
export function getQueryVariable(variable) {
    var locationPath = window.location.search.substring(1);
    if (locationPath) {
        var query = window.location.search.substring(1).replaceAll("&amp;", "&");
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
    }
    return "";
}
function getDevEnvBaseUrl() {
    if (process.env.REACT_APP_IS_LOCAL_DEV_ENV === "true") {
        return process.env["REACT_APP_API_ENDPOINT_DEV_" + getTenantForHeader()];
    }
}
export function getCommonRequestBaseUrl() {
    return process.env.REACT_APP_API_ENDPOINT || getDevEnvBaseUrl() || "";
}
export var commonRequestHeaders = {
    "Accept-Language": getLocaleFromLocalStorage(),
    Tenant: getTenantForHeader(),
    Channel: "SB_Remote",
    "Flow-Type": getQueryVariable("function"),
    TimezoneOffset: new Date().getTimezoneOffset(),
    "Client-App": "DDE",
};
export var axiosInstance = axios.create({
    baseURL: getCommonRequestBaseUrl(),
    headers: __assign({}, commonRequestHeaders),
    withCredentials: true,
});
var refreshToken = function () {
    return axiosInstance.request({
        url: "/authorization/token/dde/refresh",
        method: "POST",
    });
};
var getCurrentPortalStatuses = function () {
    return axiosInstance.request({
        url: "/utilities/noauth/cached/currentPortalStatuses",
        method: "POST",
    });
};
var axiosCallHistory = {};
export var getAuthRequestHeaders = function () {
    var headers = {};
    headers["flowId"] = localStorage.getItem("flowId");
    headers["Accept-Language"] = getLocaleFromLocalStorage();
    headers["Tenant"] = getTenantForHeader();
    if (isIgnorePortalBlock()) {
        headers["ignoreportalblock"] = "true";
    }
    return headers;
};
axiosInstance.interceptors.request.use(function (config) {
    config.baseURL = getCommonRequestBaseUrl();
    var authHeaders = getAuthRequestHeaders();
    // @ts-ignore
    Object.keys(authHeaders).forEach(function (key) { return (config.headers[key] = authHeaders[key]); });
    return config;
});
var urlDoesNotContainToken = function (url) {
    return !url.includes("/token");
};
export var forceUserToLoginAgain = function () {
    var _a, _b;
    removeAuthFromLocalStorage();
    removeExternalParamFromLocalStorage();
    var tenant = getTenantForHeader();
    var loginPagePathNames = [
        "/intranet".concat(tenant, "/intranet").concat(tenant, ".html"),
        "/intranet".concat(tenant, ".html"),
        "/intranet".concat(tenant),
        "/internet".concat(tenant, "/internet").concat(tenant, ".html"),
        "/internet".concat(tenant, ".html"),
        "/internet".concat(tenant),
        "/index".concat(tenant, ".html"),
        "/index".concat(tenant),
        "/",
    ];
    // TODO: don't redirect to login page if tha app is on that
    if (!loginPagePathNames.includes(window.location.pathname)) {
        if (process.env.REACT_APP_IS_LOCAL_DEV_ENV === "true") {
            var intranetPathName = (_a = window.location.href.match("intranet[A-Z][A-Z]")) === null || _a === void 0 ? void 0 : _a.at(0);
            var internetPathName = (_b = window.location.href.match("index[A-Z][A-Z]")) === null || _b === void 0 ? void 0 : _b.at(0);
            window.location.href = intranetPathName || internetPathName || "" ? "/" + intranetPathName || internetPathName || "" : "/";
        }
        else {
            window.location.href = "/";
        }
    }
};
axiosInstance.interceptors.response.use(function (originalResponse) {
    handleDates(originalResponse.data);
    return originalResponse;
}, function (error) {
    var _a;
    if (error.config.url && axiosCallHistory[error.config.url]) {
        axiosCallHistory[error.config.url]++;
    }
    else {
        axiosCallHistory[error.config.url] = 1;
    }
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) &&
        error.response.status === 401 &&
        axiosCallHistory[error.config.url] < 2 &&
        urlDoesNotContainToken(error.config.url)) {
        return refreshToken().then(function (response) {
            setAuthInLocalStorage(__assign({}, response.data));
            return axiosInstance.request(error.config).then(function (response) {
                axiosCallHistory[error.config.url] = 0;
                return response;
            });
        }, function () {
            forceUserToLoginAgain();
        });
    }
    else {
        if (error.config && error.response) {
            if (error.response.status === 307) {
                return getCurrentPortalStatuses().then(function (_a) {
                    var data = _a.data;
                    var blockedPortal = data.ALL && data.ALL.currentlyBlocked ? data.ALL : data[getQueryVariable("function")];
                    document.dispatchEvent(new CustomEvent(GlobalEvents.PORTAL_BLOCKED, { detail: blockedPortal }));
                    return Promise.reject(error);
                }, function (portalStatusError) {
                    return Promise.reject(portalStatusError);
                });
            }
            else if (error.response.status && error.response.status === 401 && urlDoesNotContainToken(error.config.url)) {
                forceUserToLoginAgain();
            }
            else {
                if (is5xxStatus(error.response.status)) {
                    axiosCallHistory[error.config.url] = 0;
                    var event_1 = new CustomEvent(GlobalEvents.SHOW_SNACK_BAR, {});
                    window.dispatchEvent(event_1);
                }
                return Promise.reject(error);
            }
        }
        else {
            return Promise.reject(error);
        }
    }
});
var isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*Z)?$/;
function isIsoDateString(value) {
    return value && typeof value === "string" && isoDateFormat.test(value);
}
function handleDates(body) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;
    for (var _i = 0, _a = Object.keys(body); _i < _a.length; _i++) {
        var key = _a[_i];
        var value = body[key];
        if (isIsoDateString(value))
            body[key] = moment(value).toDate();
        else if (typeof value === "object")
            handleDates(value);
    }
}
export var useAxios = function (params) {
    var _a = useState(null), response = _a[0], setResponse = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var fetchData = useCallback(function () {
        axiosInstance
            .request(params)
            .then(function (response) {
            setResponse(response.data);
            setLoading(false);
        })
            .catch(function (error) {
            setError(error.response);
        })
            .finally(function () {
            setLoading(false);
        });
    }, [params]);
    return { response: response, error: error, loading: loading, fetchData: fetchData };
};
export function executeAxios(_a) {
    var params = _a.params, onSuccess = _a.onSuccess, onFail = _a.onFail, onLoading = _a.onLoading;
    onLoading && onLoading(true);
    return axiosInstance
        .request(params)
        .then(function (response) {
        onSuccess && onSuccess(response.data);
    })
        .catch(function (error) {
        var _a;
        onFail && ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== 307 && onFail(error.response);
    })
        .finally(function () {
        onLoading && onLoading(false);
    });
}
var executeAxiosCache = {};
export function executeAxiosPreventMultipleServerCalls(_a) {
    var params = _a.params, onSuccess = _a.onSuccess, onFail = _a.onFail, onLoading = _a.onLoading;
    onLoading && onLoading(true);
    var paramsJson = JSON.stringify(params);
    var promise = executeAxiosCache[paramsJson] || axiosInstance.request(params);
    executeAxiosCache[paramsJson] = promise;
    return promise
        .then(function (response) {
        onSuccess && onSuccess(response.data);
    })
        .catch(function (error) {
        var _a;
        onFail && ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== 307 && onFail(error.response);
    })
        .finally(function () {
        delete executeAxiosCache[paramsJson];
        onLoading && onLoading(false);
    });
}
export function executeMultipleAxios(_a) {
    var promisesConfig = _a.promisesConfig, onSuccess = _a.onSuccess, onFail = _a.onFail, onLoading = _a.onLoading;
    onLoading && onLoading(true);
    return Promise.all(promisesConfig.map(function (config) { return axiosInstance.request(config); }))
        .then(function (responseArray) {
        onSuccess && onSuccess(responseArray);
    })
        .catch(function (error) {
        var _a;
        onFail && ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== 307 && onFail(error.isAxiosError);
    })
        .finally(function () {
        onLoading && onLoading(false);
    });
}
