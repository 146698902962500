var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockCircleOutlined, CopyOutlined, FileOutlined, FileTextOutlined, FormOutlined, IdcardOutlined, LinkOutlined, SearchOutlined, UnorderedListOutlined, WarningTwoTone, } from "@ant-design/icons/lib";
import styled from 'styled-components';
export function setResultsDataType(results, key) {
    results.label = "";
    results.text = "";
    results.key = key;
    results.children = [];
    if (results.container) {
        key = key + 1;
        setContainerDataType(results.container, key);
        if (results.container.children)
            results.children = __spreadArray(__spreadArray([], results.children, true), [
                results.container,
                {
                    key: ++key,
                    label: "dde.electronicsignature.documents",
                    text: "",
                    renderLabel: function (data) {
                        return (_jsxs(_Fragment, { children: [_jsx(CopyOutlined, {}), data] }));
                    },
                    children: results.container.documents,
                },
            ], false);
    }
    if (results.document) {
        key = key + 1;
        setDocumentDataType(results.document, key);
        results.children = __spreadArray(__spreadArray([], results.children, true), [results.document], false);
    }
}
var TextTagStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tbackground-color: #1c84c6;\n  \tpadding: 2px 5px;\n  \tborder-radius: 3px;\n  \tcolor: #fff;\n  \ttext-align: center;\n  \t\n"], ["\n\tbackground-color: #1c84c6;\n  \tpadding: 2px 5px;\n  \tborder-radius: 3px;\n  \tcolor: #fff;\n  \ttext-align: center;\n  \t\n"])));
var renderTextTag = function (text) {
    return _jsx("div", { children: _jsx(TextTagStyled, { children: text }) });
};
export function setContainerDataType(container, key) {
    console.log("set container");
    container.label = "dde.electronicsignature.validationresult";
    container.text = "dde.electronicsignature.validationresult." + container.validationResult.toLowerCase();
    container.key = key;
    container.renderText = renderTextTag;
    container.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(FormOutlined, {}), label] }));
    };
    key = key * 10;
    container.children = [];
    if (container.signatures && container.signatures.length > 0) {
        container.signatures.forEach(function (value) {
            key = key + 1;
            setSignatureDataType(value, key);
        });
        container.children = __spreadArray(__spreadArray([], container.children, true), container.signatures, true);
    }
    if (container.documents && container.documents.length > 0) {
        container.documents.forEach(function (value) {
            key = key + 1;
            setDocumentDataType(value, key);
        });
    }
    if (container.containers && container.containers.length > 0) {
        container.containers.forEach(function (value) {
            key = key + 1;
            setContainerDataType(value, key);
        });
        container.children = __spreadArray(__spreadArray([], container.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.containers",
                text: "",
                children: container.containers,
            },
        ], false);
    }
}
export function setDocumentDataType(document, key) {
    document.label = document.originalRootDocumentFileName ? document.originalRootDocumentFileName : document.fileName + "(" + document.fileId + ")";
    document.text = "";
    document.key = key;
    document.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(FileOutlined, {}), label] }));
    };
    key = key * 10;
    document.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.document.filename",
            text: document.originalRootDocumentFileName ? document.originalRootDocumentFileName : document.fileName,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.document.fileid",
            text: document.fileId,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.document.mimetype",
            text: document.mimeType,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.document.integrityprotected",
            text: document.integrityProtected ? "dde.electronicsignature.yes" : "dde.electronicsignature.no",
        },
    ];
    if (document.signatures && document.signatures.length > 0) {
        document.signatures.forEach(function (value) {
            key = key + 1;
            setSignatureDataType(value, key);
        });
        document.children = __spreadArray(__spreadArray([], document.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.document.signatures",
                text: "",
                renderLabel: function (label) {
                    return (_jsxs(_Fragment, { children: [_jsx(FormOutlined, {}), label] }));
                },
                children: document.signatures,
            },
        ], false);
    }
}
export function setSignatureDataType(signature, key) {
    var signingCertificate = signature.certificateChain.find(function (value) { return value.certificateId === signature.signingCertificate; });
    signature.label = (signingCertificate === null || signingCertificate === void 0 ? void 0 : signingCertificate.certificateAttributes.cn) + "-" + signature.signingTime;
    signature.text = "dde.electronicsignature.signatureindication." + signature.signatureIndication.toLowerCase();
    signature.key = key;
    signature.renderText = renderTextTag;
    signature.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(FormOutlined, {}), label] }));
    };
    key = key * 10;
    signature.children = [];
    if (signature.type === "signature") {
        var data_1 = [
            {
                key: ++key,
                label: "dde.electronicsignature.timestampinformation.signature",
                text: "",
            },
            {
                key: ++key,
                label: "dde.electronicsignature.signingtime",
                text: signature.signingTime + "",
            },
        ];
        signature.children = __spreadArray(__spreadArray([], signature.children, true), data_1, true);
    }
    if (signature.type === "signature" && signature.timestampProductionTime) {
        var data_2 = [
            {
                key: ++key,
                label: "dde.electronicsignature.embeddedtimestampproductiontime",
                text: signature.timestampProductionTime,
            },
        ];
        signature.children = __spreadArray(__spreadArray([], signature.children, true), data_2, true);
    }
    if (signature.type !== "signature") {
        var data_3 = [
            {
                key: ++key,
                label: "dde.electronicsignature.timestampproductiontime",
                text: signature.timestampProductionTime,
            },
        ];
        signature.children = __spreadArray(__spreadArray([], signature.children, true), data_3, true);
    }
    if (signature.certificateChain && signature.certificateChain.length > 0 && signature.certificateChain[0].certificateAttributes.cn) {
        var data_4 = [
            {
                key: ++key,
                label: "dde.electronicsignature.signature.certificate.cn",
                text: signature.certificateChain[0].certificateAttributes.cn,
            },
        ];
        signature.children = __spreadArray(__spreadArray([], signature.children, true), data_4, true);
    }
    if (signature.certificateChain && signature.certificateChain.length > 0 && signature.certificateChain[0].certificateAttributes.o) {
        var data_5 = [
            {
                key: ++key,
                label: "dde.electronicsignature.signature.certificate.o",
                text: signature.certificateChain[0].certificateAttributes.o,
            },
        ];
        signature.children = __spreadArray(__spreadArray([], signature.children, true), data_5, true);
    }
    var data = [
        {
            key: ++key,
            label: "dde.electronicsignature.signature.id",
            text: signature.signatureId,
        },
    ];
    signature.children = __spreadArray(__spreadArray([], signature.children, true), data, true);
    if (signature.isCorporateAcceptable && !signature.isPrivateIndividualAcceptable) {
        var data_6 = [
            {
                key: ++key,
                label: "dde.electronicsignature.signature.acceptable",
                text: "",
            },
        ];
        signature.children = __spreadArray(__spreadArray([], signature.children, true), data_6, true);
    }
    var signatureDetails = {
        key: ++key,
        label: "dde.electronicsignature.signature.details",
        text: "",
        renderLabel: function (label) {
            return (_jsxs(_Fragment, { children: [_jsx(UnorderedListOutlined, {}), label] }));
        },
    };
    signatureDetails.children = [];
    signature.children = __spreadArray(__spreadArray([], signature.children, true), [signatureDetails], false);
    if (signature.type === "signature") {
        var data_7 = [
            {
                key: ++key,
                label: "dde.electronicsignature.signature.type",
                text: signature.type,
            },
            {
                key: ++key,
                label: "dde.electronicsignature.signingtime",
                text: signature.signingTime + "",
            },
        ];
        if (signature.timestampProductionTime) {
            data_7 = __spreadArray(__spreadArray([], data_7, true), [
                {
                    key: ++key,
                    label: "dde.electronicsignature.timestampproductiontime",
                    text: signature.timestampProductionTime + "",
                },
            ], false);
        }
        data_7 = __spreadArray(__spreadArray([], data_7, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.signingcertificate.signature",
                text: signature.signingCertificate + "",
            },
        ], false);
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), data_7, true);
    }
    if (signature.type !== "signature") {
        var data_8 = [
            {
                key: ++key,
                label: "dde.electronicsignature.timestampproductiontime",
                text: signature.timestampProductionTime + "",
            },
            {
                key: ++key,
                label: "dde.electronicsignature.signingCertificate.timestamp",
                text: signature.signingCertificate + "",
            },
        ];
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), data_8, true);
    }
    signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [
        {
            key: ++key,
            label: "dde.electronicsignature.sourcefileid",
            text: signature.sourceFileId,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.position",
            text: signature.position + "",
        },
        {
            key: ++key,
            label: "dde.electronicsignature.signatureindication",
            text: signature.signatureIndication,
        },
    ], false);
    if (signature.signatureSubIndication) {
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.signaturesubindication",
                text: signature.signatureSubIndication,
            },
        ], false);
    }
    signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [
        {
            key: ++key,
            label: "dde.electronicsignature.isinherited",
            text: signature.isInherited ? "dde.electronicsignature.yes" : "dde.electronicsignature.no",
        },
        {
            key: ++key,
            label: "dde.electronicsignature.isprivateindividualacceptable",
            text: signature.isPrivateIndividualAcceptable ? "dde.electronicsignature.yes" : "dde.electronicsignature.no",
        },
        {
            key: ++key,
            label: "dde.electronicsignature.iscorporateacceptable",
            text: signature.isCorporateAcceptable ? "dde.electronicsignature.yes" : "dde.electronicsignature.no",
        },
    ], false);
    if (signature.signatureInformation) {
        setSignatureInformationDataType(signature.signatureInformation, ++key);
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [signature.signatureInformation], false);
    }
    if (signature.comment) {
        key = key + 1;
        setCommentDataType(signature.comment, key);
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [signature.comment], false);
    }
    if (signature.certificateChain && signature.certificateChain.length > 0) {
        signature.certificateChain.forEach(function (value) {
            key = key + 1;
            setCertificateDataType(value, key);
        });
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificatechain",
                text: "",
                renderLabel: function (label) {
                    return (_jsxs(_Fragment, { children: [_jsx(LinkOutlined, {}), label] }));
                },
                children: signature.certificateChain,
            },
        ], false);
    }
    if (signature.timestampInformation) {
        key = key + 1;
        setTimestampInformationDataType(signature.timestampInformation, key);
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [signature.timestampInformation], false);
    }
    if (signature.timestampCertificateChain && signature.timestampCertificateChain.length > 0) {
        signature.timestampCertificateChain.forEach(function (value) {
            key = key + 1;
            setCertificateDataType(value, key);
        });
        signatureDetails.children = __spreadArray(__spreadArray([], signatureDetails.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.timestampcertificatechain",
                text: "",
                renderLabel: function (label) {
                    return (_jsxs(_Fragment, { children: [_jsx(LinkOutlined, {}), label] }));
                },
                children: signature.timestampCertificateChain,
            },
        ], false);
    }
    if (signature.validationErrorGroups && signature.validationErrorGroups.length > 0) {
        signature.validationErrorGroups.forEach(function (value) {
            key = key + 1;
            setErrorGroupDataType(value, key);
        });
        var errorCritical_1 = signature.validationErrorGroups.some(function (value) { return value.errorCritical; });
        signature.children = __spreadArray(__spreadArray([], signature.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.validationerrors",
                text: "",
                renderLabel: function (label) {
                    return (_jsxs(_Fragment, { children: [_jsx(WarningTwoTone, { twoToneColor: errorCritical_1 ? "red" : "orange" }), label] }));
                },
                children: signature.validationErrorGroups,
            },
        ], false);
    }
}
export function setCertificateDataType(certificate, key) {
    certificate.label = certificate.certificateAttributes
        ? certificate.certificateAttributes.cn + " - " + certificate.certificateAttributes.o
        : certificate.certificateId;
    certificate.text = "";
    certificate.key = key;
    certificate.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(IdcardOutlined, {}), label] }));
    };
    certificate.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.certificateid",
            text: certificate.certificateId,
        },
    ];
    if (certificate.certificateOwner) {
        certificate.children = __spreadArray(__spreadArray([], certificate.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateowner",
                text: certificate.certificateOwner,
            },
        ], false);
    }
    certificate.children = __spreadArray(__spreadArray([], certificate.children, true), [
        {
            key: ++key,
            label: "dde.electronicsignature.certificatevalidity",
            text: certificate.notBefore + "-" + certificate.notAfter,
        },
    ], false);
    key = key * 10;
    setCertificateAttributesDataType(certificate.certificateAttributes, key);
    certificate.children = __spreadArray(__spreadArray([], certificate.children, true), [certificate.certificateAttributes], false);
    if (certificate.errorGroups && certificate.errorGroups.length > 0) {
        certificate.errorGroups.forEach(function (value) {
            key = key + 1;
            setErrorGroupDataType(value, key);
        });
        var errorCritical_2 = certificate.errorGroups.some(function (value) { return value.errorCritical; });
        certificate.children = __spreadArray(__spreadArray([], certificate.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateerrors",
                text: "",
                renderLabel: function (label) {
                    return (_jsxs(_Fragment, { children: [_jsx(WarningTwoTone, { twoToneColor: errorCritical_2 ? "red" : "orange" }), label] }));
                },
                children: certificate.errorGroups,
            },
        ], false);
    }
}
export function setCertificateAttributesDataType(certificateAttributes, key) {
    certificateAttributes.label = "dde.electronicsignature.certificateattributes";
    certificateAttributes.text = "";
    certificateAttributes.key = key;
    certificateAttributes.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(FileTextOutlined, {}), label] }));
    };
    key = key * 10;
    certificateAttributes.children = [];
    if (certificateAttributes.cn) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.cn",
                text: certificateAttributes.cn,
            },
        ], false);
    }
    if (certificateAttributes.cn) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.serialnumber",
                text: certificateAttributes.serialNumber,
            },
        ], false);
    }
    if (certificateAttributes.c) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.c",
                text: certificateAttributes.c,
            },
        ], false);
    }
    if (certificateAttributes.o) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.o",
                text: certificateAttributes.o,
            },
        ], false);
    }
    if (certificateAttributes.ou) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.ou",
                text: certificateAttributes.ou,
            },
        ], false);
    }
    if (certificateAttributes.st) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.st",
                text: certificateAttributes.st,
            },
        ], false);
    }
    if (certificateAttributes.l) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.l",
                text: certificateAttributes.l,
            },
        ], false);
    }
    if (certificateAttributes.title) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.title",
                text: certificateAttributes.title,
            },
        ], false);
    }
    if (certificateAttributes.sn) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.sn",
                text: certificateAttributes.sn,
            },
        ], false);
    }
    if (certificateAttributes.gn) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.gn",
                text: certificateAttributes.gn,
            },
        ], false);
    }
    if (certificateAttributes.pseudonym) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.pseudonym",
                text: certificateAttributes.pseudonym,
            },
        ], false);
    }
    if (certificateAttributes.intEmail) {
        certificateAttributes.children = __spreadArray(__spreadArray([], certificateAttributes.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.certificateattributes.intemail",
                text: certificateAttributes.intEmail,
            },
        ], false);
    }
}
export function setSignatureInformationDataType(signatureInformation, key) {
    signatureInformation.label = "dde.electronicsignature.signatureinformation";
    signatureInformation.text = "";
    signatureInformation.key = key;
    signatureInformation.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(SearchOutlined, {}), label] }));
    };
    key = key * 10;
    signatureInformation.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.signatureinformation.signatureformat",
            text: signatureInformation.signatureFormat,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.signatureinformation.signaturequalification",
            text: signatureInformation.signatureQualification,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.signatureinformation.contentcovered",
            text: signatureInformation.contentCovered ? "dde.electronicsignature.yes" : "dde.electronicsignature.no",
        },
        {
            key: ++key,
            label: "dde.electronicsignature.signatureinformation.tbmoenabled",
            text: signatureInformation.tbmoEnabled ? "dde.electronicsignature.yes" : "dde.electronicsignature.no",
        },
    ];
}
export function setTimestampInformationDataType(timestampInformation, key) {
    timestampInformation.label = "dde.electronicsignature.timestampinformation";
    timestampInformation.text = "";
    timestampInformation.key = key;
    timestampInformation.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(ClockCircleOutlined, {}), label] }));
    };
    key = key * 10;
    timestampInformation.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.timestampinformation.timestampid",
            text: timestampInformation.timestampId,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.timestampinformation.timestampformat",
            text: timestampInformation.timestampFormat,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.timestampinformation.timestampqualification",
            text: timestampInformation.timestampQualification,
        },
    ];
    if (timestampInformation.errorGroups && timestampInformation.errorGroups.length > 0) {
        timestampInformation.errorGroups.forEach(function (value) {
            key = key * 10;
            setErrorGroupDataType(value, key);
        });
        var errorCritical_3 = timestampInformation.errorGroups.some(function (value) { return value.errorCritical; });
        timestampInformation.children = __spreadArray(__spreadArray([], timestampInformation.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.timestampinformation.errors",
                text: "",
                renderLabel: function (label) {
                    return (_jsxs(_Fragment, { children: [_jsx(WarningTwoTone, { twoToneColor: errorCritical_3 ? "red" : "orange" }), label] }));
                },
                children: timestampInformation.errorGroups,
            },
        ], false);
    }
}
export function setErrorGroupDataType(errorGroup, key) {
    errorGroup.label = "dde.electronicsignature.errorgroup." + errorGroup.errorGroupId;
    errorGroup.text = "";
    errorGroup.key = key;
    errorGroup.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(WarningTwoTone, { twoToneColor: errorGroup.errorCritical ? "red" : "orange" }), label] }));
    };
    key = key * 10;
    errorGroup.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.errorgroupid",
            text: errorGroup.errorGroupId,
        },
    ];
    if (errorGroup.errorGroupDescription) {
        errorGroup.children = __spreadArray(__spreadArray([], errorGroup.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.errorgroupdescription",
                text: errorGroup.errorGroupDescription,
            },
        ], false);
    }
    else {
        errorGroup.children = __spreadArray(__spreadArray([], errorGroup.children, true), [
            {
                key: ++key,
                label: "dde.electronicsignature.errorgroupdescription.empty",
                text: "",
            },
        ], false);
    }
    errorGroup.errors.forEach(function (error) { return setErrorDataType(error, ++key); });
    errorGroup.children = __spreadArray(__spreadArray([], errorGroup.children, true), errorGroup.errors, true);
}
export function setErrorDataType(error, key) {
    error.label = "dde.electronicsignature.error.description." + error.errorId;
    error.text = "";
    error.key = key;
    error.renderLabel = function (label) {
        return (_jsxs(_Fragment, { children: [_jsx(WarningTwoTone, { twoToneColor: error.status === "NOT_OK" ? "red" : "orange" }), label] }));
    };
    key = key * 10;
    error.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.error.type",
            text: "dde.electronicsignature.error.type." + error.status.toLowerCase(),
        },
        {
            key: ++key,
            label: "dde.electronicsignature.error.id",
            text: error.errorId,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.error.description",
            text: "dde.electronicsignature.error.description." + error.errorId,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.error.targettype",
            text: "dde.electronicsignature.error.targettype." + error.targetId.toLowerCase().charAt(0),
        },
        {
            key: ++key,
            label: "dde.electronicsignature.error.targetid",
            text: error.targetId,
        },
    ];
}
export function setCommentDataType(comment, key) {
    comment.label = "dde.electronicsignature.comment";
    comment.text = "";
    comment.key = key;
    comment.children = [
        {
            key: ++key,
            label: "dde.electronicsignature.comment.commenttypetranslated",
            text: comment.commentTypeTranslated,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.comment.creationdate",
            text: comment.creationDate + "",
        },
        {
            key: ++key,
            label: "dde.electronicsignature.comment.filename",
            text: comment.fileName,
        },
        {
            key: ++key,
            label: "dde.electronicsignature.comment.mimetype",
            text: comment.mimeType,
        },
    ];
}
var templateObject_1;
