var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import login_bg from "../../../assets/login_bg.png";
export var LoginLeftHalfStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tbackground-image: url(", ");\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\tbackground-position: center;\n\theight: 100%;\n\twidth: 500px;\n\n\t@media screen and (max-width: 800px) {\n\t\tdisplay: none;\n\t}\n"], ["\n\tbackground-image: url(", ");\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\tbackground-position: center;\n\theight: 100%;\n\twidth: 500px;\n\n\t@media screen and (max-width: 800px) {\n\t\tdisplay: none;\n\t}\n"])), login_bg);
export var LoginRightHalfStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\twidth: 100%;\n\tmax-width: calc(100% - 580px);\n\tdisplay: block;\n\tmargin-top: 64px;\n\tmargin-left: 40px;\n\n\t@media screen and (max-width: 800px) {\n\t\twidth: 100%;\n\t\tmax-width: initial;\n\t\tmargin-left: 0;\n\t}\n"], ["\n\twidth: 100%;\n\tmax-width: calc(100% - 580px);\n\tdisplay: block;\n\tmargin-top: 64px;\n\tmargin-left: 40px;\n\n\t@media screen and (max-width: 800px) {\n\t\twidth: 100%;\n\t\tmax-width: initial;\n\t\tmargin-left: 0;\n\t}\n"])));
export var LoginWrapperStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex: 1 1;\n"], ["\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex: 1 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
