var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { CloseIcon, Input, Select } from "@g4p/react-styleguide-library";
import Typography from "antd/lib/typography";
import { TerminateDokuBoxDetailsItemContainer, TerminateDokuBoxDetailsModal, } from "./TerminateDokuBoxDetailsPopUpStyle";
import { usersByUsernamesWithFullnameApi, } from "./TerminateDokuBoxDetailUserManagmentApi";
import { useEffect, useMemo, useState } from "react";
import { getByLocale } from "../../services/appApi";
import { useFormatMessage } from "../../services/formatMessageHook";
import { TERMINATE_DOKUBOX_CANCELLATION_REASON, } from "../../pages/banker/BankerManageDokuBox/services/domain";
import { useAppContext } from "../../services/appContext";
var TerminateDokuBoxDetailsPopUp = function (_a) {
    var isDokuBoxDetailVisible = _a.isDokuBoxDetailVisible, setIsDokuBoxDetailVisible = _a.setIsDokuBoxDetailVisible, dokuBox = _a.dokuBox;
    var getUsersByUsernamesWithFullname = usersByUsernamesWithFullnameApi().getUsersByUsernamesWithFullname;
    var _b = useState(""), fullName = _b[0], setFullName = _b[1];
    var _c = useState(""), userType = _c[0], setUserType = _c[1];
    var _d = useState(null), value = _d[0], setValue = _d[1];
    var catalogues = useAppContext().catalogues;
    var formatMessage = useFormatMessage().formatMessage;
    var TextArea = Input.TextArea;
    var reasonCatalogueItems = useMemo(function () {
        var catalogueItems = catalogues &&
            catalogues.catalogueList &&
            catalogues.catalogueList.find(function (c) { return c && c.catalogueType === TERMINATE_DOKUBOX_CANCELLATION_REASON; })
            ? catalogues.catalogueList.find(function (c) { return c && c.catalogueType === TERMINATE_DOKUBOX_CANCELLATION_REASON; }).catalogueItemList || []
            : [];
        var reasonCatalogueItems = catalogueItems.map(function (item) {
            return {
                reasonCode: item.value,
                reasonName: item.translationKey,
                key: item.listOrder,
            };
        });
        return reasonCatalogueItems;
    }, [catalogues]);
    var getFormattedReasonNameByCode = function (reasonCode) {
        var foundReasonItem = reasonCatalogueItems.find(function (item) { return item.reasonCode === reasonCode; });
        if (foundReasonItem) {
            return formatMessage(foundReasonItem.reasonName);
        }
        return reasonCode;
    };
    var options = [
        { value: dokuBox.reasonOfCancellation, label: getFormattedReasonNameByCode(dokuBox.reasonOfCancellation), key: 0 }
    ];
    useEffect(function () {
        setValue(options[0]);
    }, []);
    var getFullUserNameByUserName = function (userNames) {
        return getUsersByUsernamesWithFullname({ data: userNames })
            .then(function (response) {
            (response.data.users[0].fullName !== undefined
                && response.data.users[0].fullNameLocal) && setFullName(getByLocale(response.data.users[0].fullName, response.data.users[0].fullNameLocal));
            response.data.users[0].type !== undefined && setUserType(response.data.users[0].type);
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    useEffect(function () {
        getFullUserNameByUserName({ usernames: [dokuBox.cancellationCreatorUserId] });
    }, []);
    return (_jsxs(TerminateDokuBoxDetailsModal, __assign({ closeIcon: _jsx(CloseIcon, { height: 24, width: 24, style: { marginTop: '20px' } }), open: isDokuBoxDetailVisible, title: formatMessage('dde.terminatedDokuBoxDetails.title'), onCancel: function () { return setIsDokuBoxDetailVisible(false); }, closable: true, cancelButtonProps: { style: { visibility: "hidden" } }, okButtonProps: { style: { visibility: "hidden" } } }, { children: [_jsxs(Typography.Paragraph, __assign({ style: { marginBottom: "0px" } }, { children: [formatMessage('dde.terminatedDokuBoxDetails.terminatedBy'), ": ", _jsxs("b", { children: [fullName, " - ", formatMessage("dde.terminatedDokuBoxDetails.terminatedBy.role.".concat(userType))] })] })), _jsxs(TerminateDokuBoxDetailsItemContainer, { children: [_jsx("label", { children: formatMessage('dde.terminatedDokuBoxDetails.reasonOfCancellation') }), _jsx(Select, { value: value, options: options, disabled: true })] }), _jsxs(TerminateDokuBoxDetailsItemContainer, { children: [_jsx("label", { children: formatMessage('dde.terminatedDokuBoxDetails.cancellationNote') }), _jsx(TextArea, { defaultValue: dokuBox.cancellationNote, autoSize: { minRows: 3, maxRows: 5 }, disabled: true })] })] })));
};
export default TerminateDokuBoxDetailsPopUp;
