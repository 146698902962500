var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, showErrorMessage, showSuccessMessage } from "@g4p/react-styleguide-library";
import LoginWrapper from "../../common/Login/components/LoginWrapper";
import { useState } from "react";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { useAppContext } from "../../../services/appContext";
import { NamirialSettingsForm } from "./NamirialSettingsForm";
import { updateBankUserApi, updateNamirialClientUserApi } from "./services/namirialSettingsApi";
var NamirialSettingsDialog = function (props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var _a = useAppContext(), user = _a.user, setUser = _a.setUser;
    var _b = useState({
        namirialUserId: user.namirialUserId,
        namirialDeviceId: user.namirialDeviceId,
    }), initialValues = _b[0], setInitialValues = _b[1];
    var formatMessage = useFormatMessage().formatMessage;
    var form = Form.useForm()[0];
    var handleCancel = function () {
        setInitialValues({ namirialDeviceId: user.namirialDeviceId ? user.namirialDeviceId : "", namirialUserId: user.namirialUserId ? user.namirialUserId : "" });
        setIsVisible(false);
        form.resetFields();
        props.onClose();
    };
    function handleSubmit(formValues) {
        var namirialUrl = updateBankUserApi;
        if (!props.isBanker) {
            namirialUrl = updateNamirialClientUserApi;
        }
        executeAxios({
            params: __assign(__assign({}, namirialUrl), { data: __assign({}, formValues) }),
            onSuccess: function () {
                showSuccessMessage({
                    title: formatMessage("dde.namirialSettingsDlg.successTitle"),
                    message: formatMessage("dde.namirialSettingsDlg.successMsg"),
                });
                setInitialValues({
                    namirialUserId: formValues.namirialUserId,
                    namirialDeviceId: formValues.namirialDeviceId,
                });
                setUser(function (prevState) {
                    prevState.namirialDeviceId = formValues.namirialDeviceId;
                    prevState.namirialUserId = formValues.namirialUserId;
                    return prevState;
                });
                setIsVisible(false);
                props.onClose();
            },
            onLoading: function () { },
            onFail: function (resp) {
                var _a;
                showErrorMessage({
                    title: formatMessage("dde.namirialSettingsDlg.errorTitle"),
                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
                setIsVisible(true);
            },
        });
    }
    return (_jsx(_Fragment, { children: isVisible && (_jsx("div", __assign({ style: { position: "fixed", inset: 0, background: "white", zIndex: 999 } }, { children: _jsx(LoginWrapper, __assign({ title: formatMessage("dde.namirialSettingsDlg.title"), showBackButton: true, onBackActionClick: handleCancel, goBackLabel: formatMessage("dde.namirialSettingsDlg.button.GoBack") }, { children: _jsx(NamirialSettingsForm, { form: form, onSubmit: function (formValues) {
                        handleSubmit(formValues);
                    }, initialValues: initialValues }) })) }))) }));
};
export default NamirialSettingsDialog;
