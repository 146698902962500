export var NotificationEntityType;
(function (NotificationEntityType) {
    NotificationEntityType["Dokubox"] = "DOKUBOX";
    NotificationEntityType["Task"] = "TASK";
})(NotificationEntityType || (NotificationEntityType = {}));
export var NotificationTaskStatus;
(function (NotificationTaskStatus) {
    NotificationTaskStatus["Pending"] = "PENDING";
    NotificationTaskStatus["Completed"] = "COMPLETED";
})(NotificationTaskStatus || (NotificationTaskStatus = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["InactiveDokuBox"] = "InactiveDokuBox";
    NotificationType["TerminationRequest"] = "TerminationRequest";
    NotificationType["ApproveClientTaskCreated"] = "ApproveClientTaskCreated";
    NotificationType["ClientCorrectionNeededTaskCreated"] = "ClientCorrectionNeededTaskCreated";
    NotificationType["ChangeClientTaskCreated"] = "ChangeClientTaskCreated";
    NotificationType["ChangeClientCorrectionNeededTaskCreated"] = "ChangeClientCorrectionNeededTaskCreated";
})(NotificationType || (NotificationType = {}));
export var dokuBoxNotificationsApi = {
    url: "/dde/settings/notification/listNotifications",
    method: "POST",
};
export var dokuBoxReadNotificationApi = {
    url: "/dde/settings/notification/readNotification/",
    method: "POST",
};
