var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Form, Input, Row } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../services/formatMessageHook";
export var NamirialSettingsForm = function (props) {
    var form = props.form;
    var formatMessage = useFormatMessage().formatMessage;
    var requiredRule = { required: true, message: formatMessage("dde.changePasswordForm.requiredError") };
    var minLengthRule = { min: 8, message: formatMessage("dde.usermanagement.password.error.length") };
    var maxLengthRule = { max: 32, message: formatMessage("dde.usermanagement.password.error.length") };
    var handleOnSubmit = function (values) {
        props.onSubmit(values);
    };
    return (_jsx(Row, { children: _jsx(Col, __assign({ ds: 6 }, { children: _jsxs(Form, __assign({ form: form, initialValues: props.initialValues, onFinish: handleOnSubmit }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "namirialDeviceId" }, { children: formatMessage("dde.namirialSettingsForm.namirialDeviceId") })), _jsx(Form.Item, __assign({ rules: [requiredRule], name: "namirialDeviceId" }, { children: _jsx(Input, { id: "namirialDeviceId", placeholder: formatMessage("dde.namirialSettingsForm.namirialDeviceId") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "namirialUserId" }, { children: formatMessage("dde.namirialSettingsForm.namirialUserId") })), _jsx(Form.Item, __assign({ rules: [requiredRule], name: "namirialUserId" }, { children: _jsx(Input, { id: "namirialUserId", placeholder: formatMessage("dde.namirialSettingsForm.namirialUserId") }) })), _jsx(Button, __assign({ type: "primary", htmlType: "submit" }, { children: formatMessage("dde.changePasswordForm.submitBtn") }))] })) })) }));
};
