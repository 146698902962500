var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Loader } from "@g4p/react-styleguide-library";
import EnvInfoBadge from "./pages/common/EnvInfoBadge/EnvInfoBadge";
import GeneralError from "./pages/common/GeneralError/GeneralError";
import { IntlProvider } from "react-intl";
import { getDefaultLocale, getLocaleFromLocalStorage, isCookieConsentAccepted } from "./services/appStorage";
import { TranslationsContextProvider } from "./services/translationsContext";
import "styles/App.scss";
import AppWrapper from "./AppWrapper";
import ErrorHandler from "./ErrorHandler";
import InternetRouter from "./InternetRouter";
import { useAppInitialization } from "./services/appApi";
import { AppContextProvider, UserType } from "./services/appContext";
import CookieConsent from "./pages/client/Cookies/CookieConsent";
import { useTenantContext } from "./services/tenantContext";
var InternetApp = function (props) {
    var _a = useAppInitialization(UserType.CLIENT, props.skipContractSignedCheck), loading = _a.loading, data = _a.data, user = _a.user, permissions = _a.permissions, roles = _a.roles, error = _a.error;
    var tenant = useTenantContext().tenant;
    var isCookiesConsentFeatureEnabled = function () {
        var featureName = "ddeCookiesConsent_".concat(tenant);
        return data.configurations.hasOwnProperty(featureName) &&
            data.configurations[featureName] === true;
    };
    var renderInnerContent = function () {
        if (loading) {
            return _jsx(Loader, {});
        }
        if (error) {
            return _jsx(GeneralError, {});
        }
        return (_jsx(AppWrapper, __assign({ isExternalUser: true }, { children: _jsx(InternetRouter, {}) })));
    };
    return (_jsx(ErrorHandler, { children: _jsx(AppContextProvider, __assign({ configurations: data.configurations, catalogues: data.catalogues, portalStatus: data.portalStatus, permissions: permissions, roles: roles, user: user, clientApp: true }, { children: _jsx(IntlProvider, __assign({ locale: getLocaleFromLocalStorage(), defaultLocale: getDefaultLocale() }, { children: _jsx("div", __assign({ className: "App" }, { children: _jsxs(TranslationsContextProvider, __assign({ translations: data.translations }, { children: [isCookiesConsentFeatureEnabled() && (isCookieConsentAccepted() || _jsx(CookieConsent, {})), renderInnerContent(), _jsx(EnvInfoBadge, {})] })) })) })) })) }));
};
export default InternetApp;
