var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from "react";
import { axiosInstance } from "../../../services/axiosHook";
var changeUsernameApiConfig = {
    method: "post",
    url: "dde/usermanagement/alias",
};
export var changeUsernameApi = function () {
    return {
        changeUsername: useCallback(function (apiConfig) {
            return axiosInstance.request(__assign(__assign({}, changeUsernameApiConfig), apiConfig)).finally(function () { });
        }, []),
    };
};
export var allCharactersSame = function (s) {
    {
        var n = s.length;
        for (var i = 1; i < n; i++)
            if (s[i] != s[0])
                return false;
        return true;
    }
};
