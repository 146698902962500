var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Form, Input, Row } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
export var ClientLoginOtpForm = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    return (_jsx(Row, { children: _jsx(Col, __assign({ ds: 6 }, { children: _jsxs(Form, __assign({ initialValues: props.initialValues, onFinish: props.onSubmit }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "otpPassword" }, { children: formatMessage("dde.login.otp.label") })), _jsx(Form.Item, __assign({ rules: [{ required: true, message: formatMessage("dde.validation.common.requiredField") }], name: "otpPassword" }, { children: _jsx(Input.Password, { placeholder: formatMessage("dde.login.otp.label") }) })), _jsx(Button, __assign({ type: "primary", htmlType: "submit" }, { children: formatMessage("dde.login.login.btn") }))] })) })) }));
};
