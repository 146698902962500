var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var LabelWarning = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #EC6608;\n    font-size: 12px;\n    font-weight: bold;\n    line-height: 16px;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #EC6608;\n    font-size: 12px;\n    font-weight: bold;\n    line-height: 16px;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export { LabelWarning };
var LabelPending = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #666666;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #666666;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export { LabelPending };
var LabelFinalized = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #2e844d;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #2e844d;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export { LabelFinalized };
var LabelCanceled = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #d73928;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"], ["\n\n    white-space: nowrap;\n    color: #ffffff;\n    background-color: #d73928;\n    width: auto;\n    height: 22px;\n    border-radius: 4px;\n    text-align: center;\n    font-size: 12px;\n    margin-left: 10px;\n    margin-right: 10px;\n    padding: 3px 7px 3px 7px;\n    text-transform: uppercase;\n    gap: 10;\n"])));
export { LabelCanceled };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
