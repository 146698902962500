import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { showErrorMessage } from "@g4p/react-styleguide-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { executeAxios, getCommonRequestBaseUrl } from "../../services/axiosHook";
import { useFormatMessage } from "../../services/formatMessageHook";
import { useTenantContext } from "../../services/tenantContext";
import { useAppContext } from "../../services/appContext";
var OauthAuthorize = function () {
    var formatMessage = useFormatMessage().formatMessage;
    var location = useLocation();
    var _a = useAppContext(), clientApp = _a.clientApp, configurations = _a.configurations;
    var _b = useState(false), wrongUser = _b[0], setWrongUser = _b[1];
    var _c = useState(false), missingUser = _c[0], setMissingUser = _c[1];
    var tenant = useTenantContext().tenant;
    var showGeneralError = function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    };
    useEffect(function () {
        executeAxios({
            params: {
                url: "/authorization/oauth2/consent/noauth/esaw" + location.search + "&clientApp=" + clientApp,
                method: "get",
            },
            onFail: showGeneralError,
            onSuccess: function (res) {
                if (!res.missingUser && !res.wrongUser) {
                    if (res.redirectBanker) {
                        var ddeOauthBankerURL = configurations["ddeOauthBankerURL_" + tenant] || configurations["ddeOauthBankerURL"];
                        window.location.href = ddeOauthBankerURL + "/oauth/authorize" + location.search;
                    }
                    else {
                        window.location.href =
                            getCommonRequestBaseUrl() + "/authorization/noauth/oauth2/authorize" + location.search + "&consentCode=" + res.consentCode;
                    }
                }
                else if (res.wrongUser) {
                    setWrongUser(true);
                }
                else {
                    setMissingUser(true);
                }
            },
        });
    });
    return (_jsxs(_Fragment, { children: [wrongUser && _jsx(_Fragment, { children: "Wrong user!" }), missingUser && _jsx(_Fragment, { children: "Missing user!" })] }));
};
export default OauthAuthorize;
