import { useEffect, useState, useCallback } from "react";
export function useCountdown(durationMs, onCountdownEnd, stopped) {
    if (stopped === void 0) { stopped = false; }
    var _a = useState(Date.now() + durationMs), end = _a[0], setEnd = _a[1];
    var _b = useState(), updateState = _b[1];
    var forceUpdate = useCallback(function () { return updateState({}); }, []);
    useEffect(function () {
        if (end > Date.now() && !stopped) {
            var timer_1 = setTimeout(forceUpdate, 1000);
            return function () { return clearTimeout(timer_1); };
        }
        else if (!stopped) {
            onCountdownEnd();
            return function () { };
        }
        return function () { };
    });
    var difference = Math.floor((end - Date.now()) / 1000);
    return [
        Math.max(0, difference),
        function () {
            setEnd(Date.now() + durationMs);
        },
    ];
}
