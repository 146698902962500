var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { setTenantForHeader } from "./appStorage";
var initialContextState = {
    tenant: "",
    setTenant: function () { return undefined; },
    tenantComponentsMap: {},
    setTenantComponentsMap: function () { return undefined; },
    getTenantComponent: function () { return function () {
        return _jsx("div", {});
    }; },
    isTenant: function () {
        return false;
    }
};
var TenantContext = React.createContext(initialContextState);
var TenantContextProvider = function (props) {
    var _a = useState(""), tenant = _a[0], setTenant = _a[1];
    var _b = useState({}), tenantComponentsMap = _b[0], setTenantComponentsMap = _b[1];
    useEffect(function () {
        setTenant(props.tenant);
        setTenantForHeader(props.tenant);
        setTenantComponentsMap(props.tenantComponentsMap);
    }, [props.tenant, props.tenantComponentsMap]);
    var value = useMemo(function () {
        var getTenantComponent = function (componentId, DefaulComponent) {
            var TenantComponent = tenantComponentsMap[componentId];
            if (TenantComponent) {
                return TenantComponent;
            }
            else {
                return DefaulComponent;
            }
        };
        var isTenant = function (tenantList) {
            return tenantList.includes(tenant);
        };
        return { tenant: tenant, setTenant: setTenant, tenantComponentsMap: tenantComponentsMap, setTenantComponentsMap: setTenantComponentsMap, getTenantComponent: getTenantComponent, isTenant: isTenant };
    }, [tenant, tenantComponentsMap]);
    return _jsx(TenantContext.Provider, __assign({ value: value }, { children: props.children }));
};
var useTenantContext = function () {
    var _a = useContext(TenantContext), tenant = _a.tenant, tenantComponentsMap = _a.tenantComponentsMap, getTenantComponent = _a.getTenantComponent, isTenant = _a.isTenant;
    return {
        tenant: tenant,
        tenantComponentsMap: tenantComponentsMap,
        getTenantComponent: getTenantComponent,
        isTenant: isTenant
    };
};
export { TenantContextProvider, useTenantContext };
