var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../../services/formatMessageHook";
var SaveDokuMessageWarningDlg = function (props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var formatMessage = useFormatMessage().formatMessage;
    var handleCancel = function () {
        setIsVisible(false);
    };
    function handleSubmit() {
        setIsVisible(false);
        props.onSubmit();
    }
    return (_jsx(_Fragment, { children: _jsx(Modal, __assign({ open: isVisible, onCancel: handleCancel, title: formatMessage("dde.common.dokuMessages.warning.title"), cancelText: formatMessage("dde.common.dokuMessages.warning.noBtn"), okText: formatMessage("dde.common.dokuMessages.warning.yesBtn"), onOk: handleSubmit }, { children: _jsx("div", { children: formatMessage("dde.common.dokuMessages.warning.message") }) })) }));
};
export default SaveDokuMessageWarningDlg;
