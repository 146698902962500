var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { container } from "../../styles/styles";
export var PageHeaderStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmargin-bottom: 12px;\n\tposition: relative;\n\t", "\n\tpadding-top: 24px;\n\tpadding-bottom: 24px;\n\t", "\n\t> .container {\n\t\tposition: relative;\n\t\t", ";\n\t}\n\t\n\t.background-overlay {\n\t\tbackground-color: rgba(0,0,0,0.5);\n\t\tposition: absolute;\n\t\tinset: 0;\n\t}\n\n\t\n"], ["\n\tmargin-bottom: 12px;\n\tposition: relative;\n\t", "\n\tpadding-top: 24px;\n\tpadding-bottom: 24px;\n\t", "\n\t> .container {\n\t\tposition: relative;\n\t\t", ";\n\t}\n\t\n\t.background-overlay {\n\t\tbackground-color: rgba(0,0,0,0.5);\n\t\tposition: absolute;\n\t\tinset: 0;\n\t}\n\n\t\n"])), function (props) {
    return props.bg
        ? "\n\t\t\tbackground-image: url(".concat(props.bg, ");\n\t\t\tbackground-size: cover;\n\t\t\tbackground-position: center;\n\t\t\ttext-align: center;\n\t\t\t.page-title {\n\t\t\t\tcolor: #fff;\n\t\t\t}\n  ")
        : "\n    background-image: none;\n    background-color: #f5f5f5;\n    .page-title {\n\t\t\tcolor: #262626;\n\t\t\tmargin-bottom: 4px;\n\t}\n  ";
}, function (props) { return props.variant === "default" && "\n\t\tpadding-top: 16px;\n\t\tpadding-bottom: 16px;\n\t\t.page-title {\n\t\t\tfont-size: 24px;\n\t\t\tline-height: 1;\n\t\t}\n\t"; }, container);
var templateObject_1;
