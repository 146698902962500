import { getByLocale } from "../../../../services/appApi";
export var getDokuBox = {
    url: "/dde/dokubox/",
    method: "GET",
};
export var bankerLockDokuBox = {
    url: "/dde/dokubox/banker/lockDokuBox",
    method: "POST",
};
export var checkDokuBoxAccessApi = {
    url: "/dde/dokubox/checkDokuBoxAccess",
    method: "POST",
};
export var checkDokuBoxEsawAccessApi = {
    url: "/dde/dokubox/checkDokuBoxEsawAccess",
    method: "POST",
};
export var checkDokuBoxStartSigningAccessApi = {
    url: "/dde/dokubox/checkDokuBoxStartSigningAccess",
    method: "POST",
};
export var bankerUnlockDokuBox = {
    url: "/dde/dokubox/banker/unlockDokuBox",
    method: "POST",
};
export var bankerUnlockAllDokuboxesLockedByCurrentUser = {
    url: "/dde/dokubox/banker/unlockAllDokuboxesLockedByCurrentUser",
    method: "POST",
};
export var terminateDokuBoxApiConfig = {
    method: "POST",
    url: "/dde/dokubox/terminate",
};
export var initTerminateDokuBoxApiConfig = {
    method: "POST",
    url: "/dde/dokubox/terminate/init",
};
export var initReconfigureDokuBoxApiConfig = {
    method: "POST",
    url: "/dde/dokubox/initReconfigure",
};
export var getDokuBoxByIdApiConfig = {
    method: "GET",
    url: "/dde/dokubox/get",
};
export var bankerAddOtherDokuConfig = {
    url: "/dde/dokubox/banker/addOtherDoku",
    method: "POST",
};
export var getDokuFlowTypesApi = {
    method: "post",
    url: "/dde/dokubox/flowtype/list",
};
export var bankerSubmitDokuBox = {
    url: "/dde/dokubox/submit",
    method: "POST",
};
export var finalizeDokuSetupApi = {
    url: "/dde/dokubox/doku/",
    method: "POST",
    urlPostFix: "/finalize-setup",
};
export var finishBankSigningConfig = {
    url: "/dde/dokubox/finishBankSigning/",
    method: "POST",
};
export var formatServerError = function (resp, formatMessage) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var msg = formatMessage("dde.error.generalError");
    if (((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) && formatMessage(resp.data.messageKey).includes("actionName") && ((_c = (_b = resp === null || resp === void 0 ? void 0 : resp.data) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.at(2))) {
        msg = formatMessage(resp.data.messageKey, {
            dokuTitle: getByLocale(resp.data.params[0], resp.data.params[1]),
            actionName: formatMessage("dde.doku.action.".concat(resp.data.params[2])),
        });
    }
    else if (((_d = resp === null || resp === void 0 ? void 0 : resp.data) === null || _d === void 0 ? void 0 : _d.messageKey) && formatMessage(resp.data.messageKey).includes("lockedByUser") && ((_f = (_e = resp === null || resp === void 0 ? void 0 : resp.data) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.at(0))) {
        msg = formatMessage(resp.data.messageKey, { lockedByUser: resp.data.params[0] });
    }
    else if (((_g = resp === null || resp === void 0 ? void 0 : resp.data) === null || _g === void 0 ? void 0 : _g.messageKey) && resp.data.messageKey.includes("dde.dokuBox.error.participant") && ((_j = (_h = resp === null || resp === void 0 ? void 0 : resp.data) === null || _h === void 0 ? void 0 : _h.params) === null || _j === void 0 ? void 0 : _j.at(2))) {
        msg =
            formatMessage(resp.data.messageKey) +
                " (Doku: ".concat(getByLocale(resp.data.params[0], resp.data.params[1]), ", ").concat(formatMessage("dde.dokuBox.participantRole"), ": ").concat(formatMessage("dokubox.create.ParticipantRole." + resp.data.params[2]), ")");
    }
    else if (((_k = resp === null || resp === void 0 ? void 0 : resp.data) === null || _k === void 0 ? void 0 : _k.messageKey) && resp.data.messageKey.includes("DocumentTypeSegmentVsDokuboxSegmentMismatch") && ((_m = (_l = resp === null || resp === void 0 ? void 0 : resp.data) === null || _l === void 0 ? void 0 : _l.params) === null || _m === void 0 ? void 0 : _m.at(2))) {
        msg = formatMessage(resp.data.messageKey, {
            title: getByLocale(resp.data.params[0], resp.data.params[1]),
            segment: resp.data.params[2],
        });
    }
    else if (((_o = resp === null || resp === void 0 ? void 0 : resp.data) === null || _o === void 0 ? void 0 : _o.messageKey) && formatMessage(resp.data.messageKey).includes("maxSize") && ((_q = (_p = resp === null || resp === void 0 ? void 0 : resp.data) === null || _p === void 0 ? void 0 : _p.params) === null || _q === void 0 ? void 0 : _q.at(0))) {
        msg = formatMessage(resp.data.messageKey, { maxSize: resp.data.params[0] });
    }
    else if (resp && resp.data && resp.data.messageKey) {
        msg = formatMessage(resp.data.messageKey);
    }
    return msg;
};
export var convertParticipantDataToDto = function (participantDataList) {
    var participantDtoMap = new Map();
    participantDataList.forEach(function (participantData) {
        var _a;
        if (participantDtoMap.get(participantData.participantRole) !== undefined) {
            (_a = participantDtoMap.get(participantData.participantRole)) === null || _a === void 0 ? void 0 : _a.userIds.push(participantData.user.id.toString());
        }
        else {
            var participantDto = {};
            participantDto.role = participantData.participantRole;
            participantDto.userIds = [];
            participantDto.userIds.push(participantData.user.id.toString());
            participantDtoMap.set(participantData.participantRole, participantDto);
        }
    });
    return Array.from(participantDtoMap.values());
};
export var convertParticipantDtoToData = function (participantDtoList, participantType) {
    var participantDataList = [];
    participantDtoList.forEach(function (participantDto) {
        var isStringArray = participantDto.userIds.every(function (element) {
            return typeof element === "string";
        });
        if (participantDto.userIds.length === 1 && isStringArray && participantDto.userIds[0].includes("_")) {
            var participantIds = participantDto.userIds[0].split("_");
            participantIds.forEach(function (userId) {
                var _a;
                var participantData = {};
                participantData.participantRole = participantDto.role;
                participantData.user = {};
                participantData.participantType = participantType;
                participantData.user.id = parseInt(userId);
                participantData.signaturePoints = (_a = participantDto.signingPointMap) === null || _a === void 0 ? void 0 : _a.get(Number(userId));
                participantDataList.push(participantData);
            });
        }
        else {
            participantDto.userIds.forEach(function (userId) {
                var _a;
                var participantData = {};
                participantData.participantRole = participantDto.role;
                participantData.user = {};
                participantData.participantType = participantType;
                participantData.user.id = parseInt(userId);
                participantData.signaturePoints = (_a = participantDto.signingPointMap) === null || _a === void 0 ? void 0 : _a.get(Number(userId));
                participantDataList.push(participantData);
            });
        }
    });
    return participantDataList;
};
export var convertParticipantDtoToDataWithRoleCheck = function (participantDtoList, participantType) {
    var participantDataList = [];
    participantDtoList
        .forEach(function (participantDto) {
        var isStringArray = participantDto.userIds.every(function (element) {
            return typeof element === "string";
        });
        if (participantDto.userIds.length === 1 && isStringArray && participantDto.userIds[0].includes("_")) {
            var participantIds = participantDto.userIds[0].split("_");
            participantIds.forEach(function (userId) {
                var _a;
                var participantData = {};
                participantData.participantRole = participantDto.role;
                participantData.user = {};
                participantData.participantType = participantType;
                participantData.user.id = parseInt(userId);
                participantData.signaturePoints = (_a = participantDto.signingPointMap) === null || _a === void 0 ? void 0 : _a.get(Number(userId));
                participantDataList.push(participantData);
            });
        }
        else {
            participantDto.userIds.forEach(function (userId) {
                var _a;
                var participantData = {};
                participantData.participantRole = participantDto.role;
                participantData.user = {};
                participantData.participantType = participantType;
                participantData.user.id = parseInt(userId);
                participantData.signaturePoints = (_a = participantDto.signingPointMap) === null || _a === void 0 ? void 0 : _a.get(Number(userId));
                participantDataList.push(participantData);
            });
        }
    });
    return participantDataList;
};
export var bankerApproveTerminateDokuboxConfig = {
    url: "/dde/dokubox/terminate/approve",
    method: "POST",
};
export var bankerRejectTerminateDokuboxConfig = {
    url: "/dde/dokubox/terminate/reject",
    method: "POST",
};
export var containsDokuBoxStaticAction = function (dokuboxDto, actionType) {
    return (dokuboxDto &&
        dokuboxDto.dokuBoxStaticActions &&
        dokuboxDto.dokuBoxStaticActions.length > 0 &&
        dokuboxDto.dokuBoxStaticActions.includes(actionType));
};
export var getResolvedUrlConfig = {
    url: "/dde/dokubox/getResolvedUrl/",
    method: "POST",
};
