export var resetPasswordApi = {
    url: "dde/usermanagement/noauth/password/reset",
    method: "POST",
};
export function getResetPasswordData(formValues) {
    return {
        data: {
            email: formValues.email,
            username: formValues.userName,
            phoneNumber: formValues.phoneNumber,
        },
    };
}
