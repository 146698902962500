var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
export function UserCannotSignDialog(props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var formatMessage = useFormatMessage().formatMessage;
    return (_jsx(Modal, __assign({ open: isVisible, title: formatMessage("dde.UserCannotSignDialog.error.title"), okText: formatMessage("dde.common.label.ok"), onOk: props.onClose, onCancel: props.onClose, cancelButtonProps: { disabled: true } }, { children: formatMessage("dde.UserCannotSignDialog.error.message") })));
}
