var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Form } from "@g4p/react-styleguide-library";
import styled from "styled-components";
export var DokuMessagesStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .warning-message {\n    margin-bottom: 20px;\n  }\n"], ["\n  .warning-message {\n    margin-bottom: 20px;\n  }\n"])));
export var DokuMessagesContentStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 20px;\n  margin-bottom: 20px;\n  border: 1px solid #ccc;\n  overflow-y: scroll;\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n  scrollbar-width: none;  /* Firefox */\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  padding: 20px;\n  margin-bottom: 20px;\n  border: 1px solid #ccc;\n  overflow-y: scroll;\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n  scrollbar-width: none;  /* Firefox */\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])));
export var DokuMessagesFormStyled = styled(Form)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  .input-message {\n    flex: 1;\n    margin-bottom: 0;\n    margin-right: 10px;\n  }\n  button.ant-btn-text svg {\n    width: 32px;\n    height: 20px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  .input-message {\n    flex: 1;\n    margin-bottom: 0;\n    margin-right: 10px;\n  }\n  button.ant-btn-text svg {\n    width: 32px;\n    height: 20px;\n  }\n"])));
export var MessageWrapStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n"], ["\n"])));
export var MessageWrapTitleStyled = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 0;\n  color: #000;\n  text-transform: uppercase;\n  span {\n    margin: 0 10px;\n    font-size: 15px;\n    font-weight: 500;\n  }\n  &:before, &:after {\n    content: \"\";\n    flex: 1;\n    border-top: 1px solid #ccc;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 0;\n  color: #000;\n  text-transform: uppercase;\n  span {\n    margin: 0 10px;\n    font-size: 15px;\n    font-weight: 500;\n  }\n  &:before, &:after {\n    content: \"\";\n    flex: 1;\n    border-top: 1px solid #ccc;\n  }\n"])));
export var MessageContentStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 10px;\n  span {\n    display: block;\n    margin-bottom: 5px;\n    color: #000;\n    font-size: 14px;\n    font-weight: 500;\n  }\n  p {\n    display: inline-block;\n    max-width: 80%;\n    margin: 0;\n    padding: 5px;\n    border-radius: 4px;\n  }\n  ", "\n  \n  .internalMessage {\n    background-color: #fcd5d5;\n  }\n\n  .externalMessage {\n    background-color: #e1e1e1;\n  }\n"], ["\n  margin-top: 10px;\n  span {\n    display: block;\n    margin-bottom: 5px;\n    color: #000;\n    font-size: 14px;\n    font-weight: 500;\n  }\n  p {\n    display: inline-block;\n    max-width: 80%;\n    margin: 0;\n    padding: 5px;\n    border-radius: 4px;\n  }\n  ", "\n  \n  .internalMessage {\n    background-color: #fcd5d5;\n  }\n\n  .externalMessage {\n    background-color: #e1e1e1;\n  }\n"])), function (props) { return props.$isOnLeftSide
    ? "\n      text-align: left;\n      p {\n        padding-right: 15px;\n        background-color: #e5e5e5;\n      }\n    "
    : "\n      text-align: right;\n      p {\n        padding-left: 15px;\n        background-color: #bfebf3;\n      }\n    "; });
export var Square = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\theight: 15px;\n\twidth: 15px;\n\tmargin-bottom: 15px;\n\tmargin-left: 15px;\n\tmargin-right: 5px;\n\tbackground-color: ", ";\n"], ["\n\theight: 15px;\n\twidth: 15px;\n\tmargin-bottom: 15px;\n\tmargin-left: 15px;\n\tmargin-right: 5px;\n\tbackground-color: ", ";\n"])), function (props) { return props.$bgColor; });
export var MessageTypeLegend = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tdisplay: flex;\n    position: fixed;\n\talign-items: baseline;\n    margin-top: -22px;\n"], ["\n\tdisplay: flex;\n    position: fixed;\n\talign-items: baseline;\n    margin-top: -22px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
