var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { showErrorMessage } from "@g4p/react-styleguide-library";
import { useIdleCountdown } from "./components/IdleGuard/IdleGuard";
import ClientCreateDokuBox from "./flows/ClientCreateDokuBox/ClientCreateDokuBox";
import InternetMain from "./InternetMain";
import ClientDokuBoxList from "./pages/admin/DokuBoxList/ClientDokuBoxList";
import ClientManageDokuBox from "./pages/client/ClientManageDokuBox/ClientManageDokuBox";
import { IdleLogout } from "./pages/common/Login/components/IdleLogout";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useFormatMessage } from "./services/formatMessageHook";
import OauthAuthorize from "./components/oauth/OauthAuthorize";
import { clientIdleLogout } from "./pages/client/ClientLogin/services/clientLogoutApi";
import { useAppContext } from "./services/appContext";
import { removeAuthFromLocalStorage } from "./services/appStorage";
import { executeAxios } from "./services/axiosHook";
import { clientUnlockDokuBox } from "./pages/client/ClientManageDokuBox/services/clientManageDokuBoxApi";
import { useUnlockDokuBox } from "./helper/unlockDokuBox";
import WaitForContractProcessFinish from "./pages/client/ClientLogin/components/contract/WaitForContractProcessFinish";
import WaitForDokuSignature from "./pages/client/ClientManageDokuBox/components/WaitForDokuSignature";
import { removeWindowOpen } from "./services/appApi";
import Cookies from "./pages/client/Cookies/Cookies";
import ContractProcessError from "./pages/client/ClientLogin/components/contract/ContractProcessError";
import ClientManageDokuBoxRedesign from "./pages/client/ClientManageDokuBox/ClientManageDokuBoxRedesign";
import WaitForContractSignature from "./pages/client/ClientLogin/components/contract/WaitForContractSignature";
var InternetRouter = function () {
    var _a = useAppContext(), setIsLoggedIn = _a.setIsLoggedIn, isLoggedIn = _a.isLoggedIn;
    var formatMessage = useFormatMessage().formatMessage;
    useIdleCountdown();
    var currentLockedDokuBoxId = useAppContext().currentLockedDokuBoxId;
    var unlockDokuBox = useUnlockDokuBox().unlockDokuBox;
    return (_jsx(_Fragment, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: isLoggedIn ? _jsx(Navigate, { to: "/app" }) : _jsx(Navigate, { to: "/index.html" }) }), _jsx(Route, { path: "oauth/authorize", element: _jsx(OauthAuthorize, {}) }), _jsxs(Route, __assign({ path: "app", element: _jsx(IdleLogout, __assign({ logoutHandler: function () {
                            if (currentLockedDokuBoxId) {
                                unlockDokuBox(function () { }, clientUnlockDokuBox, currentLockedDokuBoxId);
                            }
                            removeWindowOpen();
                            executeAxios({
                                params: __assign({}, clientIdleLogout),
                                onSuccess: function () {
                                    removeAuthFromLocalStorage();
                                    showErrorMessage({
                                        title: formatMessage("dde.idle.logout.title"),
                                        message: formatMessage("dde.idle.logout.message"),
                                    });
                                    setIsLoggedIn(false);
                                    return "";
                                },
                                onFail: function () {
                                    removeAuthFromLocalStorage();
                                },
                            });
                        } }, { children: _jsx(InternetMain, {}) })) }, { children: [_jsx(Route, { path: "", element: _jsx(Navigate, { to: "dokuboxlist" }) }), _jsx(Route, { path: "dokuboxlist", element: _jsx(ClientDokuBoxList, {}) }), _jsx(Route, { path: "contract/waitforsignatures", element: _jsx(WaitForContractSignature, {}) }), _jsx(Route, { path: "viewdokubox/:dokuBoxId", element: _jsx(ClientManageDokuBoxRedesign, { readOnly: true }) }), _jsx(Route, { path: "editdokubox/:dokuBoxId", element: _jsx(ClientManageDokuBoxRedesign, { readOnly: false }) }), _jsx(Route, { path: "viewdokuboxOld/:dokuBoxId", element: _jsx(ClientManageDokuBox, { readOnly: true }) }), _jsx(Route, { path: "editdokuboxOld/:dokuBoxId", element: _jsx(ClientManageDokuBox, { readOnly: false }) }), _jsx(Route, { path: "contract/waitforprocessfinish", element: _jsx(WaitForContractProcessFinish, {}) }), _jsx(Route, { path: "contract/contractprocesserror", element: _jsx(ContractProcessError, {}) }), _jsx(Route, { path: "doku/waitfordokusign/:dokuBoxId", element: _jsx(WaitForDokuSignature, {}) }), _jsx(Route, { path: "cookies", element: _jsx(Cookies, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "dokuboxlist" }) }), _jsxs(Route, __assign({ path: "flow", element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: "", element: _jsx(Navigate, { to: "dokuboxlist" }) }), _jsx(Route, { path: "createdokubox/:dokuBoxId", element: _jsx(ClientCreateDokuBox, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "dokuboxlist" }) })] }))] })), _jsx(Route, { path: "*", element: isLoggedIn ? _jsx(Navigate, { to: "/app" }) : _jsx(Navigate, { to: "/index.html" }) })] }) }));
};
export default InternetRouter;
