var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { showErrorMessage } from "@g4p/react-styleguide-library";
import { userIsLoggedIn } from "../services/appStorage";
import { commonRequestHeaders, executeAxiosPreventMultipleServerCalls, getAuthRequestHeaders, getCommonRequestBaseUrl } from "../services/axiosHook";
import { useFormatMessage } from "../services/formatMessageHook";
import { useAppContext } from "../services/appContext";
import { clientUnlockAllDokuboxesLockedByCurrentUser, clientUnlockDokuBox } from "../pages/client/ClientManageDokuBox/services/clientManageDokuBoxApi";
import { bankerUnlockAllDokuboxesLockedByCurrentUser, bankerUnlockDokuBox, formatServerError, } from "../pages/banker/BankerManageDokuBox/services/bankerManageDokuBoxApi";
import { useCallback, useState } from "react";
export function useUnlockDokuBox() {
    var formatMessage = useFormatMessage().formatMessage;
    var _a = useAppContext(), setCurrentLockedDokuBoxId = _a.setCurrentLockedDokuBoxId, clientApp = _a.clientApp;
    var _b = useState(false), unlockLoading = _b[0], setUnlockLoading = _b[1];
    var unlockDokuBox = useCallback(function (onReady, lockUrlParams, currentLockedDokuBoxId, onFinally) {
        if (onFinally === void 0) { onFinally = function () { }; }
        if (currentLockedDokuBoxId && userIsLoggedIn()) {
            var apiConfig = {
                data: {
                    dokuBoxId: currentLockedDokuBoxId,
                },
            };
            executeAxiosPreventMultipleServerCalls({
                params: __assign(__assign({}, lockUrlParams), apiConfig),
                onSuccess: function (response) {
                    try {
                        setCurrentLockedDokuBoxId("");
                        onReady();
                    }
                    finally {
                        onFinally();
                    }
                },
                onLoading: setUnlockLoading,
                onFail: function (resp) {
                    var _a;
                    try {
                        showErrorMessage({
                            title: formatMessage("dde.error.errorTitle"),
                            message: formatServerError(resp, formatMessage),
                        });
                        if (((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.params) && resp.data.params[0]) {
                            onReady();
                        }
                    }
                    finally {
                        onFinally();
                    }
                },
            });
        }
    }, [formatMessage, setCurrentLockedDokuBoxId]);
    var sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler = useCallback(function (currentLockedDokuBoxId, selectedDokuBox) {
        if ((selectedDokuBox === null || selectedDokuBox === void 0 ? void 0 : selectedDokuBox.id) && (selectedDokuBox === null || selectedDokuBox === void 0 ? void 0 : selectedDokuBox.lockStartTime) && currentLockedDokuBoxId === String(selectedDokuBox.id)) {
            var apiConfig = clientApp ? clientUnlockDokuBox : bankerUnlockDokuBox;
            // Most robust way to make a server call on window beforeunload event is using fetch with keepalive: true
            // https://stackoverflow.com/questions/60530716/execute-server-api-call-on-beforeunload-limitations-on-page-unload
            fetch(getCommonRequestBaseUrl() + apiConfig.url, {
                method: apiConfig.method,
                keepalive: true,
                headers: __assign(__assign(__assign({}, commonRequestHeaders), getAuthRequestHeaders()), { "Content-Type": "application/json" }),
                body: JSON.stringify({ dokuBoxId: selectedDokuBox.id, lockStartTimeFilter: selectedDokuBox.lockStartTime }),
            });
        }
    }, [clientApp]);
    var unlockAllDokuboxesLockedByCurrentUser = useCallback(function (onReady) {
        if (userIsLoggedIn()) {
            var apiConfig = clientApp ? clientUnlockAllDokuboxesLockedByCurrentUser : bankerUnlockAllDokuboxesLockedByCurrentUser;
            executeAxiosPreventMultipleServerCalls({
                params: __assign({}, apiConfig),
                onSuccess: function (response) {
                    setCurrentLockedDokuBoxId("");
                    onReady();
                },
                onLoading: setUnlockLoading,
                onFail: function (resp) {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatServerError(resp, formatMessage),
                    });
                },
            });
        }
    }, [formatMessage, setCurrentLockedDokuBoxId, clientApp]);
    return {
        unlockLoading: unlockLoading,
        unlockDokuBox: unlockDokuBox,
        sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler: sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler,
        unlockAllDokuboxesLockedByCurrentUser: unlockAllDokuboxesLockedByCurrentUser,
    };
}
