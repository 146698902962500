var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockIcon, CopyIcon, DeleteIcon, EditIcon, ShowIcon, Tooltip } from "@g4p/react-styleguide-library";
import { ActionButtonStyled } from "./styles";
export var TableActionButton = function (props) {
    if (props.tooltipTitle) {
        return (_jsx(ActionButtonStyled, __assign({ type: "text", onClick: props.onClick, "$disabled": !!props.disabled }, { children: _jsxs(Tooltip, __assign({ title: props.tooltipTitle }, { children: [props.type === "edit" && _jsx(EditIcon, {}), props.type === "view" && _jsx(ShowIcon, {}), props.type === "delete" && _jsx(DeleteIcon, {}), props.type === "locked" && _jsx(BlockIcon, {}), props.type === "copy" && _jsx(CopyIcon, {}), props.label] })) })));
    }
    else {
        return (_jsxs(ActionButtonStyled, __assign({ type: "text", onClick: props.onClick, "$disabled": !!props.disabled }, { children: [props.type === "edit" && _jsx(EditIcon, {}), props.type === "view" && _jsx(ShowIcon, {}), props.type === "delete" && _jsx(DeleteIcon, {}), props.type === "locked" && _jsx(BlockIcon, {}), props.type === "copy" && _jsx(CopyIcon, {}), props.label] })));
    }
};
