var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Space, Typography } from "antd";
import StatusTitle from "../../../components/StatusCard/StatusTitle";
import { getByLocale } from "../../../services/appApi";
import { SubTitleWrapperStyled } from "./styles";
import { useFormatMessage } from "../../../services/formatMessageHook";
var DokuPanelTitle = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    return (_jsxs(_Fragment, { children: [_jsxs(Typography.Title, __assign({ level: 4 }, { children: [props.index, ". ", getByLocale(props.doku.titleEn, props.doku.titleLocal)] })), _jsx(SubTitleWrapperStyled, { children: _jsx(Space, __assign({ direction: "horizontal" }, { children: _jsx(Typography.Text, { children: _jsx(StatusTitle, { status: props.doku.status }) }) })) })] }));
};
export default DokuPanelTitle;
