var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Modal, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { afterLoggedInUserDetailsLoaded, externalLinkPresent, removeAuthCookiesByServerCall, useTranslationsApi } from "../../../services/appApi";
import { useAppContext, UserType } from "../../../services/appContext";
import { getLocaleFromLocalStorage, removeAuthFromLocalStorage, setAuthInLocalStorage, setExternalParamInLocalStorage, setFlowIdInLocalStorage, setLocaleInLocalStorage, } from "../../../services/appStorage";
import { executeAxios, getQueryVariable } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import LoginForm from "../../common/Login/components/LoginForm";
import { useTenantContext } from "../../../services/tenantContext";
import LoginWrapper from "../../common/Login/components/LoginWrapper";
import { ChangePasswordForm } from "../settings/ChangePasswordForm";
import { mapToChangePasswordApiConfig } from "../settings/services/changePasswordApi";
import { ClientLoginOtpForm } from "./components/ClientLoginOtpForm";
import { ContractSignatureNotAvailableDialog } from "./components/contract/ContractSignatureNotAvailableDialog";
import { SelectClientForm } from "./components/SelectClientForm";
import { authenticationFromJwtReference, checkLoggedInClientToken, clientChangePassword, clientVerifyOtpConfig, getClientDataForLogin, prepareContractUserActivation, selectClient, sendSmsOtp, verifyAuthenticationRequestId, verifyClientPassword, } from "./services/clientLoginApi";
var LoginPhase;
(function (LoginPhase) {
    LoginPhase[LoginPhase["NAMEANDPASSWORD"] = 0] = "NAMEANDPASSWORD";
    LoginPhase[LoginPhase["PASSWORDCHANGE"] = 1] = "PASSWORDCHANGE";
    LoginPhase[LoginPhase["OTP"] = 2] = "OTP";
    LoginPhase[LoginPhase["SELECTCLIENT"] = 3] = "SELECTCLIENT";
})(LoginPhase || (LoginPhase = {}));
var ClientLogin = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), loadingOtp = _b[0], setLoadingOtp = _b[1];
    var _c = useState(false), loadingClients = _c[0], setLoadingClients = _c[1];
    var _d = useState(false), loadingCheckClientToken = _d[0], setLoadingCheckClientToken = _d[1];
    var _e = useState(false), loadingForceLogout = _e[0], setLoadingForceLogout = _e[1];
    var _f = useState(false), loadingSelectClient = _f[0], setLoadingSelectClient = _f[1];
    var _g = useState(false), loadingPrepareContract = _g[0], setLoadingPrepareContract = _g[1];
    var _h = useState(false), loginByAuthenticationRequestIdStarted = _h[0], setLoginByAuthenticationRequestIdStarted = _h[1];
    var _j = useState(LoginPhase.NAMEANDPASSWORD), currentLoginPhase = _j[0], setCurrentLoginPhase = _j[1];
    var _k = useState(false), isContractSignatureNotAvailableDlgVisible = _k[0], setIsContractSignatureNotAvailableDlgVisible = _k[1];
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var _l = useFormatMessage(), formatMessage = _l.formatMessage, formatRichTextMessage = _l.formatRichTextMessage;
    var _m = useAppContext(), setIsLoggedIn = _m.setIsLoggedIn, setPermissions = _m.setPermissions, setRoles = _m.setRoles, setUser = _m.setUser, setClientList = _m.setClientList, clientList = _m.clientList;
    var _o = useState(""), username = _o[0], setUsername = _o[1];
    var _p = useState(0), failedOTPAttemptsCount = _p[0], setFailedOTPAttemptsCount = _p[1];
    var fetchAndUpdateTranslations = useTranslationsApi().fetchAndUpdateTranslations;
    var _q = useState([]), actions = _q[0], setActions = _q[1];
    var searchParams = useSearchParams()[0];
    var tenant = useTenantContext().tenant;
    var setCurrentLockedTaskId = useAppContext().setCurrentLockedTaskId;
    var _r = useState(false), isMoreSignatureRequired = _r[0], setIsMoreSignatureRequired = _r[1];
    var handleTranslationUpdate = function (value) {
        setLocaleInLocalStorage(value);
        fetchAndUpdateTranslations(value);
    };
    var showGeneralError = useCallback(function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    }, [formatMessage]);
    var callPrepareContractUserActivation = useCallback(function (clientNumber, username) {
        var data = { clientNumber: clientNumber, username: username };
        executeAxios({
            params: __assign(__assign({}, prepareContractUserActivation), { data: data }),
            onFail: showGeneralError,
            onLoading: setLoadingPrepareContract,
            onSuccess: function (response) {
                if (!response.userCanSign) {
                    setLoadingPrepareContract(false);
                    setIsContractSignatureNotAvailableDlgVisible(true);
                }
                else {
                    navigate("/contract/waitforsignatures");
                }
            },
        });
    }, [showGeneralError]);
    var handleContractSignatureNotAvailableDlgClosed = function () {
        console.log(clientList);
        if (clientList !== undefined && clientList !== null && clientList.length > 1) {
            setIsContractSignatureNotAvailableDlgVisible(false);
        }
        else {
            setIsContractSignatureNotAvailableDlgVisible(false);
            forceLogout(backToPasswordForm, backToPasswordForm);
        }
    };
    var onClientSelected = useCallback(function (clientNumber, clientListForSearch, username) {
        var client = clientListForSearch.find(function (item) { return item.clientNumber === clientNumber; });
        if (client) {
            var data = { selectedClientData: client, username: username };
            executeAxios({
                params: __assign(__assign({}, selectClient), { data: data }),
                onFail: showGeneralError,
                onLoading: setLoadingSelectClient,
                onSuccess: function (response) {
                    setAuthInLocalStorage(response);
                    setPermissions(response.permissions);
                    setRoles(response.roles);
                    console.log("ClientLogin - afterLoggedInUserDetailsLoaded");
                    afterLoggedInUserDetailsLoaded(setUser, setLoading, showGeneralError, function () {
                        // CZ/SK contract signing
                        if ((tenant === "CZ" || tenant === "SK") &&
                            ((response.personRole === "LEGAL_REPRESENTATIVE" && response.noOfRequiredSignaturesLeft != 0 && response.contractSigned)
                                || (response.personRole != "LEGAL_REPRESENTATIVE" && response.noOfRequiredSignaturesLeft != 0))) {
                            setIsMoreSignatureRequired(true);
                        }
                        else if (!response.contractSigned && tenant !== "BG") {
                            callPrepareContractUserActivation(clientNumber, username);
                        }
                        else {
                            setIsLoggedIn(true);
                            externalLinkPresent(navigate, response.username, setCurrentLockedTaskId);
                        }
                    }, true, true);
                },
            });
        }
    }, [showGeneralError, navigate, callPrepareContractUserActivation, setIsLoggedIn, username]);
    var forceLogout = useCallback(function (onSuccess, onFail) {
        removeAuthFromLocalStorage();
        executeAxios({
            params: __assign({}, removeAuthCookiesByServerCall),
            onLoading: setLoadingForceLogout,
            onFail: function () { return onFail && onFail(); },
            onSuccess: function () { return onSuccess && onSuccess(); },
        });
    }, []);
    var backToPasswordForm = useCallback(function () {
        setIsLoggedIn(false);
        setCurrentLoginPhase(LoginPhase.NAMEANDPASSWORD);
    }, [setIsLoggedIn]);
    var onCheckClientTokenFail = useCallback(function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.login.error.noClientFound"),
        });
        forceLogout(backToPasswordForm, backToPasswordForm);
    }, [forceLogout, formatMessage, backToPasswordForm]);
    var checkClientTokenThen = useCallback(function (username, onSuccess) {
        var data = { username: username };
        executeAxios({
            params: __assign(__assign({}, checkLoggedInClientToken), { data: data }),
            onFail: onCheckClientTokenFail,
            onLoading: setLoadingCheckClientToken,
            onSuccess: function (result) {
                if (result === null || result === void 0 ? void 0 : result.tokenValid) {
                    onSuccess();
                }
                else {
                    onCheckClientTokenFail();
                }
            },
        });
    }, [onCheckClientTokenFail]);
    var initiateSelectClientIfNeeded = useCallback(function (usernameForLoadingClient) {
        checkClientTokenThen(usernameForLoadingClient, function () {
            var data = { username: usernameForLoadingClient };
            executeAxios({
                params: __assign(__assign({}, getClientDataForLogin), { data: data }),
                onFail: showGeneralError,
                onLoading: setLoadingClients,
                onSuccess: function (result) {
                    if (result && result.clientList && result.clientList.length > 1) {
                        setClientList(result.clientList);
                        setCurrentLoginPhase(LoginPhase.SELECTCLIENT);
                    }
                    else if (result && result.clientList && result.clientList.length === 1 && result.clientList[0] && result.clientList[0].clientNumber) {
                        setClientList([]);
                        onClientSelected(result.clientList[0].clientNumber, result.clientList, usernameForLoadingClient);
                    }
                    else {
                        showErrorMessage({
                            title: formatMessage("dde.error.errorTitle"),
                            message: formatMessage("dde.login.error.noClientFound"),
                        });
                    }
                },
            });
        });
    }, [showGeneralError, onClientSelected, checkClientTokenThen, username]);
    var initiateOtpVerification = function () {
        executeAxios({
            params: __assign({}, sendSmsOtp),
            onFail: showGeneralError,
            onLoading: setLoadingOtp,
            onSuccess: function () {
                setCurrentLoginPhase(LoginPhase.OTP);
            },
        });
    };
    var description = (_jsx(_Fragment, { children: _jsx(Typography.Text, { children: formatRichTextMessage("dde.login.client.welcometext") }) }));
    var getSubtitle = function () {
        switch (currentLoginPhase) {
            case LoginPhase.OTP:
                return formatMessage("dde.login.client.subtitle.otp");
            case LoginPhase.PASSWORDCHANGE:
                return formatMessage("dde.login.client.subtitle.passwordchange");
            default:
                return;
        }
    };
    var getActions = useCallback(function () {
        var actions = [];
        if (!window.location.href.includes("/intranet")) {
            actions.push({
                onActionClick: function () { return navigate("/forgottenPassword"); },
                actionLabel: formatMessage("dde.forgottenPassword.title"),
            });
        }
        return actions;
    }, [formatMessage, navigate]);
    var loginByAuthenticationRequestId = useCallback(function (authenticationRequestId) {
        removeAuthFromLocalStorage();
        var apiConfig = {
            data: {
                authenticationRequestId: authenticationRequestId,
            },
        };
        setLoginByAuthenticationRequestIdStarted(true);
        executeAxios({
            params: __assign(__assign({}, verifyAuthenticationRequestId), apiConfig),
            onFail: showGeneralError,
            onLoading: setLoading,
            onSuccess: function (response) {
                var usernameFromResponse = response.username;
                if (usernameFromResponse) {
                    setUsername(usernameFromResponse);
                    if (searchParams.get("ddeParam") != null) {
                        setExternalParamInLocalStorage(searchParams.get("ddeParam"));
                    }
                    setPermissions(response.permissions);
                    setRoles(response.roles);
                    setAuthInLocalStorage({
                        flowId: response.flowId,
                        username: usernameFromResponse,
                        alias: response.alias,
                    });
                    initiateSelectClientIfNeeded(usernameFromResponse);
                }
                else {
                    showGeneralError();
                }
            },
        });
    }, [showGeneralError, initiateSelectClientIfNeeded]);
    useEffect(function () {
        var authenticationRequestId = searchParams.get("AuthenticationRequestID");
        if (authenticationRequestId && !loginByAuthenticationRequestIdStarted) {
            loginByAuthenticationRequestId(authenticationRequestId);
        }
    }, [searchParams, loginByAuthenticationRequestId]);
    useEffect(function () {
        setActions(getActions());
        loginUsingJwt();
    }, [getActions]);
    var loginUsingJwt = function () {
        var jwtReference = getQueryVariable("jwtReference");
        var apiConfig = {
            data: {
                jwtReference: jwtReference,
            },
        };
        if (jwtReference) {
            executeAxios({
                params: __assign(__assign({}, authenticationFromJwtReference), apiConfig),
                onFail: showGeneralError,
                onLoading: setLoading,
                onSuccess: function (response) {
                    var usernameFromResponse = response.username;
                    setUsername(usernameFromResponse);
                    setRoles(response.roles[0]);
                    setAuthInLocalStorage({
                        flowId: response.flowID,
                        username: usernameFromResponse,
                        alias: response.alias,
                    });
                    initiateSelectClientIfNeeded(usernameFromResponse);
                },
            });
        }
    };
    var getHelpTooltipMessage = function () {
        var mainMsg = "";
        if (currentLoginPhase === LoginPhase.NAMEANDPASSWORD) {
            mainMsg = formatMessage("dde.login.help.doyouneedsupport.client.usernamepassword");
        }
        else if (currentLoginPhase === LoginPhase.PASSWORDCHANGE) {
            mainMsg = formatMessage("dde.login.help.doyouneedsupport.client.passwordchange");
        }
        else if (currentLoginPhase === LoginPhase.OTP) {
            mainMsg = formatMessage("dde.login.help.doyouneedsupport.client.otp");
        }
        else if (currentLoginPhase === LoginPhase.SELECTCLIENT) {
            mainMsg = formatMessage("dde.login.help.doyouneedsupport.client.selectclient");
        }
        return "".concat(mainMsg, " ").concat(formatMessage("dde.login.help.doyouneedsupport.client.support.phonenumber"));
    };
    var handleOnClose = function () {
        setIsMoreSignatureRequired(false);
        setCurrentLoginPhase(LoginPhase.NAMEANDPASSWORD);
        removeAuthFromLocalStorage();
        setIsLoggedIn(false);
    };
    return (_jsx(LoginWrapper, __assign({ description: currentLoginPhase === LoginPhase.NAMEANDPASSWORD ? description : undefined, title: formatMessage("dde.login.mainTitle"), subtitle: getSubtitle(), showBackButton: currentLoginPhase !== LoginPhase.NAMEANDPASSWORD, onBackActionClick: function () { return setCurrentLoginPhase(LoginPhase.NAMEANDPASSWORD); }, actions: actions, helpTooltipMessage: getHelpTooltipMessage() }, { children: _jsxs(_Fragment, { children: [currentLoginPhase === LoginPhase.NAMEANDPASSWORD && (_jsx(LoginForm, { userType: UserType.CLIENT, onSubmit: function (formValues) {
                        var username = tenant === "RO" ? formValues.username.toLowerCase().trim() : formValues.username.trim();
                        var apiConfig = {
                            data: {
                                username: username,
                                password: formValues.password,
                            },
                        };
                        forceLogout(function () {
                            executeAxios({
                                params: __assign(__assign({}, verifyClientPassword), apiConfig),
                                onFail: function () {
                                    showErrorMessage({
                                        title: formatMessage("dde.error.errorTitle"),
                                        message: formatMessage("dde.login.error.client.userBlocked"),
                                    });
                                },
                                onLoading: setLoading,
                                onSuccess: function (response) {
                                    setFlowIdInLocalStorage(response.flowID);
                                    if (searchParams.get("ddeParam") != null) {
                                        setExternalParamInLocalStorage(searchParams.get("ddeParam"));
                                    }
                                    if (response.changePassword) {
                                        setUsername(username);
                                        setCurrentLoginPhase(LoginPhase.PASSWORDCHANGE);
                                    }
                                    else {
                                        initiateOtpVerification();
                                    }
                                },
                            });
                        }, showGeneralError);
                    }, initialValues: { language: getLocaleFromLocalStorage(), username: "", password: "" }, onLanguageChange: handleTranslationUpdate })), currentLoginPhase === LoginPhase.PASSWORDCHANGE && (_jsx(ChangePasswordForm, { onSubmit: function (formValues) {
                        var apiConfig = __assign({}, mapToChangePasswordApiConfig(formValues, username, "LOGIN"));
                        executeAxios({
                            params: __assign(__assign({}, clientChangePassword), apiConfig),
                            onSuccess: function () {
                                initiateOtpVerification();
                            },
                            onLoading: setLoading,
                            onFail: function (resp) {
                                var _a;
                                showErrorMessage({
                                    title: formatMessage("dde.error.errorTitle"),
                                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                                });
                            },
                        });
                    }, initialValues: { repeatedPassword: "", newPassword: "", currentPassword: "" }, form: form })), currentLoginPhase === LoginPhase.OTP && (_jsx(ClientLoginOtpForm, { onSubmit: function (values) {
                        var apiConfig = {
                            data: {
                                otpCode: values.otpPassword,
                                userName: username,
                            },
                        };
                        executeAxios({
                            params: __assign(__assign({}, clientVerifyOtpConfig), apiConfig),
                            onFail: function () {
                                showErrorMessage({
                                    title: formatMessage("dde.error.errorTitle"),
                                    message: formatMessage("dde.login.error.otp"),
                                });
                                if (failedOTPAttemptsCount >= 2) {
                                    removeAuthFromLocalStorage();
                                    setUsername("");
                                    setFailedOTPAttemptsCount(0);
                                    setCurrentLoginPhase(LoginPhase.NAMEANDPASSWORD);
                                }
                                else {
                                    setFailedOTPAttemptsCount(failedOTPAttemptsCount + 1);
                                }
                            },
                            onLoading: setLoading,
                            onSuccess: function (response) {
                                var flowId = localStorage.getItem("flowId");
                                setUsername(response.username);
                                setPermissions(response.permissions);
                                setRoles(response.roles);
                                setAuthInLocalStorage({
                                    username: response.username,
                                    flowId: flowId ? flowId : "",
                                    alias: response.alias,
                                });
                                initiateSelectClientIfNeeded(response.username);
                            },
                        });
                    }, initialValues: { otpPassword: "", userName: username } })), currentLoginPhase === LoginPhase.SELECTCLIENT && (_jsx(SelectClientForm, { onSubmit: function (formValues) {
                        onClientSelected(formValues.client, clientList, username);
                    }, initialValues: { client: "" }, clientList: clientList })), isMoreSignatureRequired && (_jsx(Modal, { title: formatMessage("dde.login.error.contract.moreSignatures.required"), open: isMoreSignatureRequired, onOk: handleOnClose, onClose: handleOnClose, cancelButtonProps: { style: { visibility: "hidden" } }, okText: "OK" })), _jsx(ContractSignatureNotAvailableDialog, { isVisible: isContractSignatureNotAvailableDlgVisible, setIsVisible: setIsContractSignatureNotAvailableDlgVisible, onClose: handleContractSignatureNotAvailableDlgClosed }), (loading ||
                    loadingOtp ||
                    loadingClients ||
                    loadingSelectClient ||
                    loadingPrepareContract ||
                    loadingCheckClientToken ||
                    loadingForceLogout) && _jsx(Spinner, {})] }) })));
};
export default ClientLogin;
