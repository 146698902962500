export var DokuBoxStatus;
(function (DokuBoxStatus) {
    DokuBoxStatus["CLIENT_SETUP"] = "CLIENT_SETUP";
    DokuBoxStatus["BANK_SETUP"] = "BANK_SETUP";
    DokuBoxStatus["IN_PROGRESS"] = "IN_PROGRESS";
    DokuBoxStatus["FINALIZED"] = "FINALIZED";
    DokuBoxStatus["CANCELLED"] = "CANCELLED";
    DokuBoxStatus["PENDING_CANCELLATION_APPROVAL"] = "PENDING_CANCELLATION_APPROVAL";
})(DokuBoxStatus || (DokuBoxStatus = {}));
export var DokuStatus;
(function (DokuStatus) {
    DokuStatus["WAITING_CLIENT_UPLOAD"] = "WAITING_CLIENT_UPLOAD";
    DokuStatus["WAITING_BANK_UPLOAD"] = "WAITING_BANK_UPLOAD";
    DokuStatus["UPLOADED_CLIENT"] = "UPLOADED_CLIENT";
    DokuStatus["UPLOADED_BANK"] = "UPLOADED_BANK";
    DokuStatus["UNDER_NEGOTIATION_CLIENT"] = "UNDER_NEGOTIATION_CLIENT";
    DokuStatus["UNDER_NEGOTIATION_BANK"] = "UNDER_NEGOTIATION_BANK";
    DokuStatus["UNDER_REVIEW_CLIENT"] = "UNDER_REVIEW_CLIENT";
    DokuStatus["UNDER_REVIEW_BANK"] = "UNDER_REVIEW_BANK";
    DokuStatus["WAITING_CLIENT_FEEDBACK"] = "WAITING_CLIENT_FEEDBACK";
    DokuStatus["WAITING_BANK_FEEDBACK"] = "WAITING_BANK_FEEDBACK";
    DokuStatus["WAITING_CLIENT_SIGNATURE"] = "WAITING_CLIENT_SIGNATURE";
    DokuStatus["WAITING_BANK_SIGNATURE"] = "WAITING_BANK_SIGNATURE";
    DokuStatus["WAITING_BANK_SIGNATURE_AUX"] = "WAITING_BANK_SIGNATURE_AUX";
    DokuStatus["WAITING_ARCHIVAL"] = "WAITING_ARCHIVAL";
    DokuStatus["UNDER_ARCHIVING"] = "UNDER_ARCHIVING";
    DokuStatus["ARCHIVED"] = "ARCHIVED";
    DokuStatus["UNDER_REVIEW_CLIENT_1"] = "UNDER_REVIEW_CLIENT_1";
    DokuStatus["UNDER_REVIEW_CLIENT_2"] = "UNDER_REVIEW_CLIENT_2";
    DokuStatus["UNDER_REVIEW_CLIENT_3"] = "UNDER_REVIEW_CLIENT_3";
    DokuStatus["UNDER_REVIEW_BANK_1"] = "UNDER_REVIEW_BANK_1";
    DokuStatus["UNDER_REVIEW_BANK_2"] = "UNDER_REVIEW_BANK_2";
    DokuStatus["UNDER_REVIEW_BANK_3"] = "UNDER_REVIEW_BANK_3";
    DokuStatus["WAITING_BANK_SIGNATURE_2"] = "WAITING_BANK_SIGNATURE_2";
    DokuStatus["WAITING_CLIENT_ACKNOWLEDGEMENT"] = "WAITING_CLIENT_ACKNOWLEDGEMENT";
})(DokuStatus || (DokuStatus = {}));
export var DokuBoxSearchStatus;
(function (DokuBoxSearchStatus) {
    DokuBoxSearchStatus["IN_PROGRESS"] = "IN_PROGRESS";
    DokuBoxSearchStatus["FINALIZED"] = "FINALIZED";
    DokuBoxSearchStatus["CANCELLED"] = "CANCELLED";
    DokuBoxSearchStatus["PENDING_CANCELLATION_APPROVAL"] = "PENDING_CANCELLATION_APPROVAL";
})(DokuBoxSearchStatus || (DokuBoxSearchStatus = {}));
