import { useEffect, useState } from "react";
import { useIdle } from "react-use";
import { useCountdown } from "./useCountdown";
export function useIdleCountdown(idleTime, showWarningBefore, onCountdownEnd) {
    var idle = useIdle(1000);
    var _a = useState(false), showWarning = _a[0], setShowWarning = _a[1];
    var _b = useCountdown(idleTime, onCountdownEnd, !idle && !showWarning), countdown = _b[0], resetTimer = _b[1];
    useEffect(function () {
        if (!idle && !showWarning && countdown > 0) {
            resetTimer();
        }
    }, [idle, showWarning, resetTimer]);
    useEffect(function () {
        if (countdown * 1000 < showWarningBefore) {
            setShowWarning(true);
        }
    }, [idle, showWarning, countdown, showWarningBefore]);
    var dismissWarning = function () {
        resetTimer();
        setShowWarning(false);
    };
    return { countdown: countdown, showWarning: showWarning, dismissWarning: dismissWarning };
}
