var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Input, Modal } from "@g4p/react-styleguide-library";
import { useFormatMessage } from "../../../../services/formatMessageHook";
var ClientAddingOtherDoku = function (props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var formatMessage = useFormatMessage().formatMessage;
    var addOtherDokuForm = Form.useForm()[0];
    var requiredRule = { required: true, message: formatMessage("dde.validation.common.requiredField") };
    var handleSubmit = function () {
        addOtherDokuForm.validateFields().then(function (values) {
            props.addOtherDoku(addOtherDokuForm.getFieldsValue());
            setIsVisible(false);
            addOtherDokuForm.resetFields();
        });
    };
    var handleCancel = function () {
        setIsVisible(false);
        addOtherDokuForm.resetFields();
    };
    return (_jsx(_Fragment, { children: _jsx(Modal, __assign({ title: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.title"), open: isVisible, onCancel: handleCancel, onOk: handleSubmit, okText: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.addBtn"), cancelText: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.cancelBtn") }, { children: _jsx("div", __assign({ style: { padding: "10px" } }, { children: _jsxs(Form, __assign({ form: addOtherDokuForm, initialValues: {} }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "documentName" }, { children: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.documentName") })), _jsx(Form.Item, __assign({ name: "documentName", rules: [requiredRule, { max: 50, message: formatMessage("dde.validation.common.maxLength") }] }, { children: _jsx(Input, { id: "documentName", placeholder: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.documentName") }) })), _jsx("label", __assign({ className: "input-label", htmlFor: "dokuDescription" }, { children: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.dokuDescription") })), _jsx(Form.Item, __assign({ name: "dokuDescription", rules: [{ max: 200, message: formatMessage("dde.validation.common.maxLength") }] }, { children: _jsx(Input.TextArea, { rows: 3, id: "dokuDescription", placeholder: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.dokuDescription") }) }))] })) })) })) }));
};
export default ClientAddingOtherDoku;
