var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAppContext } from "../../../services/appContext";
import Alert from "antd/es/alert";
import { useFormatMessage } from "../../../services/formatMessageHook";
import styled from "styled-components";
import moment from "moment/moment";
import { dateFormat24 } from "../../../services/formatters";
var AlertWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
var PortalBlock = function () {
    var _a = useAppContext(), portalBlockScheduled = _a.portalBlockScheduled, portalBlockScheduledDates = _a.portalBlockScheduledDates, portalBlocked = _a.portalBlocked, portalBlockedDate = _a.portalBlockedDate;
    var formatMessage = useFormatMessage().formatMessage;
    if (portalBlockScheduled) {
        return (_jsx(AlertWrapper, { children: _jsx(Alert, { description: formatMessage("dde.login.portal.block.scheduled.message", { "dateAndTimeTo": moment(portalBlockScheduledDates.dateAndTimeTo).format(dateFormat24), "dateAndTimeFrom": moment(portalBlockScheduledDates.dateAndTimeFrom).format(dateFormat24) }), message: formatMessage("dde.login.portal.block.scheduled.title"), type: "warning", showIcon: true, closable: true }) }));
    }
    if (portalBlocked) {
        return (_jsx(AlertWrapper, { children: _jsx(Alert, { description: formatMessage("dde.login.portal.block.started.message", { "dateAndTime": moment(portalBlockedDate.dateAndTimeTo).format(dateFormat24) }), message: formatMessage("dde.login.portal.block.started.title"), type: "error", showIcon: true }) }));
    }
    return null;
};
export default PortalBlock;
var templateObject_1;
