var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from "antd";
import { useFormatMessage } from "../../../services/formatMessageHook";
import CookiesDetails from "./CookiesDetails";
import { PageTitleWrapperStyled } from "../../banker/BankerManageDokuBox/styles";
import { MainContainerStyled } from "../../../styles/styles";
import { Button, Divider } from "@g4p/react-styleguide-library";
import { useNavigate } from "react-router-dom";
var Cookies = function () {
    var formatMessage = useFormatMessage().formatMessage;
    var navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx(PageTitleWrapperStyled, { children: _jsx(MainContainerStyled, { children: _jsx("div", { children: _jsx(Typography.Title, __assign({ className: "title", level: 3, style: { margin: 0 } }, { children: formatMessage("dde.cookiesConsent.aboutPage.title") })) }) }) }), _jsx(MainContainerStyled, { children: _jsxs("div", { children: [_jsx(CookiesDetails, {}), _jsx(Button, __assign({ type: "default", onClick: function () { return navigate(-1); } }, { children: formatMessage("dde.cookiesConsent.aboutPage.goBack") })), _jsx(Divider, { style: { visibility: "hidden" } })] }) })] }));
};
export default Cookies;
