var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BackIcon, Button, Col, Row, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { PageHeader } from "../../../../../components/PageHeader/PageHeader";
import { MainContainerStyled } from "../../../../../styles/styles";
import { executeAxios } from "../../../../../services/axiosHook";
import { useEffect, useState } from "react";
import { useFormatMessage } from "../../../../../services/formatMessageHook";
import { waitForContractSignatures } from "../../services/clientLoginApi";
import { useNavigate } from "react-router-dom";
import { clientLogout } from "../../services/clientLogoutApi";
import { removeAuthFromLocalStorage } from "../../../../../services/appStorage";
import { useTenantContext } from "../../../../../services/tenantContext";
import { useAppContext } from "../../../../../services/appContext";
var WaitForContractSignature = function () {
    var _a = useState(false), loadingWaitForContract = _a[0], setLoadingWaitForContract = _a[1];
    var formatMessage = useFormatMessage().formatMessage;
    var _b = useState("in_progress"), callbackStatus = _b[0], setCallBackStatus = _b[1];
    var navigate = useNavigate();
    var tenant = useTenantContext().tenant;
    var setIsLoggedIn = useAppContext().setIsLoggedIn;
    var showGeneralError = function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    };
    var waitForSignaturesCall = function () {
        executeAxios({
            params: __assign({}, waitForContractSignatures),
            onFail: showGeneralError,
            onLoading: setLoadingWaitForContract,
            onSuccess: function (res) {
                setCallBackStatus(res.callbackResult);
                console.log("waitForContractSignatures  ", callbackStatus);
                if ("success" === callbackStatus) {
                    window.open(res.esawviewerUrl, "_self");
                }
                if ("failed" === callbackStatus) {
                    setLoadingWaitForContract(false);
                }
            },
        });
    };
    useEffect(function () {
        var timer = setTimeout(waitForSignaturesCall, 1000);
        return function () { return clearTimeout(timer); };
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: formatMessage("dde.usermanagement.waitForContractSignaturesCallback.title") }), _jsxs(MainContainerStyled, { children: [_jsx("div", { children: ("in_progress" === callbackStatus || loadingWaitForContract) && _jsx(Spinner, {}) }), "failed" === callbackStatus && (_jsxs("div", { children: [_jsx("h1", { children: formatMessage("dde.error.generalError") }), _jsx(Row, __assign({ maxWidth: "100%" }, { children: _jsx(Col, __assign({ ds: 12, mb: 12 }, { children: _jsxs(Button, __assign({ type: "text", onClick: function () {
                                            return executeAxios({
                                                params: __assign({}, clientLogout),
                                                onSuccess: function () {
                                                    removeAuthFromLocalStorage();
                                                    setIsLoggedIn(false);
                                                    navigate("/index".concat(tenant, ".html"));
                                                },
                                            });
                                        } }, { children: [_jsx(BackIcon, {}), " ", formatMessage("dde.link.backToLogin")] })) })) }))] }))] })] }));
};
export default WaitForContractSignature;
