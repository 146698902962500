import { parsePhoneNumber } from "react-phone-number-input";
export var getPhoneNumberPrefix = function (phoneNumber, phoneNumberCountryCode) {
    if (phoneNumber === undefined || phoneNumber === null) {
        return "";
    }
    var parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    return phoneNumberCountryCode
        ? phoneNumberCountryCode
        : phoneNumber && parsedPhoneNumber
            ? "+" + parsedPhoneNumber.countryCallingCode
            : "";
};
export var getCountryCode = function (phoneNumber, tenant) {
    if (phoneNumber === undefined || phoneNumber === null) {
        return tenant;
    }
    var parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    return (parsedPhoneNumber === null || parsedPhoneNumber === void 0 ? void 0 : parsedPhoneNumber.country) ? parsedPhoneNumber.country : tenant;
};
export var phoneInputChange = function (event, form, phonePrefix) {
    var result = "+" + event.target.value.replace(/\D/g, "");
    form.setFieldValue("phoneNumber", result);
    if (result !== event.target.value) {
        event.target.value = result;
    }
    if (event.target.value.slice(0, phonePrefix.length) !== phonePrefix) {
        form.setFieldValue("phoneNumber", phonePrefix);
    }
};
