var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, Tooltip } from "antd";
import { Dropdown } from "antd";
import { HeaderDropdownContainer, ScrollableMenu } from "./HeaderDropdownStyle";
import { ProfileIcon, SingleTickIcon, UniCreditPassIcon, LargeEnterpriseIcon, showErrorMessage, Modal, } from "@g4p/react-styleguide-library";
import { useCallback, useEffect, useState } from "react";
import { DdeDownIcon, DdeUpIcon, IconWrapper } from "../Header/styles";
import { useTenantContext } from "../../services/tenantContext";
import { useFormatMessage } from "../../services/formatMessageHook";
import { executeAxios } from "../../services/axiosHook";
import { prepareContractUserActivation, selectClient, } from "../../pages/client/ClientLogin/services/clientLoginApi";
import { useAppContext } from "../../services/appContext";
import { setAuthInLocalStorage } from "../../services/appStorage";
import { getByLocaleBG } from "../../services/appApi";
import { useNavigate } from "react-router-dom";
import { ContractSignatureNotAvailableDialog, } from "../../pages/client/ClientLogin/components/contract/ContractSignatureNotAvailableDialog";
var UserHeaderDropdown = function (props) {
    var _a = useState(false), isDropdownOpen = _a[0], setIsDropdownOpen = _a[1];
    var _b = useState(false), isMoreSignatureRequired = _b[0], setIsMoreSignatureRequired = _b[1];
    var _c = useState([]), previousClientNumber = _c[0], setPreviousClientNumber = _c[1];
    var _d = useState(false), isContractSignatureNotAvailableDlgVisible = _d[0], setIsContractSignatureNotAvailableDlgVisible = _d[1];
    var _e = useAppContext(), clientList = _e.clientList, user = _e.user, setPermissions = _e.setPermissions, setRoles = _e.setRoles, setClientList = _e.setClientList;
    var tenant = useTenantContext().tenant;
    var formatMessage = useFormatMessage().formatMessage;
    var navigate = useNavigate();
    useEffect(function () {
        if (clientList.length > 0) {
            localStorage.setItem("clientList", JSON.stringify(clientList));
        }
        else {
            var list = localStorage.getItem("clientList");
            if (list !== null) {
                setClientList(JSON.parse(list));
            }
        }
    }, []);
    var companyNameFormatter = function (name) {
        if (name === undefined)
            return;
        if (name.length > 28) {
            return name.substring(0, 28).trim() + "...";
        }
        else {
            return name;
        }
    };
    var getSliceNumber = function () {
        return (tenant !== "HU" && tenant !== "RO") ? 4 : 3;
    };
    var handleContractSignatureNotAvailableDlgClosed = function () {
        setIsContractSignatureNotAvailableDlgVisible(false);
        onClientSelected(previousClientNumber[0], clientList, user.username, true);
    };
    var showGeneralError = useCallback(function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    }, [formatMessage]);
    var handleOnClose = function () {
        setIsMoreSignatureRequired(false);
        onClientSelected(previousClientNumber[0], clientList, user.username, true);
    };
    var callPrepareContractUserActivation = useCallback(function (clientNumber, username) {
        var data = { clientNumber: clientNumber, username: username };
        executeAxios({
            params: __assign(__assign({}, prepareContractUserActivation), { data: data }),
            onFail: showGeneralError,
            onLoading: props.setLoading,
            onSuccess: function (response) {
                if (response.userCanSign) {
                    navigate("/app/contract/waitforsignatures");
                }
                else {
                    setIsContractSignatureNotAvailableDlgVisible(true);
                }
            },
        });
    }, [showGeneralError]);
    var onClientSelected = useCallback(function (clientNumber, clientListForSearch, username, notAvailableDlgClosed) {
        if (notAvailableDlgClosed === void 0) { notAvailableDlgClosed = false; }
        setPreviousClientNumber(function (previousClientNumber) {
            previousClientNumber[0] = previousClientNumber[1];
            previousClientNumber[1] = clientNumber;
            return previousClientNumber;
        });
        var number = notAvailableDlgClosed ? previousClientNumber[0] : clientNumber;
        var client = clientListForSearch.find(function (item) { return item.clientNumber === number; });
        if (client) {
            var data = { selectedClientData: client, username: username };
            props.setClientName(getByLocaleBG(tenant, client.clientName, client.clientNameLocal));
            user.clientNameLocal = client.clientNameLocal;
            user.clientName = client.clientName;
            user.clientNumber = client.clientNumber;
            !notAvailableDlgClosed && props.setLoading(true);
            executeAxios({
                params: __assign(__assign({}, selectClient), { data: data }),
                onFail: showGeneralError,
                onSuccess: function (response) {
                    setIsDropdownOpen(false);
                    user.personRole = response.personRole;
                    setAuthInLocalStorage(response);
                    setPermissions(response.permissions);
                    setRoles(response.roles);
                    localStorage.setItem("selectedCompany", client.clientNumber);
                    // CZ/SK contract signing
                    if ((tenant === "CZ" || tenant === "SK") &&
                        ((response.personRole === "LEGAL_REPRESENTATIVE" && response.noOfRequiredSignaturesLeft != 0 && response.contractSigned)
                            || (response.personRole != "LEGAL_REPRESENTATIVE" && response.noOfRequiredSignaturesLeft != 0))) {
                        setIsMoreSignatureRequired(true);
                    }
                    else if (!response.contractSigned && tenant !== "BG" && !notAvailableDlgClosed) {
                        callPrepareContractUserActivation(clientNumber, username);
                    }
                    else {
                        props.setLoading(false);
                        navigate("/app");
                    }
                },
            });
        }
    }, [showGeneralError]);
    var items = [];
    if (tenant !== "HU" && tenant !== "RO") {
        items = __spreadArray(__spreadArray([], items, true), [
            {
                key: "3",
                onClick: function () { return setIsDropdownOpen(false); },
                icon: _jsx(ProfileIcon, { height: 24, width: 24, fill: "black", className: "dde-icon" }),
                label: (_jsx("div", __assign({ className: "change-pass", onClick: function () { return props.setChangeUsernameModalVisible(true); } }, { children: _jsx("span", { children: formatMessage("dde.header.ChangeUsername") }) }))),
            },
        ], false);
    }
    items = __spreadArray(__spreadArray([], items, true), [
        {
            icon: _jsx(UniCreditPassIcon, { height: 24, width: 24, fill: "black", className: "dde-icon" }),
            onClick: function () { return setIsDropdownOpen(false); },
            key: "4",
            label: (_jsx("div", __assign({ className: "change-pass", onClick: function () { return props.setIsChangeVisible(true); } }, { children: _jsx("span", { children: formatMessage("dde.header.ChangePassword") }) }))),
        },
    ], false);
    if (clientList.length > 0) {
        items.push({
            type: "divider",
        });
        items.push({
            icon: _jsx(LargeEnterpriseIcon, { height: 24, width: 24, fill: "black", style: { "marginRight": "10px" } }),
            key: "3",
            label: _jsx("span", __assign({ className: "non-selec", style: { fontWeight: "bold", color: "black" } }, { children: formatMessage('dde.header.company.switch') })),
            disabled: true
        });
        clientList.forEach(function (client) {
            var name = getByLocaleBG(tenant, client.clientName, client.clientNameLocal);
            var isLong = name === undefined ? false : name.length > 28;
            var item = {
                key: client.clientNumber,
                label: isLong ? (_jsx(Tooltip, __assign({ overlayStyle: { maxWidth: "500px" }, overlayInnerStyle: { whiteSpace: "break-spaces" }, title: client.clientName }, { children: _jsxs("span", { children: [companyNameFormatter(name), " - ", formatMessage("dde.role.".concat(client.roleNames[0]))] }) }))) : (_jsxs("span", { children: [companyNameFormatter(name), " - ", formatMessage("dde.role.".concat(client.roleNames[0]))] })),
                itemIcon: props.clientName === getByLocaleBG(tenant, client.clientName, client.clientNameLocal) &&
                    _jsx(SingleTickIcon, { height: 24, width: 24, className: "icon" }),
            };
            items === null || items === void 0 ? void 0 : items.push(item);
        });
    }
    var menu = (_jsxs(Menu, { children: [items === null || items === void 0 ? void 0 : items.slice(0, getSliceNumber()).map(function (item) {
                return (item === null || item === void 0 ? void 0 : item.type) === 'divider' ? (_jsx(Menu.Divider, {}, item === null || item === void 0 ? void 0 : item.key)) : (_jsxs(Menu.Item, { children: [item === null || item === void 0 ? void 0 : item.icon, " ", item === null || item === void 0 ? void 0 : item.label] }, item === null || item === void 0 ? void 0 : item.key));
            }), _jsx(ScrollableMenu, { children: items === null || items === void 0 ? void 0 : items.slice(getSliceNumber()).map(function (item) { return (_jsxs(Menu.Item, __assign({ style: { "display": "flex", "justifyContent": "space-between" }, onClick: function () { var _a; return onClientSelected(((_a = item === null || item === void 0 ? void 0 : item.key) === null || _a === void 0 ? void 0 : _a.toString()) || "", clientList, user.username); } }, { children: [item === null || item === void 0 ? void 0 : item.label, " ", item === null || item === void 0 ? void 0 : item.itemIcon] }), item === null || item === void 0 ? void 0 : item.key)); }) })] }));
    return (_jsxs("div", __assign({ style: { marginLeft: 5 } }, { children: [_jsx(Dropdown, __assign({ menu: { selectable: true }, overlay: menu, placement: "bottomRight", onOpenChange: function (flag) { return setIsDropdownOpen(flag); }, dropdownRender: function (menu) { return (_jsx(HeaderDropdownContainer, { children: menu })); } }, { children: _jsx(IconWrapper, { children: isDropdownOpen ? (_jsx(DdeUpIcon, {})) : (_jsx(DdeDownIcon, {})) }) }), items === null || items === void 0 ? void 0 : items.toString()), isMoreSignatureRequired && (_jsx(Modal, { title: formatMessage("dde.login.error.contract.moreSignatures.required"), open: isMoreSignatureRequired, onOk: handleOnClose, onClose: handleOnClose, cancelButtonProps: { style: { visibility: "hidden" } }, okText: "OK" })), _jsx(ContractSignatureNotAvailableDialog, { isVisible: isContractSignatureNotAvailableDlgVisible, setIsVisible: setIsContractSignatureNotAvailableDlgVisible, onClose: handleContractSignatureNotAvailableDlgClosed })] })));
};
export default UserHeaderDropdown;
