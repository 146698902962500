var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Col, Form, Input, RangePicker, Row, Select, showSuccessMessage } from "@g4p/react-styleguide-library";
import { Collapse } from "antd";
import moment from "moment";
import 'moment/locale/en-ca';
import { getDateFormat, getLocaleFromLocalStorage } from "../../../../services/appStorage";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import { useTenantContext } from "../../../../services/tenantContext";
import { useCatalogues } from "../../../../services/appApi";
var ClientDokuBoxFilterPanel = function (props) {
    var form = Form.useForm()[0];
    var _a = useFormatMessage(), formatMessage = _a.formatMessage, formatLabelFromSelectOptions = _a.formatLabelFromSelectOptions;
    var _b = useCatalogues(), getDokuBoxSearchStatus = _b.getDokuBoxSearchStatus, getDokuSearchStatus = _b.getDokuSearchStatus;
    var maxInputSizeRule = { max: 30, message: formatMessage("dde.validation.common.maxLength30") };
    var Panel = Collapse.Panel;
    var tenant = useTenantContext().tenant;
    var resetUsedFilters = function () {
        Object.keys(form.getFieldsValue()).forEach(function (key) {
            var _a;
            var currentValue = form.getFieldsValue()[key];
            var initialValue = (_a = Object.entries(props.filter).find(function (_a) {
                var ikey = _a[0];
                return ikey === key;
            })) === null || _a === void 0 ? void 0 : _a[1];
            if (((!currentValue && !initialValue) || currentValue === initialValue || (currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) === (initialValue === null || initialValue === void 0 ? void 0 : initialValue.value)) && form.isFieldTouched(key)) {
                form.resetFields([key]);
            }
        });
    };
    var onPressEnter = function () {
        props.onSearchClick(form.getFieldsValue());
    };
    var returnContentFilters = function () {
        return (_jsxs(_Fragment, { children: [_jsxs(Row, __assign({ maxWidth: "100%" }, { children: [_jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "dokuBoxName" }, { children: formatMessage("dde.clientDokuBoxList.filter.dokuBoxName") })), _jsx(Form.Item, __assign({ rules: [maxInputSizeRule], name: "dokuBoxName" }, { children: _jsx(Input, { id: "dokuBoxName", onPressEnter: onPressEnter }) }))] })), _jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "status" }, { children: formatMessage("dde.clientDokuBoxList.filter.dokuBoxStatus") })), _jsx(Form.Item, __assign({ name: "statusSelect" }, { children: _jsx(Select, { onChange: function (target) {
                                            form.setFieldValue("status", target ? target.value : undefined);
                                        }, options: props.dokuboxStatusOptions, isClearable: true }) })), _jsx(Form.Item, { name: "status", style: { display: "none" } })] })), _jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "dokuBoxCustomName" }, { children: formatMessage("dde.clientDokuBoxList.filter.dokuBoxCustomName") })), _jsx(Form.Item, __assign({ rules: [maxInputSizeRule], name: "dokuBoxCustomName" }, { children: _jsx(Input, { id: "dokuBoxCustomName", onPressEnter: onPressEnter }) }))] })), _jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "dokuStatus" }, { children: formatMessage("dde.clientDokuBoxList.filter.dokuStatus") })), _jsx(Form.Item, __assign({ name: "dokuStatusSelect" }, { children: _jsx(Select, { onChange: function (target) {
                                            console.log("dokustatus: ", target);
                                            form.setFieldValue("dokuStatus", target ? target.value : undefined);
                                        }, options: formatLabelFromSelectOptions(getDokuSearchStatus(tenant), "translationKey"), isClearable: true }) })), _jsx(Form.Item, { name: "dokuStatus", style: { display: "none" } })] })), _jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "daterange" }, { children: formatMessage("dde.clientDokuBoxList.filter.lastModificationRange") })), _jsx(Form.Item, __assign({ name: "lastModificationRange" }, { children: _jsx(RangePicker, { locale: getLocaleFromLocalStorage(), onClick: function (value) {
                                            form.setFieldsValue({
                                                lastModificationFrom: value[0],
                                                lastModificationTo: value[1] ? moment(value[1]).add(1, "day").subtract(1, "second") : undefined,
                                            });
                                        }, forceAutoFormatting: true, format: getDateFormat() }) })), _jsxs("div", __assign({ style: { display: "none" } }, { children: [_jsx(Form.Item, { name: "lastModificationFrom" }), _jsx(Form.Item, { name: "lastModificationTo" })] }))] })), _jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "documentName" }, { children: formatMessage("dde.clientDokuBoxList.filter.documentName") })), _jsx(Form.Item, __assign({ rules: [maxInputSizeRule], name: "documentName" }, { children: _jsx(Input, { id: "documentName", onPressEnter: onPressEnter }) })), _jsx("div", { style: { display: "none" } })] })), _jsxs(Col, __assign({ ds: 6 }, { children: [_jsx("label", __assign({ className: "omnichannel-input-label", htmlFor: "dokuBoxId" }, { children: formatMessage("dde.clientDokuBoxList.filter.dokuBoxId") })), _jsx(Form.Item, __assign({ rules: [
                                        { max: 15 },
                                        {
                                            pattern: /(^-\d+$)|(^\d*$)/g,
                                            message: formatMessage("dde.validation.common.OnlyNumbersAllowed"),
                                        },
                                    ], name: "dokuBoxId" }, { children: _jsx(Input, { id: "dokuBoxId", onPressEnter: onPressEnter }) }))] }))] })), _jsxs(Col, __assign({ ds: 7, justifyContent: "flex-end" }, { children: [_jsx(Button, __assign({ style: { marginRight: "10px" }, type: "default", onClick: function () {
                                form.resetFields();
                                props.onSearchClick(form.getFieldsValue());
                            } }, { children: formatMessage("dde.clientDokuBoxList.filter.resetBtn") })), _jsx(Button, __assign({ htmlType: "submit", type: "primary" }, { children: formatMessage("dde.clientDokuBoxList.filter.searchBtn") }))] }))] }));
    };
    return (_jsx(_Fragment, { children: _jsxs(Form, __assign({ style: { width: "100%" }, form: form, initialValues: props.filter, onFinish: function (values) {
                if (form.isFieldsTouched()) {
                    props.onSearchClick(values);
                }
                else {
                    showSuccessMessage({
                        title: formatMessage("dde.warning.title"),
                        message: formatMessage("dde.dokuBoxList.filter.unchanged"),
                    });
                }
            } }, { children: [(tenant === "CZ" || tenant === "SK" || tenant === "RS" || tenant === "BG" || tenant === "HR" || tenant === "RO") && (_jsx(Collapse, { children: _jsx(Panel, __assign({ header: formatMessage("dde.dokuBoxList.filter.showFilters") }, { children: returnContentFilters() }), "1") })), tenant === "HU" && returnContentFilters()] })) }));
};
export default ClientDokuBoxFilterPanel;
