export var CLIENT_SIGNATURE_SYSTEM_NOT_RELEVANT_VALUE = "NA";
export var BANK_SIGNATURE_SYSTEM_NOT_RELEVANT_VALUE = "NA";
export var getBankerDokuBoxCategories = {
    url: "/dde/dokubox/dokuboxdefinition/getBankerDokuBoxCategories",
    method: "get",
};
export var findBankerDokuBoxDefinitionsByCategory = {
    url: "/dde/dokubox/dokuboxdefinition/findBankerDokuBoxDefinitionsByCategory",
    method: "post",
};
export var getClientDokuBoxCategories = {
    url: "/dde/dokubox/dokuboxdefinition/getClientDokuBoxCategories",
    method: "get",
};
export var getClientDokuParticipantsRoleUrl = {
    url: "/dde/dokubox/client/participant/doku/available-roles",
    method: "post",
};
export var getClientDokuBoxParticipantsRoleUrl = {
    url: "/dde/dokubox/client/participant/dokubox/available-roles",
    method: "post",
};
export var findClientDokuBoxDefinitionsByCategory = {
    url: "/dde/dokubox/dokuboxdefinition/findClientDokuBoxDefinitionsByCategory",
    method: "post",
};
export var getDokuBoxParticipantsUrl = {
    url: "/dde/dokubox/banker/participant/dokubox/available-roles",
    method: "post",
};
export var getDokuParticipantsUrl = {
    url: "/dde/dokubox/banker/participant/doku/available-roles",
    method: "post",
};
export var getAvailableDokuBoxParticipantsUrl = {
    url: "/dde/dokubox/banker/participant/dokubox/available-participants",
    method: "post",
};
export var getAvailableDokuParticipantsUrl = {
    url: "/dde/dokubox/banker/participant/doku/available-participants",
    method: "post",
};
export var saveDokuBoxParticipantsUrl = {
    url: "/dde/dokubox/banker/participant/dokubox/save-participants",
    method: "post",
};
export var saveDokuParticipantsUrl = {
    url: "/dde/dokubox/banker/participant/doku/save-participants",
    method: "post",
};
export var ParticipantRole;
(function (ParticipantRole) {
    ParticipantRole["BANK_RM"] = "BANK_RM";
    ParticipantRole["BANK_REVIEWER"] = "BANK_REVIEWER";
    ParticipantRole["BANK_VIEWER"] = "BANK_VIEWER";
    ParticipantRole["BANK_1STLEVELSIGNATORY"] = "BANK_1STLEVELSIGNATORY";
    ParticipantRole["BANK_2NDLEVELSIGNATORY"] = "BANK_2NDLEVELSIGNATORY";
    ParticipantRole["BANK_NONLEGALREPSIGNATORY"] = "BANK_NONLEGALREPSIGNATORY";
    ParticipantRole["BANK_VALIDATOR"] = "BANK_VALIDATOR";
    ParticipantRole["CLIENT_EMPLOYEE"] = "CLIENT_EMPLOYEE";
    ParticipantRole["CLIENT_REVIEWER"] = "CLIENT_REVIEWER";
    ParticipantRole["CLIENT_VIEWER"] = "CLIENT_VIEWER";
    ParticipantRole["CLIENT_LEGALREPRESENTATIVE"] = "CLIENT_LEGALREPRESENTATIVE";
    ParticipantRole["CLIENT_EMPLOYEESIGNATORY"] = "CLIENT_EMPLOYEESIGNATORY";
})(ParticipantRole || (ParticipantRole = {}));
export var ParticipantType;
(function (ParticipantType) {
    ParticipantType["BANK"] = "BANK";
    ParticipantType["CLIENT"] = "CLIENT";
})(ParticipantType || (ParticipantType = {}));
export var ParticipantState;
(function (ParticipantState) {
    ParticipantState["TODO"] = "TODO";
    ParticipantState["DONE"] = "DONE";
})(ParticipantState || (ParticipantState = {}));
export var ClientUserSignatureType;
(function (ClientUserSignatureType) {
    ClientUserSignatureType["SINGLE"] = "SINGLE";
    ClientUserSignatureType["JOINT"] = "JOINT";
})(ClientUserSignatureType || (ClientUserSignatureType = {}));
export var bankerRolesOrder = [
    ParticipantRole.BANK_RM,
    ParticipantRole.BANK_1STLEVELSIGNATORY,
    ParticipantRole.BANK_2NDLEVELSIGNATORY,
    ParticipantRole.BANK_REVIEWER,
    ParticipantRole.BANK_NONLEGALREPSIGNATORY,
    ParticipantRole.BANK_VIEWER,
    ParticipantRole.BANK_VALIDATOR,
];
export var bankerRolesCanEdit = [
    ParticipantRole.BANK_RM,
    ParticipantRole.BANK_1STLEVELSIGNATORY,
    ParticipantRole.BANK_2NDLEVELSIGNATORY,
    ParticipantRole.BANK_REVIEWER,
    ParticipantRole.BANK_NONLEGALREPSIGNATORY,
];
export function orderBankerRoles(roleList) {
    var orderedRoleList = [];
    bankerRolesOrder.forEach(function (r) {
        var index = roleList.findIndex(function (item) { return item === r; });
        if (index > -1) {
            orderedRoleList.push(roleList[index]);
            roleList.splice(index, 1);
        }
    });
    if (roleList.length > 0) {
        orderedRoleList.concat(roleList);
    }
    return orderedRoleList;
}
export var selectDokuBoxUrl = {
    url: "/dde/dokubox/banker/selectDokubox",
    method: "post",
};
export var getAvailableDokuClientParticipantsUrl = {
    url: "/dde/dokubox/client/participant/doku/available-participants",
    method: "post",
};
export var validateParticipantsBankSignatoryLevel = function (participants) {
    var valid = true;
    var participant1Level = participants.find(function (participant) { return participant.role === ParticipantRole.BANK_1STLEVELSIGNATORY; });
    var participant2Level = participants.find(function (participant) { return participant.role === ParticipantRole.BANK_2NDLEVELSIGNATORY; });
    if (participant1Level &&
        participant2Level &&
        participant1Level.userIds &&
        participant2Level.userIds &&
        (participant1Level.userIds.length !== 1 ||
            participant2Level.userIds.length !== 1 ||
            participant1Level.userIds.some(function (userId) { return participant2Level.userIds.includes(userId); }) ||
            participant2Level.userIds.some(function (userId) { return participant1Level.userIds.includes(userId); }))) {
        valid = false;
    }
    return valid;
};
export var BankSignatureSystem;
(function (BankSignatureSystem) {
    BankSignatureSystem["NA"] = "NA";
    BankSignatureSystem["CUSTOM_APP"] = "CUSTOM_APP";
    BankSignatureSystem["ESAW_LOCAL"] = "ESAW_LOCAL";
    BankSignatureSystem["ESAW_REMOTE"] = "ESAW_REMOTE";
    BankSignatureSystem["ESAW_LONGTERM"] = "ESAW_LONGTERM";
    BankSignatureSystem["AUTOMATIC_SIGNING"] = "AUTOMATIC_SIGNING";
})(BankSignatureSystem || (BankSignatureSystem = {}));
export var ClientSignatureSystem;
(function (ClientSignatureSystem) {
    ClientSignatureSystem["NA"] = "NA";
    ClientSignatureSystem["CUSTOM_APP"] = "CUSTOM_APP";
    ClientSignatureSystem["ESAW_DISPOSABLE"] = "ESAW_DISPOSABLE";
    ClientSignatureSystem["ALL"] = "ALL";
})(ClientSignatureSystem || (ClientSignatureSystem = {}));
export var signingPoints = [
    { value: "0", label: "0/5" },
    { value: "1", label: "1/5" },
    { value: "2", label: "2/5" },
    { value: "3", label: "3/5" },
    { value: "4", label: "4/5" },
    { value: "5", label: "5/5" },
];
export var listAvailableTeamHeadsApiConfig = {
    url: "/dde/dokubox/banker/listAvailableTeamHeads",
    method: "POST",
};
export var synchronizeClientParticipantsApiConfig = {
    url: "/dde/usermanagement/client/synchronizeLoginClientUsersData",
    method: "post",
};
export var ClientSignatoryLegalType = [
    { value: "CLIENT_LEGAL_REPRESENTATIVE", label: "dde.catalogue.clientsignatorylegaltype.client_legal_representative" },
    { value: "CLIENT_EMPLOYEESIGNATORY", label: "dde.catalogue.clientsignatorylegaltype.client_employeesignatory" },
    { value: "RELATED_LEGAL_ENTITY_LEGAL_REPRESENTATIVE", label: "dde.catalogue.clientsignatorylegaltype.related_legal_entity_legal_representative" },
];
export var getProductTranslationKeyByValueApiConfig = {
    url: "/dde/utilities/noauth/getProductTranslationKey/",
    method: "POST",
};
