var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Spinner } from "@g4p/react-styleguide-library";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeAuthCookiesByServerCall } from "../../../services/appApi";
import { removeAuthFromLocalStorage } from "../../../services/appStorage";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import LoginWrapper from "../../common/Login/components/LoginWrapper";
import CookiesDetails from "../Cookies/CookiesDetails";
var Cookies = function () {
    var loading = useState(false)[0];
    var _a = useState(false), loadingForceLogout = _a[0], setLoadingForceLogout = _a[1];
    var navigate = useNavigate();
    var formatMessage = useFormatMessage().formatMessage;
    var forceLogout = useCallback(function (onSuccess, onFail) {
        removeAuthFromLocalStorage();
        executeAxios({
            params: __assign({}, removeAuthCookiesByServerCall),
            onLoading: setLoadingForceLogout,
            onFail: function () { return onFail && onFail(); },
            onSuccess: function () { return onSuccess && onSuccess(); },
        });
    }, []);
    var handleBackAction = function () {
        navigate("/");
    };
    return (_jsx(LoginWrapper, __assign({ description: undefined, title: formatMessage("dde.cookiesConsent.aboutPage.title"), onBackActionClick: handleBackAction, fullWidth: true, showBackButton: true }, { children: _jsxs(_Fragment, { children: [loading && _jsx(Spinner, {}), _jsx(CookiesDetails, {}), _jsx(Divider, { style: { visibility: "hidden", padding: "4rem 0" } })] }) })));
};
export default Cookies;
