var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IdleGuard } from "../../../../components/IdleGuard/IdleGuard";
import { IdleWarningDialog } from "./IdleWarningDialog";
import { useTenantContext } from "../../../../services/tenantContext";
import { useAppContext } from "../../../../services/appContext";
export function IdleLogout(props) {
    var DEFAULT_MIN_BEFORE_IDLE = 30;
    var DEFAULT_MIN_WARN_DURATION = 1;
    var tenant = useTenantContext().tenant;
    var clientApp = useAppContext().clientApp;
    function getMinutesBeforeIdle() {
        var minutes = DEFAULT_MIN_BEFORE_IDLE;
        if (tenant === "RS") {
            minutes = 10;
        }
        if (tenant === "BG" || tenant === "RO") {
            if (clientApp) {
                minutes = 15;
            }
            else {
                minutes = 30;
            }
        }
        return minutes;
    }
    return (_jsx(IdleGuard, __assign({ minutesBeforeIdle: getMinutesBeforeIdle(), minutesWarningDuration: DEFAULT_MIN_WARN_DURATION, onIdle: props.logoutHandler, renderWarning: function (dismiss, remainingTime) { return (_jsx(IdleWarningDialog, { remainingTime: remainingTime, logout: props.logoutHandler, dismiss: dismiss })); } }, { children: props.children })));
}
