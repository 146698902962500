import _ from "lodash";
import moment from "moment";
export var dateFormat24 = "DD.MM.YYYY, HH:mm";
export var dateFormat = "DD.MM.YYYY";
export function formatDateWithMinutes(value) {
    return value ? moment(value).format(dateFormat24) : "";
}
export function formatDate(value) {
    return value ? moment(value).format("DD.MM.YYYY") : "";
}
export function formatPhoneNumber(rowPhoneNumber) {
    if (_.isEmpty(rowPhoneNumber) || rowPhoneNumber.length !== 12) {
        return rowPhoneNumber;
    }
    var formattedPhoneNumber = [];
    var indexSpacing = [2, 5, 8];
    for (var index = 0; index < rowPhoneNumber.length; index++) {
        formattedPhoneNumber.push(rowPhoneNumber[index]);
        if (indexSpacing.includes(index)) {
            formattedPhoneNumber.push(" ");
        }
    }
    return formattedPhoneNumber.join("");
}
export function formatDuration(timeSeconds) {
    var minutes = Math.floor(timeSeconds / 60);
    var seconds = timeSeconds % 60;
    var formattedTime = [minutes, seconds].map(function (component) { return component.toString().padStart(2, "0"); }).join(":");
    return unsafeLocalizedString(formattedTime);
}
export function unsafeLocalizedString(n) {
    return String(n);
}
