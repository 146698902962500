var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { afterLoggedInUserDetailsLoaded, externalLinkPresent, removeAuthCookiesByServerCall, useTranslationsApi } from "../../../services/appApi";
import { useAppContext, UserType } from "../../../services/appContext";
import { getLocaleFromLocalStorage, removeAuthFromLocalStorage, setAuthInLocalStorage, setExternalParamInLocalStorage, setLocaleInLocalStorage, } from "../../../services/appStorage";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { is4xxStatus } from "../../../utils/responseApi";
import LoginForm from "./components/LoginForm";
import LoginWrapper from "./components/LoginWrapper";
var Login = function () {
    var _a = useState([]), actions = _a[0], setActions = _a[1];
    var fetchAndUpdateTranslations = useTranslationsApi().fetchAndUpdateTranslations;
    var _b = useFormatMessage(), formatMessage = _b.formatMessage, formatRichTextMessage = _b.formatRichTextMessage;
    var _c = useAppContext(), setPermissions = _c.setPermissions, setRoles = _c.setRoles, setIsLoggedIn = _c.setIsLoggedIn, setUser = _c.setUser, setLanguage = _c.setLanguage;
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), loadingUser = _e[0], setLoadingUser = _e[1];
    var _f = useState(false), loadingForceLogout = _f[0], setLoadingForceLogout = _f[1];
    var searchParams = useSearchParams()[0];
    var setCurrentLockedTaskId = useAppContext().setCurrentLockedTaskId;
    var navigate = useNavigate();
    var bankerAuthorizationConfig = {
        url: "/authorization/banker/token",
        method: "post",
    };
    var showGeneralError = useCallback(function () {
        showErrorMessage({
            title: formatMessage("dde.error.errorTitle"),
            message: formatMessage("dde.error.generalError"),
        });
    }, [formatMessage]);
    var doBankerAuthentication = function (username, password) {
        var apiConfig = {
            data: {
                username: username,
                password: password,
            },
        };
        function successConfig(response) {
            setAuthInLocalStorage({
                username: response.username,
                flowId: response.flowId,
            });
            if (searchParams.get("ddeParam") != null) {
                setExternalParamInLocalStorage(searchParams.get("ddeParam"));
            }
            setPermissions(response.permissions);
            setRoles(response.roles);
            console.log("Login - afterLoggedInUserDetailsLoaded");
            afterLoggedInUserDetailsLoaded(setUser, setLoadingUser, showGeneralError, function () {
                setIsLoggedIn(true);
                externalLinkPresent(navigate, response.username, setCurrentLockedTaskId);
            }, true, true);
        }
        removeAuthFromLocalStorage();
        executeAxios({
            params: __assign(__assign({}, bankerAuthorizationConfig), apiConfig),
            onSuccess: successConfig,
            onLoading: setLoading,
            onFail: function (error) {
                if (is4xxStatus(error === null || error === void 0 ? void 0 : error.status)) {
                    if ("INVALID_CREDENTIALS" === (error === null || error === void 0 ? void 0 : error.data.code)) {
                        showErrorMessage({
                            title: formatMessage("dde.error.errorTitle"),
                            message: formatMessage("dde.login.error.invalidCredentials"),
                        });
                    }
                    else {
                        showErrorMessage({
                            title: formatMessage("dde.error.errorTitle"),
                            message: formatMessage("dde.login.error.noPermission"),
                        });
                    }
                }
                else {
                    showErrorMessage({
                        title: formatMessage(formatMessage("dde.login.banker.error.title.LoginFailed")),
                        message: formatMessage(formatMessage("dde.login.banker.error.details.LoginFailed")),
                    });
                }
                console.error("Login failed", error);
            },
        });
    };
    var ddeSettingsPageURL = useAppContext().configurations.ddeSettingsPageURL;
    var description = (_jsx(_Fragment, { children: _jsx(Typography.Text, { children: formatRichTextMessage("dde.login.banker.welcometext") }) }));
    var getActions = useCallback(function () {
        var actions = [];
        if (window.location.href.includes("/intranet")) {
            actions.push({
                onActionClick: function () { return (window.location.href = ddeSettingsPageURL); },
                actionLabel: formatMessage("dde.forgottenPassword.title"),
            });
        }
        return actions;
    }, [ddeSettingsPageURL, formatMessage]);
    var handleTranslationUpdate = function (value) {
        setLanguage(value);
        setLocaleInLocalStorage(value);
        fetchAndUpdateTranslations(value);
    };
    var forceLogout = useCallback(function (onSuccess, onFail) {
        removeAuthFromLocalStorage();
        executeAxios({
            params: __assign({}, removeAuthCookiesByServerCall),
            onLoading: setLoadingForceLogout,
            onFail: function () { return onFail && onFail(); },
            onSuccess: function () { return onSuccess && onSuccess(); },
        });
    }, []);
    var handleLoginSubmit = useCallback(function (formValues) {
        forceLogout(function () {
            doBankerAuthentication(formValues.username, formValues.password);
        }, showGeneralError);
    }, [forceLogout, showGeneralError]);
    useEffect(function () {
        setActions(getActions());
    }, [getActions]);
    return (_jsx(LoginWrapper, __assign({ actions: actions, description: description, title: formatMessage("dde.login.mainTitle"), helpTooltipMessage: "".concat(formatMessage("dde.login.help.doyouneedsupport.banker.usernamepassword"), " ").concat(formatMessage("dde.login.help.doyouneedsupport.banker.support.phonenumber")) }, { children: _jsxs(_Fragment, { children: [_jsx(LoginForm, { userType: UserType.BANKER, onSubmit: handleLoginSubmit, initialValues: {
                        language: getLocaleFromLocalStorage(),
                        username: "",
                        password: "",
                    }, onLanguageChange: handleTranslationUpdate }), (loading || loadingUser || loadingForceLogout) && _jsx(Spinner, {})] }) })));
};
export default Login;
