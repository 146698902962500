var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { showErrorMessage, showSuccessMessage } from "@g4p/react-styleguide-library";
import { getResetPasswordData, resetPasswordApi } from "../../settings/services/resetPasswordApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../../../services/appContext";
import { executeAxios } from "../../../../services/axiosHook";
import { useFormatMessage } from "../../../../services/formatMessageHook";
import LoginWrapper from "../../../common/Login/components/LoginWrapper";
import { ForgottenPasswordForm } from "./ForgottenPasswordForm";
var ForgottenPasswordPage = function (props) {
    var _a = useState(true), isFormVisible = _a[0], setIsFormVisible = _a[1];
    var formatMessage = useFormatMessage().formatMessage;
    var navigate = useNavigate();
    function executeResetPasswordCall(formValues) {
        setIsFormVisible(false);
        executeAxios({
            params: __assign(__assign({}, resetPasswordApi), getResetPasswordData(formValues)),
            onSuccess: function () {
                showSuccessMessage({ title: formatMessage("dde.forgottenPassword.onSuccess") });
                navigate("/");
            },
            onLoading: function () { },
            onFail: function (resp) {
                var _a;
                showErrorMessage({
                    title: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
        });
    }
    var handleSubmit = function (formValues) {
        if (props.isClient) {
            executeResetPasswordCall(formValues);
        }
    };
    var handleBackAction = function () {
        navigate("/");
    };
    return (_jsx(LoginWrapper, __assign({ title: formatMessage("dde.forgottenPassword.title"), showBackButton: true, onBackActionClick: handleBackAction, helpTooltipMessage: "".concat(formatMessage("dde.login.help.doyouneedsupport.client.forgottenpassword"), " ").concat(formatMessage("dde.login.help.doyouneedsupport.client.support.phonenumber")) }, { children: _jsx(_Fragment, { children: isFormVisible && (_jsx(ForgottenPasswordForm, { userType: props.isClient ? UserType.CLIENT : UserType.BANKER, onSubmit: function (formValues) {
                    handleSubmit(formValues);
                }, initialValues: { email: "", userName: "", phoneNumber: "" } })) }) })));
};
export default ForgottenPasswordPage;
