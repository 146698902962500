import moment from "moment";
var defaultSort = function (elem1, elem2) {
    if (elem1 && elem2)
        return elem1.localeCompare(elem2);
    else if (elem1)
        return 1;
    else if (elem2)
        return -1;
    else
        return 0;
};
var numberSort = function (numberA, numberB) { return numberA - numberB; };
var dateSort = function (dateA, dateB) { return moment(dateA).diff(moment(dateB)); };
var booleanSort = function (booleanA, booleanB) {
    if (booleanA)
        return 1;
    else if (booleanB)
        return -1;
    else
        return 0;
};
export var Sorter = {
    DEFAULT: defaultSort,
    NUMBER: numberSort,
    DATE: dateSort,
    BOOLEAN: booleanSort,
};
