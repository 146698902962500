var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTenantContext } from "../../../services/tenantContext";
import DokuActionAdditionalData from "./DokuActionAdditionalData";
var DokuActionAdditionalDataTenant = function (props) {
    var getTenantComponent = useTenantContext().getTenantComponent;
    var DokuActionAdditionalDataComponent = getTenantComponent("dokuActionAdditionalData", DokuActionAdditionalData);
    return _jsx(DokuActionAdditionalDataComponent, __assign({}, __assign(__assign({}, props), { itemKey: props.doku.id })));
};
export default DokuActionAdditionalDataTenant;
