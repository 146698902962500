var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var ExpandableListStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ExpandableListItemStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tfont-size: 14px;\n\tbackground-color: #f3f3f3;\n\tpadding: 5px 10px;\n\tborder-radius: 5px;\n\tdisplay: flex;\n\talign-items: center;\n\n\tbutton.ant-btn.row-action-icon {\n\t\tmin-width: 0;\n\t\tmin-height: 0;\n\t\tpadding: 0;\n\t  \tmargin-right: 10px;\n\n\t\t& > svg {\n\t\t\twidth: 10px;\n\t\t}\n\t}\n\n\t.content {\n\t\tflex: 1;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: space-between;\n\n\t\tspan[role=\"img\"] {\n\t\t\tpadding-right: 5px;\n\t\t}\n\n\t\t.label {\n\t\t\tmin-width: 30%;\n\t\t}\n\n\t\t.value {\n\t\t\tword-break: break-all;\n\t\t\ttext-align: right;\n\t\t\tpadding-left: 20px;\n\t\t  \tfont-weight: 700;\n\t\t}\n\t}\n"], ["\n\tfont-size: 14px;\n\tbackground-color: #f3f3f3;\n\tpadding: 5px 10px;\n\tborder-radius: 5px;\n\tdisplay: flex;\n\talign-items: center;\n\n\tbutton.ant-btn.row-action-icon {\n\t\tmin-width: 0;\n\t\tmin-height: 0;\n\t\tpadding: 0;\n\t  \tmargin-right: 10px;\n\n\t\t& > svg {\n\t\t\twidth: 10px;\n\t\t}\n\t}\n\n\t.content {\n\t\tflex: 1;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: space-between;\n\n\t\tspan[role=\"img\"] {\n\t\t\tpadding-right: 5px;\n\t\t}\n\n\t\t.label {\n\t\t\tmin-width: 30%;\n\t\t}\n\n\t\t.value {\n\t\t\tword-break: break-all;\n\t\t\ttext-align: right;\n\t\t\tpadding-left: 20px;\n\t\t  \tfont-weight: 700;\n\t\t}\n\t}\n"])));
export var ExpandableListItemWrapperStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  \tmargin-bottom: 4px;\n  \n  \t.list-item {\n\t  margin-top: 4px; \n\t}\n\t", "\n"], ["\n  \tmargin-bottom: 4px;\n  \n  \t.list-item {\n\t  margin-top: 4px; \n\t}\n\t", "\n"])), function (props) {
    return props.$hoverable
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\t\t.list-item { cursor: pointer; }\n\t\t\t  "], ["\n\t\t\t\t.list-item { cursor: pointer; }\n\t\t\t  "]))) : css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n                  .list-item { cursor: initial; }\n\t\t\t  "], ["\n                  .list-item { cursor: initial; }\n\t\t\t  "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
