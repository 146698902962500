export var verifyClientPassword = {
    url: "/authorization/client/token",
    method: "POST",
};
export var verifyAuthenticationRequestId = {
    url: "/authorization/ddeDigitalExternalChannel/token",
    method: "POST",
};
export var clientChangePassword = {
    url: "/dde/usermanagement/noauth/password/change",
    method: "POST",
};
export var sendSmsOtp = {
    url: "/authorization/client/dde/sendOtp",
    method: "POST",
};
export var clientVerifyOtpConfig = {
    url: "/authorization/client/dde/verifyOtp",
    method: "POST",
};
export var getClientDataForLogin = {
    url: "/dde/usermanagement/client/getClientDataForLogin",
    method: "post",
};
export var checkLoggedInClientToken = {
    url: "/dde/usermanagement/client/checkLoggedInClientToken",
    method: "post",
};
export var selectClient = {
    url: "/dde/usermanagement/client/selectClient",
    method: "post",
};
export var prepareContractUserActivation = {
    url: "/dde/usermanagement/contract/prepareContractUserActivation",
    method: "post",
};
export var waitForContractSignatures = {
    url: "/dde/usermanagement/contract/waitForSignatures",
    method: "post",
};
export var waitForContractProcessFinish = {
    url: "/dde/usermanagement/contract/waitForProcessFinish",
    method: "post",
};
export var getClientUserRoles = {
    url: "/dde/usermanagement/getClientUserRoles",
    method: "post",
};
export var authenticationFromJwtReference = {
    url: "/authorization/client/dde/token",
    method: "POST",
};
