var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useState } from "react";
export function useLoadingPipeline() {
    var _a = useState({}), loading = _a[0], setLoading = _a[1];
    var handleLoading = useCallback(function (partLoading) { return function (isLoading) {
        setLoading(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[partLoading] = isLoading, _a)));
        });
    }; }, []);
    return {
        loading: Object.keys(loading).length ? Object.values(loading).some(function (item) { return item; }) : true,
        handleLoading: handleLoading,
        loadingPipe: loading,
    };
}
