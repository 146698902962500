var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Form, ForthIcon, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import Typography from "antd/lib/typography";
import { cloneDeep, uniqueId } from "lodash";
import { FooterButtonsStyled, PageTitleWrapperStyled } from "../../banker/BankerManageDokuBox/styles";
import { clientLockDokuBox, clientUnlockDokuBox, finishClientSigningConfig, getResolvedUrlConfig, signCustomAppConfig, signEsawConfig, } from "./services/clientManageDokuBoxApi";
import { DokuBoxStaticAction, DokuboxSubmitReloadActionTypes, clientEsaw, dokuStates } from "../../common/DokuCard/domain";
import { UserType } from "../../common/DokuMessages/services/domain";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import { useTenantContext } from "../../../services/tenantContext";
import { MainContainerStyled } from "../../../styles/styles";
import { usePermission } from "../../../utils/permissions";
import { getByLocale } from "../../../services/appApi";
import { useAppContext } from "../../../services/appContext";
import { DokuBoxStatus, DokuStatus } from "../../admin/DokuBoxList/services/domain";
import { bankerSubmitDokuBox, checkDokuBoxAccessApi, checkDokuBoxEsawAccessApi, containsDokuBoxStaticAction, formatServerError, } from "../../banker/BankerManageDokuBox/services/bankerManageDokuBoxApi";
import DokuCard from "../../common/DokuCard/DokuCard";
import ClientAddingOtherDokuTenant from "./components/ClientAddingOtherDokuTenant";
import { UserCannotSignDialog } from "./components/UserCannotSignDialog";
import { clientAddOtherDokuConfig, getDokuBoxByIdApiConfig } from "./services/clientManageDokuBoxApi";
import FileMissingDokuBoxDialog from "../../common/DokuCard/FileMissingDokuBoxDialog";
import { useUnlockDokuBox } from "../../../helper/unlockDokuBox";
import { Banner } from "@cbp/fe-commons";
var ClientManageDokuBox = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var navigate = useNavigate();
    var UNLOCK_ON_EXIT_FIELD_NAME = "clientManageDokuBoxUnlockOnExit";
    var dokuBoxId = useParams().dokuBoxId;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), loadingLock = _b[0], setLoadingLock = _b[1];
    var _c = useState(false), loadingSubmit = _c[0], setLoadingSubmit = _c[1];
    var _d = useState(false), loadingAddOtherDoku = _d[0], setLoadingAddOtherDoku = _d[1];
    var _e = useState({}), selectedDokuBox = _e[0], setSelectedDokuBox = _e[1];
    var _f = useState(false), isUserCannotSignDlgVisible = _f[0], setIsUserCannotSignDlgVisible = _f[1];
    var _g = useState(false), isAddOtherDokuDlgVisible = _g[0], setIsAddOtherDokuDlgVisible = _g[1];
    var _h = useState(false), isFileMissingDokuBoxDlgVisible = _h[0], setIsFileMissingDokuBoxDlgVisible = _h[1];
    var _j = useState(false), isEsawSigningEnabled = _j[0], setIsEsawSigningEnabled = _j[1];
    var _k = useState([]), otherDokuList = _k[0], setOtherDokuList = _k[1];
    var _l = useState(new Map()), uploadFailed = _l[0], setUploadFailed = _l[1];
    var hasAnyOfTheRoles = usePermission().hasAnyOfTheRoles;
    var _m = useAppContext(), currentLockedDokuBoxId = _m.currentLockedDokuBoxId, setCurrentLockedDokuBoxId = _m.setCurrentLockedDokuBoxId;
    var form = Form.useForm()[0];
    var tenant = useTenantContext().tenant;
    var _o = useUnlockDokuBox(), unlockDokuBox = _o.unlockDokuBox, sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler = _o.sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler, unlockLoading = _o.unlockLoading;
    var _p = useState(true), isBannerVisibile = _p[0], setIsBannerVisible = _p[1];
    var showAddOtherDoku = useAppContext().configurations.showAddOtherDoku;
    var lockDokuBox = useCallback(function (dokuBoxId, onSuccess) {
        window[UNLOCK_ON_EXIT_FIELD_NAME] = true;
        var apiConfig = {
            data: {
                dokuBoxId: dokuBoxId,
            },
        };
        executeAxios({
            params: __assign(__assign({}, clientLockDokuBox), apiConfig),
            onSuccess: function () {
                dokuBoxId && setCurrentLockedDokuBoxId(dokuBoxId);
                onSuccess();
            },
            onLoading: setLoadingLock,
            onFail: function (resp) {
                var _a;
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
                if (((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.params) && resp.data.params[0]) {
                    navigate("/app/viewdokubox/" + dokuBoxId);
                }
                else {
                    navigate("/app/dokuboxlist");
                }
            },
        });
    }, [formatMessage, navigate, setCurrentLockedDokuBoxId]);
    var loadDokuBox = useCallback(function (dokuBoxMapper) {
        if (dokuBoxMapper === void 0) { dokuBoxMapper = function (dokuBox) { return dokuBox; }; }
        executeAxios({
            params: {
                url: getDokuBoxByIdApiConfig.url + "/" + dokuBoxId,
                method: getDokuBoxByIdApiConfig.method,
            },
            onLoading: setLoading,
            onSuccess: function (response) {
                var dokuBoxDto = dokuBoxMapper(response.dokuBox);
                setSelectedDokuBox(dokuBoxDto);
                if (dokuBoxDto.status === DokuBoxStatus.CANCELLED) {
                    navigate("/app/viewdokubox/" + dokuBoxId);
                }
                checkDokuBoxEsawAccess(dokuBoxId);
            },
            onFail: function (resp) {
                var _a;
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
        });
    }, [dokuBoxId, formatMessage]);
    var checkDokuBoxAccess = useCallback(function (dokuBoxId, onSuccess) {
        executeAxios({
            params: {
                url: checkDokuBoxAccessApi.url + "/" + dokuBoxId,
                method: checkDokuBoxAccessApi.method,
                data: { clientEdit: !props.readOnly },
            },
            onSuccess: function (resp) {
                if (resp) {
                    onSuccess();
                }
                else {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.error.dokuBoxAccessDenied"),
                    });
                    navigate("/app/dokuboxlist");
                }
            },
            onLoading: setLoadingLock,
            onFail: function () {
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage("dde.error.dokuBoxAccessDenied"),
                });
                navigate("/app/dokuboxlist");
            },
        });
    }, [formatMessage, navigate]);
    var checkDokuBoxEsawAccess = useCallback(function (dokuBoxId) {
        executeAxios({
            params: {
                url: checkDokuBoxEsawAccessApi.url + "/" + dokuBoxId,
                method: checkDokuBoxEsawAccessApi.method,
            },
            onSuccess: function (resp) {
                if (resp.esawSigningEnabled) {
                    setIsEsawSigningEnabled(true);
                }
                if (resp.legalRepresentativeInvalid) {
                    setIsUserCannotSignDlgVisible(true);
                }
            },
            onLoading: setLoadingLock,
            onFail: function (resp) {
                var _a;
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
        });
    }, [formatMessage, navigate]);
    useEffect(function () {
        checkDokuBoxAccess(dokuBoxId, function () {
            if (!props.readOnly) {
                lockDokuBox(dokuBoxId, function () {
                    loadDokuBox();
                });
            }
            else {
                loadDokuBox();
            }
        });
    }, [loadDokuBox, lockDokuBox, dokuBoxId, props.readOnly, checkDokuBoxAccess]);
    useEffect(function () {
        return function () {
            if (currentLockedDokuBoxId && window[UNLOCK_ON_EXIT_FIELD_NAME] === true) {
                unlockDokuBox(function () { }, clientUnlockDokuBox, currentLockedDokuBoxId);
            }
        };
    }, [unlockDokuBox, currentLockedDokuBoxId]);
    useEffect(function () {
        var unlockOnBrowserWindowClose = function () {
            if (currentLockedDokuBoxId && window[UNLOCK_ON_EXIT_FIELD_NAME] === true) {
                sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler(currentLockedDokuBoxId, selectedDokuBox);
                setCurrentLockedDokuBoxId("");
            }
        };
        var unlockOnBrowserNavigation = function () {
            window.removeEventListener("popstate", unlockOnBrowserNavigation);
            unlockOnBrowserWindowClose();
        };
        if (dokuBoxId && !props.readOnly && currentLockedDokuBoxId) {
            window.addEventListener("popstate", unlockOnBrowserNavigation);
            window.addEventListener("beforeunload", unlockOnBrowserWindowClose);
            return function () {
                window.removeEventListener("popstate", unlockOnBrowserNavigation);
                window.removeEventListener("beforeunload", unlockOnBrowserWindowClose);
            };
        }
    }, [
        dokuBoxId,
        props.readOnly,
        currentLockedDokuBoxId,
        setCurrentLockedDokuBoxId,
        selectedDokuBox,
        sendUnlockDokuBoxRequestFromBrowserWindowCloseHandler,
    ]);
    var handleAddOtherDoku = function (doku) {
        var addOtherDokuId = uniqueId("addOtherDokuId_");
        doku.addOtherDokuId = addOtherDokuId;
        selectedDokuBox.dokus.push({
            id: -new Date().getTime(),
            addOtherDokuId: addOtherDokuId,
            usedInBox: true,
            titleEn: doku.documentName,
            titleLocal: doku.documentName,
            instructionEn: doku.dokuDescription,
            instructionLocal: doku.dokuDescription,
            participants: [],
            dokuTransitions: [],
            dokuStaticActions: [],
            dokuAction: undefined,
            submitDokuStaticActions: [],
            dokuSetupCompleted: false,
            initiatedBy: "client",
            dokuFlowTypeId: 0,
            removed: false,
            reconfigured: false,
            documentTypeId: 0,
            unreadMessagesCount: 0,
            status: DokuStatus.WAITING_CLIENT_UPLOAD,
        });
        setOtherDokuList(__spreadArray(__spreadArray([], otherDokuList, true), [doku], false));
    };
    var undoAddOtherDoku = function (doku) {
        var modifiedDokuList = __spreadArray([], selectedDokuBox.dokus, true);
        var modifiedOtherDokuList = __spreadArray([], otherDokuList, true);
        var indexDokuList = modifiedDokuList.findIndex(function (value) { return value.addOtherDokuId && value.addOtherDokuId === doku.addOtherDokuId; });
        var indexOtherDokuList = modifiedOtherDokuList.findIndex(function (value) { return value.addOtherDokuId && value.addOtherDokuId === doku.addOtherDokuId; });
        if (indexDokuList >= 0 && indexOtherDokuList >= 0) {
            modifiedDokuList.splice(indexDokuList, 1);
            modifiedOtherDokuList.splice(indexOtherDokuList, 1);
            setSelectedDokuBox(__assign(__assign({}, selectedDokuBox), { dokus: modifiedDokuList }));
            setOtherDokuList(modifiedOtherDokuList);
        }
    };
    var submitDokuBoxCall = function () {
        var dokuActions = selectedDokuBox.dokus.map(function (doku) {
            return { dokuId: doku.id, dokuAction: doku.dokuAction };
        });
        var dokuStaticActions = [];
        var files = [];
        selectedDokuBox.dokus.forEach(function (doku) {
            if (doku.submitDokuStaticActions) {
                doku.submitDokuStaticActions.forEach(function (submitDokuStaticAction) {
                    var fileContent = submitDokuStaticAction.fileContent, staticActionNoFiles = __rest(submitDokuStaticAction, ["fileContent"]);
                    dokuStaticActions.push(staticActionNoFiles);
                    if (fileContent) {
                        files.push(fileContent);
                    }
                });
            }
        });
        var data = {
            dokuBoxId: selectedDokuBox.id,
            dokuActions: dokuActions,
            dokuStaticActions: dokuStaticActions,
        };
        var formData = new FormData();
        formData.append("request", new Blob([JSON.stringify(data)], {
            type: "application/json",
        }));
        files.forEach(function (file) { return formData.append("files", file); });
        var apiConfig = {
            data: formData,
        };
        executeAxios({
            params: __assign(__assign({}, bankerSubmitDokuBox), apiConfig),
            onSuccess: function () {
                if (dokuActions.some(function (value) { return value.dokuAction && DokuboxSubmitReloadActionTypes.includes(value.dokuAction); })) {
                    loadDokuBox();
                }
                else {
                    closeDokuBox();
                }
            },
            onLoading: setLoadingSubmit,
            onFail: function (resp) {
                var _a, _b;
                showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
                var dokuId = ((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) && resp.data.messageKey.startsWith("dde.fileupload.") && ((_b = resp.data.params) === null || _b === void 0 ? void 0 : _b.length) > 0 ? resp.data.params[0] || 0 : 0;
                setUploadFailed(new Map(uploadFailed.set(dokuId || 0, true)));
            }
        });
    };
    var saveOtherDokuList = function (otherDokusToSave, onSuccess) {
        var apiConfig = {
            data: {
                otherDokuList: otherDokusToSave,
                dokuBoxId: dokuBoxId,
            },
        };
        executeAxios({
            params: __assign(__assign({}, clientAddOtherDokuConfig), apiConfig),
            onSuccess: onSuccess,
            onLoading: setLoadingAddOtherDoku,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var validateSubmitDokubox = function () {
        var dokusExist = selectedDokuBox.dokus.filter(function (doku) { return doku.usedInBox; }).some(function (doku) { return !doku.removed; });
        if (selectedDokuBox.setupComplete && !dokusExist) {
            showErrorMessage({
                title: formatMessage("dde.error.errorTitle"),
                message: formatMessage("dde.error.missingDokus"),
            });
            return;
        }
        var hasFileError = false;
        selectedDokuBox.dokus.forEach(function (doku) {
            if (doku.usedInBox && doku.status === DokuStatus.WAITING_CLIENT_UPLOAD && !doku.fileName && !doku.removed) {
                hasFileError = true;
            }
        });
        if (hasFileError) {
            setIsFileMissingDokuBoxDlgVisible(true);
            return;
        }
        submitDokubox();
    };
    var submitDokubox = function () {
        isFileMissingDokuBoxDlgVisible && setIsFileMissingDokuBoxDlgVisible(false);
        if (otherDokuList.length > 0) {
            saveOtherDokuList(otherDokuList, function () {
                submitDokuBoxCall();
                setOtherDokuList([]);
            });
        }
        else {
            submitDokuBoxCall();
        }
    };
    var finishClientSigning = function () {
        executeAxios({
            params: {
                url: finishClientSigningConfig.url + dokuBoxId,
                method: finishClientSigningConfig.method,
            },
            onSuccess: function () {
                closeDokuBox();
            },
            onLoading: setLoadingSubmit,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var signEsaw = function () {
        executeAxios({
            params: {
                url: signEsawConfig.url + dokuBoxId,
                method: signEsawConfig.method,
            },
            onSuccess: function () {
                window[UNLOCK_ON_EXIT_FIELD_NAME] = false;
                navigate("/app/doku/waitfordokusign/" + dokuBoxId);
            },
            onLoading: setLoadingSubmit,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var signCustomApp = function () {
        executeAxios({
            params: {
                url: signCustomAppConfig.url + dokuBoxId,
                method: signCustomAppConfig.method,
            },
            onSuccess: function () {
                loadDokuBox();
            },
            onLoading: setLoadingSubmit,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var callSubmitDoku = function (doku, onSuccess, onFail) {
        setLoadingSubmit(true);
        var dokuActions = [{ dokuId: doku.id, dokuAction: doku.dokuAction }];
        var dokuStaticActions = [];
        var files = [];
        if (doku.submitDokuStaticActions) {
            doku.submitDokuStaticActions.forEach(function (submitDokuStaticAction) {
                var fileContent = submitDokuStaticAction.fileContent, staticActionNoFiles = __rest(submitDokuStaticAction, ["fileContent"]);
                dokuStaticActions.push(staticActionNoFiles);
                if (fileContent) {
                    files.push(fileContent);
                }
            });
        }
        var data = {
            dokuBoxId: selectedDokuBox.id,
            dokuActions: dokuActions,
            dokuStaticActions: dokuStaticActions,
        };
        var formData = new FormData();
        formData.append("request", new Blob([JSON.stringify(data)], {
            type: "application/json",
        }));
        files.forEach(function (file) { return formData.append("files", file); });
        var apiConfig = {
            data: formData,
        };
        executeAxios({
            params: __assign(__assign({}, bankerSubmitDokuBox), apiConfig),
            onSuccess: onSuccess,
            onLoading: setLoadingSubmit,
            onFail: onFail,
        });
    };
    var loadDoku = function (originalDokuId, reloadedDokuId) {
        loadDokuBox(function (reloadedDokuBox) {
            return replaceProcessedDokuObject(selectedDokuBox, reloadedDokuBox, originalDokuId, reloadedDokuId);
        });
    };
    var submitDoku = function (dokuParam) {
        var doku = selectedDokuBox.dokus.find(function (doku) { return doku.id === dokuParam.id; });
        if (doku) {
            if (doku.addOtherDokuId) {
                var modifiedOtherDokuList_1 = __spreadArray([], otherDokuList, true);
                var indexOtherDokuList = modifiedOtherDokuList_1.findIndex(function (value) { return value.addOtherDokuId && value.addOtherDokuId === doku.addOtherDokuId; });
                if (indexOtherDokuList >= 0) {
                    var otherDokuToSaveNow = modifiedOtherDokuList_1[indexOtherDokuList];
                    modifiedOtherDokuList_1.splice(indexOtherDokuList, 1);
                    saveOtherDokuList([otherDokuToSaveNow], function (createdIds) {
                        var _a;
                        doku.id = createdIds[0];
                        (_a = doku.submitDokuStaticActions) === null || _a === void 0 ? void 0 : _a.forEach(function (action) {
                            action.dokuId = createdIds[0];
                        });
                        setOtherDokuList(modifiedOtherDokuList_1);
                        callSubmitDoku(doku, function () {
                            setUploadFailed(new Map(uploadFailed.set(createdIds[0] || 0, false)));
                            loadDoku(doku.id || 0, createdIds[0]);
                        }, function (resp) {
                            showErrorMessage({
                                title: formatMessage("dde.error.errorTitle"),
                                message: formatServerError(resp, formatMessage),
                            });
                            setUploadFailed(new Map(uploadFailed.set(createdIds[0] || 0, true)));
                            loadDoku(doku.id || 0, createdIds[0]);
                        });
                    });
                }
                else {
                    console.error("Error: other doku not found by id: " + doku.addOtherDokuId);
                }
            }
            else {
                callSubmitDoku(doku, function () {
                    setUploadFailed(new Map(uploadFailed.set(doku.id || 0, false)));
                    loadDoku(doku.id || 0, doku.id || 0);
                }, function (resp) {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatServerError(resp, formatMessage),
                    });
                    setUploadFailed(new Map(uploadFailed.set(doku.id || 0, true)));
                });
            }
        }
    };
    var replaceProcessedDokuObject = function (originalDokuBox, reloadedDokuBox, originalDokuId, reloadedDokuId) {
        var result = cloneDeep(originalDokuBox);
        var indexOriginal = result.dokus.findIndex(function (value) { return value.id && value.id === originalDokuId; });
        var indexReloaded = reloadedDokuBox.dokus.findIndex(function (value) { return value.id && value.id === reloadedDokuId; });
        if (indexOriginal >= 0 && indexReloaded >= 0) {
            result.dokus[indexOriginal] = reloadedDokuBox.dokus[indexReloaded];
        }
        else {
            console.error("Error: reloaded doku not found.", originalDokuBox, reloadedDokuBox, originalDokuId, reloadedDokuId);
            return reloadedDokuBox;
        }
        return result;
    };
    var closeDokuBox = function () {
        unlockDokuBox(function () { }, clientUnlockDokuBox, String(dokuBoxId), function () {
            window[UNLOCK_ON_EXIT_FIELD_NAME] = false;
            navigate("/app/dokuboxlist");
        });
    };
    var closeDokuBoxOnCancel = function () {
        if (!props.readOnly) {
            unlockDokuBox(function () {
            }, clientUnlockDokuBox, String(dokuBoxId), function () {
                window[UNLOCK_ON_EXIT_FIELD_NAME] = false;
                navigate("/app/dokuboxlist");
            });
        }
        else {
            navigate("/app/dokuboxlist");
        }
    };
    var czOrSkTenant = function () {
        return tenant === "CZ" || tenant === "SK";
    };
    var rsTenant = function () {
        return tenant === "RS";
    };
    var bgTenant = function () {
        return tenant === "BG";
    };
    var hrTenant = function () {
        return tenant === "HR";
    };
    var roTenant = function () {
        return tenant === "RO";
    };
    var dokuVisible = function (doku) {
        var _a;
        return (doku.usedInBox &&
            (doku.dokuSetupCompleted ||
                (czOrSkTenant() || rsTenant() || bgTenant() || hrTenant() || roTenant() ? ((_a = doku.initiatedBy) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "client" : !doku.dokuDefinitionId)));
    };
    var resolveAndNavigateEsawUrl = function () {
        executeAxios({
            params: {
                url: getResolvedUrlConfig.url + dokuBoxId,
                method: getResolvedUrlConfig.method,
            },
            onSuccess: function (response) {
                if (response) {
                    window[UNLOCK_ON_EXIT_FIELD_NAME] = false;
                    window.location.href = response;
                }
                else {
                    showErrorMessage({
                        title: formatMessage("dde.error.errorTitle"),
                        message: formatMessage("dde.error.esaw.unavailable"),
                    });
                }
            },
            onLoading: setLoadingSubmit,
            onFail: function (resp) {
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatServerError(resp, formatMessage),
                });
            },
        });
    };
    var onFileChange = function (dokuId) {
        setUploadFailed(new Map(uploadFailed.set(dokuId, false)));
    };
    var bannerVisible = function () {
        var isDokuInStatus = false;
        if (selectedDokuBox.dokus) {
            selectedDokuBox.dokus.forEach(function (value) {
                if (value && value.status) {
                    if (dokuStates.includes(value.status)) {
                        isDokuInStatus = true;
                    }
                }
            });
        }
        console.log(isDokuInStatus);
        console.log(selectedDokuBox.clientSignatureSystem);
        if (selectedDokuBox.clientSignatureSystem === "ALL") {
            return isDokuInStatus && clientEsaw.includes(selectedDokuBox.clientSelectedSignatureSystem);
        }
        else {
            return isDokuInStatus && clientEsaw.includes(selectedDokuBox.clientSignatureSystem);
        }
    };
    return (_jsxs(Form, __assign({ form: form }, { children: [_jsx(PageTitleWrapperStyled, { children: _jsxs(MainContainerStyled, { children: [_jsx("div", { children: _jsx(Typography.Title, __assign({ className: "title", level: 2, style: { margin: 0 } }, { children: props.readOnly ? formatMessage("dde.client.manageDokuBox.view.Title") : formatMessage("dde.client.manageDokuBox.edit.Title") })) }), selectedDokuBox.dokus && (_jsx(Typography.Title, __assign({ className: "title", level: 3, style: { margin: 0 } }, { children: formatMessage("dde.manageDokuBox.title.DocumentsAvailable", {
                                noOfDocs: selectedDokuBox.dokus.filter(function (item) { return dokuVisible(item); }).length,
                            }) })))] }) }), _jsx(MainContainerStyled, { children: _jsxs("div", { children: [(bannerVisible() && isBannerVisibile) && (_jsx("div", __assign({ style: {
                                display: "flex",
                                flexDirection: "column"
                            } }, { children: _jsx(Banner, { title: _jsxs("span", { children: [formatMessage("dde.manageDokuBox.banner.title"), "  ", _jsx("b", { children: formatMessage("dde.manageDokuBox.banner.title.status") })] }), dismissible: true, withIcon: true, type: "info", onDismiss: function () { return setIsBannerVisible(false); } }) }))), selectedDokuBox && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { marginBottom: 20 } }, { children: _jsxs(Typography.Paragraph, __assign({ style: { margin: 0, fontSize: 20 } }, { children: [formatMessage("dde.manageDokuBox.title.label.DokuBox"), ": ", getByLocale(selectedDokuBox.titleEn, selectedDokuBox.titleLocal)] })) })), selectedDokuBox.dokus &&
                                    selectedDokuBox.dokus
                                        .filter(function (doku) { return dokuVisible(doku); })
                                        .map(function (doku, index) { return (_jsx(DokuCard, { form: form, doku: doku, dokuBox: selectedDokuBox, readOnly: props.readOnly, userType: UserType.CLIENT, onFinalize: function () { }, onUndoAddOtherDoku: undoAddOtherDoku, onSubmitUpload: submitDoku, closeDokuBox: closeDokuBox, onDokuActionSelected: function () { }, onFileChange: onFileChange, failedUpload: uploadFailed }, index)); }), _jsx("br", {})] })), _jsx("div", { children: !props.readOnly &&
                                (tenant != "HR" || showAddOtherDoku) &&
                                hasAnyOfTheRoles(["DOKU_ADD_OTHER"]) &&
                                selectedDokuBox &&
                                containsDokuBoxStaticAction(selectedDokuBox, DokuBoxStaticAction.AddOtherDoku) && (_jsxs(Button, __assign({ type: "text", onClick: function () { return setIsAddOtherDokuDlgVisible(true); } }, { children: [formatMessage("dde.dde.manageDokuBox.addOtherDokuBtn"), _jsx(ForthIcon, { style: { marginLeft: 10 } })] }))) }), _jsxs(FooterButtonsStyled, { children: [_jsx("div", __assign({ className: "left-side" }, { children: _jsx(Button, __assign({ type: "default", onClick: function () { return closeDokuBoxOnCancel(); } }, { children: formatMessage("dde.manageDokuBox.cancelBtn") })) })), !props.readOnly && (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "right-side" }, { children: [_jsx(Button, __assign({ type: "primary", onClick: function () { return validateSubmitDokubox(); } }, { children: formatMessage("dde.manageDokuBox.submitBtn") })), ((tenant === "RS" && isEsawSigningEnabled && !isUserCannotSignDlgVisible) || (tenant !== "RS" && (selectedDokuBox.esawViewerUrl || isEsawSigningEnabled))) &&
                                                selectedDokuBox.dokus.some(function (doku) { return doku.status === DokuStatus.WAITING_CLIENT_SIGNATURE; }) && (_jsx(Button, __assign({ type: "primary", onClick: function () {
                                                    if (selectedDokuBox.esawViewerUrl) {
                                                        window[UNLOCK_ON_EXIT_FIELD_NAME] = false;
                                                        window.location.href = selectedDokuBox.esawViewerUrl;
                                                    }
                                                    else {
                                                        resolveAndNavigateEsawUrl();
                                                    }
                                                } }, { children: formatMessage("dde.manageDokuBox.signBtn") }))), selectedDokuBox.everyDokuReadyForClientSigning &&
                                                selectedDokuBox.clientSignatureSystem === "ALL" &&
                                                !selectedDokuBox.clientSelectedSignatureSystem && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ type: "primary", onClick: function () { return signEsaw(); } }, { children: formatMessage("dde.manageDokuBox.signEsawBtn") })), _jsx(Button, __assign({ type: "primary", onClick: function () { return signCustomApp(); } }, { children: formatMessage("dde.manageDokuBox.signCustomAppBtn") }))] })), selectedDokuBox.finishClientSigningAvailable && (_jsx(Button, __assign({ type: "primary", onClick: function () { return finishClientSigning(); } }, { children: formatMessage("dde.manageDokuBox.finishSign") })))] })) }))] }), _jsx(UserCannotSignDialog, { isVisible: isUserCannotSignDlgVisible, setIsVisible: setIsUserCannotSignDlgVisible, onClose: function () { return setIsUserCannotSignDlgVisible(false); } }), _jsx(FileMissingDokuBoxDialog, { isVisible: isFileMissingDokuBoxDlgVisible, setIsVisible: setIsFileMissingDokuBoxDlgVisible, handleOk: submitDokubox }), _jsx(ClientAddingOtherDokuTenant, { isVisible: isAddOtherDokuDlgVisible, setIsVisible: setIsAddOtherDokuDlgVisible, addOtherDoku: handleAddOtherDoku, bankSignatureSystem: selectedDokuBox.bankSignatureSystem, clientSignatureSystem: selectedDokuBox.clientSignatureSystem, setupComplete: selectedDokuBox.setupComplete }), (loading || loadingLock || loadingSubmit || unlockLoading || loadingAddOtherDoku) && _jsx(Spinner, {})] }) })] })));
};
export default ClientManageDokuBox;
