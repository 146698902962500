var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Button, Radio } from "@g4p/react-styleguide-library";
import { Progress } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { Icon } from "../../banker/BankerManageDokuBox/styles";
export var HighlightBadge = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  background-color: #f55c5c;\n  border-radius: 50%;\n  color: white;\n  font-weight: bold;\n  width: 18px;\n  height: 18px;\n  right: -7.5px;\n  top: -10px;\n  z-index: 999;\n  font-size: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: absolute;\n  background-color: #f55c5c;\n  border-radius: 50%;\n  color: white;\n  font-weight: bold;\n  width: 18px;\n  height: 18px;\n  right: -7.5px;\n  top: -10px;\n  z-index: 999;\n  font-size: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var DokuPanelWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 20px;\n  width: 100%;\n  height: 100%;\n\n  .ant-upload.ant-upload-drag{\n    border: 3px dotted #CCCCCC;\n    border-radius: 5px;\n    background-color: #f5f5f5;\n    height: 85px;\n  }\n\n  ", ";\n"], ["\n  padding: 20px;\n  width: 100%;\n  height: 100%;\n\n  .ant-upload.ant-upload-drag{\n    border: 3px dotted #CCCCCC;\n    border-radius: 5px;\n    background-color: #f5f5f5;\n    height: 85px;\n  }\n\n  ", ";\n"])), function (props) { return props.$isDeleted
    ? "\n        background-color: #E7F8FB;\n        text-align: center;   \n        " :
    "\n        background-color: #FFFFFF;\n        "; });
export var UndoDeleteButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: black !important;\n  background-color: white !important;\n"], ["\n  color: black !important;\n  background-color: white !important;\n"])));
export var AccordionWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .ant-collapse-content-active {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow: visible;\n  }\n\n  .ant-collapse-content {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow: visible;\n  }\n\n"], ["\n  .ant-collapse-content-active {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow: visible;\n  }\n\n  .ant-collapse-content {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow: visible;\n  }\n\n"])));
export var AccordionContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n\n  .ant-collapse-content-active {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow:visible;\n  }\n  .ant-collapse-content {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow:visible;\n  }\n\n  \n  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {\n    right: 15px;\n    width: 20px !important;\n    margin-top: 1px;\n  }\n\n  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {\n      margin-left: auto;\n      margin-right: 15px;\n  }\n  \n  .ant-progress-success-bg, .ant-progress-bg {\n    background-color: #006375;\n  }\n  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {\n    flex: 7;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n\n  .ant-collapse-content-active {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow:visible;\n  }\n  .ant-collapse-content {\n    padding: 0 !important;\n    margin: 0 !important;\n    overflow:visible;\n  }\n\n  \n  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {\n    right: 15px;\n    width: 20px !important;\n    margin-top: 1px;\n  }\n\n  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {\n      margin-left: auto;\n      margin-right: 15px;\n  }\n  \n  .ant-progress-success-bg, .ant-progress-bg {\n    background-color: #006375;\n  }\n  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {\n    flex: 7;\n  }\n"])));
export var AccordionBox = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 48%;\n"], ["\n    width: 48%;\n"])));
export var ExtraButtonsWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-content: center;\n  height: 100%;\n  align-items: center;\n\n  .ant-collapse-extra {\n    display: flex !important;\n    align-content: center !important;\n    align-items: center !important;\n  }\n"], ["\n  display: flex;\n  align-content: center;\n  height: 100%;\n  align-items: center;\n\n  .ant-collapse-extra {\n    display: flex !important;\n    align-content: center !important;\n    align-items: center !important;\n  }\n"])));
export var SubTitleWrapperStyled = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  height: 30px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  height: 30px;\n"])));
export var ActionButtonWrapperStyled = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 5px;\n\tbutton.ant-btn-text {\n\t\tdisplay: block;\n\t\tflex: none;\n\t}\n\n\tbutton.ant-btn.ant-btn-primary span,\n\tbutton.ant-btn span {\n\t\twhite-space: normal;\n\t\tdisplay: block;\n\t\tflex: none;\n\t}\n"], ["\n  margin-top: 5px;\n\tbutton.ant-btn-text {\n\t\tdisplay: block;\n\t\tflex: none;\n\t}\n\n\tbutton.ant-btn.ant-btn-primary span,\n\tbutton.ant-btn span {\n\t\twhite-space: normal;\n\t\tdisplay: block;\n\t\tflex: none;\n\t}\n"])));
export var Description = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  overflow: hidden;\n"], ["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  overflow: hidden;\n"])));
export var StyledRadio = styled(Radio)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    .uds__radio__label {\n        color: black; \n        font-weight: bold;\n        text-transform: uppercase;\n        font-size: 14px;\n    }\n\n    &.ant-radio-wrapper-checked .uds__radio__label {\n        color: #007A91;\n    }\n"], ["\n    .uds__radio__label {\n        color: black; \n        font-weight: bold;\n        text-transform: uppercase;\n        font-size: 14px;\n    }\n\n    &.ant-radio-wrapper-checked .uds__radio__label {\n        color: #007A91;\n    }\n"])));
export var StyledPaperClipIcon = styled(PaperClipOutlined)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    padding-top: 8px;\n    svg {\n        height: 18px;\n        min-width: 18px;\n    }\n"], ["\n    padding-top: 8px;\n    svg {\n        height: 18px;\n        min-width: 18px;\n    }\n"])));
export var StyledDownloadIcon = styled(Icon)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    padding-top: 8px;\n    svg {\n        height: 20px;\n        min-width: 20px;\n    }\n"], ["\n    padding-top: 8px;\n    svg {\n        height: 20px;\n        min-width: 20px;\n    }\n"])));
export var StyledProgressBar = styled(Progress)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n\n  .ant-progress-bg {\n    background-color: #007A91;\n  }\n"], ["\n\n  .ant-progress-bg {\n    background-color: #007A91;\n  }\n"])));
export var DokuAdditionalDataDiv = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  .ant-form-item {\n    margin-bottom: 5px !important;\n  }\n  \n  .ant-form .ant-form-item {\n    margin-bottom: 5px !important;\n  }\n  \n  .ant-form-item-control-input-content {\n    margin-bottom: 6px !important;\n  }\n"], ["\n  .ant-form-item {\n    margin-bottom: 5px !important;\n  }\n  \n  .ant-form .ant-form-item {\n    margin-bottom: 5px !important;\n  }\n  \n  .ant-form-item-control-input-content {\n    margin-bottom: 6px !important;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
