var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { executeAxios, executeMultipleAxios, getQueryVariable } from "./axiosHook";
import { useLoadingPipeline } from "../helper/loadingPipeline";
import { lockAndAssignTask } from "../pages/admin/TaskListView/services/taskListApi";
import { UserType, useAppContext } from "./appContext";
import { getExternalParamFromLocalStorage, getLocaleFromLocalStorage, getLoggedInUserName, removeExternalParamFromLocalStorage, removeIgnorePortalBlockFromLocalStorage, setIgnorePortalBlockForCurrentDayInLocalStorage, } from "./appStorage";
import { useFormatMessage } from "./formatMessageHook";
import { useTranslationsContext } from "./translationsContext";
var configurationsCallConfig = { url: "/utilities/configurations/noauth?component=ddeFE" };
var translationsCallConfig = { url: "/translations/noauth?component=ddeFE&channel=SB_Remote" };
var cataloguesCallConfig = { url: "/dde/utilities/noauth/allcatalogue", method: "POST" };
var getLoggedInUserDetailsConfig = {
    url: "/dde/usermanagement/getLoggedInUserDetails",
    method: "GET",
};
var getExternalLinkData = { url: "/dde/settings/notification/getEntityForExternalAccess/", method: "GET" };
var portalStatusCallConfig = { url: "/utilities/noauth/cached/currentPortalStatuses", method: "POST" };
var saveClientUserLanguageConfig = {
    url: "/dde/usermanagement/client/saveClientUserLanguage",
    method: "POST",
};
var saveBankUserLanguageConfig = {
    url: "/dde/usermanagement/banker/saveBankUserLanguage",
    method: "POST",
};
var getTenantApiConfig = { url: "/utilities/tenant/noauth", method: "GET" };
var getRolesAndPermissions = {
    url: "/authorization/banker/getRolesAndPermissions",
    method: "GET",
};
var getClientRolesAndPermissions = {
    url: "/dde/usermanagement/client/noauth/getClientRolesAndPermissions",
    method: "GET",
};
var isCurrentUserLoggedInConfig = {
    url: "/dde/usermanagement/noauth/isCurrentUserLoggedIn",
    method: "GET"
};
export var removeAuthCookiesByServerCall = {
    url: "/dde/usermanagement/noauth/removeAuthCookies",
    method: "POST",
};
export function checkUserDetailsResponse(userDetails) {
    var loggedInUserName = getLoggedInUserName();
    return userDetails && userDetails.username && loggedInUserName && userDetails.username.toUpperCase() === loggedInUserName.toUpperCase();
}
export function getByLocale(textEn, textLocal) {
    return getLocaleFromLocalStorage().toUpperCase() === "EN" ? textEn : textLocal;
}
export function getByLocaleBG(tenant, textEn, textLocal) {
    if (tenant === "BG") {
        return getLocaleFromLocalStorage().toUpperCase() === "EN" ? textEn : textLocal;
    }
    else {
        return textEn;
    }
}
export function loadApplicationData(_a, user, userType, doNotCheckContractSigned) {
    var setError = _a.setError, handleLoading = _a.handleLoading, setData = _a.setData, setUser = _a.setUser, setPermissions = _a.setPermissions, setRoles = _a.setRoles, translateContent = _a.translateContent;
    function getResponseDataForApiUrlOrDefault(url, responseArray, defaultValue) {
        if (defaultValue === void 0) { defaultValue = undefined; }
        var response = responseArray.find(function (response) { return response.config && response.config.url === url; });
        return response ? response.data : defaultValue;
    }
    function successConfig(responseArray) {
        var responseMapped = {
            configurations: {},
            translations: {},
            catalogues: [],
            permissions: [],
            roles: [],
            portalStatus: {},
        };
        responseMapped.configurations = getResponseDataForApiUrlOrDefault(configurationsCallConfig.url, responseArray);
        responseMapped.catalogues = getResponseDataForApiUrlOrDefault(cataloguesCallConfig.url, responseArray);
        responseMapped.portalStatus = getResponseDataForApiUrlOrDefault(portalStatusCallConfig.url, responseArray);
        var translations = getResponseDataForApiUrlOrDefault(translationsCallConfig.url, responseArray);
        if (translations) {
            responseMapped.translations = translations;
        }
        setData(responseMapped);
    }
    function isCurrentUserLoggedIn() {
        executeAxios({
            params: __assign({}, isCurrentUserLoggedInConfig),
            onFail: setError,
            onLoading: handleLoading,
            onSuccess: function (response) {
                if (response) {
                    afterLoggedInUserDetailsLoaded(function (user) {
                        setUser(user);
                    }, handleLoading("user"), function () {
                        handleLoading("user")(true);
                    }, function () { }, false, doNotCheckContractSigned);
                }
            },
        });
    }
    var apisToBeCalled = [configurationsCallConfig, cataloguesCallConfig, portalStatusCallConfig];
    if (translateContent) {
        apisToBeCalled = apisToBeCalled.concat(translationsCallConfig);
    }
    executeMultipleAxios({
        promisesConfig: apisToBeCalled,
        onLoading: handleLoading("config"),
        onFail: setError,
        onSuccess: successConfig,
    });
    initPermissionsAndRoles(setPermissions, setRoles, userType, setError, handleLoading);
    console.log("appApi - afterLoggedInUserDetailsLoaded");
    console.log("appApi - user: ", user);
    console.log("appApi - userType: ", userType);
    isCurrentUserLoggedIn();
}
export function useAppInitialization(userType, skipContractSignedCheck) {
    var searchParams = useSearchParams()[0];
    var ignorePortalBlockParam = searchParams.get("ignoreportalblock");
    if (ignorePortalBlockParam === "true") {
        setIgnorePortalBlockForCurrentDayInLocalStorage();
    }
    else if (ignorePortalBlockParam === "false") {
        removeIgnorePortalBlockFromLocalStorage();
    }
    var _a = useLoadingPipeline(), loading = _a.loading, handleLoading = _a.handleLoading, loadingPipe = _a.loadingPipe;
    var _b = useState(false), error = _b[0], setError = _b[1];
    var _c = useState({
        configurations: {},
        translations: {},
        catalogues: [],
        portalStatus: {},
    }), data = _c[0], setData = _c[1];
    var _d = useState([]), permissions = _d[0], setPermissions = _d[1];
    var _e = useState([]), roles = _e[0], setRoles = _e[1];
    var _f = useState({}), user = _f[0], setUser = _f[1];
    var translateContent = useMemo(function () { return getQueryVariable("translation") !== "false"; }, []);
    var skipContractSignedCheckUrls = ["/oauth/authorize", "/app/contract/waitforprocessfinish"];
    var location = useLocation();
    useEffect(function () {
        loadApplicationData({
            setError: setError,
            setData: setData,
            setUser: setUser,
            setPermissions: setPermissions,
            setRoles: setRoles,
            handleLoading: handleLoading,
            translateContent: translateContent,
        }, user, userType, skipContractSignedCheckUrls.includes(location.pathname) ||
            (skipContractSignedCheck != undefined && skipContractSignedCheck));
    }, [handleLoading, translateContent, setUser]);
    return {
        loading: loading,
        data: data,
        user: user,
        permissions: permissions,
        roles: roles,
        error: error,
        authLoading: loadingPipe.auth,
    };
}
export function useTranslationsApi() {
    var setTranslations = useTranslationsContext().setTranslations;
    var setLanguage = useAppContext().setLanguage;
    return {
        fetchAndUpdateTranslations: useCallback(function (value) {
            return executeAxios({
                params: translationsCallConfig,
                onSuccess: function (response) {
                    setTranslations(response);
                    setLanguage(value);
                },
            });
        }, [setTranslations]),
    };
}
export function useCatalogues() {
    var catalogues = useAppContext().catalogues;
    var formatMessage = useFormatMessage().formatMessage;
    var getClientSignatureSystem = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "ClientSignatureSystem"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getClientSignatureType = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "ClientSignatureType"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getBankSignatureSystem = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "BankSignatureSystem"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getBankSignatureType = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "BankSignatureType"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getDokuBoxSearchStatus = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "DokuBoxSearchStatus"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getDokuSearchStatus = useCallback(function (tenant) {
        var cataloguesList = catalogues.catalogueList;
        var tenantCatalogueType = tenant === "CZ" || tenant === "SK" ? "DokuSearchStatus" : "DokuStatus";
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === tenantCatalogueType; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getTaskName = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "TaskName"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getTaskStatus = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "TaskStatus"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getProductCategoriesSegment = useCallback(function (segment) {
        var cataloguesList = catalogues.catalogueList;
        var productCategoryType = "ProductCategory" + segment;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === productCategoryType; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getProductCategories = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var productCategoryType = ["ProductCategoryMB", "ProductCategoryCORP", "ProductCategoryALL"];
        var catalogue = cataloguesList.filter(function (value) { return productCategoryType.includes(value.catalogueType); });
        if (catalogue && catalogue.length > 0) {
            var items_1 = [];
            catalogue.forEach(function (cat) {
                if (cat.catalogueItemList && cat.catalogueItemList.length > 0) {
                    cat.catalogueItemList.forEach(function (item) { return items_1.push(item); });
                }
            });
            return items_1;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var tryGetDeletedCategoryLabel = useCallback(function (segment, value) {
        var translationKey = "dde.catalogue.productcategory".concat(segment, ".").concat(value).toLowerCase();
        var formatted = formatMessage(translationKey);
        if (formatted && formatted !== translationKey) {
            return formatted;
        }
    }, [formatMessage]);
    var getProductTypesCategory = useCallback(function (category) {
        var cataloguesList = catalogues.catalogueList;
        var productType = "ProductType" + category;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === productType; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getUserRoles = useCallback(function () {
        var catalogoesList = catalogues.catalogueList;
        var catalogue = catalogoesList.find(function (value) { return value.catalogueType === "UserRoles"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getClientPersonRole = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "ClientRepCategoryType"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getDocumentTypes = useCallback(function () {
        var cataloguesList = catalogues.catalogueList;
        var catalogue = cataloguesList.find(function (value) { return value.catalogueType === "DocumentType"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    var getCompanyPosition = useCallback(function () {
        var catalogoesList = catalogues.catalogueList;
        var catalogue = catalogoesList.find(function (value) { return value.catalogueType === "CompanyPosition"; });
        if (catalogue) {
            return catalogue.catalogueItemList;
        }
        else {
            return [];
        }
    }, [catalogues]);
    return {
        getClientSignatureSystem: getClientSignatureSystem,
        getClientSignatureType: getClientSignatureType,
        getBankSignatureSystem: getBankSignatureSystem,
        getBankSignatureType: getBankSignatureType,
        getProductCategoriesSegment: getProductCategoriesSegment,
        getProductCategories: getProductCategories,
        tryGetDeletedCategoryLabel: tryGetDeletedCategoryLabel,
        getProductTypesCategory: getProductTypesCategory,
        getUserRoles: getUserRoles,
        getDokuBoxSearchStatus: getDokuBoxSearchStatus,
        getDokuSearchStatus: getDokuSearchStatus,
        getClientPersonRole: getClientPersonRole,
        getTaskName: getTaskName,
        getTaskStatus: getTaskStatus,
        getDocumentTypes: getDocumentTypes,
        getCompanyPosition: getCompanyPosition
    };
}
export function getDisplayName(userDetails) {
    if (userDetails.displayName) {
        return userDetails.displayName;
    }
    if (getLocaleFromLocalStorage() === "hu") {
        return ((userDetails.lastName || "") + " " + (userDetails.firstName || "")).trim();
    }
    if (getLocaleFromLocalStorage() && "BG" === getLocaleFromLocalStorage().toUpperCase()) {
        return ((userDetails.firstNameLocal || userDetails.firstName || "") + " " +
            (userDetails.lastNameLocal || userDetails.lastName || "")).trim();
    }
    return ((userDetails.firstName || "") + " " + (userDetails.lastName || "")).trim();
}
export function getDisplayRoles(formatMessage, roles) {
    return roles.map(function (r) { return formatMessage("dde.role." + r); });
}
export function getMainBankDisplayRole(formatMessage, roles) {
    if (roles && roles.length > 0) {
        return formatMessage("dde.role." + getMainBankRole(roles));
    }
    return undefined;
}
export function getMainBankRole(roles) {
    var priorityRoles = ["BANK_RM", "APPLICATION_ADMIN", "USER_ACCOUNT_ADMIN"];
    if (roles && roles.length > 0) {
        for (var _i = 0, priorityRoles_1 = priorityRoles; _i < priorityRoles_1.length; _i++) {
            var role = priorityRoles_1[_i];
            if (roles.includes(role)) {
                return role;
            }
        }
        return roles.at(0);
    }
    return undefined;
}
export function getDisplayClientNumber(userDetails, tenant) {
    return userDetails.clientNumber ? " \n" + getByLocaleBG(tenant, userDetails.clientName, userDetails.clientNameLocal) + " \n" : "\n";
}
export function getProfileIconDetails(userDetails, formatMessage, roles, tenant) {
    return getDisplayName(userDetails) + " " + getDisplayClientNumber(userDetails, tenant) + "(" + getDisplayRoles(formatMessage, roles) + ")";
}
export function initPermissionsAndRoles(setPermissions, setRoles, userType, setError, handleLoading) {
    var request;
    if (userType === UserType.BANKER) {
        request = getRolesAndPermissions;
    }
    if (userType === UserType.CLIENT) {
        request = getClientRolesAndPermissions;
    }
    executeAxios({
        params: __assign({}, request),
        onFail: setError,
        onLoading: handleLoading,
        onSuccess: function (response) {
            setPermissions(response.permissions);
            setRoles(response.roles);
        },
    });
}
export function afterLoggedInUserDetailsLoaded(setUser, onLoading, onFail, onSuccess, isLoginAction, doNotCheckContractSigned) {
    executeAxios({
        params: __assign(__assign({}, getLoggedInUserDetailsConfig), { params: { isLogin: isLoginAction } }),
        onFail: function (error) {
            setUser({});
            onFail(error);
        },
        onLoading: onLoading,
        onSuccess: function (userDetails) {
            if (checkUserDetailsResponse(userDetails)) {
                if (userDetails.userType === 'BANKER' || doNotCheckContractSigned || userDetails.contractSigned) {
                    setUser(userDetails);
                }
                onSuccess(userDetails.username);
            }
            else {
                setUser({});
                console.error("Failed to load user details");
                onFail();
            }
        },
    });
}
export function externalLinkPresent(navigate, username, setCurrentLockedTaskId) {
    var uuid = getExternalParamFromLocalStorage();
    if (uuid != null) {
        getExternalLinkData.url += uuid;
        executeAxios({
            params: __assign({}, getExternalLinkData),
            onSuccess: function (response) {
                if (response.notificationLinkAccess.dokuboxId) {
                    removeExternalParamFromLocalStorage();
                    navigate("/app/editdokubox/" + response.notificationLinkAccess.dokuboxId);
                }
                else if (response.notificationLinkAccess.taskId) {
                    removeExternalParamFromLocalStorage();
                    lockAndAssignTask(response.notificationLinkAccess.notificationType, response.notificationLinkAccess.taskId, setCurrentLockedTaskId, username, navigate);
                }
                else {
                    navigate("/app");
                }
            },
        });
    }
    return "";
}
export function getTenant(onSuccess) {
    executeAxios({
        params: __assign({}, getTenantApiConfig),
        onSuccess: function (response) {
            onSuccess(response);
        },
    });
}
export var saveClientUserLanguage = function () {
    executeAxios({
        params: __assign({}, saveClientUserLanguageConfig),
    });
};
export var saveBankUserLanguage = function () {
    executeAxios({
        params: __assign({}, saveBankUserLanguageConfig),
    });
};
var removeWindowOpenConfig = {
    url: "/dde/usermanagement/noauth/removeUserOpenedWindow",
    method: "GET",
};
export var removeWindowOpen = function () {
    executeAxios({
        params: __assign({}, removeWindowOpenConfig),
    });
};
