var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Spinner, Typography } from "@g4p/react-styleguide-library";
import { Alert } from "antd";
import { useEffect, useState } from "react";
import { executeAxios } from "../../../services/axiosHook";
import { useFormatMessage } from "../../../services/formatMessageHook";
import ExpandableList from "./ExpandableList/ExpandableList";
import { setResultsDataType } from "./services/domain";
import { useAppContext } from "../../../services/appContext";
import { useTenantContext } from "../../../services/tenantContext";
var ElectronicSignature = function (props) {
    var formatMessage = useFormatMessage().formatMessage;
    var tenant = useTenantContext().tenant;
    var _a = useState([]), dataSource = _a[0], setDataSource = _a[1];
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var _b = useState(), subTitle = _b[0], setSubTitle = _b[1];
    var _c = useState(false), loading = _c[0], setloading = _c[1];
    var _d = useState(""), errorMessage = _d[0], setErrorMessage = _d[1];
    var validSignatureQualifications = [
        'ADESIG', 'ADESIG_QC', 'QESIG'
    ];
    var configurations = useAppContext().configurations;
    var ddeNotValidCertificatesEnabled = (tenant === "HU" && configurations["ddeNotValidCertificatesEnabled_HU"]) || configurations["ddeNotValidCertificatesEnabled"];
    useEffect(function () {
        var _a;
        if ((props.isVisible === true && !loading && errorMessage) || (props.isVisible === null && ((_a = props.doku) === null || _a === void 0 ? void 0 : _a.id))) {
            var apiConfig = {
                url: "/dde/dokubox/doku/" + props.doku.id + "/validateSignature",
                method: "POST",
            };
            setDataSource([]);
            setErrorMessage("");
            executeAxios({
                params: __assign({}, apiConfig),
                onSuccess: function (response) {
                    var _a, _b, _c;
                    if (response && response.archivePossible &&
                        ((_c = (_b = (_a = response.validationResults) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b.signatures) === null || _c === void 0 ? void 0 : _c.some(function (signature) { var _a; return validSignatureQualifications.includes((_a = signature === null || signature === void 0 ? void 0 : signature.signatureInformation) === null || _a === void 0 ? void 0 : _a.signatureQualification); }))) {
                        console.log("valid doku");
                        props.setValidDoku(!props.doku.bankSignedDokuUploadNeeded);
                    }
                    else {
                        console.log("invalid doku");
                        if (ddeNotValidCertificatesEnabled) {
                            props.setValidDoku(true);
                        }
                        else {
                            props.setValidDoku(false);
                        }
                    }
                    var validationResults = response.validationResults;
                    setResultsDataType(validationResults, 2);
                    var subTitle;
                    if (validationResults.container) {
                        subTitle = {
                            key: 1,
                            label: validationResults.container.documents[0].fileId,
                            text: response.archivePossible ? "dde.electronicsignature.archivepossible.yes" : "dde.electronicsignature.archivepossible.no",
                        };
                    }
                    else if (validationResults.document) {
                        subTitle = {
                            key: 1,
                            label: validationResults.document.fileId,
                            text: response.archivePossible ? "dde.electronicsignature.archivepossible.yes" : "dde.electronicsignature.archivepossible.no",
                        };
                    }
                    else {
                        subTitle = {
                            key: 1,
                            label: "dde.electronicsignature.noValidationResults",
                            text: "",
                        };
                    }
                    setSubTitle(subTitle);
                    if (validationResults.children) {
                        setDataSource(validationResults.children);
                    }
                },
                onLoading: setloading,
                onFail: function (resp) {
                    var _a;
                    console.log("fail");
                    props.setValidDoku(false);
                    setErrorMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) ? formatMessage(resp.data.messageKey) : formatMessage("dde.error.generalError"));
                },
            });
        }
    }, [props.doku, props.setValidDoku, props.isVisible, formatMessage]);
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ title: formatMessage("dde.validateSignature.title"), open: isVisible === true, cancelButtonProps: {
                style: { visibility: "hidden" },
            }, onOk: function () { return setIsVisible(false); }, onCancel: function () { return setIsVisible(false); } }, { children: [loading && _jsx(Spinner, {}), !loading && errorMessage && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(Alert, { message: errorMessage, type: "error" })] })), !loading && !errorMessage && (_jsxs("div", { children: [_jsx(Typography.Title, __assign({ level: 5 }, { children: formatMessage("dde.electronicsignature.label.ValidationResults") })), _jsxs("div", __assign({ style: { display: "flex", justifyContent: "space-between", marginBottom: "20px", fontSize: "14px" } }, { children: [_jsx(Typography.Text, { children: subTitle === null || subTitle === void 0 ? void 0 : subTitle.label }), _jsx(Typography.Text, { children: "".concat((subTitle === null || subTitle === void 0 ? void 0 : subTitle.text) ? " " + formatMessage(subTitle.text) : "") })] })), !!dataSource ? _jsx(ExpandableList, { dataSource: dataSource }) : null] }))] })) }));
};
export default ElectronicSignature;
