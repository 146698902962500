import FullScreenLoader from "coredde/lib/components/FullScreenLoader";
import InternetApp from "coredde/lib/InternetApp";
import { TenantContextProvider } from "coredde/lib/services/tenantContext";
import InternetLoginRouterHU from "hu/lib/InternetLoginRouterHU";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

export const indexHuContent = (
	<StrictMode>
		<Suspense fallback={<FullScreenLoader />}>
			<BrowserRouter basename={process.env.REACT_APP_INTERNET_ROUTER_BASENAME ? process.env.REACT_APP_INTERNET_ROUTER_BASENAME + "HU" : ""}>
				<TenantContextProvider
					tenant={"HU"}
					tenantComponentsMap={{
						internetLoginRouter: InternetLoginRouterHU,
					}}
				>
					<InternetApp />
				</TenantContextProvider>
			</BrowserRouter>
		</Suspense>
	</StrictMode>
);

ReactDOM.render(indexHuContent, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
