var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Loader } from "@g4p/react-styleguide-library";
var FullScreenLoaderStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: fixed;\n\twidth: 100%;\n\theight: 100%;\n"], ["\n\tposition: fixed;\n\twidth: 100%;\n\theight: 100%;\n"])));
var FullScreenLoader = function () {
    return (_jsx(FullScreenLoaderStyled, { children: _jsx(Loader, {}) }));
};
export default FullScreenLoader;
var templateObject_1;
