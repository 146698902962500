export var clientLockDokuBox = {
    url: "/dde/dokubox/client/lockDokuBox",
    method: "POST",
};
export var clientUnlockDokuBox = {
    url: "/dde/dokubox/client/unlockDokuBox",
    method: "POST",
};
export var clientUnlockAllDokuboxesLockedByCurrentUser = {
    url: "/dde/dokubox/client/unlockAllDokuboxesLockedByCurrentUser",
    method: "POST",
};
export var getDokuBoxByIdApiConfig = {
    method: "GET",
    url: "/dde/dokubox/get",
};
export var clientAddOtherDokuConfig = {
    url: "/dde/dokubox/client/addOtherDoku",
    method: "POST",
};
export var finishClientSigningConfig = {
    url: "/dde/dokubox/finishClientSigning/",
    method: "POST",
};
export var signEsawConfig = {
    url: "/dde/dokubox/signEsaw/",
    method: "POST",
};
export var signCustomAppConfig = {
    url: "/dde/dokubox/signCustomApp/",
    method: "POST",
};
export var waitForDokuSignatures = {
    url: "/dde/dokubox/waitClientSigning",
    method: "post",
};
export var clientAutoCancelSigning = {
    url: "/dde/dokubox/autoCancelSigning",
    method: "POST",
};
export var getResolvedUrlConfig = {
    url: "/dde/dokubox/getResolvedUrl/",
    method: "POST",
};
