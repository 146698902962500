var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "antd/lib/typography";
import { PageHeaderStyled } from "./styles";
import imageBg from "../../assets/unicredit_banner.png";
import { Breadcrumb } from "@g4p/react-styleguide-library";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
export var PageHeader = function (props) {
    switch (props.variant) {
        case "bread-crumb":
            return (_jsx(PageHeaderStyled, { children: _jsxs("div", __assign({ className: "container" }, { children: [_jsx(Typography.Title, __assign({ className: "page-title", level: 2 }, { children: props.title })), props.subtitle && (_jsx("div", __assign({ style: { marginBottom: props.children ? 40 : undefined } }, { children: _jsx(Typography.Text, { children: _jsx(_Fragment, { children: props.subtitle }) }) }))), props.breadcrumbs && (_jsx(Breadcrumb, { children: props.breadcrumbs.map(function (breadcrumb) { return (_jsx(BreadcrumbItem, __assign({ href: breadcrumb.path }, { children: _jsx("span", { children: breadcrumb.label }) }))); }) })), props.children] })) }));
        case "image":
            return (_jsxs(PageHeaderStyled, __assign({ bg: imageBg }, { children: [_jsx("div", { className: "background-overlay" }), _jsxs("div", __assign({ className: "container" }, { children: [_jsx(Typography.Title, __assign({ className: "page-title", level: 1, style: { margin: 0 } }, { children: props.title })), props.children] }))] })));
        case "default":
        default:
            return (_jsx(PageHeaderStyled, __assign({ variant: "default" }, { children: _jsxs("div", __assign({ className: "container" }, { children: [_jsx(Typography.Title, __assign({ className: "page-title", level: 2, style: { margin: !props.subtitle && !props.children ? 0 : undefined } }, { children: props.title })), props.subtitle && (_jsx("div", __assign({ style: { marginBottom: props.children ? 40 : undefined } }, { children: _jsx(Typography.Text, { children: _jsx(_Fragment, { children: props.subtitle }) }) }))), props.children] })) })));
    }
};
